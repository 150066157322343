import { MantineTheme, createStyles } from "@mantine/core";

export const useStyles = createStyles((theme: MantineTheme) => ({
  valueContainer: {
    padding: "44px",
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    maxHeight: "750px",
  },
  shareholderDiv: {
    display: "flex",
    justifyContent: "space-evenly",
    width: "900px",
  },
}));
