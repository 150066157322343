import { createSlice } from "@reduxjs/toolkit";

const bizBorSlice = createSlice({
  name: "borBiz",
  initialState: {
    company: null,
    regNumber: null,
    legalStructure: null,
    typeOfInstitute: null,
    country: null,
    address: null,
    postal: null,
    website: null,
    turnover: null,
  },
  reducers: {
    setCompanyRedux: (state, { payload: { company } }) => {
      state.company = company;
    },
    setRegNumberRedux: (state, { payload: { regNumber } }) => {
      state.regNumber = regNumber;
    },
    setLegalStructureRedux: (state, { payload: { legalStructure } }) => {
      state.legalStructure = legalStructure;
    },
    setTypeOfInstituteRedux: (state, { payload: { typeOfInstitute } }) => {
      state.typeOfInstitute = typeOfInstitute;
    },
    setAddressRedux: (state, { payload: { address } }) => {
      state.address = address;
    },
    setCountryRedux: (state, { payload: { country } }) => {
      state.country = country;
    },
    setPostalRedux: (state, { payload: { postal } }) => {
      state.postal = postal;
    },
    setWebsiteRedux: (state, { payload: { website } }) => {
      state.website = website;
    },
    setTurnOverRedux: (state, { payload: { turnover } }) => {
      state.turnover = turnover;
    },
  },
});

export const {
  setCompanyRedux,
  setRegNumberRedux,
  setLegalStructureRedux,
  setTypeOfInstituteRedux,
  setCountryRedux,
  setPostalRedux,
  setWebsiteRedux,
  setTurnOverRedux,
  setAddressRedux,
} = bizBorSlice.actions;

export default bizBorSlice.reducer;
