import { FC, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import useLoanManagement from "../../../../hooks/useLoanManagement";
import { LoanManagementResponse } from "../../../../services/loanManagement";
import { LoanStatus } from "../../../../constant/enum";
import { toast } from "react-toastify";
import {
  MRT_ColumnDef,
  MantineReactTable,
  useMantineReactTable,
  MRT_RowSelectionState,
} from "mantine-react-table";
import { formatNumbersWithThousandSeperators } from "@funded-here-interface/common/src/Utils/formatter";
import { convertDate } from "@funded-here-interface/common/src/Utils/date";
import { isStringNumberZero } from "@funded-here-interface/common/src/Utils/string";
import { Button, color } from "@funded-here-interface/common";
import useGetDisbursementSummary from "../../../../hooks/useGetDisbursementSummary";
import { DisbursementSummaryResponse } from "../../../../services/getDisbursementSummary";
import { useDisclosure } from "@mantine/hooks";
import { Modal, Title } from "@mantine/core";
import DisbursementSummary from "../Modal/DisbursementSummary";
import useDisburseDistributorFunds from "../../../../hooks/useDisburseDistributorFunds";
import { useLocation } from "react-router-dom";
import { SkuLoanApplicationPaymentTerm } from "@funded-here-interface/common/src/constant/enum";
import useGetBulkDisbursementSummary from "../../../../hooks/useGetBulkDisbursementSummary";
import { BulkDisbursementSummaryResponse } from "../../../../services/getBulkDisbursementSummary";
import useBulkDisburseDistributorFunds from "../../../../hooks/useBulkDisburseDistributorFunds";
import { useStyle } from "./VerifyInvoice.styles";
import { downloadFile } from "@funded-here-interface/common/src/Utils/file";
import { FileType } from "@funded-here-interface/common/src/constant/enum";
import useDownloadDisbursementCSV from "../../../../hooks/useDownloadDisbursementsCSV";
import useGetSN from "../../../../hooks/useGetSN";

const Disbursement: FC = () => {
  const { classes } = useStyle();
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const token = useSelector((state: RootState) => state.auth.token);
  const useLoanManagementMutation = useLoanManagement();
  const [isLoading, setIsLoading] = useState(true);
  const [isDisbursingFundsLoading, setIsDisbursingFundsLoading] =
    useState(false);
  const [data, setData] = useState<LoanManagementResponse[]>([]);
  const useGetDisbursementSummaryMutation = useGetDisbursementSummary();
  const [opened, { open, close }] = useDisclosure(false);
  const useDisburseDistributorFundsMutation = useDisburseDistributorFunds();
  const [disbursementSummary, setDisbursementSummary] =
    useState<DisbursementSummaryResponse>();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const noteId: string | null = queryParams.get("noteId");
  const [noteDisplayId, setNoteDisplayId] = useState("");
  const useGetBulkDisbursementSummaryMutation = useGetBulkDisbursementSummary();
  const [openedBulk, modalAction] = useDisclosure(false);
  const [bulkDisbursementSummary, setBulkDisbursementSummary] =
    useState<BulkDisbursementSummaryResponse>();
  const snDetail = useGetSN(token, noteId!);

  const useBulkDisburseDistributorFundsMutation =
    useBulkDisburseDistributorFunds();
  const [isBulkDisburseEnabled, setIsBulkDisburseEnabled] = useState(false);
  const [optionPopUpFlag, setDisbursementOptionPopUp] = useState(false);
  const [IDs, appendIDs] = useState<string[]>([]);
  const [skuIdNums, setskuIdNumsSelected] = useState(0);
  const useDownloadDisbursementMutation = useDownloadDisbursementCSV();

  const fetchData = async () => {
    setIsLoading(true);

    useLoanManagementMutation.mutate(
      {
        token,
        type: LoanStatus.DISBURSEMENT,
        noteId: noteId ?? undefined,
      },
      {
        onSuccess: (data) => {
          setData(data);

          if (noteId) {
            data.forEach((item) => {
              if (item.pendingDistributorFundsDispersal) {
                setIsBulkDisburseEnabled(item.pendingDistributorFundsDispersal);
              }
            });
          }
          setIsLoading(false);
        },
        onError: (error) => {
          setIsLoading(false);
          toast.error((error as Error).message);
        },
      }
    );
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (noteId) {
      setNoteDisplayId(snDetail.data?.structuredNoteDisplayId ?? "");
      console.log(snDetail.data?.structuredNoteDisplayId);
    }
  }, [snDetail.data]);

  const getDisbursementSummary = async (skuId: string) => {
    useGetDisbursementSummaryMutation.mutate(
      { token, skuId },
      {
        onSuccess: (data) => {
          setDisbursementSummary(data);
          open();
        },
        onError: (error) => {
          toast.error((error as Error).message);
        },
      }
    );
  };

  const getBulkDisbursementSummary = async (noteId: string) => {
    useGetBulkDisbursementSummaryMutation.mutate(
      { token, noteId: parseInt(noteId) },
      {
        onSuccess: (data) => {
          setBulkDisbursementSummary(data);
          modalAction.open();
        },
        onError: (error) => {
          toast.error((error as Error).message);
        },
      }
    );
  };

  const confirmDisbursement = async () => {
    setIsDisbursingFundsLoading(true);
    useDisburseDistributorFundsMutation.mutate(
      {
        token,
        disbursementSummary: disbursementSummary as DisbursementSummaryResponse,
      },
      {
        onSuccess: () => {
          toast.success("Funds Disbursed");
          fetchData();
          close();
          setIsDisbursingFundsLoading(false);
        },
        onError: (error) => {
          toast.error((error as Error).message);
          setIsDisbursingFundsLoading(false);
          fetchData();
        },
      }
    );
  };

  const confirmBulkDisbursement = async (noteId: string) => {
    setIsDisbursingFundsLoading(true);
    useBulkDisburseDistributorFundsMutation.mutate(
      {
        token,
        noteId: parseInt(noteId),
      },
      {
        onSuccess: () => {
          toast.success("Funds Disbursed");
          fetchData();
          setIsBulkDisburseEnabled(false);
          modalAction.close();
          setIsDisbursingFundsLoading(false);
        },
        onError: (error) => {
          toast.error((error as Error).message);
          setIsDisbursingFundsLoading(false);
          fetchData();
        },
      }
    );
  };

  const displayDisburseFunds = (
    paymentTerm: SkuLoanApplicationPaymentTerm,
    pendingDistributorFundsDispersal: boolean,
    distributorPartialFundFirstAmountDispersed: string,
    distributorPartialFundSecondAmountDispersed: string,
    distributorFundAmountDispersed: string,
    skuId: string
  ) => {
    try {
      let htmlToDispay = null;

      switch (paymentTerm) {
        case SkuLoanApplicationPaymentTerm.CASH_ON_DELIVERY:
        case SkuLoanApplicationPaymentTerm.PAYMENT_ON_PURCHASE_ORDER:
          let enableDisbursementButton: boolean = false;
          let disbursementButtonText: string = "Pending";

          if (
            pendingDistributorFundsDispersal === false &&
            !isStringNumberZero(distributorFundAmountDispersed)
          ) {
            disbursementButtonText = "Disbursed";
          }

          if (pendingDistributorFundsDispersal === true) {
            enableDisbursementButton = true;
            disbursementButtonText = "Disburse";
          }

          htmlToDispay = (
            <div style={{ display: "flex", justifyContent: "left" }}>
              <Button
                onClick={() => {
                  getDisbursementSummary(skuId);
                }}
                backgroundColor={color.FHGREEN}
                textColor={color.WHITE}
                children={disbursementButtonText}
                width="30%"
                disabled={!enableDisbursementButton}
              />
            </div>
          );
          break;
        case SkuLoanApplicationPaymentTerm.PARTIAL_PAYMENT:
          let enableFirstDisbursementButton: boolean = false;
          let enableSecondDisbursementButton: boolean = false;
          let firstDisbursementButtonText: string = "Pending";
          let secondDisbursementButtonText: string = "Pending";

          if (pendingDistributorFundsDispersal === true) {
            if (
              isStringNumberZero(distributorPartialFundFirstAmountDispersed) &&
              isStringNumberZero(distributorPartialFundSecondAmountDispersed)
            ) {
              enableFirstDisbursementButton = true;
              firstDisbursementButtonText = "Disburse 1st";
            }
          } else {
            if (
              !isStringNumberZero(distributorPartialFundFirstAmountDispersed) &&
              !isStringNumberZero(distributorPartialFundSecondAmountDispersed)
            ) {
              secondDisbursementButtonText = "Disbursed 2nd";
            }
          }

          if (!isStringNumberZero(distributorPartialFundFirstAmountDispersed)) {
            firstDisbursementButtonText = "Disbursed 1st";

            if (
              pendingDistributorFundsDispersal === true &&
              isStringNumberZero(distributorPartialFundSecondAmountDispersed)
            ) {
              secondDisbursementButtonText = "Disburse 2nd";
              enableSecondDisbursementButton = true;
            }
          }

          htmlToDispay = (
            <div
              style={{
                display: "flex",
                width: "360px",
                justifyContent: "left",
              }}
            >
              <Button
                onClick={() => {
                  getDisbursementSummary(skuId);
                }}
                backgroundColor={color.FHGREEN}
                textColor={color.WHITE}
                children={firstDisbursementButtonText}
                width="45%"
                disabled={!enableFirstDisbursementButton}
              />
              <div style={{ marginLeft: "20px" }} />
              <Button
                onClick={() => {
                  getDisbursementSummary(skuId);
                }}
                backgroundColor={color.FHGREEN}
                textColor={color.WHITE}
                children={secondDisbursementButtonText}
                width="45%"
                disabled={!enableSecondDisbursementButton}
              />
            </div>
          );
          break;
      }

      return htmlToDispay;
    } catch (error) {
      return "-";
    }
  };

  const columns = useMemo<MRT_ColumnDef<LoanManagementResponse>[]>(
    () => [
      {
        accessorKey: "skuId",
        header: "SKU ID",
      },
      {
        accessorKey: "upc",
        header: "UPC",
      },
      {
        accessorKey: "orgName",
        header: "Org Name",
      },
      {
        accessorKey: "grossMargin",
        header: "Gross Margin",
        Cell: ({ cell }) => <p>{cell.getValue<string>() || "-"}</p>,
      },

      {
        accessorKey: "interest",
        header: "Interest",
        Cell: ({ cell, row }) => {
          const currency = row?.original?.loanApplicationCurrency || "";
          return (
            <p>
              {currency} {cell.getValue<number>() || "-"}
            </p>
          );
        },
      },
      {
        accessorKey: "rbp",
        header: "RBP",
        Cell: ({ cell }) => <p>{cell.getValue<string>() || "-"}</p>,
      },
      {
        accessorKey: "rbu",
        header: "RBU",
        Cell: ({ cell }) => <p>{cell.getValue<string>() || "-"}</p>,
      },
      {
        accessorKey: "loanAmount0",
        header: "Loan Amount 0",
        Cell: ({ row }) => {
          const currency = row?.original?.loanApplicationCurrency || "";
          const formattedLoanAmt0 = formatNumbersWithThousandSeperators(
            parseFloat(row?.original?.loanAmount0 || "0")
          );
          return <p>{`${currency} ${formattedLoanAmt0}`}</p>;
        },
      },
      {
        accessorKey: "loanAmount1",
        header: "Loan Amount 1",
        Cell: ({ row }) => {
          const currency = row?.original?.loanApplicationCurrency || "";
          const formattedLoanAmt1 = formatNumbersWithThousandSeperators(
            parseFloat(row?.original?.loanAmount1 || "0")
          );
          return <p>{`${currency} ${formattedLoanAmt1}`}</p>;
        },
      },
      {
        accessorKey: "loanAmount2",
        header: "Loan Amount 2",
        Cell: ({ row }) => {
          const currency = row?.original?.loanApplicationCurrency || "";
          const formattedLoanAmt2 = formatNumbersWithThousandSeperators(
            parseFloat(row?.original?.loanAmount2 || "0")
          );
          return <p>{`${currency} ${formattedLoanAmt2}`}</p>;
        },
      },
      {
        accessorKey: "adminFee",
        header: "Admin Fee",
        Cell: ({ cell, row }) => {
          const currency = row?.original?.loanApplicationCurrency || "";
          return (
            <p>
              {currency} {cell.getValue<number>() || "-"}
            </p>
          );
        },
      },
      {
        accessorKey: "transAndChannelFee",
        header: "Trans & Channel Fee",
        Cell: ({ cell, row }) => {
          const currency = row?.original?.loanApplicationCurrency || "";
          return (
            <p>
              {currency} {cell.getValue<number>() || "-"}
            </p>
          );
        },
      },
      {
        accessorKey: "thirdPartyFee",
        header: "Third Party Fee",
        Cell: ({ cell, row }) => {
          const currency = row?.original?.loanApplicationCurrency || "";
          return (
            <p>
              {currency} {cell.getValue<number>() || "-"}
            </p>
          );
        },
      },
      {
        accessorKey: "acquirerFees",
        header: "Acquirer Fees",
        Cell: ({ cell, row }) => {
          const currency = row?.original?.loanApplicationCurrency || "";
          return (
            <p>
              {currency} {cell.getValue<number>() || "-"}
            </p>
          );
        },
      },
      {
        accessorKey: "paymentTerm",
        header: "Payment Terms",
        Cell: ({ cell }) => <p>{cell.getValue<number>() || "-"}</p>,
      },
      {
        accessorKey: "distributorFundAmountDispersed",
        header: "Disbursement Amount",
        Cell: ({ row }) => {
          const currency = row?.original?.loanApplicationCurrency || "";
          const amount = formatNumbersWithThousandSeperators(
            parseFloat(row?.original?.distributorFundAmountDispersed || "0")
          );
          return <p>{`${currency} ${amount}`}</p>;
        },
      },
      {
        accessorKey: "distributorFundDispersedDate",
        header: "Disbursement Date",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? convertDate(cell.getValue<string>())
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "quantityOrderedPo",
        header: "QTY Ordered PO",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? formatNumbersWithThousandSeperators(cell.getValue<number>())
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "wmsQuantityDelivered",
        header: "WMS QTY",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? formatNumbersWithThousandSeperators(cell.getValue<number>())
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "shortfall",
        header: "Shortfall",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? formatNumbersWithThousandSeperators(cell.getValue<number>())
              : "0"}
          </p>
        ),
      },
      {
        accessorKey: "topUpAmount",
        header: "Top Up",
        Cell: ({ row }) => {
          const currency = row?.original?.loanApplicationCurrency || "";
          const amount = formatNumbersWithThousandSeperators(
            parseFloat(row?.original?.topUpAmount || "0")
          );
          return <p>{`${currency} ${amount}`}</p>;
        },
      },
      {
        accessorKey: "topUpAmountPaid",
        header: "Top Up Paid",
        Cell: ({ row }) => {
          const currency = row?.original?.loanApplicationCurrency || "";
          const amount = formatNumbersWithThousandSeperators(
            parseFloat(row?.original?.topUpAmountPaid || "0")
          );
          return <p>{`${currency} ${amount}`}</p>;
        },
      },
      {
        accessorKey: "preFinancingFees",
        header: "Prefinance",
        Cell: ({ row }) => {
          const currency = row?.original?.loanApplicationCurrency || "";
          const amount = formatNumbersWithThousandSeperators(
            parseFloat(row?.original?.preFinancingFees || "0")
          );
          return <p>{`${currency} ${amount}`}</p>;
        },
      },
      {
        accessorKey: "preFinancingFeesPaid",
        header: "Prefinance Paid",
        Cell: ({ row }) => {
          const currency = row?.original?.loanApplicationCurrency || "";
          const amount = formatNumbersWithThousandSeperators(
            parseFloat(row?.original?.preFinancingFeesPaid || "0")
          );
          return <p>{`${currency} ${amount}`}</p>;
        },
      },
      {
        accessorKey: "distributorPartialFundFirstAmountDispersed",
        header: "Split 1st Pmt",
        Cell: ({ row }) => {
          const currency = row?.original?.loanApplicationCurrency || "";
          const amount = formatNumbersWithThousandSeperators(
            parseFloat(
              row?.original?.distributorPartialFundFirstAmountDispersed || "0"
            )
          );
          return <p>{`${currency} ${amount}`}</p>;
        },
      },
      {
        accessorKey: "distributorPartialFundSecondAmountDispersed",
        header: "Split 2nd Pmt",
        Cell: ({ row }) => {
          const currency = row?.original?.loanApplicationCurrency || "";
          const amount = formatNumbersWithThousandSeperators(
            parseFloat(
              row?.original?.distributorPartialFundSecondAmountDispersed || "0"
            )
          );
          return <p>{`${currency} ${amount}`}</p>;
        },
      },
      {
        accessorKey: "distributorOrgName",
        header: "Supplier Name",
        Cell: ({ cell }) => <p>{cell.getValue<string>() || "-"}</p>,
      },
      {
        accessorKey: "distributorInvoiceVerificationStatus",
        header: "Invoice Verification",
        Cell: ({ cell }) => <p>{cell.getValue<string>() || "-"}</p>,
      },
      {
        accessorKey: "pendingDistributorFundsDispersal",
        header: "Disburse Funds",
        Cell: ({ row }) => (
          <div>
            {displayDisburseFunds(
              row?.original?.paymentTerm as SkuLoanApplicationPaymentTerm,
              row?.original?.pendingDistributorFundsDispersal as boolean,
              row?.original
                ?.distributorPartialFundFirstAmountDispersed as string,
              row?.original
                ?.distributorPartialFundSecondAmountDispersed as string,
              row?.original?.distributorFundAmountDispersed as string,
              row?.original?.skuId as string
            )}
          </div>
        ),
      },
    ],
    []
  );

  const table = useMantineReactTable({
    enableRowSelection: true,
    enableColumnActions: false,
    enableColumnFilters: true,
    paginationDisplayMode: "pages",
    onRowSelectionChange: setRowSelection,
    state: { rowSelection, isLoading },
    columns,
    data: data,
    initialState: { density: "xs" },
    mantineTableProps: {
      withBorder: true,
    },
  });

  useEffect(() => {
    const keys = Object.keys(rowSelection);
    setskuIdNumsSelected(keys.length);
  }, [rowSelection]);

  const validateSKUOption = () => {
    appendIDs([]);
    const keys = Object.keys(rowSelection);

    for (const key in data) {
      if (!keys.includes(key)) {
        continue;
      }

      const item = data[key];
      if (item?.pendingDistributorFundsDispersal === false) {
        toast.error("Pick SKU that has not been disbursed");
        return;
      } else {
        const id = item?.skuId?.toString();
        if (id) {
          appendIDs((prev) => [...prev, id]);
        }
      }
    }

    setDisbursementOptionPopUp(true);
    open();
  };

  const closeSelectedDisbursementPopUp = () => {
    close();
    setDisbursementOptionPopUp(false);
  };

  const getSelectedDisbursement = async (skuId: string) => {
    try {
      return await useGetDisbursementSummaryMutation.mutateAsync({
        token,
        skuId,
      });
    } catch (error) {
      throw error;
    }
  };

  const validateDisburse = async () => {
    var validFund: boolean = true;

    try {
      const summaries = await Promise.all(
        IDs.map((id) => getSelectedDisbursement(id))
      );

      summaries.forEach((disbursementSummary) => {
        if (
          disbursementSummary?.allowFundsDisbursement === false &&
          !disbursementSummary?.distributorBenefId
        ) {
          toast.error(
            "Unable to disburse funds, distributor's bank beneficiary account has not been set up correctly for " +
              disbursementSummary.skuId
          );
          validFund = false;
        }

        if (
          disbursementSummary?.allowFundsDisbursement === false &&
          !disbursementSummary?.externalUserId
        ) {
          toast.error(
            "Unable to disburse funds, merchant external user id has not been setup correctly for " +
              disbursementSummary.skuId
          );
          validFund = false;
        }

        if (!validFund) {
          close();
          return;
        }
      });

      if (validFund) {
        confirmSelectedDisburse(summaries);
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  const confirmSelectedDisburse = (
    summaries: DisbursementSummaryResponse[]
  ) => {
    summaries.forEach((disbursementSummary) => {
      setIsDisbursingFundsLoading(true);
      useDisburseDistributorFundsMutation.mutate(
        {
          token,
          disbursementSummary:
            disbursementSummary as DisbursementSummaryResponse,
        },
        {
          onSuccess: () => {
            fetchData();
            setIsDisbursingFundsLoading(false);
          },
          onError: (error) => {
            toast.error((error as Error).message);
            setIsDisbursingFundsLoading(false);
            fetchData();
          },
        }
      );
    });

    toast.success("Selected Funds Disbursed");
    close();
  };

  const handleDownloadCsv = () => {
    const filteredData = table
      .getFilteredRowModel()
      .rows.map((row) => row.original);
    let ls_ids = "";

    filteredData.forEach((key) => {
      ls_ids += key.skuId + ",";
    });

    let skuIds = ls_ids.substring(0, ls_ids.length - 1);

    useDownloadDisbursementMutation.mutate(
      { token, type: LoanStatus.DISBURSEMENT, skuIds },
      {
        onSuccess: (data: Blob) => {
          downloadFile(data, "SKU_Disburse", FileType.CSV);
        },
        onError: (e) => {
          toast.error((e as Error).message);
        },
      }
    );
  };

  return (
    <>
      {!optionPopUpFlag && (
        <Modal
          opened={opened}
          onClose={close}
          size="lg"
          title={
            <Title order={4} style={{ fontWeight: "bold" }}>
              Confirm Funds Disbursement to Supplier
            </Title>
          }
        >
          <DisbursementSummary
            isLoading={isDisbursingFundsLoading}
            disbursementSummary={disbursementSummary}
            confirmDisbursement={confirmDisbursement}
          />
        </Modal>
      )}

      {optionPopUpFlag && (
        <Modal
          opened={opened}
          onClose={closeSelectedDisbursementPopUp}
          size="lg"
          title={
            <Title order={4} style={{ fontWeight: "bold" }}>
              Selected SKU Disbursement Summary
            </Title>
          }
        >
          {
            <div style={{ padding: "0px 30px" }}>
              <p style={{ padding: "10px 0px" }}>
                Do you want to disburse the following SKU?
              </p>

              {IDs.map((e, idx) => (
                <p>
                  <span>
                    <strong>{idx + 1}.</strong>
                  </span>{" "}
                  {e}
                </p>
              ))}
              <div style={{ display: "flex" }}>
                <div style={{ margin: "10px auto" }}>
                  <Button
                    onClick={validateDisburse}
                    backgroundColor={color.FHGREEN}
                    textColor={color.WHITE}
                    children="Confirm"
                  ></Button>
                </div>
              </div>
            </div>
          }
        </Modal>
      )}

      {noteId && (
        <>
          <div
            style={{
              padding: "5px 0px",
            }}
          >
            <span>Displaying SKUs for Note ID: {noteDisplayId}</span>
            {isBulkDisburseEnabled === true && (
              <Button
                onClick={() => {
                  getBulkDisbursementSummary(noteId);
                }}
                backgroundColor={color.FHGREEN}
                textColor={color.WHITE}
                children="Bulk Disburse"
              ></Button>
            )}
          </div>

          <Modal
            opened={openedBulk}
            onClose={modalAction.close}
            size="lg"
            title={
              <Title order={4} style={{ fontWeight: "bold" }}>
                Confirm Bulk Funds Disbursement to Supplier
              </Title>
            }
          >
            <div>
              Do you want to proceed for disburse funds for all SKUs for Note ID{" "}
              {noteId}
            </div>
            {bulkDisbursementSummary?.allowBulkFundsDisbursement === true && (
              <div style={{ padding: "5px 0px" }}>
                Total disbursement amount:
                <div style={{ padding: "5px 0px" }}>
                  SGD {bulkDisbursementSummary?.totalAmountToDisburseInSgd}
                </div>
                <div>
                  {bulkDisbursementSummary?.otherCurrency}{" "}
                  {
                    bulkDisbursementSummary?.totalAmountToDisburseInOtherCurrency
                  }
                </div>
              </div>
            )}
            {bulkDisbursementSummary?.allowBulkFundsDisbursement === false && (
              <p style={{ padding: "5px 0px" }}>
                Unable to disburse funds, distributor's bank beneficiary account
                has not been set up correctly.
              </p>
            )}
            {bulkDisbursementSummary?.skusFundsDisbursementNotAllowed.length !==
              0 && (
              <div>
                <div>Unable to disburse funds for the following skus:</div>
                {bulkDisbursementSummary?.skusFundsDisbursementNotAllowed.map(
                  (sku) => (
                    <div>{sku}</div>
                  )
                )}
              </div>
            )}
            <div
              style={{
                padding: "5px 0px",
              }}
            >
              <Button
                onClick={() => {
                  confirmBulkDisbursement(noteId);
                }}
                backgroundColor={color.FHGREEN}
                textColor={color.WHITE}
                children="Confirm"
                width="20%"
                disabled={
                  bulkDisbursementSummary?.allowBulkFundsDisbursement === false
                }
              />
            </div>
          </Modal>
        </>
      )}

      {isBulkDisburseEnabled === false && (
        <div className={classes.buttonWrapper}>
          <Button
            onClick={validateSKUOption}
            backgroundColor={color.FHGREEN}
            textColor="#fff"
            children="Disburse"
            width="154px"
            disabled={skuIdNums === 0}
          />
          <Button
            onClick={handleDownloadCsv}
            backgroundColor={color.LOANTEXT}
            textColor="#fff"
            children="Download Disburse"
            width="154px"
          />
        </div>
      )}
      <MantineReactTable table={table} />
    </>
  );
};

export default Disbursement;
