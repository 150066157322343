import { MantineTheme, createStyles } from "@mantine/core";

export const useStyle = createStyles((theme: MantineTheme) => ({
  headerText: {
    width: 361,
    height: 46,
    top: 95,
    left: 259,
    fontSize: 24,
    fontWeight: 700,
    lineHeight: "normal",
    letterSpacing: "0em",
    textAlign: "left",
    fontStyle: "normal",
  },
}));
