import { FC, ReactNode } from "react";
import { useStyles } from "./ProductLayout.styles";

import { ToastContainer, toast } from "react-toastify";
import {
  Icon,
  IconCoin,
  IconFileStack,
  IconHome,
  IconNotebook,
  IconPennant,
  IconTruck,
} from "@tabler/icons-react";
import AdminNavBar, {
  IMenuItem,
} from "../../components/AdminNavBar/AdminNavBar";

interface IProps {
  content: ReactNode;
}

const menus: IMenuItem[] = [
  {
    label: "Dashboard",
    path: "/pm-dashboard/overview",
    icon: IconHome,
  },
  {
    label: "Campaign",
    submenu: [
      { label: "Create new", path: "/create-campaign" },
      { label: "Campaign List", path: "/campaign-list" },
    ],
    icon: IconPennant,
  },
  {
    label: "SKU",
    submenu: [
      { label: "Verify SKU Loans", path: "/verify-sku-loans" },
      { label: "Loan Details", path: "/loan-details" },
    ],
    icon: IconFileStack,
  },
  {
    label: "Note",
    submenu: [
      { label: "Create", path: "/structured-note/sku-pooling" },
      { label: "List", path: "/note-list" },
    ],
    icon: IconNotebook,
  },
  {
    label: "Issuing SPV",
    path: "/issuisng-spv",
    icon: IconTruck,
  },
  {
    label: "Virtual Accounts",
    path: "/virtual-accounts",
    icon: IconCoin,
  },
];

const ProductLayout: FC<IProps> = ({ content }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.mainContainer}>
      <AdminNavBar menus={menus} role="Product Manager" />
      <ToastContainer />

      <div className={classes.hero}>{content}</div>
    </div>
  );
};

export default ProductLayout;
