import { color } from "@funded-here-interface/common";
import { MantineTheme, createStyles } from "@mantine/core";

export const useStyles = createStyles((theme: MantineTheme) => ({
  mainContainer: {
    marginTop: "65px",
    display: "flex",
    flexDirection: "column",
  },
  rowContainer: {
    display: "flex",
    marginBottom: "20px",
  },
  childContainer: {
    flex: 1,
  },
  head: {
    color: color.ADMINHEADING,
    marginBottom: 6,
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
  },
  detailsDiv: {
    marginTop: "30px",
  },

  body: {
    color: color.ADMINHEADING,
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },
}));
