import { createStyles } from "@mantine/styles";

export const useStyles = createStyles(() => ({
  sideBarContainer: {
    width: "240px",
    height: "100vh",
    backgroundColor: "#000",
    position: "fixed",
    display: "flex",
    flexDirection: "column",
  },
  iconbox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  logo: {
    width: 170,
    marginTop: 56,
    marginLeft: 0,
  },
  link: {
    textDecoration: "none",
    color: "white",
    display: "flex",
    alignItems: "center",

    "&:hover": {
      backgroundColor: "#757575",
      color: "#FFFFFF",
      borderRadius: "10px",
    },
    "&[data-active]": {
      borderRadius: "10px",
      color: "#FFFFFF",
      backgroundColor: "#7D8CA3",
    },
  },
  scrollArea: {
    flex: 1,
    margin: "0 20px",
  },
  footer: {
    margin: "40px 20px",
    borderTop: "1px solid #FFFFFF",
  },
}));
