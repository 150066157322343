import { getApiResponseErrorMsg } from "@funded-here-interface/common/src/Utils/apiHelper";
import { BASE_URL } from "@funded-here-interface/common/src/constant/constant";
import axios from "axios";

export interface ApprovePassportPayload {
  token: string;
  clientId: string;
  userAddress: string;
  dvct: string;
  status: string;
  userId: number;
}

const approvePassport = async ({
  token,
  clientId,
  userAddress,
  dvct,
  status,
  userId,
}: ApprovePassportPayload) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/trade-directory/users/passport-approval`,
      { clientId, userAddress, dvct, status, userId },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    const message = getApiResponseErrorMsg(error);
    throw new Error(message);
  }
};
export default approvePassport;
