import { FC, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import useLoanManagement from "../../../../hooks/useLoanManagement";
import { LoanManagementResponse } from "../../../../services/loanManagement";
import { LoanStatus } from "../../../../constant/enum";
import { toast } from "react-toastify";
import {
  MRT_ColumnDef,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import {
  formatNumbersWithThousandSeperators,
  formatPercentageFromRatio,
} from "@funded-here-interface/common/src/Utils/formatter";

const LtvSf: FC = () => {
  const token = useSelector((state: RootState) => state.auth.token);
  const useLoanManagementMutation = useLoanManagement();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<LoanManagementResponse[]>([]);

  useEffect(() => {
    setIsLoading(true);
    useLoanManagementMutation.mutate(
      { token, type: LoanStatus.Ltv },
      {
        onSuccess: (data) => {
          setData(data);
          setIsLoading(false);
        },
        onError: (error) => {
          setIsLoading(false);
          toast.error((error as Error).message);
        },
      }
    );
  }, []);

  const columns = useMemo<MRT_ColumnDef<LoanManagementResponse>[]>(
    () => [
      {
        accessorKey: "skuId",
        header: "SKU ID",
      },
      {
        accessorKey: "orgName",
        header: "Org Name",
      },
      {
        accessorKey: "distributorOrgName",
        header: "Supplier Name", // Distributor / Supplier
      },
      {
        accessorKey: "availableCreditLine",
        header: "Available Credit Line",
        Cell: ({ row }) => {
          const currency = row?.original?.loanApplicationCurrency || "";
          const creditLine = formatNumbersWithThousandSeperators(
            parseFloat(row?.original?.availableCreditLine || "0")
          );
          return <p>{`${currency} ${creditLine}`}</p>;
        },
      },
      {
        accessorKey: "invoiceValue",
        header: "Invoice Value",
        Cell: ({ row }) => {
          const currency = row?.original?.loanApplicationCurrency || "";
          const creditLine = formatNumbersWithThousandSeperators(
            parseFloat(row?.original?.invoiceValue || "0")
          );
          return <p>{`${currency} ${creditLine}`}</p>;
        },
      },
      {
        accessorKey: "status",
        header: "Status",
      },
      {
        accessorKey: "loanApplicationRejectionReason",
        header: "Application Rejection Reason",
        Cell: ({ cell }) => <p>{cell.getValue<string>() || "-"}</p>,
      },
      {
        accessorKey: "loanToValuePercentage",
        header: "LTV %",
        Cell: ({ cell }) => (
          <p>{formatPercentageFromRatio(cell.getValue<string>()) + "%"}</p>
        ),
      },
      {
        accessorKey: "loanAmount2",
        header: "LTV Amount",
        Cell: ({ row }) => {
          const currency = row?.original?.loanApplicationCurrency || "";
          const creditLine = formatNumbersWithThousandSeperators(
            parseFloat(row?.original?.loanAmount2 || "0")
          );
          return <p>{`${currency} ${creditLine}`}</p>;
        },
      },
      {
        accessorKey: "interestRate",
        header: "Interest Rate",
        Cell: ({ cell }) => (
          <p>{formatPercentageFromRatio(cell.getValue<string>()) + "%"}</p>
        ),
      },
      {
        accessorKey: "tenure",
        header: "Tenure",
        Cell: ({ cell }) => <p>{cell.getValue<number>() || "-"}</p>,
      },
      {
        accessorKey: "moe",
        header: "QC",
        Cell: ({ row }) => {
          const currency = row?.original?.loanApplicationCurrency || "";
          const creditLine = formatNumbersWithThousandSeperators(
            parseFloat(row?.original?.moe || "0")
          );
          return <p>{`${currency} ${creditLine}`}</p>;
        },
      },
      {
        accessorKey: "sinkingFund",
        header: "SF",
        Cell: ({ row }) => {
          const currency = row?.original?.loanApplicationCurrency || "";
          const creditLine = formatNumbersWithThousandSeperators(
            parseFloat(row?.original?.sinkingFund || "0")
          );
          return <p>{`${currency} ${creditLine}`}</p>;
        },
      },
      {
        accessorKey: "additionalSinkingFund",
        header: "ASF",
        Cell: ({ row }) => {
          const currency = row?.original?.loanApplicationCurrency || "";
          const creditLine = formatNumbersWithThousandSeperators(
            parseFloat(row?.original?.additionalSinkingFund || "0")
          );
          return <p>{`${currency} ${creditLine}`}</p>;
        },
      },
    ],
    []
  );

  const table = useMantineReactTable({
    enableColumnActions: false,
    enableColumnFilters: false,
    paginationDisplayMode: "pages",
    state: { isLoading },
    columns,
    data: data,
    initialState: { density: "xs" },
    mantineTableProps: {
      withBorder: true,
    },
  });

  return <MantineReactTable table={table} />;
};

export default LtvSf;
