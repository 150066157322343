import { MantineTheme, createStyles } from "@mantine/core";

export const useStyles = createStyles((theme: MantineTheme) => ({
  valueContainerDoc: {
    padding: "44px",
  },
  DocContain: {
    display: "flex",
    marginTop: "30px",
    marginLeft: "30px",
  },
  DocText: {
    fontSize: "18px",
    width: "400px",
  },
  downloadIcon: {
    cursor: "pointer",
    display: "flex",
  },
  linkText: {
    color: "#338F86",
    cursor: "pointer",
    fontWeight: "bold",
    width: "100%",
  },
  docuSign: {
    height: "800px",
  },
  buttonContainer: {
    margin: "20px 50px",
    paddingBottom: "50px",
  },
  docviewer: {
    "#msdoc-renderer": {
      height: "450px",
    },
    "#pdf-controls": {
      display: "none",
    },
  },
  proceedButton: {
    backgroundColor: "#338F86",
    "&:hover": {
      backgroundColor: "#197a70",
    },
  },
}));
