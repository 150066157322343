import React, { useEffect, useState } from "react";
import investimg1 from "../../Images/Capture1.PNG";
import investimg2 from "../../Images/Capture.PNG";
import HomeServices from "../../../../services/HomeServices";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
// header missing

const InvestAmtSec = ({
  selectedInvestor,
  handleInvestorChange,
  investorOptions,
}) => {
  const [overViewData, setOverviewData] = useState([]);
  const token = useSelector((state) => state.auth.token);
  // console.log("vvkkksss",overViewData)

  // >>>Setting data in Loop dynamically<<<
  const loop = [
    {
      id: 1,
      head: "Total Asset",
      num: overViewData?.[0]?.totalAssets?.toLocaleString("en-US"),
    },
    {
      id: 2,
      head: "Active Notes Investment",
      num: overViewData?.[0]?.totalActiveInvestment?.toLocaleString("en-US"),
    },
    {
      id: 3,
      head: "Cash Balance",
      num: overViewData?.[0]?.cashBalance?.toLocaleString("en-US"),
    },
    { id: 4, head: "Effective ROR", num: overViewData?.[0]?.effectiveROR },
    // { id: 4, head: "SN Returns (in %)", num: "12.64%" },
  ];

  // >>>Setting data in Loop2 dynamically<<<
  const loop2 = [
    {
      id: 1,
      head: "Invested Notes Count",
      num: overViewData?.[0]?.investedSNCount,
    },
    {
      id: 2,
      head: "Active & Closed Notes Investment",
      num: overViewData?.[0]?.totalInvestment?.toLocaleString("en-US"),
    },
    {
      id: 3,
      head: "Portfolio Return",
      num: overViewData?.[0]?.portfolioReturns,
    },
  ];

  // >>>Fetching Investor Overview Summery Report Data<<<
  const fetchInvestorOverview = async () => {
    const res = await HomeServices.investorOverviewSummery(
      selectedInvestor.value,
      token
    );
    if (res && res?.status) {
      setOverviewData(res?.data);
    } else {
      toast.error("Unable to Fetch Overview Summery data");
    }
  };

  useEffect(() => {
    fetchInvestorOverview();
  }, [selectedInvestor]);

  return (
    <>
      <div className="invAmtSec">
        <div className="graphAmtDiv">
          <div className="graphInner">
            {loop.map((details, index) => {
              return (
                <div className="graphAmtDivInn" key={index}>
                  <h5 className="graphPoint">{details.head}</h5>
                  <p className="graphNum">{details.num}</p>
                </div>
              );
            })}
          </div>
          <figure className="graphamtFig">
            <img src={investimg1} alt="img" />
          </figure>
        </div>

        <div className="graphAmtDiv">
          <div className="graphInner">
            {loop2.map((details, index) => {
              return (
                <div className="graphAmtDivInn" key={index}>
                  <h5 className="graphPoint">{details.head}</h5>
                  <p className="graphNum">{details.num}</p>
                </div>
              );
            })}
          </div>
          <figure className="graphamtFig">
            <img src={investimg2} alt="img" />
          </figure>
        </div>
      </div>
    </>
  );
};

export default InvestAmtSec;
