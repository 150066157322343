import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import HomeServices from "../../../../services/HomeServices";
import toast from "react-hot-toast";
import { RiArrowDropDownLine } from "react-icons/ri";
import { useSelector } from "react-redux";

const PortfolioReturnChart = ({
  selectedInvestor,
  handleInvestorChange,
  investorOptions,
}) => {
  const [portfolioData, setPortfolioData] = useState([]);
  const [notesData, setNotesData] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [chartWidth, setChartWidth] = useState(0);
  const token = useSelector((state) => state.auth.token);

  const state = {
    portfolioSeries: [
      {
        name: "Portfolio Returns",
        data: portfolioData.map((data) => data.portfolioReturn),
      },
    ],
    notesSeries: [
      {
        name: "Notes Returns",
        data: notesData.map((data) => data.effectiveROR),
      },
    ],
    options: {
      chart: {
        type: "area",
        // height: 500,
        zoom: {
          enabled: false,
        },
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return value + "%";
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        lineCap: "butt",
        width: 2,
        colors: ["#efb5b9"],
      },
      fill: {
        colors: ["#f9e1e3"],
        opacity: 0.9,
        type: "solid",
        gradient: {
          shade: "dark",
          type: "horizontal",
          shadeIntensity: 0.5,
          gradientToColors: undefined,
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 100],
          colorStops: [],
        },
      },
      markers: {
        size: 3,
        colors: "#efb5b9",
        strokeColors: "#efb5b9",
        hover: {
          size: 3,
        },
      },
      title: {
        text: activeTab === 0 ? "Portfolio Returns" : "Notes Returns",
        align: "left",
        style: {
          fontSize: "13px",
          colors: "#595959",
        },
      },
      labels:
        activeTab === 0
          ? portfolioData.map((data) => data.date)
          : notesData.map((data) => data.date), // Sort the labels in increasing order
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
    },
  };

  const [showmenu, setShowmenu] = useState(false);

  const handClick = () => {
    setShowmenu(!showmenu);
  };

  const fetchPortfolioData = async (id) => {
    const res = await HomeServices.investorPortfolioReturn(id, token);
    if (res && res.status) {
      setPortfolioData(res.data);
    } else {
      toast.error("Error fetching portfolio data");
    }
  };

  const fetchNotesData = async (id) => {
    const res = await HomeServices.investorNotesReturn(id, token);
    if (res && res.status) {
      setNotesData(res.data);
    } else {
      toast.error("Error fetching notes data");
    }
  };

  useEffect(() => {
    const width = Math.max(portfolioData.length * 100, 600);
    setChartWidth(width);
  }, [portfolioData]);

  useEffect(() => {
    fetchPortfolioData(selectedInvestor.value);
    fetchNotesData(selectedInvestor.value);
  }, [selectedInvestor]);

  return (
    <>
      <div className="returnAnDiv">
        <Tabs onSelect={(index) => setActiveTab(index)}>
          <TabList>
            <Tab>
              Portfolio Returns
              <span className="portTooltip">Click here to follow link</span>
            </Tab>
            <Tab>
              Notes Returns
              <span className="portTooltip">Click here to follow link</span>
            </Tab>
            <div className="PortfolioDiv">
              <div className="merselectDiv">
                <div>
                  <button className="merselectBtn" onClick={handClick}>
                    {selectedInvestor.label}
                    <span className="snIconSpan">
                      <RiArrowDropDownLine size={"2em"} />
                    </span>
                  </button>
                  {showmenu && (
                    <div className="merSlctDrop">
                      {investorOptions.map((option) => (
                        <div key={option.value} className="inputDiv">
                          <input
                            type="radio"
                            checked={option.value === selectedInvestor.value}
                            onChange={() => handleInvestorChange(option)}
                          />
                          <p>{option.label}</p>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </TabList>
          <TabPanel>
            <div className="snApex">
              <ReactApexChart
                options={state.options}
                series={state.portfolioSeries}
                width={chartWidth}
                height={500}
                type="area"
              />
              <p className="apexVerticalTxt">Portfolio Returns</p>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="snApex">
              <ReactApexChart
                options={state.options}
                series={state.notesSeries}
                width={chartWidth}
                height={500}
                type="area"
              />
              <p className="apexVerticalTxt">Notes Returns</p>
            </div>
          </TabPanel>
        </Tabs>
        <p className="selectDroptext">*Select Investor from dropdown</p>
      </div>
    </>
  );
};

export default PortfolioReturnChart;
