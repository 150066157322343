import { useEffect, FC, useState } from "react";
import { Select, Tabs, Text } from "@mantine/core";
import { useStyles } from "./SkuLoanApplication.styles";
import { useNavigate, useParams } from "react-router-dom";
import SkuLoanApplicationNew from "./Tabs/SkuLoanApplicationNew";
import SkuLoanApplicationSubmitted from "./Tabs/Submitted";
import Review from "./Tabs/Review";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import useGetPendingSku from "@funded-here-interface/common/src/hooks/useGetPendingSku";
import { toast } from "react-toastify";

const SkuLoanApplication: FC = () => {
  const { classes } = useStyles();
  const { tabValue } = useParams();
  const [activeTab, setActiveTab] = useState(tabValue || "new");
  const token = useSelector((state: RootState) => state.auth.token);
  const { data, error, isLoading } = useGetPendingSku(token, undefined);
  const [uniqueMerchants, setUniqueMerchants] = useState<
    { email: string; orgRoleId: number }[]
  >([]);
  const [selectedMerchant, setSelectedMerchant] = useState<number>();

  useEffect(() => {
    if (data) {
      const filteredItems = Array.from(
        data.reduce((map, item) => {
          const key = `${item.email}-${item.orgRoleId}`;
          if (!map.has(key)) {
            map.set(key, { email: item.email, orgRoleId: item.orgRoleId });
          }
          return map;
        }, new Map())
      ).map(([, value]) => value);

      setUniqueMerchants(filteredItems);
    }
  }, [data]);

  if (error) {
    toast.error((error as Error).message);
  }

  const navigate = useNavigate();

  const handleTabChange = (value: string) => {
    setActiveTab(value);
    setSelectedMerchant(undefined);
  };

  const handleTabChangeReload = (value: string) => {
    navigate(`/sales-support/${value}`);
    window.location.reload();
  };

  useEffect(() => {
    navigate(`/sales-support/${activeTab}`);
  }, [activeTab]);

  const handleOnMerchantSelect = (email: string) => {
    const selectedMerchant = uniqueMerchants.find(
      (user) => user.email === email
    );

    if (selectedMerchant) {
      setSelectedMerchant(selectedMerchant.orgRoleId);
    } else {
      setSelectedMerchant(undefined);
    }
  };

  return (
    <div className={classes.loanApplicationContainer}>
      <Text className={classes.heading}>Loan Application</Text>
      <Tabs defaultValue={tabValue || "new"} onTabChange={handleTabChange}>
        <Tabs.List>
          <Tabs.Tab value="new">New</Tabs.Tab>
          <Tabs.Tab value="review">Review</Tabs.Tab>
          <Tabs.Tab value="submitted">Submitted</Tabs.Tab>
        </Tabs.List>
        <br />

        {activeTab === "new" && (
          <Tabs.Panel value="new">
            <SkuLoanApplicationNew
              onEvent={handleTabChangeReload}
              merchants={uniqueMerchants}
              data={data}
              isLoading={isLoading}
            />
          </Tabs.Panel>
        )}

        {activeTab === "review" && (
          <Tabs.Panel value="review">
            <div className={classes.select}>
              <Select
                label="Select merchant for PO review"
                placeholder="Select merchant"
                onChange={handleOnMerchantSelect}
                data={uniqueMerchants.map((user) => user.email)}
              />
            </div>
            {selectedMerchant && <Review orgRoleId={selectedMerchant} />}
          </Tabs.Panel>
        )}

        {activeTab === "submitted" && (
          <Tabs.Panel value="submitted">
            <SkuLoanApplicationSubmitted />
          </Tabs.Panel>
        )}
      </Tabs>
    </div>
  );
};

export default SkuLoanApplication;
