import { MantineTheme, createStyles } from "@mantine/core";

export const useStyle = createStyles((theme: MantineTheme) => ({
  csvTable: {
    width: "100%",
    marginTop: "30px",
  },
  th: {
    color: "#343a40",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "14.889px",
    paddingTop: "0px",
    paddingBottom: "0px",
    paddingLeft: "5px",
    paddingRight: "5px",
    borderBottom: "2px solid #dfdfdf",
  },
  thinside: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginBottom: "0px",
  },
  td: {
    paddingLeft: "5px",
    borderBottom: "0.6px solid #dfdfdf",
    paddingTop: "9px",
    paddingBottom: "9px",
    fontSize: "15px",
    color: "#6c757d",
    fontWeight: 400,
  },
  tr: {
    borderBottom: "1px solid #dfdfdf",
  },
  tableFlex: {
    display: "flex",
  },
}));
