import React, { FC, useEffect, useState } from "react";
import { useStyles } from "./review.styles";
import { Text } from "@mantine/core";
import usePoolingReview from "../../../hooks/usePoolingReview";
import { RootState } from "../../../store";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

interface SkuReviewProps {
  selectedRows: number[];
}

const Review: FC<SkuReviewProps> = ({ selectedRows }) => {
  const { classes } = useStyles();
  const [reviewData, setReviewData] = useState<any>();
  const token = useSelector((state: RootState) => state.auth.token);
  const mutation = usePoolingReview();
  const navigate = useNavigate();

  useEffect(() => {
    if (selectedRows.length === 0) {
      navigate("/structured-note/sku-pooling");

      return;
    }

    if (selectedRows.length > 0) {
      mutation.mutate(
        {
          token,
          data: selectedRows,
        },
        {
          onSuccess: async (data) => {
            setReviewData(data);
          },
          onError: (error) => {
            toast.error((error as Error).message);
          },
        }
      );
    }
  }, [selectedRows]);

  return (
    <div className={classes.mainContainer}>
      <div className={classes.rowContainer}>
        <div className={classes.childContainer}>
          <h1 className={classes.head} style={{ marginBottom: 5 }}>
            Summary
          </h1>
          <Text>Tenor: {reviewData?.tenor ?? "N/A"} days</Text>
          <Text>
            Merchant Interest rate: {reviewData?.merchantInterestRate ?? "N/A"}{" "}
            %
          </Text>

          <Text>
            Sr. Investors Interest rate:{" "}
            {reviewData?.seniorInterestRate ?? "N/A"}%
          </Text>
          <Text>
            Jr. Investors Interest rate:{" "}
            {reviewData?.juniorInterestRate ?? "N/A"}%
          </Text>
          <Text>Total Merchants: {reviewData?.totalBorrower ?? "N/A"}</Text>
          <Text>Total Number of SKU's: {reviewData?.numberOfSKU ?? "N/A"}</Text>
          <Text>Total Investor: {reviewData?.totalInvestor ?? "N/A"}</Text>
        </div>
        <div className={classes.childContainer}>
          <div className={classes.detailsDiv}>
            <h1 className={classes.head} style={{ marginBottom: 5 }}>
              Value
            </h1>
            <Text>Total SN value: {reviewData?.totalSNValue ?? "N/A"}</Text>
            <Text>Total QC: {reviewData?.totalMOE ?? "N/A"}</Text>
            <Text>Total SF: {reviewData?.totalSF ?? "N/A"}</Text>
            <Text>Total Additional SF: {reviewData?.totalASF ?? "N/A"}</Text>
            <Text>
              Total Float(QC+SF+ASF): {reviewData?.totalFloat ?? "N/A"}
            </Text>
          </div>
        </div>
      </div>
      <div className={classes.rowContainer}>
        <div className={classes.childContainer}>
          <h1 className={classes.head} style={{ marginBottom: 5 }}>
            Details
          </h1>
          <Text>Average RBU: {reviewData?.averageRBU ?? "N/A"}</Text>
          <Text>Average RBP: {reviewData?.averageRBP ?? "N/A"}</Text>
          <Text>
            Average Dynamic LTV: {reviewData?.averageDynamicLTV + "%" ?? "N/A"}
          </Text>
          <Text>
            Average SKU Loan size: {reviewData?.averageLoanSize ?? "N/A"}
          </Text>
          <Text>Average DOI: {reviewData?.averageDOI ?? "N/A"}</Text>
          <Text>Average GM: {reviewData?.averageGM + "%" ?? "N/A"}</Text>
        </div>
      </div>
    </div>
  );
};

export default Review;
