import { color } from "@funded-here-interface/common";
import { createStyles } from "@mantine/core";

export const useStyles = createStyles(() => ({
  DocContain: {
    display: "flex",
    marginTop: "20px",
    marginLeft: "30px",
  },
  subDoc: {
    fontSize: "18px",
    width: "400px",
    marginLeft: "20px",
  },
  header: {
    marginLeft: "30px",
    fontSize: "18px",
    fontWeight: 700,
    marginTop: "20px",
  },
  upload: {
    marginLeft: "35px",
    fontSize: "16px",
    fontWeight: 700,
    marginTop: "10px",
    color: color.FHGREEN,
    cursor: "pointer",
  },
  downloadIcon: {
    cursor: "pointer",
    display: "flex",
  },
}));
