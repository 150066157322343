import axios from "axios";
import { BASE_URL } from "@funded-here-interface/common/src/constant/constant";
import { getApiResponseErrorMsg } from "@funded-here-interface/common/src/Utils/apiHelper";

export interface VirtualAccountTransactionsResponse {
  receiverType: string;
  receiverVaAccountId: string;
  receiverAccountId: string;
  receiverRefId: string;
  receiverStructuredNoteId: string;
  receiverBalanceBefore: string;
  receiverBalanceAfter: string;

  senderType: string;
  senderVAAccountId: string;
  senderAccountId: string;
  senderRefId: string;
  senderStructuredNoteId: string;
  senderBalanceBefore: string;
  senderBalanceAfter: string;

  createdDate: string;
  currency: string;
  amount: string;
  otherCurrency: string;
  amountOtherCurrency: string;
  feesIncurred: string;
  remarks: string;
}

const getVirtualAccountsTransactions = async ({
  token,
}: {
  token: string;
}): Promise<VirtualAccountTransactionsResponse[]> => {
  try {
    const response = await axios.get(
      `${BASE_URL}/payment/ledger/getVATransactions`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      }
    );

    return response.data;
  } catch (error) {
    const message = getApiResponseErrorMsg(error);

    throw new Error(message);
  }
};

export default getVirtualAccountsTransactions;
