import { BASE_URL } from "@funded-here-interface/common/src/constant/constant";
import axios from "axios";
import { getApiResponseErrorMsg } from "@funded-here-interface/common/src/Utils/apiHelper";

type FundingLimitRequest = {
  token: string;
  creditLimit: string;
  orgRoleId: number;
};

const fundingLimit = async ({
  token,
  creditLimit,
  orgRoleId,
}: FundingLimitRequest) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/trade-directory/organization/funding-limit`,
      { creditLimit, orgRoleId },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      }
    );

    return response.data;
  } catch (e) {
    const message = getApiResponseErrorMsg(e);

    throw new Error(message);
  }
};

export default fundingLimit;
