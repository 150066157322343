import React, { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { MDBDataTable } from "mdbreact";
// import DataTable from "../../Component/DataTable";
import HomeServices from "../../../services/HomeServices";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

function SNSanalysis() {
  const [activeSnData, setActiveSnData] = useState([]);
  const [closedSnData, setClosedSnData] = useState([]);
  const token = useSelector((state) => state.auth.token);
  // console.log("smndfbjh843r8rub", closedSnData);

  //>>>Showing Active SN Data in Table<<<
  const ASNrows = activeSnData?.map((item, ind) => ({
    sn_id: item?.snId,
    loan_amnt: item?.loanAmount?.toLocaleString("en-US"),
    repaid_amnt: item?.repaidAmount?.toLocaleString("en-US"),
    balance_amnt: item?.balanceAmount?.toLocaleString("en-US"),
    maturity: item?.daysToMaturity < 0 ? 0 : item?.daysToMaturity,
    default: item?.default,
  }));
  const data = {
    columns: [
      {
        label: "NOTE ID",
        field: "sn_id",
        sort: "asc",
      },

      {
        label: "Loan Amount",
        field: "loan_amnt",
        sort: "asc",
      },
      {
        label: "Repaid Amount",
        field: "repaid_amnt",
        sort: "asc",
      },
      {
        label: "Balance Amount",
        field: "balance_amnt",
        sort: "asc",
      },
      {
        label: "Days to Maturity",
        field: "maturity",
        sort: "asc",
      },
      {
        label: "Default (%)",
        field: "default",
        sort: "asc",
      },
    ],
    rows: ASNrows,
  };

  //>>>Showing Closed SN Data in Table<<<
  const CSNrows = closedSnData?.map((item, ind) => ({
    sn_id: item?.snId,
    loan_amnt: item?.loanAmount?.toLocaleString("en-US"),
    sector: item?.sector,
    country: item?.country,
    return_prcnt: item?.effectiveROR,
  }));
  const datatwo = {
    columns: [
      {
        label: "NOTE ID",
        field: "sn_id",
        sort: "asc",
      },

      {
        label: "Loan Amount",
        field: "loan_amnt",
        sort: "asc",
      },
      {
        label: "Sector",
        field: "sector",
        sort: "asc",
      },
      {
        label: "Country",
        field: "country",
        sort: "asc",
      },
      {
        label: "Returns (in %)",
        field: "return_prcnt",
        sort: "asc",
      },
    ],
    rows: CSNrows,
  };

  // >>> Fetchi Active SN Data<<<
  const fetchActiveSN = async () => {
    const res = await HomeServices.snActive(token);
    if (res && res?.status) {
      setActiveSnData(res?.data);
      // console.log("SnActive2343",res?.data)
    } else {
      toast.error("Unable to Fetch Active SN Data");
    }
  };

  // >>> Fetchi Closed SN Data<<<
  const fetchClosedSN = async () => {
    const res = await HomeServices.snClosed(token);
    if (res && res?.status) {
      setClosedSnData(res?.data);
      // console.log("SnActive2343",res?.data)
    } else {
      toast.error("Unable to Fetch Closed SN Data");
    }
  };

  useEffect(() => {
    fetchActiveSN();
    fetchClosedSN();
  }, []);

  return (
    <div className="sns_analysis">
      <Tabs>
        <TabList>
          <Tab onClick={fetchActiveSN}> Active Notes</Tab>
          <Tab onClick={fetchClosedSN}> Closed Notes</Tab>
        </TabList>

        <TabPanel>
          <div className="">
            <p className="invTabPara">Active Notes Analysis</p>
            <div className="mt-2">
              <MDBDataTable
                bordered
                entries={10000}
                hover
                data={data}
                noBottomColumns={true}
                small
                stripes
                selectRows={true}
                className="text-center"
              />
            </div>
          </div>
        </TabPanel>

        <TabPanel>
          <div className="">
            <p className="invTabPara">Closed SNs Analysis</p>
            <div className="mt-2">
              <MDBDataTable
                bordered
                entries={10000}
                hover
                data={datatwo}
                noBottomColumns={true}
                small
                stripes
                selectRows={true}
                className="text-center"
              />
            </div>
          </div>
        </TabPanel>
      </Tabs>
    </div>
  );
}

export default SNSanalysis;
