import axios from "axios";
import { BASE_URL } from "@funded-here-interface/common/src/constant/constant";
import { getApiResponseErrorMsg } from "@funded-here-interface/common/src/Utils/apiHelper";

interface ApproveSkuLoansRequest {
  token: string;
  skuIds: string[];
}

export interface ApprovedSkusLoanResponse {
  groupedSkusByResult: ApprovedSkuStatusSummary;
  numberOfApprovedSkus: number;
  numberOfRejectedSkus: number;
}

interface ApprovedSkuStatusSummary {
  [key: string]: {
    rejectionReason: string;
    skuId: string;
  }[];
}

const approveSkuLoans = async ({
  token,
  skuIds,
}: ApproveSkuLoansRequest): Promise<ApprovedSkusLoanResponse> => {
  try {
    const response = await axios.post(
      `${BASE_URL}/sku-finance/loan-application/pm-verify-sku-loan-applications`,
      { skuIds },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      }
    );

    return response.data;
  } catch (error) {
    const message = getApiResponseErrorMsg(error);

    throw new Error(message);
  }
};

export default approveSkuLoans;
