import { FC, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import useLoanManagement from "../../../../hooks/useLoanManagement";
import { LoanManagementResponse } from "../../../../services/loanManagement";
import { LoanStatus } from "../../../../constant/enum";
import { toast } from "react-toastify";
import {
  MRT_ColumnDef,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import { formatNumbersWithThousandSeperators } from "@funded-here-interface/common/src/Utils/formatter";

const Application: FC = () => {
  const token = useSelector((state: RootState) => state.auth.token);
  const useLoanManagementMutation = useLoanManagement();
  const [isLoading, setisLoading] = useState(true);
  const [data, setData] = useState<LoanManagementResponse[]>([]);

  useEffect(() => {
    setisLoading(true);
    useLoanManagementMutation.mutate(
      { token, type: LoanStatus.APPLICATION },
      {
        onSuccess: (data) => {
          setData(data);
          setisLoading(false);
        },
        onError: (error) => {
          setisLoading(false);
          toast.error((error as Error).message);
        },
      }
    );
  }, []);

  const columns = useMemo<MRT_ColumnDef<LoanManagementResponse>[]>(
    () => [
      {
        accessorKey: "skuId",
        header: "SKU ID",
      },
      {
        accessorKey: "upc",
        header: "UPC",
      },
      {
        accessorKey: "orgName",
        header: "Org Name",
      },
      {
        accessorKey: "distributorOrgName",
        header: "Supplier Name", // Distributor / Supplier
      },
      {
        accessorKey: "status",
        header: "Status",
      },
      {
        accessorKey: "availableCreditLine",
        header: "Available Credit Line",
        Cell: ({ row }) => {
          const currency = row?.original?.loanApplicationCurrency || "";
          const creditLine = formatNumbersWithThousandSeperators(
            parseFloat(row?.original?.availableCreditLine || "0")
          );
          return <p>{`${currency} ${creditLine}`}</p>;
        },
      },
      {
        accessorKey: "invoiceValue",
        header: "Invoice Value",
        Cell: ({ cell }) => <p>{cell.getValue<string>() || "-"}</p>,
      },
      {
        accessorKey: "doi",
        header: "DOI",
        Cell: ({ cell }) => <p>{cell.getValue<string>() || "-"}</p>,
      },
      {
        accessorKey: "grossMargin",
        header: "Gross Margin",
        Cell: ({ cell }) => <p>{cell.getValue<string>() || "-"}</p>,
      },
      {
        accessorKey: "skuReturn",
        header: "SKU Return",
        Cell: ({ cell }) => <p>{cell.getValue<string>() || "-"}</p>,
      },
      {
        accessorKey: "skuSalesVolume",
        header: "SKU Sales Volume",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<number>()
              ? formatNumbersWithThousandSeperators(cell.getValue<number>())
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "productSalesVolume",
        header: "Product Sales Volume",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<number>()
              ? formatNumbersWithThousandSeperators(cell.getValue<number>())
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "highestOrderedQuantityFromPastRecords",
        header: "Highest Ordered Quantity",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<number>()
              ? formatNumbersWithThousandSeperators(cell.getValue<number>())
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "paymentTerm",
        header: "Payment Terms",
        Cell: ({ cell }) => <p>{cell.getValue<string>() || "-"}</p>,
      },
    ],
    []
  );

  const table = useMantineReactTable({
    enableColumnActions: false,
    enableColumnFilters: false,
    paginationDisplayMode: "pages",
    state: { isLoading },
    columns,
    data: data,
    initialState: { density: "xs" },
    mantineTableProps: {
      withBorder: true,
    },
  });

  return <MantineReactTable table={table} />;
};

export default Application;
