import axios from "axios";
import { BASE_URL } from "@funded-here-interface/common/src/constant/constant";
import { getApiResponseErrorMsg } from "@funded-here-interface/common/src/Utils/apiHelper";
import { OcrType } from "../constant/enum";

interface OcrUploadRequest {
  token: string;
  file: File;
  type: OcrType;
  orgRoleId: number;
}

const ocrUpload = async ({
  token,
  file,
  type,
  orgRoleId,
}: OcrUploadRequest) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/document-management/ocr/upload`,
      { file, type, orgRoleId },
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      }
    );

    return response.data;
  } catch (e) {
    const message = getApiResponseErrorMsg(e);
    throw new Error(message);
  }
};
export default ocrUpload;
