export const convertDate = (
  originalDateValue: string,
  format: string = "YYYY-MM-DD"
) => {
  const originalDate = new Date(originalDateValue);

  // // Extract year, month, and day components
  // const year = originalDate.getFullYear() % 100; // Get the last two digits of the year
  // const month = originalDate.getMonth() + 1; // Month is zero-based, so add 1
  // const day = originalDate.getDate();

  // // Format the components in the desired format
  // const formattedDate = `${day.toString().padStart(2, "0")}/${month
  //   .toString()
  //   .padStart(2, "0")}/${year.toString().padStart(2, "0")}`;
  // return formattedDate;
  const yearFull = originalDate.getFullYear().toString();
  const yearShort = yearFull.substr(-2);
  const month = (originalDate.getMonth() + 1).toString().padStart(2, "0");
  const day = originalDate.getDate().toString().padStart(2, "0");

  const replacements: { [key: string]: string } = {
    YYYY: yearFull,
    YY: yearShort,
    MM: month,
    DD: day,
  };

  return format.replace(/YYYY|YY|MM|DD/g, (match) => replacements[match]);
};
