import { KYCStatus } from "@funded-here-interface/common/src/constant/enum";

export const getKycColor = (kyc: string): string => {
  switch (kyc) {
    case KYCStatus.APPROVED:
      return "green";
    case KYCStatus.PENDING:
      return "orange";
    case KYCStatus.REJECTED:
      return "red";
    default:
      return "green";
  }
};
