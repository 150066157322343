import { useRef, useState, ChangeEvent, FC } from "react";
import {
  InputGroup,
  LargeBtn,
  InputSelect,
} from "@funded-here-interface/common";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useStyle } from "./CreateCampaign.styles";
import { countries } from "@funded-here-interface/common/src/constant/country";
import useDownloadCampaignCSV from "../../hooks/useDownloadCampaignCSV";
import { downloadFile } from "@funded-here-interface/common/src/Utils/file";
import { FileType } from "@funded-here-interface/common/src/constant/enum";
import useCreateCampaign from "../../hooks/useCreateCampaign";

const CreateCampaign: FC = () => {
  const { classes } = useStyle();
  const [campaignname, setCampaignName] = useState("");
  const [country, setCountry] = useState("");
  const [productdescription, setProductDescription] = useState("");
  const [startdate, setStartDate] = useState("");
  const [enddate, setEndDate] = useState("");
  const [industry, setIndustry] = useState("");
  const [principle, setPrinciple] = useState("");
  const [activeSection, setActiveSection] = useState("form");
  const useDownloadCampaignCSVMutation = useDownloadCampaignCSV();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const token = useSelector((state: RootState) => state.auth.token);
  const useCreateCampaignMutation = useCreateCampaign();

  const handleFileClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      setSelectedFile(file);
      toast.success("File Uploaded");
    }
  };

  const handleSubmit = async () => {
    if (!selectedFile) {
      toast.error("No file selected");
      return;
    }

    const formData = new FormData();

    formData.append("name", campaignname);
    formData.append("country", country);
    formData.append("startDate", startdate);
    formData.append("endDate", enddate);
    formData.append("industry", industry);
    formData.append("principalManufacturer", principle);
    formData.append("productDescription", productdescription);
    formData.append("csv", selectedFile);

    setIsLoading(true);
    useCreateCampaignMutation.mutate(
      { token, formData },
      {
        onSuccess: () => {
          setIsLoading(false);
          navigate("/campaign-list");
        },
        onError: (e) => {
          setIsLoading(false);
          toast.error((e as Error).message);
        },
      }
    );
  };
  const handleDownload = async () => {
    useDownloadCampaignCSVMutation.mutate(
      { token },
      {
        onSuccess: (data: Blob) => {
          downloadFile(data, "Campaign CSV Template", FileType.CSV);
        },
        onError: (e) => {
          toast.error((e as Error).message);
        },
      }
    );
  };

  const handleNext = () => {
    let newErrors = {
      industry: "",
      country: "",
      productdescription: "",
      startdate: "",
      enddate: "",
      campaignname: "",
      principle: "",
    };

    let isValid = true;

    if (!industry) {
      isValid = false;
      newErrors.industry = "Industry is required";
    }

    if (!campaignname) {
      isValid = false;
      newErrors.campaignname = "Campaign Name is required";
    }

    if (!productdescription) {
      isValid = false;
      newErrors.productdescription = "Product Description is required";
    }

    if (!country) {
      isValid = false;
      newErrors.country = "Country is required";
    }
    if (!startdate) {
      isValid = false;
      newErrors.startdate = "Start Date is required";
    }

    if (!enddate) {
      isValid = false;
      newErrors.enddate = "End Date is required";
    }

    if (!principle) {
      isValid = false;
      newErrors.principle = "Principle/Manufacturer is required";
    }

    setError(newErrors);

    if (isValid) {
      setActiveSection("csv");
    }
  };

  const [error, setError] = useState({
    industry: "",
    country: "",
    startdate: "",
    enddate: "",
    productdescription: "",
    campaignname: "",
    principle: "",
  });

  const renderFormSection = () => {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "86%",
            marginTop: "20px",
          }}
        >
          <div style={{ width: "50%", marginRight: 60 }}>
            <label> Campaign Name</label>

            <InputGroup
              isErrored={error.campaignname}
              onChange={(value: any) => {
                error.campaignname = "";
                setCampaignName(value);
              }}
              labelText=""
              value={campaignname}
              id="campaignname"
            />
          </div>
          <div style={{ width: "50%" }}>
            <label> Industry</label>
            <InputGroup
              isErrored={error.industry}
              onChange={(value: any) => {
                error.industry = "";
                setIndustry(value);
              }}
              labelText=""
              value={industry}
              id="industry"
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "86%",
          }}
        >
          <div style={{ width: "50%", marginRight: 60 }}>
            <label> Country</label>
            <InputSelect
              isErrored={error?.country}
              onChange={(value: string) => {
                error.country = "";
                setCountry(value);
              }}
              labelText=""
              id="country"
              value={country}
              data={countries}
            />
          </div>
          <div style={{ width: "50%" }}>
            <label> Product Description</label>
            <InputGroup
              isErrored={error.productdescription}
              onChange={(value: any) => {
                error.productdescription = "";
                setProductDescription(value);
              }}
              labelText=""
              value={productdescription}
              id="productdescription"
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "80%",
          }}
        >
          <div style={{ width: "50%", marginRight: 80 }}>
            <label> Start Date</label>

            <InputGroup
              isErrored={error.startdate}
              onChange={(value: any) => {
                error.startdate = "";
                setStartDate(value);
              }}
              labelText=""
              value={startdate}
              id="startdate"
              type="date"
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "80%",
          }}
        >
          <div style={{ width: "50%", marginRight: 80 }}>
            <label> End Date</label>

            <InputGroup
              isErrored={error.enddate}
              onChange={(value: any) => {
                error.enddate = "";
                setEndDate(value);
              }}
              labelText=""
              value={enddate}
              id="enddate"
              type="date"
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "80%",
          }}
        >
          <div style={{ width: "50%", marginRight: 80 }}>
            <label> Principle/Manufacturer</label>

            <InputGroup
              isErrored={error.principle}
              onChange={(value: any) => {
                error.principle = "";
                setPrinciple(value);
              }}
              labelText=""
              value={principle}
              id="principle"
            />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "start",
            marginTop: "10px",
          }}
        >
          <LargeBtn
            onClick={handleNext}
            backgroundColor="#3786D6"
            textColor="#FFFFFF"
            width="auto"
          >
            Next
          </LargeBtn>
        </div>
      </>
    );
  };

  const renderCSVSection = () => {
    return (
      <div style={{ marginTop: "20px" }}>
        <p>
          1.{" "}
          <img
            style={{ marginRight: 10, marginLeft: 10, cursor: "pointer" }}
            src="https://blogger.googleusercontent.com/img/a/AVvXsEjXBv0AUJK3r5hFNKcVyNBJLVnZItWLzA0NizyZW8LKC3HIFXaL1rrJUyOb9OwZiMYfw6FpeqUuyIt7ex5WCIPNcZRpTACNcUdp1luCNhTszOHIqB1zwzyiGcVYgAlqrlyNaQDVpcpa3yfH8bwwqxxsfvTQvTCwP8iCBSrrVBL-9Ttus9K3A9rGm3xKP1c"
            alt=""
            onClick={handleDownload}
          />
          <span style={{ fontWeight: 700 }} onClick={handleDownload}>
            {" "}
            Download CSV template
          </span>
        </p>
        <p style={{ marginTop: "10px" }}>
          2.{" "}
          <span style={{ marginRight: 10, marginLeft: 10 }}>
            {" "}
            Enter Supplier and Merchant (preferably the authorised
            representatives contact details){" "}
          </span>
        </p>

        <p style={{ marginTop: "10px" }}>
          3.{" "}
          <img
            style={{ marginRight: 5, marginLeft: 3, cursor: "pointer" }}
            src="https://blogger.googleusercontent.com/img/a/AVvXsEhXgY6Ufx_X5AaicDYqyUWzYbXBYWNa-_W82W72HTdNhatOJS-XgEoIDH5LimhFvKyJi8B5xJpHYHPIJ9ukwVpFig7pGYtx4ji5xk6omsu61RAjqYzCeXhgJaxSUnSiXEXGxGUzFRexM56GFlCN2VD39V81-U2u8YyXz_ohVS3jCRLsPj5pnQgITqFYJfs"
            alt=""
            onClick={handleFileClick}
          />
          <span style={{ fontWeight: 700 }} onClick={handleFileClick}>
            Upload CSV
          </span>
        </p>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={handleFileChange}
          accept=".csv"
        />

        {selectedFile && (
          <p
            style={{ marginTop: "10px" }}
          >{`Uploaded File: ${selectedFile.name}`}</p>
        )}

        <div
          style={{
            display: "flex",
            justifyContent: "start",
            marginTop: "20px",
          }}
        >
          <div style={{ marginRight: "20px" }}>
            <LargeBtn
              disabled={!selectedFile || isLoading}
              onClick={handleSubmit}
              backgroundColor="#3786D6"
              textColor="#FFFFFF"
              width="auto"
            >
              Submit
            </LargeBtn>
          </div>
          <LargeBtn
            onClick={() => {
              setActiveSection("form");
            }}
            backgroundColor="transparent"
            textColor="#338F86"
            border="1px solid #338F86"
            width="auto"
          >
            Back
          </LargeBtn>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div>
        <h1 className={classes.headerText}>Create New Campaign</h1>
      </div>
      <div style={{ display: "flex", marginTop: "10px", alignItems: "center" }}>
        <div style={{ marginRight: 40 }}>
          <h1 className={classes.heading}>All Details</h1>
          {activeSection === "form" && <div className={classes.uline}></div>}
        </div>
        <div>
          {activeSection === "csv" ? (
            <>
              <h1 className={classes.heading}>Upload CSV</h1>
              <div className={classes.uline}></div>
            </>
          ) : (
            <p>Upload CSV</p>
          )}
        </div>
      </div>

      {activeSection === "form" && renderFormSection()}
      {activeSection === "csv" && renderCSVSection()}
    </div>
  );
};

export default CreateCampaign;
