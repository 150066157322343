import { MantineTheme, createStyles } from "@mantine/core";

export const useStyles = createStyles((theme: MantineTheme) => ({
  container: {
    marginTop: "37.5px",
    display: "flex",
    width: "100%",
    height: "500px",
  },

  childContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  seperator: {
    width: "50px",
  },

  chartTitle: {
    fontWeight: 700,
    marginBottom: "10px",
  },
}));
