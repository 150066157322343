import React, { useEffect, useState } from "react";
import HomeServices from "../../../../services/HomeServices";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

const MerDataSec = ({ selectedMerchant }) => {
  const [merchantData, setMerchantData] = useState([]);
  const token = useSelector((state) => state.auth.token);

  const loop = [
    {
      id: 1,
      head: "Funding Limit",
      num: merchantData?.[0]?.fundingLimit?.toLocaleString("en-US"),
    },
    {
      id: 2,
      head: "Loan Amount",
      num: merchantData?.[0]?.loanAmount?.toLocaleString("en-US"),
    },
    {
      id: 3,
      head: "Repaid Amount",
      num: merchantData?.[0]?.repaidAmount?.toLocaleString("en-US"),
    },
    {
      id: 4,
      head: "Balance Amount",
      num: merchantData?.[0]?.balanceAmount?.toLocaleString("en-US"),
    },
  ];

  const loop2 = [
    {
      id: 1,
      head: "Total Sales Proceed",
      num: merchantData?.[0]?.totalSalesProceed?.toLocaleString("en-US"),
    },
    {
      id: 2,
      head: "Sales Proceed After Repayment(SPAR)",
      num: merchantData?.[0]?.spar?.toLocaleString("en-US"),
    },
    {
      id: 3,
      head: "SPAR Units Sold",
      num: merchantData?.[0]?.sparUnitsSold?.toLocaleString("en-US"),
    },
    {
      id: 4,
      head: "SPAR Units Not Yet Sold",
      num: merchantData?.[0]?.sparUnitsUnsold?.toLocaleString("en-US"),
    },
  ];

  const loop3 = [
    {
      id: 1,
      head: "MoE",
      num: merchantData?.[0]?.moePaid?.toLocaleString("en-US"),
    },
    { id: 2, head: "SF", num: merchantData?.[0]?.sf?.toLocaleString("en-US") },
    {
      id: 3,
      head: "ASF",
      num: merchantData?.[0]?.asf?.toLocaleString("en-US"),
    },
  ];

  const fetchingMerchantData = async () => {
    try {
      const res = await HomeServices.getMerchantData(
        selectedMerchant.value,
        token
      );
      if (res && res.status) {
        setMerchantData(res.data);
      } else {
        toast.error("Unable To Fetch Merchant Data");
      }
    } catch (error) {
      toast.error("Unable To Fetch Merchant Data");
    }
  };

  useEffect(() => {
    fetchingMerchantData(selectedMerchant.value);
  }, [selectedMerchant]);

  return (
    <>
      <div className="graphAmtSec">
        <div className="graphAmtDiv">
          <div className="graphInner">
            {loop.map((details, index) => {
              return (
                <div className="graphAmtDivInn" key={index}>
                  <h5 className="graphPoint">{details.head}</h5>
                  <p className="graphNum">{details.num}</p>
                </div>
              );
            })}
          </div>
        </div>

        <div className="graphAmtDiv">
          <div className="graphInner">
            {loop2.map((details, index) => {
              return (
                <div className="graphAmtDivInn" key={index}>
                  <h5 className="graphPoint">{details.head}</h5>
                  <p className="graphNum">{details.num}</p>
                </div>
              );
            })}
          </div>
        </div>

        <div className="graphAmtDiv">
          <div className="graphInner">
            {loop3.map((details, index) => {
              return (
                <div className="graphAmtDivInn" key={index}>
                  <h5 className="graphPoint">{details.head}</h5>
                  <p className="graphNum">{details.num}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default MerDataSec;
