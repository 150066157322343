import { FC } from "react";
import { toast } from "react-toastify";
import useGetNoteRepayment from "../../../../hooks/useGetNoteRepayment";
import { RootState } from "../../../../store";
import { useSelector } from "react-redux";
import { useStyle } from "./Note.styles";
import { SKUDetails } from "../../../../services/getNoteRepayment";
import { formatNumbersWithThousandSeperators } from "@funded-here-interface/common/src/Utils/formatter";

interface IProps {
  noteId: string;
}

const Note: FC<IProps> = ({ noteId }) => {
  const { classes } = useStyle();
  const token = useSelector((state: RootState) => state.auth.token);
  const { data, error } = useGetNoteRepayment(token, noteId);

  if (error) {
    toast.error((error as Error).message);
  }

  const dataOrder = [
    { label: "Acquirer Fees", key: "acquirerFees" as keyof SKUDetails },
    { label: "FH Fee", key: "fhAdminFee" as keyof SKUDetails },
    {
      label: "FH Additional Fee",
      key: "fhAddAdminFee" as keyof SKUDetails,
    },
    { label: "Int Difference", key: "intDiff" as keyof SKUDetails },
    {
      label: "Senior Principal",
      key: "seniorInvestorPrincipal" as keyof SKUDetails,
    },
    {
      label: "Senior Interest",
      key: "seniorInvestorInterest" as keyof SKUDetails,
    },
    {
      label: "Senior Additional Interest",
      key: "seniorInvestorAddInterest" as keyof SKUDetails,
    },
    {
      label: "Junior Principal",
      key: "juniorInvestorPrincipal" as keyof SKUDetails,
    },
    {
      label: "Junior Interest",
      key: "juniorInvestorInterest" as keyof SKUDetails,
    },
    {
      label: "Junior Additional Interest",
      key: "juniorInvestorAddInterest" as keyof SKUDetails,
    },
    { label: "SPAR (Merchant)", key: "spar" as keyof SKUDetails },
  ];

  return (
    <table className={classes.tableStyle}>
      <thead>
        <tr className={classes.trStyle}>
          <th className={classes.thStyle}></th>
          <th className={classes.thStyle}>Total Due</th>
          <th className={classes.thStyle}>Paid Up</th>
          <th className={classes.thStyle}>Balance</th>
        </tr>
      </thead>
      <tbody>
        {dataOrder.map(({ label, key }) => {
          const item = data?.SNLevel[key];
          return (
            <tr key={key} className={classes.trStyle}>
              <td className={classes.thTdStyle}>{label}</td>
              <td className={classes.thTdStyle}>
                SGD:{" "}
                {item?.totalDue
                  ? formatNumbersWithThousandSeperators(
                      parseFloat(item?.totalDue)
                    )
                  : "0"}
              </td>
              <td className={classes.thTdStyle}>
                SGD:{" "}
                {item?.paidUp
                  ? formatNumbersWithThousandSeperators(
                      parseFloat(item?.paidUp)
                    )
                  : "0"}
              </td>
              <td className={classes.thTdStyle}>
                SGD:{" "}
                {item?.balance
                  ? formatNumbersWithThousandSeperators(
                      parseFloat(item?.balance)
                    )
                  : "0"}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default Note;
