import axios from "axios";
import { BASE_URL } from "@funded-here-interface/common/src/constant/constant";
import { getApiResponseErrorMsg } from "@funded-here-interface/common/src/Utils/apiHelper";

interface GetNoteDefaultRequest {
  noteId: string;
  token: string;
}

interface FeeDetails {
  totalDue: string;
  paidUp: string;
  balance: string;
}

export interface GetNoteDefaultResponse {
  id: number;
  merchant: string;
  skuId: string;
  "l2+E": string;
  salesProcced: string;
  repaidAmount: string;
  repayment: string;
  loan2AndEquityWithoutRepaidAmount: string;
  topUp: string;
  sfUtilized: string;
  asfUtilized: string;
  moeUtilized: string;
  sfOtherUtilized: string;
  asfOtherUtilized: string;
  moeOtherUtilized: string;
  dl0: string;
  indicativeDl0: string;
  dl1: string;
  indicativeDl1: string;
  dl2: string;
  indicativeDl2: string;
  fhAdditionalInterestFees: string;
  coverageDL0AddAminEqualsQcSFASF: string | null;
  indicativeFhAdditionalInterestFees: string;
  coveredForceSell: string;
  insurance: string;
  fhAddAdminFee: FeeDetails;
  forceSell: {
    totalDue: string;
    actual: string;
  };
  seniorInvestorPrincipal: FeeDetails;
  seniorInvestorInterest: FeeDetails;
  seniorInvestorAddInterest: FeeDetails;
  juniorInvestorPrincipal: FeeDetails;
  juniorInvestorInterest: FeeDetails;
  juniorInvestorAddInterest: FeeDetails;
  acquirerFees: FeeDetails;
  spar: FeeDetails;
  addInt: FeeDetails;
}

const getNoteDefault = async ({
  token,
  noteId,
}: GetNoteDefaultRequest): Promise<GetNoteDefaultResponse[]> => {
  try {
    const response = await axios.get(
      `${BASE_URL}/sku-finance/loan-repayment/structured-note-default-detail/${noteId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      }
    );

    return response.data;
  } catch (e) {
    const message = getApiResponseErrorMsg(e);

    throw new Error(message);
  }
};

export default getNoteDefault;
