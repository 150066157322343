import { useQuery } from "react-query";
import getNoteDefault from "../services/getNoteDefault";

function useGetNoteDefault(token: string, noteId: string) {
  return useQuery(
    ["get-note-default", noteId, token],
    () => getNoteDefault({ noteId, token }),
    {
      retry: false,
    }
  );
}

export default useGetNoteDefault;
