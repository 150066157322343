import { FC, ReactNode, useEffect, useState } from "react";
import Logo from "@funded-here-interface/common/src/assets/logo2.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Icon, IconLogout } from "@tabler/icons-react";

import useLogoutMutation from "../../hooks/useLogout";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

import { toast } from "react-toastify";
import { useStyles } from "./AdminNavBar.styles";
import { Avatar, Group, NavLink, Paper, ScrollArea, Text } from "@mantine/core";

export interface IMenuItem {
  label: string;
  path?: string;
  submenu?: ISubMenu[];
  icon: Icon;
}

interface ISubMenu {
  label: string;
  path: string;
}

interface INavBar {
  menus: IMenuItem[];
  role?: string;
}

const AdminNavBar: FC<INavBar> = ({ menus, role }) => {
  const { classes } = useStyles();

  const [activeSubMenu, setActiveSubMenu] = useState<number | null>(null);
  const navigation = useNavigate();
  const token = useSelector((state: RootState) => state.auth.token);
  const location = useLocation();
  const logoutMutation = useLogoutMutation();

  // Automatically open submenu if any submenu path matches the current location.pathname
  useEffect(() => {
    menus.forEach((menu, index) => {
      if (menu.submenu && isCurrentPage(getAllSubPaths(menu.submenu))) {
        setActiveSubMenu(index);
      }
    });
  }, [location.pathname]); // This runs when the location.pathname changes

  const handleLogout = () => {
    logoutMutation.mutate(
      { token },
      {
        onSuccess: () => {
          navigation("/login");
        },
        onError: (e) => {
          toast.error((e as Error).message);
        },
      }
    );
  };

  const toggleSubMenu = (index: any) => {
    setActiveSubMenu(activeSubMenu === index ? null : index);
  };

  const isCurrentPage = (paths: string[]) => {
    return paths.includes(location.pathname);
  };

  const getAllSubPaths = (subMenu: ISubMenu[]): string[] => {
    return subMenu.map((item) => item.path);
  };

  const items = menus.map((item, index) => {
    if (item.submenu) {
      // If there are submenus, render nested NavLink
      return (
        <NavLink
          key={index}
          label={item.label}
          className={classes.link}
          childrenOffset={20}
          icon={<item.icon size="1.5rem" stroke={1.5} />}
          opened={activeSubMenu === index} // Control submenu open state
          onClick={() => toggleSubMenu(index)} // Toggle open/closed state
        >
          {item.submenu.map((submenu, subIndex) => (
            <NavLink
              key={subIndex}
              label={submenu.label}
              component="a"
              href={submenu.path}
              className={classes.link}
              active={submenu.path === location.pathname}
            />
          ))}
        </NavLink>
      );
    }

    // Render top-level menu item
    return (
      <NavLink
        key={index}
        label={item.label}
        component="a"
        href={item.path}
        className={classes.link}
        icon={<item.icon size="1.5rem" stroke={1.5} />}
        active={item.path === location.pathname}
      />
    );
  });

  return (
    <nav className={classes.sideBarContainer}>
      <div className={classes.iconbox}>
        <img className={classes.logo} alt="LOGO" src={Logo} />
      </div>
      <ScrollArea className={classes.scrollArea}>
        <div>{items}</div>
      </ScrollArea>

      <div className={classes.footer}>
        {role && (
          <Group my={10}>
            <Avatar radius="xl" />
            <div style={{ flex: 1 }}>
              <Text c="white" size="sm">
                {role}
              </Text>
            </div>
          </Group>
        )}

        <NavLink
          label={"Logout"}
          icon={<IconLogout size="1.5rem" stroke={1.5} />}
          onClick={handleLogout}
          className={classes.link}
        />
      </div>
    </nav>
  );
};

export default AdminNavBar;
