import HttpClientXml from "../Utils/HttpClientXmlPm";

// >>>All POST Api<<<
const activeAmountByCountry = async (token) => {
  let endPoint = "donut-chart-active-sn-sku-count-by-country";

  return HttpClientXml.post(endPoint, undefined, token);
};

const activeAmountBySector = async (token) => {
  let endPoint = "donut-chart-active-sn-sku-count-by-sector";

  return HttpClientXml.post(endPoint, undefined, token);
};

const investorAnalysis = async (id, token) => {
  let endPoint;

  if (id) {
    endPoint = `investor-level-analysis/${id}`;
  } else {
    endPoint = "investor-level-analysis";
  }

  return HttpClientXml.post(endPoint, undefined, token);
};

const loanAndSales = async (id, token) => {
  let endPoint;

  if (id) {
    endPoint = `loan-and-sales-analysis-skuSummary/${id}`;
  } else {
    endPoint = "loan-and-sales-analysis-skuSummary";
  }

  return HttpClientXml.get(endPoint, undefined, token);
};

const investorSnAnalysis = async (token) => {
  let endPoint = "investor-sn-level-analysis";
  return HttpClientXml.post(endPoint, undefined, token);
};

const structureNote = async (id, token) => {
  let endPoint = `structure-note-by-sn/${id}`;
  return HttpClientXml.post(endPoint, undefined, token);
};

const paymentDue = async (token) => {
  let endPoint = "active-repayment-tracking";
  return HttpClientXml.get(endPoint, undefined, token);
};

const defaultPaymentData = async (token) => {
  let endPoint = "default-sn-repayment-summary";
  return HttpClientXml.get(endPoint, undefined, token);
};

const earnedReturnAnalysis = async (token) => {
  let endPoint = "investor-earned-return-anlysis";

  return HttpClientXml.get(endPoint, undefined, token);
};

const merchnatSummaryLeftside = async (data, token) => {
  let endPoint = "merchnat-summary-left-side";
  return HttpClientXml.post(endPoint, data, token);
};

const allMerchantAmountReport = async (data, token) => {
  let endPoint = "all-merchant-amount-due-analysis-report-in-merchat-summery";
  return HttpClientXml.post(endPoint, data, token);
};
// >>>All GET Api
const allInvestorOverView = async (token) => {
  let endPoint = "all-invester-overview";
  return HttpClientXml.get(endPoint, undefined, token);
};

const snActive = async (token) => {
  let endPoint = "view-snactive-for-allinvester";
  return HttpClientXml.get(endPoint, undefined, token);
};

const snClosed = async (token) => {
  let endPoint = "view-snclosed-for-allinvester";
  return HttpClientXml.get(endPoint, undefined, token);
};

const balanceAmountAnalysis = async (token) => {
  let endPoint = "balance-amount-analysis";
  return HttpClientXml.get(endPoint, undefined, token);
};

const investorAmountAnalysis = async (token) => {
  let endPoint = "investor-invested-amount-analysis";
  return HttpClientXml.get(endPoint, undefined, token);
};

const highestPotentialSN = async (token) => {
  let endPoint = "top-sns-with-highest-default";
  return HttpClientXml.get(endPoint, undefined, token);
};

const highestPotentialSku = async (id, token) => {
  let endPoint;
  if (id && id !== "") {
    endPoint = `merchant-top-skus-highest-default-in-merchant-summary/${id}`;
  } else {
    endPoint = "merchant-top-skus-highest-default-in-merchant-summary";
  }
  return HttpClientXml.get(endPoint, undefined, token);
};

const investorOverviewSummery = async (id, token) => {
  let endPoint;

  if (id && id !== "") {
    endPoint = `investor-overview-summery-reports/${id}`;
  } else {
    endPoint = "investor-overview-summery-reports";
  }
  return HttpClientXml.get(endPoint, undefined, token);
};

const investorSummaryAnalysis = async (token) => {
  let endPoint = "investor-summery-anlysis-reports";
  return HttpClientXml.get(endPoint, token);
};

const activeRepaymentTracking = async (token) => {
  let endPoint = "active-sn-repayment-summary";
  return HttpClientXml.get(endPoint, undefined, token);
};

const getSKUDropDown = async (token) => {
  let endPoint = "get-sku-drop-down";
  return HttpClientXml.get(endPoint, undefined, token);
};

const getSKUsBySN = async (token) => {
  let endPoint = `top-10-sku-by-doi`;
  return HttpClientXml.get(endPoint, undefined, token);
};

const getAllMerchantwithID = async (token) => {
  let endPoint = "all-merchant-list";
  return HttpClientXml.get(endPoint, undefined, token);
};

const getAmountDueChartData = async (id, token) => {
  let endPoint = `merchant-amount-due-analysis-report-in-merchant-summary/${id}`;
  return HttpClientXml.get(endPoint, undefined, token);
};

const getActiveMerchantPaymentReport = async (id, token) => {
  let endPoint = `merchant-active-payment-in-merchant-summary/${id}`;
  return HttpClientXml.get(endPoint, undefined, token);
};

const getCloseMerchantPaymentReport = async (id, token) => {
  let endPoint = `merchant-closed-payment-in-merchant-summary/${id}`;
  return HttpClientXml.get(endPoint, undefined, token);
};

const getDefaultMerchantPayment = async (id, token) => {
  let endPoint = `merchant-default-payment-in-merchant-summary/${id}`;
  return HttpClientXml.get(endPoint, undefined, token);
};

const getMerchantData = async (id, token) => {
  let endPoint = `merchant-summary/${id}`;

  return HttpClientXml.get(endPoint, undefined, token);
};
const getSNDropDown = async (token) => {
  let endPoint = "get-sn-drop-down";

  return HttpClientXml.get(endPoint, undefined, token);
};

const dailySalesReport = async (selectedSKUs, token) => {
  const endPoint = "daily-sales-report";

  let body = {};

  // Check if selectedSKUs is an array and not empty

  if (
    Array.isArray(selectedSKUs) &&
    selectedSKUs.length > 0 &&
    !selectedSKUs.includes(null) &&
    !selectedSKUs.includes(undefined)
  ) {
    body = {
      skuIds: selectedSKUs,
    };
  }

  return HttpClientXml.post(endPoint, body, token);
};

const allMerchantActiveData = async (token) => {
  let endPoint = "all-marchant-active-payment-merchant-summery";
  return HttpClientXml.get(endPoint, token);
};

const allMerchantCloseData = async (token) => {
  let endPoint = "all-marchant-closed-payment-merchant-summery";
  return HttpClientXml.get(endPoint, token);
};

const getDonutChartDataByCountry = async (token) => {
  let endPoint = `donut-chart-sku-allocation-by-country`;
  return HttpClientXml.get(endPoint, undefined, token);
};

const getDonutChartDataBySector = async (token) => {
  let endPoint = "donut-chart-sku-allocation-by-sector";

  return HttpClientXml.get(endPoint, undefined, token);
};

const getInvestorList = async (token) => {
  let endPoint = "all-investor-list";

  return HttpClientXml.get(endPoint, undefined, token);
};

const investorPortfolioReturn = async (id, token) => {
  let endPoint = `investor-portfolio-return/${id}`;

  return HttpClientXml.get(endPoint, undefined, token);
};

const investorNotesReturn = async (id, token) => {
  let endPoint = `investor-effective-ror/${id}`;

  return HttpClientXml.get(endPoint, undefined, token);
};

const getSKUbySNID = async (id, token) => {
  let endPoint = `view-sku-by-snId/${id}`;
  return HttpClientXml.get(endPoint, token);
};

const getSKUCountByCurrency = (async) => {
  let endPoint = `donut-chart-active-sn-sku-count-by-currency`;

  return HttpClientXml.post(endPoint);
};

export default {
  // >>> Get <<<
  allInvestorOverView,
  snActive,
  snClosed,
  getSKUCountByCurrency,
  balanceAmountAnalysis,
  investorAmountAnalysis,
  investorOverviewSummery,
  investorSummaryAnalysis,
  activeRepaymentTracking,
  getSKUDropDown,
  getSKUsBySN,
  getAllMerchantwithID,
  getAmountDueChartData,
  getActiveMerchantPaymentReport,
  getCloseMerchantPaymentReport,
  allMerchantActiveData,
  allMerchantCloseData,
  getDonutChartDataByCountry,
  getDonutChartDataBySector,
  earnedReturnAnalysis,
  getSNDropDown,
  getInvestorList,
  investorPortfolioReturn,
  dailySalesReport,
  investorNotesReturn,
  highestPotentialSN,
  highestPotentialSku,
  loanAndSales,
  defaultPaymentData,
  getMerchantData,
  getSKUbySNID,

  // >>> Post <<<
  activeAmountByCountry,

  activeAmountBySector,
  investorAnalysis,
  investorSnAnalysis,
  structureNote,
  paymentDue,
  allMerchantAmountReport,
  merchnatSummaryLeftside,
  getDefaultMerchantPayment,
};
