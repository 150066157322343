import { Box, Input, Modal, Space } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconEdit } from "@tabler/icons-react";
import { FC, useState } from "react";
import { Button, Text } from "@mantine/core";
import { color } from "@funded-here-interface/common";

interface IProps {
  columnName: string;
  selectedRows: number[];
  handleOnColumnEdit: (
    value: string,
    onCallBack: (success: boolean) => void
  ) => void;
}

const EditColumn: FC<IProps> = (props) => {
  const { columnName, selectedRows, handleOnColumnEdit } = props;
  const [opened, { open, close }] = useDisclosure(false);
  const [input, setInput] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleOnHeaderEdit = (event: React.MouseEvent) => {
    event.stopPropagation();

    if (!selectedRows || selectedRows.length === 0) {
      return;
    }

    open();
  };

  const handleClose = (event: React.MouseEvent) => {
    event.stopPropagation();
    close();
  };

  const handleOnCallback = (success: boolean) => {
    if (success) {
      close();
    }

    setIsLoading(false);

    return;
  };

  const handleOnSave = () => {
    if (!input) {
      return;
    }

    setIsLoading(true);
    handleOnColumnEdit(input, handleOnCallback);
  };

  const handleOnInputChanged = (value: string) => {
    setInput(value);
  };

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          gap: "8px",
        }}
      >
        <Box
          sx={{
            flexShrink: 0,
            display: "flex",
            alignItems: "center",
          }}
        >
          <IconEdit
            size={20}
            stroke={1.5}
            color={!selectedRows || selectedRows.length === 0 ? "gray" : "blue"}
            onClick={(event) => handleOnHeaderEdit(event)}
            style={{
              cursor:
                !selectedRows || selectedRows.length === 0
                  ? "not-allowed"
                  : "pointer",
            }}
          />
        </Box>
        <span>{columnName}</span>
      </Box>
      <Modal
        onClick={(event) => event.stopPropagation()}
        opened={opened}
        onClose={close}
        size="auto"
        withCloseButton={false}
      >
        <>
          <Text>{`Please enter the ${columnName} for the selected rows.`}</Text>
          <Space h="md" />
          <Input
            value={input}
            onChange={(event) => handleOnInputChanged(event.target.value)}
          />
          <Button
            loading={isLoading}
            onClick={handleOnSave}
            disabled={!input}
            sx={{
              width: "150px",
              height: "40px",
              marginTop: "20px",
              backgroundColor: color.FHGREEN,
              "&:hover": {
                backgroundColor: color.FHGREEN,
              },
            }}
          >
            Save
          </Button>
          <Button
            disabled={isLoading}
            onClick={(event) => handleClose(event)}
            sx={{
              marginLeft: "20px",
              width: "150px",
              height: "40px",
              marginTop: "20px",
              backgroundColor: color.LOANTEXT,
              "&:hover": {
                backgroundColor: color.LOANTEXT,
              },
            }}
          >
            Cancel
          </Button>
        </>
      </Modal>
    </div>
  );
};

export default EditColumn;
