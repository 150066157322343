import { useQuery } from "react-query";
import getNoteRepayment from "../services/getNoteRepayment";

function useGetNoteRepayment(token: string, noteId: string) {
  return useQuery(
    ["get-note-repayment", noteId, token],
    () => getNoteRepayment({ noteId, token }),
    {
      retry: false,
    }
  );
}

export default useGetNoteRepayment;
