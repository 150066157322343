import { BASE_URL } from "@funded-here-interface/common/src/constant/constant";
import axios from "axios";
import { getApiResponseErrorMsg } from "@funded-here-interface/common/src/Utils/apiHelper";

type currentNote = {
  token: string;
  id: string;
};

export interface AmtTransferDataItem {
  merchantOrgRoleId: number;
  merchantOrgName: string;
  amountToTransfer: number;
  currency: string;
}

export interface TransferAmtResponse {
  amtTransferResult: AmtTransferDataItem[];
}

const getAmtTransfer = async ({
  token,
  id,
}: currentNote): Promise<TransferAmtResponse> => {
  try {
    const response = await axios.get(
      `${BASE_URL}/sku-finance/sn/get-fund-transfer-merchant-va-to-fh/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
        timeout:5000,
    });
    return response.data;
  } catch (e) {
    const message = getApiResponseErrorMsg(e);
    throw new Error(message);
  }
};

export default getAmtTransfer;
