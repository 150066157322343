import React, { createContext, useState, useContext } from "react";

const MerchantContext = createContext();

export const useMerchantContext = () => useContext(MerchantContext);

export const MerchantProvider = ({ children }) => {
  const [merchantOrgRoleID, setMerchantRoleID] = useState("");

  const setMerchantIDHandler = (id) => {
    setMerchantRoleID(id);
  };

  return (
    <MerchantContext.Provider
      value={{ merchantOrgRoleID, setMerchantIDHandler }}
    >
      {children}
    </MerchantContext.Provider>
  );
};
