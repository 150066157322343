import { FC } from "react";
import { useStyle } from "./IssueNote.styles";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { FaArrowLeftLong } from "react-icons/fa6";
import { color } from "@funded-here-interface/common";
import { toast } from "react-toastify";
import { Tabs } from "@mantine/core";
import useGetSN from "../../hooks/useGetSN";
import Overview from "./Components/Tabs/Overview";
import Merchant from "./Components/Tabs/Merchant";
import Investor from "./Components/Tabs/Investor";
import TransferFund from "./Components/Tabs/TransferFund";

const IssueNote: FC = () => {
  const { classes } = useStyle();
  const { id } = useParams();
  const token = useSelector((state: RootState) => state.auth.token);
  const { data, error, isLoading } = useGetSN(token, id!);

  if (error) {
    toast.error((error as Error).message);
  }

  enum IssueNoteTab {
    OVERVIEW = "overview",
    MERCHANT = "merchant",
    INVESTOR = "investor",
    TRANSFER_FUND = "transfer-fund",
  }
  return (
    <div>
      <div>
        <div className={classes.header}>
          <Link to="/note-list">
            <FaArrowLeftLong style={{ color: color.FHADMIN, fontSize: 32 }} />
          </Link>
          <h1 className={classes.headerText}>Issue Note</h1>
        </div>
        {isLoading && <h1 className={classes.text}>{`Note Id: -`}</h1>}
        {!isLoading && (
          <h1
            className={classes.text}
          >{`Note Id: ${data?.structuredNoteDisplayId}`}</h1>
        )}
      </div>
      <Tabs defaultValue={IssueNoteTab.OVERVIEW}>
        <Tabs.List>
          <Tabs.Tab value={IssueNoteTab.OVERVIEW}>Overview</Tabs.Tab>
          <Tabs.Tab value={IssueNoteTab.TRANSFER_FUND}>Transfer Fund</Tabs.Tab>
          <Tabs.Tab value={IssueNoteTab.MERCHANT}>Merchant</Tabs.Tab>
          <Tabs.Tab value={IssueNoteTab.INVESTOR}>Investor</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value={IssueNoteTab.OVERVIEW} pt="xs">
          <Overview data={data} />
        </Tabs.Panel>
         <Tabs.Panel value={IssueNoteTab.TRANSFER_FUND} pt="xs">
          <TransferFund id={id!}/>
        </Tabs.Panel>
        <Tabs.Panel value={IssueNoteTab.MERCHANT} pt="xs">
          <Merchant data={data?.merchantData} isLoading={isLoading} />
        </Tabs.Panel>
        <Tabs.Panel value={IssueNoteTab.INVESTOR} pt="xs">
          <Investor data={data?.investorData} isLoading={isLoading} />
        </Tabs.Panel>
      </Tabs>
    </div>
  );
};

export default IssueNote;
