import { BASE_URL } from "@funded-here-interface/common/src/constant/constant";
import axios from "axios";
import { AgreementType, PoolingType } from "../types/login.type";
import { getApiResponseErrorMsg } from "@funded-here-interface/common/src/Utils/apiHelper";

export interface PoolingReviewResponse {
  averageDynamicLTV: string;
  averageLoanSize: string;
  averageGM: string;
  averageDOI: string;
  totalInvestor: string;
}

export const PoolingReview = async ({
  token,
  data,
}: PoolingType): Promise<PoolingReviewResponse> => {
  try {
    const response = await axios.post(
      `${BASE_URL}/sku-finance/sn/pooling-sku-review/`,
      {
        ids: data,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (e) {
    const message = getApiResponseErrorMsg(e);

    throw new Error(message);
  }
};

export const AllocateSubscription = async ({
  token,
  data,
  seniorAllocate,
  juniorAllocate,
  privateAllocate,
  currency,
  ispvId,
}: AgreementType) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/sku-finance/sn/pooling-sku-send-agreements/`,
      {
        poolingSkuIds: data,
        seniorAllocationPercentage: seniorAllocate,
        juniorAllocationPercentage: juniorAllocate,
        privatePlacementAllocationPercentage: privateAllocate,
        currency,
        ispvId,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (e) {
    const message = getApiResponseErrorMsg(e);

    throw new Error(message);
  }
};
