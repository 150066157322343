import { getApiResponseErrorMsg } from "@funded-here-interface/common/src/Utils/apiHelper";
import axios from "axios";
import { BASE_URL } from "../constant/constant";

export interface EditSkuPoOcrColumnRequest {
  ids: number[];
  token: string;
  column: string;
  value: string;
}

const editSkuPoOcrColumn = async ({
  ids,
  token,
  column,
  value,
}: EditSkuPoOcrColumnRequest) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/sku-finance/loan-application/edit-sku-po-ocr-column`,
      { ids, column, value },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      }
    );

    return response.data;
  } catch (e) {
    const message = getApiResponseErrorMsg(e);

    throw new Error(message);
  }
};

export default editSkuPoOcrColumn;
