import React, { useState, useEffect, useCallback } from "react";
import ReactApexChart from "react-apexcharts";
import HomeServices from "../../../../services/HomeServices";
import { useSelector } from "react-redux";

const DailySalesChart = ({ selectedSKU }) => {
  const token = useSelector((state) => state.auth.token);

  const [chartData, setChartData] = useState({
    series: [
      {
        name: "Quantity Sold (Previous 30 Days)",
      },
      {
        name: "Projected Sales (Moving Avg Last 5 Days)",
      },
    ],
    grid: {
      show: false,
    },
    options: {
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      title: {
        text: "Sales Trends Analysis (Past 30 Days)",
        align: "left",
        style: {
          fontSize: "13px",
          colors: "#595959",
        },
      },
      xaxis: {
        labels: {
          show: true,
          rotate: -50,
          rotateAlways: true,
        },
        categories: [],
        title: {
          text: "Time Period",
        },
        tooltip: {
          enabled: false,
        },
      },
      legend: {
        show: "true",
        showForSingleSeries: true,
        showForNullSeries: true,
        showForZeroSeries: true,
        position: "top",
        horizontalAlign: "left",
        offsetX: -15,
        fontWeight: "bold",
        fontSize: "11px",
        markers: {
          fillColors: ["#128fcb", "#00e396"], // Set marker fill colors
        },
      },
      yaxis: {
        show: true,
        labels: {
          formatter: function (val, index) {
            return val.toLocaleString("en-US");
          },
        },
      },
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      stroke: {
        curve: "straight",
        colors: ["#128fcb", "#00e396"], // Set line color here
        dashArray: [0, 5], // Set dashArray for dashed line
      },
      forecastDataPoints: {
        count: 10,
      },
    },
  });

  const getData = useCallback(async () => {
    const res = await HomeServices.dailySalesReport(selectedSKU, token);
    if (res && res.status) {
      const previous30Days = res.previous30Days || [];
      const projectedSales = res.projectedSales || [];

      previous30Days.sort((a, b) => a.timePeriod - b.timePeriod);
      projectedSales.sort((a, b) => a.timePeriod - b.timePeriod);

      let salesData = previous30Days.map((item) => item.quantitySold).reverse();
      salesData = salesData.concat(
        projectedSales.slice(1).map((item) => item.quantitySold)
      );

      const reversePrev30Days = previous30Days
        .map((item) => item.timePeriod)
        .reverse();

      let allTimePeriods = reversePrev30Days.map((timePeriod) => {
        if (timePeriod == 0) {
          return "T";
        } else if (timePeriod <= 30) {
          return "T-" + timePeriod;
        }
      });
      // allTimePeriods = allTimePeriods.reverse();
      allTimePeriods = allTimePeriods.concat(
        projectedSales.map((item) => {
          return "T+" + (item.timePeriod + 1);
        })
      );

      setChartData((prevState) => ({
        ...prevState,
        series: [{ name: "Quantity Sold (Previous 30 Days)", data: salesData }],
        options: {
          ...prevState.options,
          labels: allTimePeriods,
          xaxis: {
            ...prevState.options.xaxis,
            categories: allTimePeriods,
          },
          tooltip: {
            enabled: true,
            followCursor: true,
            custom: function ({ series, seriesIndex, dataPointIndex }) {
              const date = allTimePeriods[dataPointIndex];
              const quantity =
                series[seriesIndex][dataPointIndex]?.toLocaleString("en-US");
              const seriesName =
                seriesIndex === 0 ? "Sales" : "Projected Sales";
              return `<div class="arrow_box">
                  <span class="headspan">Time Period: </span>
                  <span class="paraspan">${date}</span>
                </div>
                <div class="arrow_box">
                  <span class="headspan">${seriesName}: </span>
                  <span class="paraspan">${quantity}</span>
                </div>`;
            },
            y: {
              formatter: function (val) {
                return val + "K";
              },
            },
          },
          stroke: {
            width: 4,
            curve: "straight",
            colors: ["#128fcb", "#00e396"], // Set line color here
            dashArray: [0, 5], // Set dashArray for dashed line
          },
        },
      }));
    } else {
      console.error("Failed to fetch data or empty response");
    }
  }, [setChartData, selectedSKU]);

  useEffect(() => {
    getData();
  }, [selectedSKU, getData]);

  return (
    <div id="chart">
      <ReactApexChart
        options={chartData.options}
        series={chartData.series}
        height={300}
      />
    </div>
  );
};

export default DailySalesChart;
