import React, { FC, useEffect, useState } from "react";
import { useStyles } from "./allocate.styles";
import { Text, Divider, Select } from "@mantine/core";
import { Button } from "@funded-here-interface/common";
import useSendAgreements from "../../../hooks/useSendAgreements";
import { RootState } from "../../../store";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import useAllocateSku from "../../../hooks/useAllocateSku";
import useGetIspv from "../../../hooks/useGetIspv";
import { IspvStatusEnum } from "@funded-here-interface/common/src/constant/enum";

interface SkuAllocateProps {
  selectedRows: number[];
}
type OptionType = { value: string; label: string };

const Allocate: FC<SkuAllocateProps> = ({ selectedRows }) => {
  const currency = ["USD", "SGD"];
  const { classes } = useStyles();
  const [value, setValue] = useState<string>("");
  const [allocateData, setAllocateData] = useState<any>([]);
  const selectedRowsAsString: string[] = selectedRows.map(String);
  const [auto, setAuto] = useState(0);
  const [manual, setManual] = useState(0);
  const [junior, setJunior] = useState(0);
  const [Private, setPrivate] = useState(0);
  const mutation = useSendAgreements();
  const token = useSelector((state: RootState) => state.auth.token);
  const [availableIspv, setAvailableIspv] = useState<OptionType[]>([]);
  const useGetIspvMutation = useGetIspv();
  const navigate = useNavigate();
  const [selectedIspvId, setSelectedIspvId] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const sendAgreement = async () => {
    setIsLoading(true);
    mutation.mutate(
      {
        token,
        data: selectedRowsAsString,
        seniorAllocate: auto + manual,
        juniorAllocate: junior,
        privateAllocate: Private,
        currency: value,
        ispvId: parseInt(selectedIspvId),
      },
      {
        onSuccess: async (data) => {
          if (data) {
            toast.success("Agreement Sent Successfully");
            setTimeout(() => {
              setIsLoading(false);
              navigate("/note-list");
            }, 2000);
          }
        },
        onError: (error) => {
          setIsLoading(false);
          toast.error((error as Error).message);
        },
      }
    );
  };
  const AllocationMutation = useAllocateSku();
  useEffect(() => {
    if (selectedRows.length === 0) {
      navigate("/structured-note/sku-pooling");

      return;
    }

    AllocationMutation.mutate(
      {
        token,
      },
      {
        onSuccess: (data) => {
          setAllocateData(data);
        },
        onError: (e) => {
          toast.error((e as Error).message);
        },
      }
    );

    useGetIspvMutation.mutate(
      { token, status: IspvStatusEnum.AVAILABLE },
      {
        onSuccess: (data) => {
          const processedData: OptionType[] = data.map((data) => {
            return {
              value: data.id.toString(),
              label: data.name,
            };
          });

          setAvailableIspv(processedData);
        },
        onError: (e) => {
          toast.error((e as Error).message);
        },
      }
    );
  }, [selectedRows]);
  const handlePercentageChange = (
    type: "auto" | "manual" | "junior" | "Private",
    newValue: number
  ) => {
    if (type === "auto") setAuto(newValue);
    else if (type === "manual") setManual(newValue);
    else if (type === "junior") setJunior(newValue);
    else if (type === "Private") setPrivate(newValue);
  };

  return (
    <div className={classes.mainContainer}>
      <div style={{ display: "flex" }}>
        <div style={{ width: "800px" }}>
          <Text style={{ marginBottom: 8 }}>Select Available Issuing SPV</Text>
          <Select
            data={availableIspv}
            placeholder="Choose a SPV"
            onChange={(value: string) => setSelectedIspvId(value)}
            className={classes.spvDropdown}
          />
        </div>
        <div style={{ width: "800px" }}>
          <Text style={{ marginBottom: 8 }}>Select currency for SN</Text>
          <Select
            defaultValue={currency[0]}
            data={currency}
            placeholder="Choose a Currency"
            className={classes.spvDropdown}
            value={value}
            onChange={(option: string) => setValue(option)}
          />
        </div>
      </div>
      <Divider className={classes.divider} />
      <div className={classes.investorDetailsContainer}>
        <div>
          <div className={classes.investorTotalNumberContainer}>
            <Text>
              Total no of Investors:{" "}
              {allocateData?.totalInvestors?.total ?? "0"}
            </Text>
          </div>
          <div className={classes.investorInterestRate}>
            <Text>
              Senior Interest Rate: {allocateData?.seniorInterestRate ?? "N/A"}%
            </Text>
            <br />
            <Text>
              Junior Interest Rate: {allocateData?.juniorInterestRate ?? "N/A"}%
            </Text>
          </div>
        </div>
        <br />
        <br />
        <div className={classes.investorTypeContainer}>
          <div className={classes.investorTypeOuterContainer}>
            <div className={classes.allocationPercentage}>
              <input
                className={classes.percentageInput}
                value={manual}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const newValue = Number(event.target.value);
                  if (!isNaN(newValue) && newValue >= 0 && newValue <= 100) {
                    handlePercentageChange("junior", 0);
                    handlePercentageChange("manual", newValue);
                    handlePercentageChange("junior", 100 - newValue);
                  }
                }}
              />
              <div className={classes.percentage}>%</div>
            </div>
            <div className={classes.investorTypeContainer}>
              <Text className={classes.investorTypeTitle}>Senior - Manual</Text>
              <div>
                <Text className={classes.investorTypeTitle}>
                  Committed Amount SGD:{" "}
                  {allocateData?.breakdownByInvestorMode?.manual
                    ?.investorVirtualAccountSGDBalance ?? "0"}{" "}
                  SGD
                </Text>
                <Text className={classes.investorTypeTitle}>
                  Committed Amount USD:{" "}
                  {allocateData?.breakdownByInvestorMode?.manual
                    ?.investorVirtualAccountUSDBalance ?? "0"}{" "}
                  USD
                </Text>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.investorTypeContainer}>
          <div className={classes.investorTypeOuterContainer}>
            <div className={classes.allocationPercentage}>
              <input
                disabled={true}
                className={classes.percentageInput}
                value={junior}
              />
              <div className={classes.percentage}>%</div>
            </div>
            <div className={classes.investorTypeContainer}>
              <Text className={classes.investorTypeTitle}>Junior</Text>
            </div>
          </div>
        </div>
        <Button
          disabled={!selectedIspvId || !value || isLoading || !manual}
          textColor="#ffffff"
          backgroundColor="#3786D6"
          width="410px"
          children="Send agreements"
          onClick={() => sendAgreement()}
        ></Button>
      </div>
    </div>
  );
};

export default Allocate;
