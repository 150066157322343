import {
  Button,
  EMAIL_REGEX,
  InputGroup,
  LargeBtn,
  NAME_REGEX,
  color,
} from "@funded-here-interface/common";
import React, { FC, useEffect, useState } from "react";
import CustomTableForIssuingSPV from "../../components/TableIssuingSPV/CustomTableIssuingSPV";
import { useStyle } from "./Issusingspv.styles";
import { CloseButton, Divider, Text, Title } from "@mantine/core";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { toast } from "react-toastify";
import {
  EntityTypeEnum,
  InvestorCategory,
  InvestorCategoryEnum,
  Persona,
  SVPCategory,
} from "@funded-here-interface/common/src/constant/enum";
import { Invites } from "../../services/inviteUser";
import useInviteUser from "../../hooks/useInviteUser";
import useGetIspv from "../../hooks/useGetIspv";
import { GetAllocateSkuResponse } from "../../services/getIspv";

const Issusingspv: FC = () => {
  const { classes } = useStyle();
  const [issuingOptions, setIssuingOptions] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [inviteName, setInviteName] = useState("");
  const [invitePopup, setInvitePopup] = useState(false);
  const [invites, setInvites] = useState(1);
  const [inviteData, setInviteData] = useState<Invites[]>([]);
  const [data, setData] = useState<GetAllocateSkuResponse[]>([]);
  const useInviteUserMutation = useInviteUser();
  const useGetIspvMutation = useGetIspv();
  const token = useSelector((state: RootState) => state.auth.token);

  const investors: string[] = [
    InvestorCategory.PRIVATE_INDIVIDUAL,
    InvestorCategory.PRIVATE_INSTITUTION,
    InvestorCategory.JUNIOR_INDIVIDUAL,
    InvestorCategory.JUNIOR_INSTITUTION,
  ];

  useEffect(() => {
    setIsLoading(true);
    useGetIspvMutation.mutate(
      { token },
      {
        onSuccess: (data) => {
          setData(data);
          setIsLoading(false);
        },
        onError: (error) => {
          setIsLoading(false);
          toast.error((error as Error).message);
        },
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateInvitesValue = (index: any, field: string, value: any) => {
    setInviteData((prevInvites) => {
      const updatedInvites: any = [...prevInvites];
      updatedInvites[index] = {
        ...updatedInvites[index],
        [field]: value,
      };

      return updatedInvites;
    });
  };

  const sendInvite = (invite: string) => {
    setInvitePopup(true);
    setInviteName(invite);
    setInviteData([]);
    setIssuingOptions(false);
  };

  const handleNext = () => {
    if (inviteData.length === 0) {
      toast.warn("User details required");

      return;
    }

    let validInputs = true;

    inviteData.forEach((user, index) => {
      if (!user.firstName) {
        validInputs = false;

        toast.warn(`User ${index + 1} First Name is required`);
      }

      if (!user.lastName) {
        validInputs = false;

        toast.warn(`User ${index + 1} Last Name is required`);
      }

      if (!user.email) {
        validInputs = false;

        toast.warn(`User ${index + 1} Email required`);
      }
    });

    if (!validInputs) {
      return;
    }

    if (investors.includes(inviteName)) {
      const updatedInviteData = inviteData.map((invite) => {
        const entityType =
          inviteName === InvestorCategory.PRIVATE_INDIVIDUAL ||
          inviteName === InvestorCategory.JUNIOR_INDIVIDUAL
            ? EntityTypeEnum.INDIVIDUAL
            : EntityTypeEnum.INSTITUTION;
        const category =
          inviteName === InvestorCategory.JUNIOR_INDIVIDUAL ||
          inviteName === InvestorCategory.JUNIOR_INSTITUTION
            ? InvestorCategoryEnum.JUNIOR
            : undefined;
        const privatePlacement =
          inviteName === InvestorCategory.PRIVATE_INDIVIDUAL ||
          inviteName === InvestorCategory.PRIVATE_INSTITUTION;

        // Set investorCategory for the current invite
        invite.investorCategory = {
          entityType,
          category,
          privatePlacement,
        };

        invite.entityType = entityType;

        return invite;
      });
      setInviteData(updatedInviteData);
    }
    if (inviteName === SVPCategory.ISPV) {
      useInviteUserMutation.mutate(
        { invites: inviteData, token, userType: SVPCategory.ISPV },
        {
          onSuccess: () => {
            inviteSent();
          },
          onError: (error) => {
            toast.error((error as Error).message);
          },
        }
      );
    } else if (inviteName === SVPCategory.FUNDINGSPV) {
      useInviteUserMutation.mutate(
        { invites: inviteData, token, userType: SVPCategory.FUNDINGSPV },
        {
          onSuccess: () => {
            inviteSent();
          },
          onError: (error) => {
            toast.error((error as Error).message);
          },
        }
      );
    } else if (investors.includes(inviteName)) {
      useInviteUserMutation.mutate(
        { invites: inviteData, token, userType: Persona.INVESTOR },
        {
          onSuccess: () => {
            inviteSent();
          },
          onError: (error) => {
            toast.error((error as Error).message);
          },
        }
      );
    }
  };

  const inviteSent = () => {
    toast.success("Invitation Sent Successfully");
    setInvitePopup(false);
  };

  const handleOnClose = () => {
    setInvitePopup(false);
  };

  const handleRemoveUser = () => {
    setInvites(invites - 1);

    if (inviteData.length >= invites) {
      const newInviteData = [...inviteData];

      newInviteData.pop();

      setInviteData(newInviteData);
    }
  };

  const handleAddUser = () => {
    setInvites(invites + 1);
  };

  return (
    <div className="">
      {invitePopup && (
        <div className={classes.popupDiv}>
          <div className={classes.inviteDiv}>
            <CloseButton
              size="md"
              onClick={handleOnClose}
              className={classes.crossIcon}
            />
            <Text className={classes.inviteText}>Invite-{inviteName}</Text>
            {invites > 0 && (
              <>
                {Array.from({ length: invites }).map((_, index) => (
                  <div key={index} style={{ scrollBehavior: "smooth" }}>
                    {index >= 1 && (
                      <>
                        <div
                          style={{
                            borderTop: "2px solid black",
                            opacity: "20%",
                            marginTop: "30px",
                            marginBottom: "30px",
                          }}
                        ></div>
                      </>
                    )}
                    <InputGroup
                      onChange={(value: any) => {
                        updateInvitesValue(
                          index,
                          "firstName",
                          value.replace(NAME_REGEX, "")
                        );
                      }}
                      labelText="First Name"
                      id={`First_name_${index}`}
                      value={inviteData[index]?.firstName || ""}
                    />
                    <InputGroup
                      onChange={(value: any) => {
                        updateInvitesValue(
                          index,
                          "lastName",
                          value.replace(NAME_REGEX, "")
                        );
                      }}
                      labelText="Last Name"
                      id={`Last_name_${index}`}
                      value={inviteData[index]?.lastName || ""}
                    />
                    <InputGroup
                      onChange={(value: any) => {
                        updateInvitesValue(
                          index,
                          "email",
                          value.replace(EMAIL_REGEX, "").trim()
                        );
                      }}
                      labelText="Contact person email"
                      id={`email_${index}`}
                      value={inviteData[index]?.email || ""}
                    />
                  </div>
                ))}
              </>
            )}
            {invites > 1 && (
              <Text
                onClick={handleRemoveUser}
                className={classes.morePeopleText}
              >
                <Text style={{ color: color.REJECT }}>- </Text>Remove user
              </Text>
            )}
            <Text onClick={handleAddUser} className={classes.morePeopleText}>
              <Text style={{ color: color.FHADMIN }}>+ </Text>Invite more people
            </Text>
            <div
              className={classes.buttonWrapper}
              style={{ width: "100%", marginLeft: "0px" }}
            >
              <Button
                onClick={handleNext}
                backgroundColor={color.FHADMIN}
                textColor={color.WHITE}
                border={`1px solid ${color.FHGREEN}`}
                width="450%"
              >
                Send Invite
              </Button>
            </div>
          </div>
        </div>
      )}
      <div className={classes.headerContainer}>
        <Title>ISPVs</Title>

        <div className={classes.buttonWrapper}>
          <LargeBtn
            onClick={() => setIssuingOptions(!issuingOptions)}
            backgroundColor={color.FHADMIN}
            textColor={color.WHITE}
            border={`1px solid ${color.FHGREEN}`}
            width="0%"
          >
            Send Invite
          </LargeBtn>
          {issuingOptions && (
            <div className={classes.optionsDiv}>
              <Text
                onClick={() => sendInvite(InvestorCategory.PRIVATE_INSTITUTION)}
                className={classes.options}
              >
                Private placement Investor
              </Text>
              <Text
                onClick={() => sendInvite(InvestorCategory.PRIVATE_INDIVIDUAL)}
                className={classes.options}
              >
                Private placement Individual
              </Text>
              <Text
                onClick={() => sendInvite(InvestorCategory.JUNIOR_INSTITUTION)}
                className={classes.options}
              >
                Junior Investor-Institution
              </Text>
              <Text
                onClick={() => sendInvite(InvestorCategory.JUNIOR_INDIVIDUAL)}
                className={classes.options}
              >
                Junior Investor-Individual
              </Text>
              <Text
                onClick={() => sendInvite(SVPCategory.ISPV)}
                className={classes.options}
              >
                ISPV
              </Text>
              <Text
                onClick={() => sendInvite(SVPCategory.FUNDINGSPV)}
                className={classes.options}
              >
                Funding SPV
              </Text>
            </div>
          )}
        </div>
      </div>
      <Divider my="md" className={classes.divider} />

      <CustomTableForIssuingSPV data={data} isLoading={isLoading} />

      <div></div>
    </div>
  );
};

export default Issusingspv;
