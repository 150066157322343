import { MantineTheme, createStyles } from "@mantine/core";

export const useStyles = createStyles((theme: MantineTheme) => ({
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
  },
  rowSelection: {
    marginRight: "10px",
    width: "80px",
  },
  noData: {
    marginTop: "10px",
    textAlign: "center",
    width: "100%",
  },
}));
