import { BASE_URL } from "@funded-here-interface/common/src/constant/constant";
import axios from "axios";
import { getApiResponseErrorMsg } from "@funded-here-interface/common/src/Utils/apiHelper";

type GetDocumentRequest = {
  token: string;
  path: string;
};

const getDocument = async ({ token, path }: GetDocumentRequest) => {
  try {
    const PathSplit = path.split("\\");
    const bucketName = PathSplit[0];
    const trimUpTo = bucketName;
    const trimIndex = path.indexOf(trimUpTo) + trimUpTo.length;
    const pathAfterTrim = path.substring(trimIndex);

    const response = await axios.get(
      `${BASE_URL}/trade-directory/upload/document/${bucketName}\\${pathAfterTrim}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob",
        withCredentials: true,
      }
    );

    return response.data;
  } catch (e) {
    const message = getApiResponseErrorMsg(e);

    throw new Error(message);
  }
};

export default getDocument;
