import { FC } from "react";
import { DisbursementSummaryResponse } from "../../../../services/getDisbursementSummary";
import { Button, color } from "@funded-here-interface/common";
import { formatDate } from "@funded-here-interface/common/src/Utils/formatter";
import { useStyle } from "./DisbursementSummary.styles";

interface IProps {
  disbursementSummary: DisbursementSummaryResponse | undefined;
  confirmDisbursement: () => void;
  isLoading: boolean;
}

const DisbursementSummary: FC<IProps> = (props) => {
  const { classes } = useStyle();
  const { disbursementSummary, confirmDisbursement, isLoading } = props;

  return (
    <div className={classes.modalContent}>
      <ul>
        <li>
          <strong>Sku Id:</strong> {disbursementSummary?.skuId ?? "-"}
        </li>
        <li>
          <strong>Payment Term:</strong>{" "}
          {disbursementSummary?.paymentTerm ?? "-"}
        </li>
        {disbursementSummary?.partialPaymentType && (
          <li>
            <strong>Partial Payment Type:</strong>{" "}
            {disbursementSummary?.partialPaymentType}
          </li>
        )}
        <li>
          <strong>Amount to Disburse:</strong>{" "}
          {disbursementSummary?.currency +
            " " +
            disbursementSummary?.amountToDisburse}
        </li>
        <li>
          <strong>Amount to Disburse (Other Currency):</strong>{" "}
          {disbursementSummary?.amountToDisburseOtherCurrency
            ? disbursementSummary?.otherCurrency +
              " " +
              disbursementSummary?.amountToDisburseOtherCurrency
            : "-"}
        </li>
        <li>
          <strong>Supplier Org Name:</strong>{" "}
          {disbursementSummary?.distributorOrgName ?? "-"}
        </li>
        <li>
          <strong>Supplier Bank Account No.:</strong>{" "}
          {disbursementSummary?.distributorBankAccountNo ?? "-"}
        </li>
        <li>
          <strong>Expected Payment Date:</strong>{" "}
          {disbursementSummary?.expectedPaymentDate
            ? formatDate(
                disbursementSummary.expectedPaymentDate as unknown as string
              )
            : "-"}
        </li>
      </ul>
      <br />
      {disbursementSummary?.allowFundsDisbursement === false &&
        !disbursementSummary?.distributorBenefId && (
          <p className={classes.warning}>
            Unable to disburse funds, distributor's bank beneficiary account has
            not been set up correctly.
          </p>
        )}
      {disbursementSummary?.allowFundsDisbursement === false &&
        !disbursementSummary?.externalUserId && (
          <p className={classes.warning}>
            Unable to disburse funds, merchant external user id has not been set
            up correctly.
          </p>
        )}
      <div className={classes.button}>
        <Button
          onClick={confirmDisbursement}
          backgroundColor={color.FHGREEN}
          textColor={color.WHITE}
          children="Confirm"
          width="20%"
          disabled={
            disbursementSummary?.allowFundsDisbursement === false || isLoading
          }
        />
      </div>
    </div>
  );
};

export default DisbursementSummary;
