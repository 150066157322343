import { FC } from "react";
import { useStyles } from "./ApproveSummary.styles";
import { ApprovedSkusLoanResponse } from "../../../../services/approveSkuLoan";
import { Button, color } from "@funded-here-interface/common";
import { SkuStatus } from "../../../../constant/enum";

interface IProps {
  approvedSkusSummary: ApprovedSkusLoanResponse | undefined;
  handleCloseApprovedSkusSummaryModal: () => void;
}

const ApproveSummary: FC<IProps> = ({
  approvedSkusSummary,
  handleCloseApprovedSkusSummaryModal,
}) => {
  const { classes } = useStyles();
  const loanApplicationApprovedStatusesMapping = {
    [SkuStatus.LOAN_APPLICATION_REJECTED_CREDIT_LINE_EXCEEDED]:
      "Credit Limit Exceeded",
    [SkuStatus.LOAN_APPLICATION_REJECTED_DELIVERY_DAYS_EXCEEDED]:
      "DOI Exceeded",
    [SkuStatus.LOAN_APPLICATION_REJECTED_RETURN_RATIO_EXCEEDED]:
      "Return Ratio Exceeded",
    [SkuStatus.LOAN_APPLICATION_REJECTED]: "Application Rejected",
    [SkuStatus.LOAN_APPLICATION_APPROVED]: "Application Approved",
  };

  return (
    <div>
      <p>
        SKU’s that qualify for a Loan:{" "}
        {approvedSkusSummary?.numberOfApprovedSkus}
      </p>
      <p>
        SKU’s that do not qualify for a Loan: :{" "}
        {approvedSkusSummary?.numberOfRejectedSkus}
      </p>
      {approvedSkusSummary?.groupedSkusByResult && (
        <>
          {Object.keys(approvedSkusSummary?.groupedSkusByResult).map(
            (skuStatus, skuStatusIndex) => {
              const statusKey =
                skuStatus as keyof typeof loanApplicationApprovedStatusesMapping;
              return (
                <div key={skuStatusIndex}>
                  <br />
                  <p>{loanApplicationApprovedStatusesMapping[statusKey]}</p>
                  <div className={classes.modalContent}>
                    <ul>
                      {approvedSkusSummary?.groupedSkusByResult[statusKey].map(
                        (sku, skuIndex) => (
                          <li key={skuIndex}>{sku.skuId}</li>
                        )
                      )}
                    </ul>
                  </div>
                </div>
              );
            }
          )}
        </>
      )}

      <br />
      <div className={classes.button}>
        <Button
          onClick={handleCloseApprovedSkusSummaryModal}
          backgroundColor={color.TEXTGREY}
          textColor={color.WHITE}
          children="Ok"
          width="20%"
        />
      </div>
    </div>
  );
};

export default ApproveSummary;
