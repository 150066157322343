import { FC, ReactNode } from "react";
import { useStyles } from "./OperationAndRiskLayout.styles";
import { ToastContainer } from "react-toastify";
import { IconBuilding, IconUser } from "@tabler/icons-react";
import AdminNavBar, {
  IMenuItem,
} from "../../components/AdminNavBar/AdminNavBar";

interface IProps {
  content: ReactNode;
}

const menus: IMenuItem[] = [
  {
    label: "Organizations",
    path: "/org-list",
    icon: IconBuilding,
  },
  {
    label: "Individuals",
    path: "/individual-list",
    icon: IconUser,
  },
];

const OperationAndRiskLayout: FC<IProps> = ({ content }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.mainContainer}>
      <AdminNavBar menus={menus} />
      <ToastContainer />
      <div className={classes.hero}>{content}</div>
    </div>
  );
};

export default OperationAndRiskLayout;
