import { createStyles } from "@mantine/core";

export const useStyle = createStyles(() => ({
  trStyle: {
    "&:nth-child(even)": { backgroundColor: "#f9f9f9" },
  },
  thStyle: {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "left",
    backgroundColor: "#f2f2f2",
    color: "black",
  },
  tableStyle: {
    width: "100%",
    borderCollapse: "collapse",
  },
  thTdStyle: {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "left",
  },
}));
