import { FC, useState } from "react";
import { Badge, Modal, Table } from "@mantine/core";
import { useStyle } from "./CustomTableOrgDetails.styles";
import { getKycColor } from "../../Utils/kyc";
import { capitalizeFirstLetter } from "@funded-here-interface/common/src/Utils/string";
import { getName } from "country-list";
import { Persona } from "@funded-here-interface/common/src/constant/enum";
import OrgDetails from "../OrgDetails/OrgDetails";
import { useDisclosure } from "@mantine/hooks";
import { convertDate } from "@funded-here-interface/common/src/Utils/date";

interface IProps {
  data: any;
  refetchList: () => void;
}

interface ISignDocument {
  documentName: string;
  personSigning: IPersonSigning[];
}

interface IPersonSigning {
  email: string;
  name: string;
  roleName: string;
  status: "pending" | "signed";
}

const CustomTableForOrgDetails: FC<IProps> = ({ data, refetchList }) => {
  const [opened, { open, close }] = useDisclosure(false);
  const { classes } = useStyle();
  const [id, setId] = useState<number>(0);
  const [role, setRole] = useState("");

  const getSignatureStatus = (signDocument: ISignDocument[]) => {
    let result = "-";
    if (signDocument && signDocument.length !== 0) {
      const signDoc = signDocument.filter(
        (e: any) => e.documentName === "Master SKU Agreement"
      );
      if (signDoc.length !== 0) {
        for (const signer of signDoc[0].personSigning) {
          if (signer.name === "Kenneth") {
            result = "pending";
            if (signer.status === "signed") {
              result = "signed";
            }

            break;
          }
        }
      }
    }
    return result;
  };

  const getSignColor = (signDocument: ISignDocument[]) => {
    const sign = getSignatureStatus(signDocument);
    switch (sign) {
      case "signed":
        return "green";
      case "pending":
        return "orange";

      default:
        return "green";
    }
  };

  // TODO: handle multiple roles in one org
  const rows = data.map((row: any, index: number) => (
    <tr
      key={index}
      onClick={() => {
        open();
        setRole(row?.user[0]?.orgRole[0]?.role?.name);
        setId(row.id);
      }}
    >
      <td className={classes.td}>
        {row?.createdDate ? convertDate(row.createdDate) : "-"}
      </td>
      <td className={classes.td}>{row?.companyName || "-"}</td>
      <td className={classes.td}>{row?.companyRegistrationNo || "-"}</td>
      <td className={classes.td}>
        {row?.nameOfAuthorisedRepresentiative || "-"}
      </td>
      <td className={classes.td}>
        {getName(row?.countryISOCode || "") || "-"}
      </td>
      <td className={classes.td}>
        {capitalizeFirstLetter(row?.user[0]?.orgRole[0]?.role?.name) || "-"}
      </td>
      <td>
        <Badge color={getKycColor(row?.kycStatus)}>
          {row?.kycStatus || "-"}
        </Badge>
      </td>
      <td>
        {row?.spv?.kycStatus ? (
          <Badge color={getKycColor(row?.spv?.kycStatus)}>
            {row?.spv?.kycStatus}
          </Badge>
        ) : (
          "-"
        )}
      </td>
      <td>
        {getSignatureStatus(row?.signDocument) !== "-" ? (
          <Badge color={getSignColor(row?.signDocument)}>
            {getSignatureStatus(row?.signDocument)}
          </Badge>
        ) : (
          "-"
        )}
      </td>
    </tr>
  ));

  return (
    <div className={classes.tableFlex}>
      <Table
        highlightOnHover
        withBorder
        withColumnBorders
        className={classes.csvTable}
      >
        <thead>
          <tr className={classes.tr}>
            <th className={classes.th}>
              <p className={classes.thinside}>Onboarding Date</p>
            </th>
            <th className={classes.th}>
              <p className={classes.thinside}>Org Name</p>
            </th>
            <th className={classes.th}>
              <p className={classes.thinside}>Registration No.</p>
            </th>
            <th className={classes.th}>
              <p className={classes.thinside}>Auth Rep Name</p>
            </th>
            <th className={classes.th}>
              <p className={classes.thinside}>Country</p>
            </th>
            <th className={classes.th}>
              <p className={classes.thinside}>Role</p>
            </th>
            <th className={classes.th}>
              <p className={classes.thinside}>KYC Status</p>
            </th>
            <th className={classes.th}>
              <p className={classes.thinside}>SPV KYC Status</p>
            </th>
            <th className={classes.th}>
              <p className={classes.thinside}>Signature</p>
            </th>
          </tr>
        </thead>
        <tbody style={{ cursor: "pointer" }}>{rows}</tbody>
      </Table>

      <Modal
        opened={opened}
        onClose={close}
        size="85%"
        withCloseButton={false}
        padding="0"
      >
        <OrgDetails
          id={id}
          role={role as Persona}
          close={close}
          refetchList={refetchList}
        />
      </Modal>
    </div>
  );
};

export default CustomTableForOrgDetails;
