import { createSlice } from "@reduxjs/toolkit";

const BorbankSlice = createSlice({
  name: "BorBank",
  initialState: {
    bankName: null,
    accountNumber: null,
    accountBan: null,
    accountHolderName: null,
    swiftCode: null,
  },
  reducers: {
    setBankNameRedux: (state, { payload: { bankName } }) => {
      state.bankName = bankName;
    },
    setAccountNoRedux: (state, { payload: { accountNumber } }) => {
      state.accountNumber = accountNumber;
    },
    setAccountBanRedux: (state, { payload: { accountBan } }) => {
      state.accountBan = accountBan;
    },
    setAccountHolderNameRedux: (state, { payload: { accountHolderName } }) => {
      state.accountHolderName = accountHolderName;
    },
    setSwiftCodeReduct: (state, { payload: { swiftCode } }) => {
      state.swiftCode = swiftCode;
    },
  },
});

export const {
  setBankNameRedux,
  setAccountNoRedux,
  setAccountBanRedux,
  setAccountHolderNameRedux,
  setSwiftCodeReduct,
} = BorbankSlice.actions;

export default BorbankSlice.reducer;
