import {
  TextInput,
  createStyles,
  Text,
  Group,
  MantineTheme,
} from "@mantine/core";
import { CSS } from "@mantine/styles/lib/tss/types";
import { ChangeEvent, FC, useEffect } from "react";

type Props = {
  labelText: string;
  id: string;
  style?: CSS;
  isErrored?: string;
  value: string | number;
};

const InputGroupDisable: FC<Props> = ({
  labelText,
  id,
  style,
  isErrored,
  value,
}) => {
  const useStyles = createStyles((theme: MantineTheme) => ({
    mainContainer: {},
    text: {
      paddingBottom: "0px",
      paddingLeft: "0px",
      color: "#3C4257",
      opacity: 50,
      textAlign: "left",
    },
    inputField: {
      width: "378px",
      marginBottom: "20px",
      border: "1px #D9DCE1 solid",
      paddingLeft: "15px",
      paddingBottom: "7px",
      paddingTop: "7px",
      borderRadius: "5px",
      color: "gray",
      paddingRight: "15px",
    },
    errorMessage: {
      color: "red",
      position: "relative",
      bottom: "20px",
      fontSize: "13px",
    },
  }));
  const { classes } = useStyles();

  return (
    <div className={classes.mainContainer}>
      <Group position="left" mb={5}>
        <Text className={classes.text} component="label" htmlFor={id} size="sm">
          {labelText}
        </Text>
      </Group>
      <div className={classes.inputField}>
        <Text>{value}</Text>
      </div>

      {isErrored && <div className={classes.errorMessage}>{isErrored}</div>}
    </div>
  );
};

export default InputGroupDisable;
