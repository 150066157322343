import { FC } from "react";
import { useStyles } from "./rbpDistribution.styles";
import { Text } from "@mantine/core";
import { BarGraphChart } from "@funded-here-interface/common";

type OriginalDataItem = {
  name: string;
  value: number;
};

type RBPType = {
  RBPData: OriginalDataItem[];
  selctedRBPData: any[];
  scale: number;
};

const RbpDistribution: FC<RBPType> = ({ RBPData, selctedRBPData, scale }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <Text className={classes.chartTitle}>RBP Distribution</Text>

      <BarGraphChart
        chartData={RBPData}
        XAxisLabel="Days"
        YAxisLabel="# of SKU"
        selectedPoolData={selctedRBPData}
        scale={scale}
        YAxisScaleInterval={1}
      />
    </div>
  );
};

export default RbpDistribution;
