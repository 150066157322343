import { FC } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useStyles } from "./BarChart.styles";

type Props = {
  chartData: Array<{
    name: string;
    value: number;
  }>;
  XAxisLabel: string;
  YAxisLabel: string;
  YAxisScaleInterval: number;
  selectedPoolData: any[];
  scale: number;
};

const CustomizedAxisTick = ({ x, y, payload }: any) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={12}
        textAnchor="end"
        fill="#666"
        fontSize={10}
        transform="rotate(-60)"
      >
        {payload.value}
      </text>
    </g>
  );
};

const BarGraphChart: FC<Props> = ({
  chartData,
  YAxisLabel,
  YAxisScaleInterval,
  XAxisLabel,
  selectedPoolData,
  scale,
}) => {
  const { classes } = useStyles();
  const CustomTooltip = ({ payload, active, label }: any) => {
    if (active && label) {
      let [startRange, endRange] = label.split(" - ").map(parseFloat);

      startRange = (startRange / scale).toFixed(2);
      endRange = (endRange / scale).toFixed(2);

      const itemsInRange = selectedPoolData.filter((item: any) => {
        return item >= startRange && item <= endRange;
      });

      const ltvValues = itemsInRange
        .map((item: any) => (parseFloat(item) * scale).toFixed(2))
        .join(", ");

      return (
        <div className={classes.customTooltip}>
          <p className="label">{`Values: ${ltvValues ? ltvValues : "0"}`}</p>
          <p className="value">{`Count: ${payload[0].payload.value}`}</p>
        </div>
      );
    }

    return null;
  };
  return (
    <ResponsiveContainer>
      <BarChart data={chartData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" tick={<CustomizedAxisTick />} dy={10} />
        <YAxis
          label={{
            value: YAxisLabel,
            angle: -90,
            position: "insideLeft",
          }}
          tickCount={YAxisScaleInterval}
        />
        <Tooltip content={<CustomTooltip />} />
        <Bar dataKey="value" fill="#8884d8" />
        <Legend
          formatter={(value) => XAxisLabel}
          wrapperStyle={{ position: "relative", marginTop: "20px" }}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarGraphChart;
