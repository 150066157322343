import { BASE_URL } from "@funded-here-interface/common/src/constant/constant";
import axios from "axios";
import { getApiResponseErrorMsg } from "@funded-here-interface/common/src/Utils/apiHelper";
import { IspvStatusEnum } from "@funded-here-interface/common/src/constant/enum";

interface GetIspvRequest {
  token: string;
  status?: IspvStatusEnum;
}

export interface GetAllocateSkuResponse {
  id: number;
  name: string;
  orgId: number;
  SNIssueNumber: number;
  status: IspvStatusEnum;
  onBoardingStatus: string;
  createdDate: Date;
  SNIssueNumberDate: Date;
}

const getIspv = async ({
  token,
  status,
}: GetIspvRequest): Promise<GetAllocateSkuResponse[]> => {
  try {
    let url = `${BASE_URL}/sku-finance/ispv`;

    if (status) {
      url = `${BASE_URL}/sku-finance/ispv?status=${status}`;
    }

    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    });

    return response.data;
  } catch (e) {
    const message = getApiResponseErrorMsg(e);

    throw new Error(message);
  }
};

export default getIspv;
