import { FC, useEffect, useMemo, useState } from "react";
import useGetCampaignMutation from "../../hooks/useGetCampaign";
import { toast } from "react-toastify";
import { useStyle } from "./CampaignList.styles";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import {
  MRT_ColumnDef,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import { CampaignResponse } from "../../services/getCampaign";
import { convertDate } from "@funded-here-interface/common/src/Utils/date";
import { Divider, Title } from "@mantine/core";

const CampaignList: FC = () => {
  const { classes } = useStyle();
  const [data, setData] = useState<CampaignResponse[]>([]);
  const [isLoading, setisLoading] = useState(true);
  const token = useSelector((state: RootState) => state.auth.token);
  const campaignMutation = useGetCampaignMutation();

  useEffect(() => {
    setisLoading(true);
    campaignMutation.mutate(
      { token },
      {
        onSuccess: (data) => {
          setData(
            data.sort(
              (a, b) =>
                new Date(b["Createddate"]).getTime() -
                new Date(a["Createddate"]).getTime()
            )
          );
          setisLoading(false);
        },
        onError: (error) => {
          setisLoading(false);
          toast.error((error as Error).message);
        },
      }
    );
  }, []);

  const columns = useMemo<MRT_ColumnDef<CampaignResponse>[]>(
    () => [
      {
        accessorKey: "Campaign",
        header: "Campaign",
      },
      {
        accessorKey: "Createddate",
        header: "Created Date",
        Cell: ({ cell }) => <p>{convertDate(cell.getValue<string>())}</p>,
      },
      {
        accessorKey: "Country",
        header: "Country",
      },
      {
        accessorKey: "upc",
        header: "Upcs",
        enableSorting: false,
        Cell: ({ cell }) => {
          const upcs = cell.getValue<string[]>();
          const [showAll, setShowAll] = useState(false);

          const limitedUpcs = showAll ? upcs : upcs.slice(0, 5);

          return (
            <>
              <ul>
                {limitedUpcs.map((upc, index) => (
                  <li key={index}>{upc}</li>
                ))}
              </ul>
              {upcs.length > 5 && (
                <button
                  onClick={() => setShowAll(!showAll)}
                  style={{
                    background: "none",
                    color: "blue",
                    border: "none",
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                >
                  {showAll ? "See Less" : "See More"}
                </button>
              )}
            </>
          );
        },
      },
    ],
    []
  );

  const table = useMantineReactTable({
    enableColumnActions: false,
    enableColumnFilters: false,
    paginationDisplayMode: "pages",
    state: { isLoading },
    columns,
    data: data,
    initialState: { density: "xs" },
    mantineTableProps: {
      withBorder: true,
    },
  });

  return (
    <div>
      <Title>Campaigns</Title>
      <Divider my="md" className={classes.divider} />
      <MantineReactTable table={table} />
    </div>
  );
};

export default CampaignList;
