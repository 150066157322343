import { FC } from "react";
import { useStyle } from "./VirtualAccounts.styles";
import { Tabs } from "@mantine/core";
import CurrentBalance from "./Tabs/CurrentBalance";
import Transactions from "./Tabs/Transactions";
import InternalTransfer from "./Tabs/InternalTransfer";

const VirtualAccounts: FC = () => {
  const { classes } = useStyle();

  return (
    <div>
      <div>
        <h1 className={classes.headerText}>Virtual Accounts</h1>
      </div>

      <Tabs defaultValue="currentBalance">
        <Tabs.List>
          <Tabs.Tab value="currentBalance">Current Balances</Tabs.Tab>
          <Tabs.Tab value="transactions">Transactions</Tabs.Tab>
          <Tabs.Tab value="internalTransfer">Internal Fund Transfer</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="currentBalance" pt="xs">
          <CurrentBalance />
        </Tabs.Panel>

        <Tabs.Panel value="transactions" pt="xs">
          <Transactions />
        </Tabs.Panel>

        <Tabs.Panel value="internalTransfer" pt="xs">
          <InternalTransfer />
        </Tabs.Panel>
      </Tabs>
    </div>
  );
};

export default VirtualAccounts;
