import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Text, Tabs, MantineProvider } from "@mantine/core";
import { useStyles } from "./skuPooling.styles";
import LTVGM from "./SubTabs/LTVGM";
import RbpDistribution from "./SubTabs/RbpDistribution";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import usePoolingSku from "../../../hooks/usePoolingSku";
import { toast } from "react-toastify";
import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import { convertDate } from "@funded-here-interface/common/src/Utils/date";
import { capitalizeFirstLetter } from "@funded-here-interface/common/src/Utils/string";

interface PoolingSku {
  id: number;
  createdDate: string;
  sku: string;
  purchaseCost: string;
  doi: string;
  country: string;
  industry: string;
  merchant: string;
  ltv: string;
  grossMarginPercentage: string;
  RBP: string;
  deliveryDate: string;
  fixedTenure: string;
}

interface SkuPoolingProps {
  selectedRows: number[];
  setSelectedRows: Dispatch<SetStateAction<number[]>>;
}

const SkuPooling: FC<SkuPoolingProps> = ({ selectedRows, setSelectedRows }) => {
  const { classes } = useStyles();
  const [poolingData, setPoolingData] = useState<PoolingSku[]>([]);
  const token = useSelector((state: RootState) => state.auth.token);
  const usePoolingSkuMutation = usePoolingSku();
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    usePoolingSkuMutation.mutate(
      { token },
      {
        onSuccess: (data: PoolingSku[]) => {
          setPoolingData(
            data.sort(
              (a, b) =>
                new Date(b["createdDate"]).getTime() -
                new Date(a["createdDate"]).getTime()
            )
          );
          setIsLoading(false);
        },
        onError: (e) => {
          toast.error((e as Error).message);
        },
      }
    );
  }, [token]);

  useEffect(() => {
    const selectedIds = Object.keys(rowSelection)
      .filter((key) => rowSelection[key])
      .map(Number);

    setSelectedRows(selectedIds);
  }, [rowSelection, setSelectedRows]);

  const totalPurchaseCost = () => {
    if (selectedRows.length === 0) {
      return 0;
    }

    const selectedData = poolingData.filter((item: any) =>
      selectedRows.includes(item.id)
    );

    const totalPurchaseCost = selectedData.reduce(
      (sum, item: any) => sum + parseFloat(item.purchaseCost),
      0
    );

    const formattedTotalPurchaseCost = `SGD ${totalPurchaseCost
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;

    return formattedTotalPurchaseCost;
  };

  const hightDoi = () => {
    if (selectedRows.length === 0) {
      return 0;
    }

    const selectedData = poolingData.filter((item: any) =>
      selectedRows.includes(item.id)
    );

    const selectedUniqueFixedTenures = Array.from(
      new Set(selectedData.map((item) => item.fixedTenure))
    );

    if (selectedUniqueFixedTenures.length > 1) {
      return "SKUs with multiple fixed tenure. Invalid tenure ";
    }

    if (selectedUniqueFixedTenures[0] !== null) {
      return selectedUniqueFixedTenures[0];
    }

    const doiValues = selectedData.map((item: any) => parseFloat(item.doi));

    const highestDoi = Math.max(...doiValues);

    const roundedDoi = Math.ceil(highestDoi / 30) * 30;

    return roundedDoi;
  };

  const selectedPoolData: any = poolingData.filter((item: any) =>
    selectedRows.includes(item.id)
  );
  const selctedLtvData: any = selectedPoolData.map((item: any) =>
    parseFloat(item.ltv)
  );
  const selctedGmData = selectedPoolData.map((item: any) =>
    parseFloat(item.grossMarginPercentage)
  );
  const selctedRBPData = selectedPoolData.map((item: any) =>
    parseFloat(item.RBP)
  );

  const fixedSections = generateFixedSections(0, 1, 0.01);

  const ltvGmScale: number = 100;
  const rbpScale: number = 1;

  const LTVdata = fixedSections.map((section) => {
    const count = selectedPoolData.filter((item: any) => {
      const ltv = parseFloat(item.ltv);
      return ltv >= section.startRange && ltv < section.endRange;
    }).length;

    return {
      name: `${(section.startRange * ltvGmScale).toFixed(0)} - ${(
        section.endRange * ltvGmScale
      ).toFixed(0)}`,
      value: count,
    };
  });

  const GMdata = fixedSections.map((section) => {
    const count = selectedPoolData.filter((item: any) => {
      const GM = parseFloat(item.grossMarginPercentage);
      return GM >= section.startRange && GM < section.endRange;
    }).length;
    return {
      name: `${(section.startRange * ltvGmScale).toFixed(0)} - ${(
        section.endRange * ltvGmScale
      ).toFixed(0)}`,
      value: count,
    };
  });

  const fixedSectionsRBP = generateFixedSections(0, 100000, 1);

  function generateFixedSections(
    startRange: number,
    endRange: number,
    interval: number
  ) {
    const fixedSections = [];
    for (let i = startRange; i < endRange; i += interval) {
      fixedSections.push({ startRange: i, endRange: i + interval });
    }
    return fixedSections;
  }

  const RBPDistributionData = fixedSectionsRBP.map((section) => {
    const count = selectedPoolData.filter((item: any) => {
      const RBP = parseFloat(item.RBP);
      return RBP >= section.startRange && RBP < section.endRange;
    }).length;
    return {
      name: `${section.startRange} - ${section.endRange}`,
      value: count,
    };
  });

  const columns = useMemo<MRT_ColumnDef<PoolingSku>[]>(
    () => [
      {
        accessorKey: "createdDate",
        header: "Date",
        sortDescFirst: true,
        Cell: ({ cell }) => <p>{convertDate(cell.getValue<string>())}</p>,
      },
      {
        accessorKey: "sku",
        header: "SKU Id",
      },
      {
        accessorKey: "purchaseCost",
        header: "Invoice",
      },
      {
        accessorKey: "doi",
        header: "DOI",
      },
      {
        accessorKey: "country",
        header: "Country",
        Cell: ({ cell }) => (
          <p>{capitalizeFirstLetter(cell.getValue<string>())}</p>
        ),
      },
      {
        accessorKey: "industry",
        header: "Industry",
        Cell: ({ cell }) => (
          <p>{capitalizeFirstLetter(cell.getValue<string>())}</p>
        ),
      },
      {
        accessorKey: "merchant",
        header: "Merchant",
        Cell: ({ cell }) => (
          <p>{capitalizeFirstLetter(cell.getValue<string>())}</p>
        ),
      },
      {
        accessorKey: "deliveryDate",
        header: "Expected Delivery Date",
        Cell: ({ cell }) => <p>{convertDate(cell.getValue<string>())}</p>,
      },
      {
        accessorKey: "fixedTenure",
        header: "Tenure",
      },
    ],
    []
  );

  const table = useMantineReactTable({
    enableRowSelection: true,
    enableColumnActions: false,
    enableColumnFilters: false,
    paginationDisplayMode: "pages",
    onRowSelectionChange: setRowSelection,
    state: { rowSelection, isLoading },
    getRowId: (row) => row?.id?.toString(),
    columns,
    data: poolingData,
    initialState: { density: "xs" },
    mantineTableProps: {
      withBorder: true,
    },
  });

  //  Remove redundant x-axis sections if there are no values in that section
  const filterChartData = (chartData: any) => {
    let filteredLtvChartData: any[] = [];

    for (let i = 0; i < chartData.length; i++) {
      const data = chartData[i];

      if (data.value > 0) {
        filteredLtvChartData.push(data);
      }
    }

    return filteredLtvChartData;
  };

  return (
    <div>
      <Text className={classes.skuLoanTitle}>Select SKU Loans</Text>
      <div className={classes.skuLoanContainer}>
        <MantineProvider theme={{ cursorType: "pointer" }}>
          <MantineReactTable table={table} />
        </MantineProvider>
        <div className={classes.skuLoanSummaryContainer}>
          <Text>
            Total Invoice Amount{" "}
            <a className={classes.skuSummaryValues}>{totalPurchaseCost()}</a>
          </Text>
          <Text>
            Tenure{" "}
            <a className={classes.skuSummaryValues}> {hightDoi()} days </a>
          </Text>
        </div>
      </div>
      <div className={classes.skuLoansGraphsContainer}>
        <Tabs defaultValue="ltv/gm">
          <Tabs.List>
            <Tabs.Tab value="ltv/gm">LTV/GM</Tabs.Tab>
            <Tabs.Tab value="rbp_distribution">RBP Distribution</Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="ltv/gm">
            <LTVGM
              LTVData={filterChartData(LTVdata)}
              GMData={filterChartData(GMdata)}
              selectedLtvData={selctedLtvData}
              selectedGmData={selctedGmData}
              scale={ltvGmScale}
            />
          </Tabs.Panel>
          <Tabs.Panel value="rbp_distribution">
            <RbpDistribution
              RBPData={filterChartData(RBPDistributionData)}
              selctedRBPData={selctedRBPData}
              scale={rbpScale}
            />
          </Tabs.Panel>
        </Tabs>
      </div>
    </div>
  );
};
export default SkuPooling;
