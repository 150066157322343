import { Divider, Modal, Title } from "@mantine/core";
import { FC, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useStyle } from "./PmVerifySkuLoanDetails.styles";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
import { Button, color } from "@funded-here-interface/common";
import VerifySkuLoanDetailsMantineTable from "./Components/Table/VerifySkuLoanDetailsMantineTable";
import { InvoiceGroup } from "../../services/getLoanForVerification";
import useDownloadPo from "../../hooks/useDownloadPo";
import { downloadFile } from "@funded-here-interface/common/src/Utils/file";
import { FileType } from "@funded-here-interface/common/src/constant/enum";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useDisclosure } from "@mantine/hooks";
import VerifyInvoiceSummary from "../../components/Modal/VerifySkuSummary";
import useDownloadSkuLoanCsv from "../../hooks/useDownloadSkuLoanCsv";
import useRejectSkuLoan from "../../hooks/useRejectSkuLoan";
import useApproveSkuLoans from "../../hooks/useApproveSkuLoans";
import useGetLoanForVerification from "../../hooks/useGetLoanForVerification";
import ApproveSummary from "./Components/ApproveSummary/ApproveSummary";
import { ApprovedSkusLoanResponse } from "../../services/approveSkuLoan";
import { MRT_RowSelectionState } from "mantine-react-table";

const PmVerifySkuLoanDetails: FC = () => {
  const { classes } = useStyle();
  const location = useLocation();
  const orgName: InvoiceGroup = location.state?.orgName;
  const navigate = useNavigate();
  const [allSelectedIdsMap, setAllSelectedIdsMap] = useState<{
    [key: string]: string[];
  }>({});
  const [allSelectedIds, setAllSelectedIds] = useState<string[]>([]);
  const useDownloadPoMutation = useDownloadPo();
  const token = useSelector((state: RootState) => state.auth.token);
  const [
    approveModalOpened,
    { open: openApproveModal, close: closeApproveModal },
  ] = useDisclosure(false);
  const [
    rejectModalOpened,
    { open: openRejectModal, close: closeRejectModal },
  ] = useDisclosure(false);
  const [
    approveSummmaryModalOpened,
    { open: openApproveSummaryModal, close: closeApproveSummaryModal },
  ] = useDisclosure(false);
  const [isApproveSkuLoading, setIsApproveSkuLoading] = useState(false);
  const [isRejectSkuLoading, setIsRejectSkuLoading] = useState(false);
  const useDownloadSkuLoanCsvMutaiton = useDownloadSkuLoanCsv();
  const useRejectSkuLoanMutation = useRejectSkuLoan();
  const [skuDetails, setSkuDetails] = useState<InvoiceGroup>({});
  const useApproveSkuLoansMutation = useApproveSkuLoans();
  const useGetLoanForVerificationMutation = useGetLoanForVerification();
  const [loanApplicationSummary, setLoanApplicationSummary] =
    useState<ApprovedSkusLoanResponse>();
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

  const getData = () => {
    useGetLoanForVerificationMutation.mutate(
      { token },
      {
        onSuccess: (data) => {
          const skuLoans = data[`${orgName}`];

          if (!skuLoans) {
            navigate("/verify-sku-loans");
          } else {
            setSkuDetails(skuLoans);
          }
        },
        onError: (error) => {
          toast.error((error as Error).message);
        },
      }
    );
  };

  useEffect(() => {
    if (!orgName) {
      navigate("/verify-sku-loans");
    } else {
      getData();
    }
  }, [orgName]);

  useEffect(() => {
    const allValues = Object.values(allSelectedIdsMap);
    setAllSelectedIds(allValues.flatMap((value) => value));
  }, [allSelectedIdsMap]);

  const updateAllSelectedIds = (tableId: string, selectedIds: string[]) => {
    setAllSelectedIdsMap((prevSelectedIds) => {
      const updatedSelections = { ...prevSelectedIds };
      Object.keys(updatedSelections).forEach((key) => {
        if (key !== tableId) {
          updatedSelections[key] = updatedSelections[key].filter(
            (id) => !selectedIds.includes(id)
          );
        }
      });

      updatedSelections[tableId] = selectedIds;
      return updatedSelections;
    });
  };

  const handleDownloadPo = (poId: string) => {
    useDownloadPoMutation.mutate(
      { token, poId },
      {
        onSuccess: (data: Blob) => {
          downloadFile(data, `PO_${poId}`, FileType.PDF);
        },
        onError: (e) => {
          toast.error((e as Error).message);
        },
      }
    );
  };

  const handleApproveSkus = () => {
    setIsApproveSkuLoading(true);
    useApproveSkuLoansMutation.mutate(
      { token, skuIds: allSelectedIds },
      {
        onSuccess: (data) => {
          toast.success(`SKUs approved successfully`);
          setLoanApplicationSummary(data);
          setRowSelection({});
          closeApproveModal();
          setIsApproveSkuLoading(false);
          openApproveSummaryModal();
        },
        onError: (error) => {
          toast.error((error as Error).message);
          setIsApproveSkuLoading(false);
        },
      }
    );
  };

  const handleRejectSkus = () => {
    setIsRejectSkuLoading(true);
    useRejectSkuLoanMutation.mutate(
      { token, skuIds: allSelectedIds },
      {
        onSuccess: () => {
          toast.success(`SKUs rejected  successfully`);
          setRowSelection({});
          getData();
          closeRejectModal();
          setIsRejectSkuLoading(false);
        },
        onError: (error) => {
          toast.error((error as Error).message);
          setIsRejectSkuLoading(false);
        },
      }
    );
  };

  const handleAcknowledgeApproveSummary = () => {
    getData();
    closeApproveSummaryModal();
  };

  const handleDownloadCsv = () => {
    useDownloadSkuLoanCsvMutaiton.mutate(
      { token },
      {
        onSuccess: (data: Blob) => {
          downloadFile(
            data,
            "Sku Loan Application for PM Verification",
            FileType.CSV
          );
        },
        onError: (e) => {
          toast.error((e as Error).message);
        },
      }
    );
  };

  return (
    <div>
      <div className={classes.header}>
        <Link to="/verify-sku-loans">
          <FaArrowLeftLong style={{ color: color.FHADMIN, fontSize: 32 }} />
        </Link>
        <h1 className={classes.headerText}>CSV Details</h1>
      </div>
      <Divider my="md" className={classes.divider} />
      <Modal
        opened={approveModalOpened}
        onClose={closeApproveModal}
        size="lg"
        title={
          <Title order={4} style={{ fontWeight: "bold" }}>
            Confirmation of SKUs
          </Title>
        }
      >
        <VerifyInvoiceSummary
          isLoading={isApproveSkuLoading}
          skuIds={allSelectedIds}
          onSubmit={handleApproveSkus}
          header="The SKUs below will be approved:"
        />
      </Modal>
      <Modal
        opened={rejectModalOpened}
        onClose={closeRejectModal}
        size="lg"
        title={
          <Title order={4} style={{ fontWeight: "bold" }}>
            Confirmation of SKUs
          </Title>
        }
      >
        <VerifyInvoiceSummary
          isLoading={isRejectSkuLoading}
          skuIds={allSelectedIds}
          onSubmit={handleRejectSkus}
          header="The SKUs below will be rejected:"
        />
      </Modal>
      <Modal
        opened={approveSummmaryModalOpened}
        onClose={handleAcknowledgeApproveSummary}
        size="lg"
        title={
          <Title order={4} style={{ fontWeight: "bold" }}>
            Loan Application Status
          </Title>
        }
      >
        <ApproveSummary
          approvedSkusSummary={loanApplicationSummary}
          handleCloseApprovedSkusSummaryModal={handleAcknowledgeApproveSummary}
        />
      </Modal>
      <div className={classes.buttonWrapper}>
        <Button
          onClick={openApproveModal}
          backgroundColor={color.FHGREEN}
          textColor="#fff"
          children="Approve"
          disabled={allSelectedIds.length === 0}
          width="154px"
        />
        <Button
          onClick={openRejectModal}
          backgroundColor={color.REJECT}
          textColor="#fff"
          children="Reject"
          disabled={allSelectedIds.length === 0}
          width="154px"
        />
        <Button
          onClick={handleDownloadCsv}
          backgroundColor={color.LOANTEXT}
          textColor="#fff"
          children="Download CSV"
          width="154px"
        />
      </div>
      {!skuDetails ||
        (Object.keys(skuDetails).length !== 0 && (
          <>
            {Object.entries(skuDetails).map(([key, value], index, array) => (
              <div key={key}>
                <VerifySkuLoanDetailsMantineTable
                  onUpdateSelectedIds={(tableId, selectedIds) =>
                    updateAllSelectedIds(tableId, selectedIds)
                  }
                  poId={key}
                  onDownloadPo={(poId) => handleDownloadPo(poId)}
                  data={value}
                  setRowSelection={setRowSelection}
                  rowSelection={rowSelection}
                />
                {index !== array.length - 1 && (
                  <Divider my="md" className={classes.divider} />
                )}
              </div>
            ))}
          </>
        ))}
    </div>
  );
};

export default PmVerifySkuLoanDetails;
