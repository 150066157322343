import { MantineTheme, createStyles } from "@mantine/styles";

export const useStyles = createStyles((theme: MantineTheme) => ({
  divider: {
    marginBottom: "40px !important",
  },
  view: {
    color: "#3786D6",
    cursor: "pointer",
  },
}));
