import { FC } from "react";
import { useStyle } from "./LoanDetails.styles";
import { Tabs } from "@mantine/core";
import Application from "./Components/Tabs/Application";
import LtvSf from "./Components/Tabs/LtvSf";
import Loa from "./Components/Tabs/Loa";
import Disbursement from "./Components/Tabs/Disbursement";
import VerifyInvoice from "./Components/Tabs/VerifyInvoice";
import { LoanTab } from "../../constant/enum";
import { Link, useLocation } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
import { color } from "@funded-here-interface/common";

const LoanDetails: FC = () => {
  const { classes } = useStyle();
  const location = useLocation();
  const tab = location.state?.tab;
  const backPath = location.state?.backPath;

  return (
    <div>
      <div className={classes.header}>
        {backPath && (
          <Link to="/note-list">
            <FaArrowLeftLong style={{ color: color.FHADMIN, fontSize: 32 }} />
          </Link>
        )}
        <h1 className={classes.headerText}>Loan Details</h1>
      </div>

      <Tabs defaultValue={tab ?? LoanTab.APPLICATION}>
        <Tabs.List>
          <Tabs.Tab value={LoanTab.APPLICATION}>Application</Tabs.Tab>
          <Tabs.Tab value={LoanTab.LTV_SF}>LTV & SF</Tabs.Tab>
          <Tabs.Tab value={LoanTab.LOA}>LOA</Tabs.Tab>
          <Tabs.Tab value={LoanTab.INVOICES}>Verify Invoices</Tabs.Tab>
          <Tabs.Tab value={LoanTab.DISBURSEMENT}>Disbursement</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value={LoanTab.APPLICATION} pt="xs">
          <Application />
        </Tabs.Panel>
        <Tabs.Panel value={LoanTab.LTV_SF} pt="xs">
          <LtvSf />
        </Tabs.Panel>
        <Tabs.Panel value={LoanTab.LOA} pt="xs">
          <Loa />
        </Tabs.Panel>
        <Tabs.Panel value={LoanTab.INVOICES} pt="xs">
          <VerifyInvoice />
        </Tabs.Panel>
        <Tabs.Panel value={LoanTab.DISBURSEMENT} pt="xs">
          <Disbursement />
        </Tabs.Panel>
      </Tabs>
    </div>
  );
};

export default LoanDetails;
