import axios from "axios";
import { BASE_URL } from "@funded-here-interface/common/src/constant/constant";
import { getApiResponseErrorMsg } from "@funded-here-interface/common/src/Utils/apiHelper";

interface GetSkuPoOcrReviewRequest {
  token: string;
  orgRoleId: number;
}

export interface GetSkuPoOcrReviewResponse {
  id: number;
  upc?: string;
  orgRoleId: number;
  documentId: string;
  documentName: string;
  status: string;
  documentPath: string;
  poNumber?: string;
  poDate?: Date;
  deliveryDate?: Date;
  paymentTerm?: string;
  purchasePricePerUnit?: string;
  sellingPricePerUnit?: string;
  quantityPurchased?: number;
  discount?: string;
  tax?: string;
  nettInvoiceAmount?: string;
  skuReturnPercentage?: string;
  skuReturnMoreThanLimitReason?: string;
  partialPaymentFirstPaymentPercentage?: string;
  expectedPaymentDate?: Date;
  otherCosts?: string;
  createdAt: Date;
  updatedAt: Date;
}

const getSkuPoOcrReview = async ({
  token,
  orgRoleId,
}: GetSkuPoOcrReviewRequest): Promise<GetSkuPoOcrReviewResponse[]> => {
  try {
    const response = await axios.get(
      `${BASE_URL}/sku-finance/loan-application/sku-po-ocr-review/${orgRoleId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      }
    );

    return response.data;
  } catch (e) {
    const message = getApiResponseErrorMsg(e);
    throw new Error(message);
  }
};
export default getSkuPoOcrReview;
