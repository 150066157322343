import axios from "axios";
import { BASE_URL } from "@funded-here-interface/common/src/constant/constant";
import { getApiResponseErrorMsg } from "@funded-here-interface/common/src/Utils/apiHelper";
import { File as FileEnum } from "@funded-here-interface/common/src/constant/enum";

export interface ReuploadDocumentRequest {
  file: File;
  name: string;
  token: string;
  userId: number;
  fileType: FileEnum;
}

export interface ReuploadDocumentReponse {
  docName: string;
  path: string;
}

const reuploadDocument = async ({
  file,
  token,
  userId,
  name,
}: ReuploadDocumentRequest): Promise<ReuploadDocumentReponse> => {
  try {
    const response = await axios.post(
      `${BASE_URL}/trade-directory/upload/notarised-reupload`,
      { file, fileName: name, userId },
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      }
    );

    return response.data;
  } catch (e) {
    const message = getApiResponseErrorMsg(e);

    throw new Error(message);
  }
};

export default reuploadDocument;
