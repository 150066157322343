import { getApiResponseErrorMsg } from "@funded-here-interface/common/src/Utils/apiHelper";
import axios from "axios";
import { BASE_URL } from "../constant/constant";

export interface DownloadSkuPoCsvRequest {
  orgRoleId: number;
  token: string;
}

const downloadSkuPoCsv = async ({
  orgRoleId,
  token,
}: DownloadSkuPoCsvRequest) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/sku-finance/loan-application/download-sku-po-ocr-csv/${orgRoleId}`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob",
        withCredentials: true,
      }
    );

    return response.data;
  } catch (e) {
    const message = getApiResponseErrorMsg(e);

    throw new Error(message);
  }
};

export default downloadSkuPoCsv;
