export enum Persona {
  MERCHANT = "merchant",
  DISTRIBUTOR = "distributor",
  INVESTOR = "investor",
  MERCHANT_SHAREHOLDER = "merchant-shareholder",
  MERCHANT_DIRECTOR = "merchant-director",
  INVESTOR_SHAREHOLDER = "investor-shareholder",
  INVESTOR_DIRECTOR = "investor-director",
  MERCHANT_SPV = "merchant-spv",
  OTHERS = "others",
  AUTH_REP = "auth-rep",
  ISPV = "ispv",
  FUNDINGSPV = "funding-spv",
}

export enum KYCStatus {
  APPROVED = "approved",
  PENDING = "pending",
  REJECTED = "rejected",
}

export enum SpvStatus {
  PENDING_INITIATION = "pending-initiation",
  INITIATED = "initiated",
  COMPLETED = "completed",
}
export enum InvestorCategory {
  PRIVATE_INDIVIDUAL = "Private Placement Individual",
  PRIVATE_INSTITUTION = "Private Placement Investor",
  JUNIOR_INDIVIDUAL = "Junior Investor-Individual",
  JUNIOR_INSTITUTION = "Junior Investor-Institution",
}

export enum SVPCategory {
  ISPV = "ispv",
  FUNDINGSPV = "funding-spv",
}

export enum EntityTypeEnum {
  INDIVIDUAL = "individual",
  INSTITUTION = "institution",
}

export enum InvestorCategoryEnum {
  JUNIOR = "junior",
  SENIOR = "senior",
}

export enum InvestmentMode {
  AUTO = "auto",
  MANUAL = "manual",
}

export enum CompanyRole {
  SHAREHOLDER = "Shareholder",
  DIRECTOR = "Director",
  BOTH = "Both",
}

export enum ShareholderDirectorField {
  ROLE = "role",
  EMAIL = "email",
  FIRSTNAME = "firstName",
  LASTNAME = "lastName",
}

export enum IspvStatusEnum {
  AVAILABLE = "AVAILABLE",
  UNAVAILABLE = "UNAVAILABLE",
}

export enum FileType {
  PDF = "pdf",
  CSV = "csv",
  DOCX = "docx",
}

export enum SanctionIOStatus {
  APPROVED = "approved",
  PENDING = "pending",
  REJECTED = "rejected",
}

export enum DocuSignEventHandler {
  READY = "ready",
  SESSION_END = "sessionEnd",
}

export enum DocuSignEvent {
  SIGNING_COMPLETE = "signing_complete",
  CANCEL = "cancel",
  DECLINE = "decline",
  EXCEPTION = "exception",
  FAX_PENDING = "fax_pending",
  SESSION_TIMEOUT = "session_timeout",
  TTL_EXPIRED = "ttl_expired",
  VIEWING_COMPLETE = "viewing_complete",
}

export enum File {
  BUSINESS_PROFILE = "bussiness_profile",
  SHAREHOLDERS_DIRECTORS_REGISTERS = "shareholders_directors_registers",
  BALANCE_SHEET = "balance_sheet",
  PROOF_OF_ADDRESS = "proof_of_address",
  BANK_STATEMENT = "bank_statement",
  FINANCIAL_STATEMENT = "financial_statement",
  SALARY_PROOF = "salary_proof",
  INVESTMENT_MANDATE = "investment_mandate",
  FUND_MANAGER_LICENSE = "fund_manager_license",
}

export enum SpvFile {
  SPV_BUSINESS_PROFILE = "spv_business_profile",
  SPV_COMPANY_CONSTITUTION = "spv_company_constitution",
  SPV_CONSENT = "spv_consent",
  SPV_CERTIFICATE_OF_INCORPORATION = "spv_certificate_of_incorporation",
  SPV_REGISTER_OF_MEMBERS = "spv_register_of_members",
  SPV_REGISTER_OF_DIRECTORS = "spv_register_of_directors",
  SPV_ORGANIZATION_CHART = "spv_organization_chart",
  SPV_PROOF_OF_ADDRESS = "spv_proof_of_address",
  SPV_BANK_STATEMENT = "spv_bank_statement",
  SPV_BALANCE_SHEET = "spv_balance_sheet",
}

export enum SkuLoanApplicationPaymentTerm {
  CASH_ON_DELIVERY = "cash-on-delivery",
  PARTIAL_PAYMENT = "partial-payment",
  PAYMENT_ON_PURCHASE_ORDER = "payment-on-purchase-order",
}

export enum OcrType {
  PO = "purchaseOrder",
}
