import { Text } from "@mantine/core";
import { InputGroup, ForgotPasswordInput } from "@funded-here-interface/common";
import { ChangeEvent, FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setEnvironment, setToken } from "../../features/auth/authSlice";
import { useStyles } from "./LoginCss";
import { toast } from "react-toastify";
import useLoginMutation from "@funded-here-interface/common/src/hooks/useLogin";
import useGetUser from "@funded-here-interface/common/src/hooks/useGetUsers";
import Flogo from "../../assets/Funded_Here_LogoA4.svg";

const Login: FC = () => {
  const { classes } = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState({ email_error: "", pass_error: "" });
  const navigate = useNavigate();
  const mutation = useLoginMutation();
  const getUserMutation = useGetUser();
  const dispatch = useDispatch();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const newError = { email_error: "", pass_error: "" };
    if (!email) {
      newError.email_error = "Email is required";
    }
    if (!password) {
      newError.pass_error = "Password is required";
    }

    setError(newError);

    if (email && password) {
      mutation.mutate(
        { email, password },
        {
          onSuccess: async (data) => {
            if (data) {
              sessionStorage.setItem("Auth", data);
              const token = data;
              dispatch(setToken({ token }));
              getUserMutation.mutate(
                {
                  token,
                },
                {
                  onSuccess: (userData) => {
                    const data = userData.user
                    const role = data?.orgRole?.[0]?.role?.name;
                    const roleId = data?.currentOrgRoleId;

                    sessionStorage.setItem("orgRoleId", roleId);

                    dispatch(setEnvironment({ env: userData.environment }))

                    if (role === "product-manager") {
                      navigate("/pm-dashboard/overview");
                    } else if (role === "operation-and-risk") {
                      navigate("/org-list");
                    } else if (role === "sales-support") {
                      navigate("/sales-support/new");
                    } else {
                      toast.error("Credentials are not valid.");
                    }
                  },
                  onError: (e) => {
                    toast.error((e as Error).message);
                  },
                }
              );
            }
          },
          onError: (e) => {
            toast.error((e as Error).message);
          },
        }
      );
    }
  };

  return (
    <div className={classes.container}>
      <div>
        <img className={classes.logo} src={Flogo} alt="fh_logo" />
      </div>
      <div style={{ textAlign: "left" }}>
        <Text className={classes.loginHeading}>
          Welcome to the FundedHere <br /> Admin Portal
        </Text>
      </div>
      <div className={classes.loginContainer}>
        <form className={classes.inputGroupContainer} onSubmit={handleSubmit}>
          <div style={{ marginLeft: "0px" }}>
            <InputGroup
              labelText="Email"
              id="your-email"
              value={email}
              placeHolder="Enter Email Address"
              onChange={(value: string) => setEmail(value)}
              isErrored={error.email_error}
            />
          </div>

          <div style={{ marginLeft: "0px" }}>
            <ForgotPasswordInput
              className="LoginForgotPasswordInput"
              name="password"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setPassword(e.target.value.trim())
              }
              forgotPassword={() => navigate("/password-reset")}
              isErrored={error.pass_error}
            />
          </div>

          <div className={classes.buttonDiv}>
            <button
              className={classes.input}
              type="submit"
              style={{ width: "100%", fontSize: "17px", fontWeight: 700 }}
            >
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
