import { FC, useEffect, useRef, useState } from "react";
import { OrgDocsUploadSignedDocuments } from "../../../types/org.type";
import { useStyles } from "./Agreements.styles";
import { Text, Image, Button } from "@mantine/core";
import { toast } from "react-toastify";
import downloadbutton from "../../../assets/download_24px.svg";
import { RootState } from "../../../store";
import { useSelector } from "react-redux";
import useGetDocument from "../../../hooks/useGetDocument";
import { downloadFile } from "@funded-here-interface/common/src/Utils/file";
import {
  DocuSignEvent,
  DocuSignEventHandler,
  FileType,
} from "@funded-here-interface/common/src/constant/enum";
import useBmcaSecondSigner from "../../../hooks/useSecondSignerBMCA";
import {
  REST_SERVER,
  WEB_DOMAIN,
} from "@funded-here-interface/common/src/constant/domain";

import { getApiResponseErrorMsg } from "@funded-here-interface/common/src/Utils/apiHelper";
import useCompleteBmca from "../../../hooks/useCompleteBmca";
import useGetOrgMutation from "../../../hooks/useGetOrg";
import { color } from "@funded-here-interface/common/src/constant/color";
import { useDisclosure } from "@mantine/hooks";
import useSignedDocument from "../../../hooks/useSignedDocument";
// import { Button } from "@funded-here-interface/common";
import DocViewer from "@cyntler/react-doc-viewer";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
declare global {
  interface Window {
    DocuSign: any;
  }
}

interface IProps {
  bmcaSigning?: number;
  signedDocuments: OrgDocsUploadSignedDocuments[];
}

const Agreements: FC<IProps> = ({ bmcaSigning, signedDocuments }) => {
  const { classes } = useStyles();
  const token = useSelector((state: RootState) => state.auth.token);
  const useGetDocumentMutaiton = useGetDocument();
  const useBmcaSecondSignerMutaiton = useBmcaSecondSigner();
  const bmcaRef = useRef(null);
  const [documentsDisplay, setDocumentsDisplay] = useState<
    OrgDocsUploadSignedDocuments[]
  >([]);
  const useCompleteBmcaMutation = useCompleteBmca();
  const signedDocument = useSignedDocument();

  const getOrgMutation = useGetOrgMutation();
  const [numOfSignedDocument, setNumOfSignedDocument] = useState(0);

  const [realUrl, setRealUrl] = useState("");
  const [documentId, setDocumentId] = useState(0);

  const [opened, { open, close }] = useDisclosure(false);

  const [isProceedSignLoading, setIsProceedSignLoading] = useState(false);

  const docs = [
    {
      uri: realUrl,
      fileType: "pdf",
    },
  ];
  const [viewerKey, setViewerKey] = useState(0);

  const downloadDocument = async (path: string, fileName: string) => {
    const pathArray = path.split(".");
    const extension = pathArray[pathArray.length - 1];

    useGetDocumentMutaiton.mutate(
      { token, path },
      {
        onSuccess: (data: Blob) => {
          downloadFile(data, fileName, extension as FileType);
        },
        onError: (e) => {
          toast.error((e as Error).message);
        },
      }
    );
  };

  useEffect(() => {
    setDocumentsDisplay(signedDocuments);
    setNumOfSignedDocument(signedDocuments.length);
  }, []);

  const getMasterSKUDoc = async () => {
    toast.info("Document is loading");
    setViewerKey((prevKey) => prevKey + 1);
    if (bmcaSigning !== 0) {
      useBmcaSecondSignerMutaiton.mutate(
        {
          orgId: bmcaSigning as number,
          webDomain: WEB_DOMAIN,
          redirectUrl: `${REST_SERVER}/document-management/docusign/webhook`,
          token,
        },
        {
          onSuccess: async (data) => {
            setRealUrl(data.url);
            setDocumentId(data.documentId);
          },
          onError: (e) => {
            toast.error((e as Error).message);
          },
        }
      );
    }
  };

  const updateDocumentPending = () => {
    let tempDoc: OrgDocsUploadSignedDocuments[] = [];
    for (let doc of documentsDisplay) {
      if (doc.docName === "Master SKU Agreement") {
        doc.path = "pending";
      }

      tempDoc.push(doc);
    }

    setDocumentsDisplay(tempDoc);
  };

  const handleSign = () => {
    setIsProceedSignLoading(true);
    signedDocument.mutate(
      { documentId, token },
      {
        onSuccess: () => {
          handleRefresh();
          setRealUrl("");
          setIsProceedSignLoading(false);
        },
        onError: (e) => {
          setIsProceedSignLoading(false);
          toast.error((e as Error).message);
          setViewerKey((prevKey) => prevKey + 1);
        },
      }
    );
  };

  const handleRefresh = async () => {
    getOrgMutation.mutate(
      {
        token,
        id: `${bmcaSigning}`,
      },
      {
        onSuccess: async (data) => {
          let updatedSignDocument = data.documentsUpload.signedDocuments;

          let foundAgreement = false;
          for (const doc of updatedSignDocument) {
            if (doc.info) {
              let info = JSON.parse(doc.info);

              if (info.title === "master_sku_agreement") {
                foundAgreement = true;
                break;
              }
            }
          }

          if (foundAgreement) {
            setDocumentsDisplay(updatedSignDocument);
          } else {
            // show refresh when master sku agreement not find
            updatedSignDocument.push({
              docName: "Master SKU Agreement",
              path: "pending",
            });

            setDocumentsDisplay(updatedSignDocument);
          }
        },
        onError: (e) => {
          toast.error((e as Error).message);
        },
      }
    );
  };

  return (
    <div className={classes.valueContainerDoc}>
      {documentsDisplay?.map((doc, index) => (
        <div className={classes.DocContain} key={index}>
          {doc.path === "pending" ? (
            <>
              <Text className={classes.DocText}>{doc.docName}</Text>
              <Text onClick={handleRefresh} className={classes.linkText}>
                (Refresh)
              </Text>
            </>
          ) : doc.path !== "" ? (
            <>
              <Text className={classes.DocText}>{doc.docName}</Text>
              <Image
                onClick={() => downloadDocument(doc.path, doc.docName)}
                src={downloadbutton}
                className={classes.downloadIcon}
              />
            </>
          ) : (
            <>
              <Text className={classes.DocText}>{doc.docName}</Text>
              <Button
                variant="subtle"
                disabled={realUrl !== ""}
                onClick={getMasterSKUDoc}
                className={classes.linkText}
              >
                (Sign Agreement)
              </Button>
            </>
          )}
        </div>
      ))}

      {realUrl !== "" && (
        <>
          <DocViewer
            documents={docs}
            style={{ width: "100%", height: 500, overflow: "auto" }}
            className={classes.docviewer}
            key={viewerKey}
            config={{
              header: {
                disableHeader: false,
                disableFileName: true,
                retainURLParams: false,
              },
              pdfVerticalScrollByDefault: true, // false as default
            }}
          />

          <div className={classes.buttonContainer}>
            <Button
              onClick={handleSign}
              className={classes.proceedButton}
              loading={isProceedSignLoading}
            >
              Proceed to Sign
            </Button>
          </div>
        </>
      )}

      {/* {ENV === "production" && (
        <div id="bmca" ref={bmcaRef} className={classes.docuSign}>
        </div>
      )} */}
    </div>
  );
};

export default Agreements;
