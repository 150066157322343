import { createStyles } from "@mantine/core";

export const useStyles = createStyles(() => ({
  button: {
    display: "flex",
    width: "100%",
    justifyContent: "space-evenly",
  },
  modalContent: {
    padding: "0px 30px",
  },
}));
