import { FC, useRef } from "react";
import { Text, Image, FileButton } from "@mantine/core";
import downloadbutton from "../../../../assets/download_24px.svg";
import { File as FileEnum } from "@funded-here-interface/common/src/constant/enum";
import { useStyles } from "./DocumentList.styles";

interface DocumentListProps {
  documents: string[];
  header: string;
  fileType: FileEnum;
  uploadDocument: (file: File | null, fileType: FileEnum) => void;
  downloadDocument: (path: string, fileName: string) => void;
  getNameFromPath: (path: string) => string;
}

const DocumentList: FC<DocumentListProps> = ({
  documents,
  header,
  fileType,
  uploadDocument,
  downloadDocument,
  getNameFromPath,
}) => {
  const { classes } = useStyles();
  const resetRef = useRef<() => void>(null);

  const handleFileChange = (file: File | null) => {
    uploadDocument(file, fileType);

    // Reset the file input value to allow the same file to be selected again
    if (resetRef.current) {
      resetRef.current();
    }
  };

  return (
    <>
      <Text className={classes.header}>{header}</Text>
      <FileButton resetRef={resetRef} onChange={handleFileChange} accept=".pdf">
        {(props) => (
          <Text {...props} className={classes.upload}>
            Upload
          </Text>
        )}
      </FileButton>
      {documents.map((doc) => (
        <div key={doc} className={classes.DocContain}>
          <Text className={classes.subDoc}>{getNameFromPath(doc)}</Text>
          <Image
            onClick={() => downloadDocument(doc, getNameFromPath(doc))}
            src={downloadbutton}
            className={classes.downloadIcon}
          />
        </div>
      ))}
    </>
  );
};

export default DocumentList;
