import { FC, useMemo } from "react";
import {
  MRT_ColumnDef,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import { convertDate } from "@funded-here-interface/common/src/Utils/date";
import { VirtualAccountResponse } from "../../../services/getVirtualAccounts";
import useGetVirtualAccounts from "../../../hooks/useGetVirtualAccounts";

import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { toast } from "react-toastify";
import { Box, Button } from "@mantine/core";
import { IconDownload } from "@tabler/icons-react";
import useDownloadVirtualAccountsCSV from "../../../hooks/useDownloadVirtualAccountsCSV";
import { downloadFile } from "@funded-here-interface/common/src/Utils/file";
import { FileType } from "@funded-here-interface/common/src/constant/enum";

const CurrentBalance: FC = () => {
  const token = useSelector((state: RootState) => state.auth.token);
  const useDownloadVirtualAccountsCSVMutation = useDownloadVirtualAccountsCSV();
  const { data, error, isLoading } = useGetVirtualAccounts(token);

  if (error) {
    toast.error((error as Error).message);
  }

  const columns = useMemo<MRT_ColumnDef<VirtualAccountResponse>[]>(
    () => [
      {
        accessorKey: "type",
        header: "AccountType",
      },
      {
        accessorKey: "bankAccountId",
        header: "Virtual Account Number",
      },
      {
        accessorKey: "ref",
        header: "ID",
        accessorFn: (row) => row.ref ?? "",
        Cell: ({ renderedCellValue }) =>
          renderedCellValue ? (
            <span>{renderedCellValue}</span>
          ) : (
            <div>&#8210;</div>
          ),
      },
      {
        accessorKey: "createdDate",
        header: "Date created",
        Cell: ({ cell }) => <p>{convertDate(cell.getValue<string>())}</p>,
      },
      {
        accessorKey: "currency",
        header: "Currency",
      },
      {
        accessorKey: "balance",
        header: "Balance",
      },
    ],
    []
  );

  const table = useMantineReactTable({
    enableColumnActions: false,
    enableColumnFilters: false,
    paginationDisplayMode: "pages",
    state: { isLoading },
    columns,
    initialState: { density: "xs" },
    mantineTableProps: {
      withBorder: true,
    },
    data: data ?? [],
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          color="lightblue"
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          onClick={handleExportData}
          leftIcon={<IconDownload />}
          variant="filled"
        >
          Export All Data
        </Button>
      </Box>
    ),
  });

  const handleExportData = () => {
    useDownloadVirtualAccountsCSVMutation.mutate(
      { token },
      {
        onSuccess: (data: Blob) => {
          downloadFile(data, "Virtual Accounts", FileType.CSV);
        },
        onError: (e) => {
          toast.error((e as Error).message);
        },
      }
    );
  };

  return (
    <div>
      <MantineReactTable table={table} />
    </div>
  );
};

export default CurrentBalance;
