import { useQuery } from "react-query";
import getSkuPendingPmVerify from "../services/getSkuPendingPmVerify";

const useGetPendingPmVerify = (token: string) => {
  return useQuery(
    ["get-sku-pending-pm-verify", token],
    () => getSkuPendingPmVerify({ token }),
    {
      retry: false,
    }
  );
};

export default useGetPendingPmVerify;
