import React, { useEffect, useState } from "react";
import HomeServices from "../../../../services/HomeServices";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

const EarnedReturn = () => {
  const [earningsData, setEarningsData] = useState([]);
  const token = useSelector((state) => state.auth.token);

  const fetchInvestorData = async () => {
    try {
      const res = await HomeServices.earnedReturnAnalysis(token);
      if (res && res.status && res.data) {
        setEarningsData(res.data);
      } else {
        toast.error("Error fetching investor data");
      }
    } catch (error) {
      toast.error("Error fetching investor data");
    }
  };

  useEffect(() => {
    fetchInvestorData();
  }, []);

  return (
    <>
      <div className="earnedAnalysis">
        <div className="earnedTop">
          <p className="earnedTopHead">
            Earned Return Analysis (all Notes, Active & Closed)
          </p>
        </div>
        <div className="earnedBtm">
          {earningsData.map((details, index) => (
            <div className="earnedDiv" key={index}>
              <div className="earnedLeft">
                <p>{details.tenure}</p>
              </div>
              <div className="earnedRight">
                <p>{details.effectiveROR}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default EarnedReturn;
