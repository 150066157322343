import React, { useEffect, useState } from "react";
import InvestAmtSec from "./InvestAmtSec";
import PortfolioReturnChart from "./PortfolioReturnChart";
import DataTable from "./DataTable";
import HomeServices from "../../../../services/HomeServices";
import { useSelector } from "react-redux";

const InvestorSummary = () => {
  const [InvestorSummary, setInvestorSummary] = useState([]);
  const [investorOptions, setInvestorOptions] = useState([]);
  const [selectedInvestor, setSelectedInvestor] = useState({
    value: "",
    label: "",
  });

  const token = useSelector((state) => state.auth.token);

  //>>>Showing Investor Summary Analysis Data in Table<<<
  const rows = InvestorSummary?.map((item) => ({
    name: item?.investorName,
    cmtd_amt: item?.commitedAmount?.toLocaleString("en-US"),
    invested_amt: item?.totalInvestment?.toLocaleString("en-US"),
    repaid_amt: item?.repaidAmount?.toLocaleString("en-US"),
    amt_avlInv: item?.amountAvailabeForInvestment?.toLocaleString("en-US"),
    effective_ror: item?.effectiveROR,
  }));

  const data = {
    columns: [
      {
        label: "Investor Name",
        field: "name",
      },
      {
        label: "Committed Amount",
        field: "cmtd_amt",
      },
      {
        label: "Invested Amount",
        field: "invested_amt",
      },
      {
        label: "Repaid Amount",
        field: "repaid_amt",
      },
      {
        label: "Amount Available for Investment",
        field: "amt_avlInv",
      },
      {
        label: "Effective ROR",
        field: "effective_ror",
      },
    ],
    rows: rows,
  };

  // >>> Fetch Investor Summary Analysis Data<<<
  const fetchInvestorAnalysisSummaryData = async (investorId) => {
    const res = await HomeServices.investorAnalysis(investorId, token);
    if (res && res?.status) {
      setInvestorSummary(res?.data);
    }
  };

  useEffect(() => {
    const fetchInvestorData = async () => {
      const res = await HomeServices.getInvestorList(token);
      if (res && res.status) {
        const options = res.data.map((investor) => ({
          label: investor.investorName,
          value: investor.investorOrgRoleId,
        }));
        setInvestorOptions(options);
        if (options.length > 0) {
          setSelectedInvestor(options[0]);
        }
      } else {
        // Handle error
      }
    };

    fetchInvestorData();
  }, []);

  useEffect(() => {
    if (selectedInvestor.value) {
      fetchInvestorAnalysisSummaryData(selectedInvestor.value);
    }
  }, [selectedInvestor]);

  const handleInvestorChange = (selectedOption) => {
    setSelectedInvestor(selectedOption);
  };

  return (
    <>
      <div className="InvestorSmry">
        <div className="investorFirstRow mb-3">
          {selectedInvestor.value && (
            <>
              <InvestAmtSec
                selectedInvestor={selectedInvestor}
                handleInvestorChange={handleInvestorChange}
                investorOptions={investorOptions}
              />
              {/* Pass selected investor and handler function as props */}
              <PortfolioReturnChart
                selectedInvestor={selectedInvestor}
                handleInvestorChange={handleInvestorChange}
                investorOptions={investorOptions}
              />
            </>
          )}
        </div>
        <div className="investorSmryBtm">
          <p className="invstTblHead">Investor Analysis</p>
          <DataTable data={data} />
        </div>
      </div>
    </>
  );
};

export default InvestorSummary;
