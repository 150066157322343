import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import HomeServices from "../../../../services/HomeServices";
import { useSelector } from "react-redux";

const PieSkuChart = () => {
  const [chartData, setChartData] = useState([]);
  const token = useSelector((state) => state.auth.token);

  const [state, setState] = useState({
    options: {
      chart: {
        type: "donut",
        width: "100%",
      },
      legend: {
        position: "right",
      },
      plotOptions: {
        pie: {
          customScale: 0.8,
          size: "60%",
        },
      },
      stroke: {
        show: false,
        width: 1,
      },
      tooltip: {
        enabled: true,
        followCursor: true,
        custom: function ({ series, seriesIndex, w }) {
          const sector = w.config.labels[seriesIndex];

          const skuAllocation = series[seriesIndex];

          // Construct tooltip HTML
          return (
            '<div class="arrow_box">' +
            '<span class="headspan">' +
            "Sector: " +
            "</span>" +
            '<span class="paraspan">' +
            sector +
            "</span>" +
            "</div>" +
            '<div class="arrow_box">' +
            '<span class="headspan">' +
            "SKU Allocation : " +
            "</span>" +
            '<span class="paraspan">' +
            skuAllocation +
            "</span>" +
            "</div>"
          );
        },
      },
    },
    series: [],
    labels: [],
  });

  const fetchSKUsBySector = async () => {
    try {
      const res = await HomeServices.getDonutChartDataBySector(token);
      if (res && res.status) {
        const sectors = res.data.map((item) => item.sector);
        const skuCounts = res.data.map((item) => item.skuCount);

        setState((prevState) => ({
          ...prevState,
          options: {
            ...prevState.options,
          },
          series: skuCounts,
          labels: sectors,
        }));
        setChartData(res.data);
      }
    } catch (error) {
      console.error("Error fetching SKU data by sector:", error);
    }
  };

  useEffect(() => {
    fetchSKUsBySector();
  }, []);

  return (
    <div id="chart">
      <ReactApexChart
        options={{ ...state.options, labels: state.labels }}
        series={state.series}
        type="donut"
        height={350}
      />
    </div>
  );
};

export default PieSkuChart;
