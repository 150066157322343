import { MantineTheme, createStyles } from "@mantine/core";

export const useStyles = createStyles((theme: MantineTheme) => ({
  container: {
    marginTop: "37.5px",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "500px",
    alignItems: "center",
  },
  chartTitle: {
    fontWeight: 700,
    marginBottom: "10px",
  },
}));
