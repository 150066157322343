import { createStyles } from "@mantine/styles";

export const useStyle = createStyles(() => ({
  list: {
    marginLeft: 20,
    marginTop: 15,
  },
  text: {
    marginTop: 20,
    marginBottom: 20,
    maxWidth: "420px",
  },
}));
