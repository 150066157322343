import { color } from "@funded-here-interface/common";
import { Button, Text } from "@mantine/core";
import { FC } from "react";

interface IProps {
  title: string;
  isDeleteLoading: boolean;
  handleOnDelete: () => void;
  close: () => void;
}

const DeleteModal: FC<IProps> = (props) => {
  const { title, isDeleteLoading, close, handleOnDelete } = props;
  return (
    <>
      <Text>{title}</Text>
      <Button
        loading={isDeleteLoading}
        onClick={handleOnDelete}
        sx={{
          width: "150px",
          height: "40px",
          marginTop: "20px",
          backgroundColor: color.REJECT,
          "&:hover": {
            backgroundColor: color.REJECT,
          },
        }}
      >
        {isDeleteLoading ? "Deleting" : "Delete"}
      </Button>
      <Button
        disabled={isDeleteLoading}
        onClick={close}
        sx={{
          marginLeft: "20px",
          width: "150px",
          height: "40px",
          marginTop: "20px",
          backgroundColor: color.LOANTEXT,
          "&:hover": {
            backgroundColor: color.LOANTEXT,
          },
        }}
      >
        Cancel
      </Button>
    </>
  );
};

export default DeleteModal;
