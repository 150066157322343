import axios from "axios";
import { BASE_URL } from "@funded-here-interface/common/src/constant/constant";
import { getApiResponseErrorMsg } from "@funded-here-interface/common/src/Utils/apiHelper";

export interface VirtualAccountResponse {
  id: string;
  accountId: string;
  type: string;
  ref: string;
  createdDate: string;
  currency: string;
  balance: string;
  refId: string;
  bankAccountId: string;
  orgName: string;
  orgDetails: string;
}

const getVirtualAccounts = async ({
  token,
}: {
  token: string;
}): Promise<VirtualAccountResponse[]> => {
  try {
    const response = await axios.get(
      `${BASE_URL}/payment/account/getVAAccounts`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      }
    );

    return response.data;
  } catch (error) {
    const message = getApiResponseErrorMsg(error);

    throw new Error(message);
  }
};

export default getVirtualAccounts;
