import { MantineTheme, createStyles } from "@mantine/styles";

export const useStyles = createStyles((theme: MantineTheme) => ({
  loginContainer: {
    gap: "32px",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  container: {
    padding: "30px 50px 40px 50px",
  },

  logo: {
    width: 130,
    marginBottom: "15px",
  },

  loginHeading: {
    height: "46px",
    top: "18px",
    marginBottom: "25px",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "29px",
    letterSpacing: "0em",
    textAlign: "left",
  },
  inputGroupContainer: {
    width: "406px",
    height: "281px",
    top: "125px",
    padding: "1px 0px 0px 0px",
  },
  checkboxContainer: {
    width: "247px",
    height: "22px",
    gap: "6px",
  },
  buttonDiv: {
    paddingTop: "10px",
    marginTop: "20px",
  },

  signInFooter: {
    marginTop: "10px",
    display: "flex",
    flexDirection: "row",
    marginLeft: "72px",
    gap: "10px",
    position: "absolute",
    justifyContent: "flex-start",
    bottom: "20px",
    marginRight: "5px",
  },
  noAccount: {
    width: "155px",
    height: "25px",
    /*typegraphy*/
    fontFamily: "SF Pro Text",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "16.71px",
    color: "#4F566B",
  },
  radioGroup: {
    width: "406px",
    height: "40px",
    top: "160px",
    left: "350px",
    gap: "20px",
    display: "flex",
    flexDirection: "row",
  },
  signUp: {
    width: "50px",
    height: "17px",
    /*typegraphy*/
    fontFamily: "SF Pro Text",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "16.71px",
    color: "#338F86",
    left: "135px",
    position: "absolute",
    cursor: "pointer",
    paddingLeft: "18px",
  },
  li: {
    color: "#595959",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "normal",
  },
  input: {
    padding: 10,
    marginBottom: 10,
    backgroundColor: "#3786D6",
    color: "white",
    border: "none",
    borderRadius: 4,
    cursor: "pointer",
    width: "90%",
    height: 45,
    marginTop: 10,
  },
}));
