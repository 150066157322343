import { Button, FileButton, List, Text } from "@mantine/core";
import { FC, useRef } from "react";
import { useStyle } from "./UploadPo.styles";
import { color } from "@funded-here-interface/common";
import { toast } from "react-toastify";

interface IProps {
  handleOnUpload: (file: File) => void;
  isLoading: boolean;
  orgRoleId?: number;
}

const UploadPo: FC<IProps> = ({ handleOnUpload, orgRoleId, isLoading }) => {
  const { classes } = useStyle();
  const resetRef = useRef<() => void>(null);

  const uploadDocument = (file: File | null) => {
    if (!file) {
      toast.error("Uploaded file not found.");

      return;
    }

    handleOnUpload(file);
  };

  const handleFileChange = (file: File | null) => {
    uploadDocument(file);

    // Reset the file input value to allow the same file to be selected again
    if (resetRef.current) {
      resetRef.current();
    }
  };

  return (
    <>
      <div className={classes.list}>
        <List>
          <List.Item>In PDF format</List.Item>
          <List.Item>Must not exceed 4 MB</List.Item>
          <List.Item>Clear & legible</List.Item>
          <List.Item>
            Multi-page files accepted; please consolidate all pages into a
            single file
          </List.Item>
        </List>
      </div>

      <div className={classes.text}>
        <Text>
          It will take approximately 5 minutes to extract data from your
          purchase order. Once it's complete, we'll notify you via email, and
          you can review the results under the Review tab.
        </Text>
      </div>

      <FileButton
        disabled={isLoading}
        resetRef={resetRef}
        accept=".pdf"
        onChange={handleFileChange}
      >
        {(props) => (
          <Button
            {...props}
            loading={isLoading}
            disabled={!orgRoleId}
            sx={{
              width: "200px",
              height: "50px",
              backgroundColor: color.FHGREEN,
              "&:hover": {
                backgroundColor: color.FHGREEN,
              },
            }}
          >
            {isLoading ? "Uploading" : "Upload PO"}
          </Button>
        )}
      </FileButton>
    </>
  );
};

export default UploadPo;
