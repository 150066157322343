import { useQuery } from "react-query";
import getSkuPoOcrReview from "@funded-here-interface/common/src/services/getSkuPoOcrReview";

const useGetSkuPoOcrReview = (token: string, orgRoleId: number) => {
  return useQuery(
    ["get-sku-po-ocr-review", token, orgRoleId],
    () => getSkuPoOcrReview({ token, orgRoleId }),
    {
      retry: false,
    }
  );
};

export default useGetSkuPoOcrReview;
