import { useQuery } from "react-query";
import getVirtualAccountsTransactions from "../services/getVirtualAccountsTransactions";
function useGetVATransactionsMutation(token: string) {
  return useQuery(
    ["get-vatransactions"],
    () => getVirtualAccountsTransactions({ token }),
    {
      retry: false,
    }
  );
}

export default useGetVATransactionsMutation;
