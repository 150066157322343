import axios from "axios";
import { BASE_URL } from "@funded-here-interface/common/src/constant/constant";
import { getApiResponseErrorMsg } from "@funded-here-interface/common/src/Utils/apiHelper";

interface GetNoteSalesRequest {
  noteId: string;
  token: string;
  globalFilter: string;
  pagination: any;
  sorting: any;
}

export interface SkuData {
  id: number;
  merchant: string;
  skuId: string;
  "l2+E": string;
  sellingPricePerUnit: string;
  minimumSellingPricePerUnit: string;
  salesProcced: string;
  unitsDelivered: number;
  unitsSold: string;
  skuVaBalance: string;
}

export interface PaginationData {
  currentPage: number;
  pageSize: number;
  totalRecords: number;
  totalPages: number;
}

export interface GetNoteSalesResponse {
  skus: SkuData[];
  pagination: PaginationData;
  canNoteBeClosed: boolean;
  noteDisplayId: string;
}

const getNotelist = async ({
  token,
  noteId,
  globalFilter,
  pagination,
  sorting,
}: GetNoteSalesRequest): Promise<GetNoteSalesResponse> => {
  try {
    const response = await axios.get(
      `${BASE_URL}/sku-finance/loan-repayment/structured-note-sales-detail/${noteId}`,

      {
        params: {
          page: `${pagination.pageIndex * pagination.pageSize}`,
          pageSize: `${pagination.pageSize}`,
          globalFilter: globalFilter ?? "",
          sorting: JSON.stringify(sorting ?? []),
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      }
    );

    return response.data;
  } catch (e) {
    const message = getApiResponseErrorMsg(e);

    throw new Error(message);
  }
};

export default getNotelist;
