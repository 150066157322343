import React from "react";
import EarnedReturn from "./EarnedReturn";
import TopInvestor from "./TopInvestor";
import "./Investor.css";
import HorizontalBar from "./HorizontalBar";
import InvestorTableChart from "./InvestorTableChart";
import ErrorBoundary from "@funded-here-interface/common/src/dashboard/components/ErrorBoundry";

const Investor = () => {
  return (
    <ErrorBoundary>
      <div className="InvestorPageTop mb-4">
        <div className="InvestorPgLeft">
          <EarnedReturn />
        </div>

        <div className="InvestorPgRight">
          <HorizontalBar />
        </div>
      </div>

      <div className="InvestorPageBtm">
        <InvestorTableChart />
      </div>
    </ErrorBoundary>
  );
};

export default Investor;
