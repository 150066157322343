import { color } from "@funded-here-interface/common";
import { createStyles } from "@mantine/core";

export const useStyle = createStyles(() => ({
  buttonWrapper: {
    marginTop: "50px",
  },
  head: {
    color: color.ADMINHEADING,
    marginBottom: 6,
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
  },
  detailsDiv: {
    marginTop: "30px",
  },
  body: {
    color: color.ADMINHEADING,
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },
  twoDiv: {
    display: "flex",
    gap: "200px",
  },
}));
