import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import HomeServices from "../../../services/HomeServices";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

const BalanceLoanAnalysis = () => {
  const [seriesData, setSeriesData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [chartHeight, setChartHeight] = useState(0);
  const token = useSelector((state) => state.auth.token);

  const state = {
    series: seriesData,
    options: {
      chart: {
        width: "100%",
        type: "bar",
        stacked: true,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: "80%",
          barWidth: "80%",
          distributed: false,
        },
      },
      dataLabels: {
        style: {
          colors: ["#151515"],
          fontSize: "12px",
          fontWeight: "600",
        },
        formatter: (val) => {
          return val?.toLocaleString("en-US");
        },
      },
      colors: ["#b2d7f9", "#92bbac"],
      states: {
        hover: {
          filter: {
            type: "none",
          },
        },
      },
      title: {
        text: "Balance Loan Analysis",
        style: {
          fontSize: "13px",
          colors: "#595959",
        },
      },
      xaxis: {
        show: true,
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        categories: categories,
      },
      fill: {
        opacity: 1,
      },
      legend: {
        position: "top",
        fontSize: "10px",
        fontWeight: "500",
        horizontalAlign: "left",
        offsetY: -10,
        markers: {
          shape: "circle",
          width: "8px",
          height: "8px",
          borderRadius: "50%",
        },
      },
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
        padding: {
          top: -22,
          bottom: -15,
        },
      },
      tooltip: {
        enabled: true,
        followCursor: true,
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          const seriesName = w.config.series[seriesIndex].name;
          let tooltipContent = "";
          if (
            seriesName === "Balance Amount" ||
            seriesName === "Repaid Amount"
          ) {
            const skuName = w.globals.labels[dataPointIndex];
            const balanceAmount = parseFloat(series[0][dataPointIndex]);
            const repaidAmount = parseFloat(series[1][dataPointIndex]);
            const totalLoanAmount = balanceAmount + repaidAmount;

            tooltipContent =
              '<div class="arrow_box">' +
              '<span class="headspan">' +
              "SKU : " +
              "</span>" +
              '<span class="paraspan">' +
              skuName +
              "</span>" +
              "</div>";

            if (seriesName === "Balance Amount") {
              tooltipContent +=
                '<div class="arrow_box">' +
                '<span class="headspan">' +
                "Balance Amount: " +
                "</span>" +
                '<span class="paraspan">' +
                balanceAmount.toLocaleString("en-US") +
                "</span>" +
                "</div>";
            } else if (seriesName === "Repaid Amount") {
              tooltipContent +=
                '<div class="arrow_box">' +
                '<span class="headspan">' +
                "Repaid Amount: " +
                "</span>" +
                '<span class="paraspan">' +
                repaidAmount.toLocaleString("en-US") +
                "</span>" +
                "</div>";
            }

            tooltipContent +=
              '<div class="arrow_box">' +
              '<span class="headspan">' +
              "Total Loan Amount: " +
              "</span>" +
              '<span class="paraspan">' +
              totalLoanAmount.toLocaleString("en-US") +
              "</span>" +
              "</div>";
          }
          return tooltipContent;
        },
        y: {
          formatter: function (val) {
            return val?.toLocaleString("en-US");
          },
        },
      },
    },
  };

  // Fetch Balance Amount Analysis
  const fetchBalanceAmount = async () => {
    try {
      const res = await HomeServices.balanceAmountAnalysis(token);
      if (res && res?.status) {
        const sortedData = res.data.sort(
          (a, b) => b.balance_amount - a.balance_amount
        );
        const balanceData = sortedData.map((ele) =>
          ele.balance_amount.toString()
        );
        const loanData = sortedData.map((ele) => ele.repaidAmount.toString());
        const category = sortedData.map((ele) => ele.skuName);

        const chartHeight = Math.max(balanceData.length * 40, 100);
        setChartHeight(chartHeight);
        setSeriesData([
          {
            name: "Balance Amount",
            data: balanceData,
          },
          {
            name: "Repaid Amount",
            data: loanData,
          },
        ]);
        setCategories(category);
      } else {
        toast.error("Unable to Fetch Balance Amount Analysis");
      }
    } catch (error) {
      console.error("Error fetching balance amount analysis: ", error);
      toast.error("Unable to Fetch Balance Amount Analysis");
    }
  };

  useEffect(() => {
    fetchBalanceAmount();
  }, []);

  return (
    <>
      <div className="merChartTab">
        <ReactApexChart
          options={state.options}
          series={state.series}
          type="bar"
          height={chartHeight}
        />
      </div>
    </>
  );
};

export default BalanceLoanAnalysis;
