import { FC, useEffect, useState } from "react";
import { useStyle } from "./Overview.styles";
import { GetCurrentSNResponse } from "../../../../services/getCurrentSn";
import { Button } from "@funded-here-interface/common";
import usePoolingReview from "../../../../hooks/usePoolingReview";
import { RootState } from "../../../../store";
import { useSelector } from "react-redux";
import { PoolingReviewResponse } from "../../../../services/StructureNote";
import { toast } from "react-toastify";
import { formatNumbersWithThousandSeperators } from "@funded-here-interface/common/src/Utils/formatter";
import useIssueSN from "../../../../hooks/useIssueSN";

interface IProps {
  data: GetCurrentSNResponse | undefined;
}

const Overview: FC<IProps> = ({ data }) => {
  const { classes } = useStyle();
  const token = useSelector((state: RootState) => state.auth.token);
  const usePoolingReviewMutation = usePoolingReview();
  const [skuData, setSkuData] = useState<PoolingReviewResponse>();
  const issueSnMutation = useIssueSN();
  const [isLoading, setIsLoading] = useState(false);

  const notifySuccess = () => toast.success("Successfully Issued");

  useEffect(() => {
    const skuids = data?.poolingSku?.map((sku) => sku.id) ?? [];

    if (skuids.length > 0) {
      usePoolingReviewMutation.mutate(
        { token, data: skuids },
        {
          onSuccess: async (data) => {
            setSkuData(data);
          },
          onError: (error) => {
            toast.error((error as Error).message);
          },
        }
      );
    }
  }, [data]);

  const handleIssueNote = () => {
    setIsLoading(true);
    const noteId = data?.id as unknown as string;

    issueSnMutation.mutate(
      { token, id: noteId },
      {
        onSuccess: () => {
          notifySuccess();
        },
        onError: (error) => {
          toast.error((error as Error).message);
        },
      }
    );
  };

  return (
    <>
      <div className={classes.twoDiv}>
        <div>
          <div className={classes.detailsDiv}>
            <h1 className={classes.head}>Summary</h1>
            <h1 className={classes.body}>Tenor: {data?.tenor ?? "-"} days</h1>
            <h1 className={classes.body}>
              {" "}
              Merchant Interest Rate: {data?.merchantInterestRate ?? "-"}%
            </h1>
            <h1 className={classes.body}>
              Sr. Investor's Interest Rate: {data?.seniorInterestRate ?? "-"}%
            </h1>
            <h1 className={classes.body}>
              Jr. Investor's Interest Rate: {data?.juniorInterestRate ?? "-"} %
            </h1>
            <h1 className={classes.body}>
              Total SKUs: {data?.totalSKU ?? "-"}
            </h1>
            <h1 className={classes.body}>
              Total Merchants: {data?.totalMerchants ?? "-"}
            </h1>
            <h1 className={classes.body}>
              Total Investor: {data?.numberOfInvestors ?? "-"}
            </h1>
            <h1 className={classes.body}>
              All Merchant Loan Agreements Signed:{" "}
              {data?.areAllMerchantLoanAgreementSigned ? "Yes" : "No"}
            </h1>
            <h1 className={classes.body}>
              Note Fully Invested: {data?.isNoteFullyInvested ? "Yes" : "No"}
            </h1>
          </div>
          <div className={classes.detailsDiv}>
            <h1 className={classes.head}>Details</h1>
            <h1 className={classes.body}>
              Average RBU:{" "}
              {data?.RBU ? formatNumbersWithThousandSeperators(data.RBU) : "-"}
            </h1>
            <h1 className={classes.body}>
              Average RBP:{" "}
              {data?.RBP ? formatNumbersWithThousandSeperators(data.RBP) : "-"}
            </h1>
            <h1 className={classes.body}>
              Average Dynamic LTV:{" "}
              {skuData?.averageDynamicLTV
                ? formatNumbersWithThousandSeperators(
                    parseFloat(skuData.averageDynamicLTV)
                  ) + "%"
                : "-"}
            </h1>
            <h1 className={classes.body}>
              Average Invoice:{" "}
              {skuData?.averageLoanSize
                ? formatNumbersWithThousandSeperators(
                    parseFloat(skuData.averageLoanSize)
                  )
                : "-"}
            </h1>
            <h1 className={classes.body}>
              Average DOI: {skuData?.averageDOI ?? "-"}
            </h1>
            <h1 className={classes.body}>
              Average GM: {skuData?.averageGM + "%" ?? "-"}
            </h1>
          </div>
          <div />
        </div>
        <div>
          <div className={classes.detailsDiv}>
            <h1 className={classes.head}>Note VA</h1>

            <h1 className={classes.body}>
              Balance:{" "}
              {data?.snBalance
                ? formatNumbersWithThousandSeperators(
                    parseFloat(data.snBalance)
                  )
                : "-"}
            </h1>
          </div>
          <div className={classes.detailsDiv}>
            <h1 className={classes.head}>Value</h1>
            <h1 className={classes.body}>
              Total Note Value:{" "}
              {data?.totalSnValue
                ? formatNumbersWithThousandSeperators(
                    parseFloat(data.totalSnValue)
                  )
                : "-"}
            </h1>
            <h1 className={classes.body}>
              Total QC:{" "}
              {data?.totalMOE
                ? formatNumbersWithThousandSeperators(parseFloat(data.totalMOE))
                : "-"}
            </h1>
            <h1 className={classes.body}>
              Total SF:{" "}
              {data?.totalSF
                ? formatNumbersWithThousandSeperators(parseFloat(data.totalSF))
                : "-"}
            </h1>
            <h1 className={classes.body}>
              Total Additional SF:{" "}
              {data?.totalASF
                ? formatNumbersWithThousandSeperators(parseFloat(data.totalASF))
                : "-"}
            </h1>
            <h1 className={classes.body}>
              Total Float(QC+SF+ASF):{" "}
              {data?.totalFloat
                ? formatNumbersWithThousandSeperators(
                    parseFloat(data.totalFloat)
                  )
                : "-"}
            </h1>
          </div>
        </div>
      </div>

      <div className={classes.buttonWrapper}>
        <Button
          onClick={handleIssueNote}
          backgroundColor="#3786D6"
          textColor="#FFFFFF"
          width="300px"
          disabled={!data?.allowNoteToBeIssued || !data || isLoading}
        >
          Issue Note
        </Button>
      </div>
    </>
  );
};

export default Overview;
