import { createSlice } from "@reduxjs/toolkit";

const bizSlice = createSlice({
  name: "biz",
  initialState: {
    company: null,
    regNumber: null,
    legalStructure: null,
    typeOfInstitute: null,
    country: null,
    address: null,
    phoneNumber: null,
  },
  reducers: {
    setCompanyRedux: (state, { payload: { company } }) => {
      state.company = company;
    },
    setRegNumberRedux: (state, { payload: { regNumber } }) => {
      state.regNumber = regNumber;
    },
    setLegalStructureRedux: (state, { payload: { legalStructure } }) => {
      state.legalStructure = legalStructure;
    },
    setTypeOfInstituteRedux: (state, { payload: { typeOfInstitute } }) => {
      state.typeOfInstitute = typeOfInstitute;
    },
    setCountryRedux: (state, { payload: { country } }) => {
      state.country = country;
    },
    setAddressRedux: (state, { payload: { address } }) => {
      state.address = address;
    },
    setPhoneNumberRedux: (state, { payload: { phoneNumber } }) => {
      state.phoneNumber = phoneNumber;
    },
  },
});

export const {
  setCompanyRedux,
  setRegNumberRedux,
  setLegalStructureRedux,
  setTypeOfInstituteRedux,
  setCountryRedux,
  setAddressRedux,
  setPhoneNumberRedux,
} = bizSlice.actions;

export default bizSlice.reducer;
