import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import HomeServices from "../../../../services/HomeServices";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

const HorizontalBar = () => {
  const [categories, setCategories] = useState([]);
  const [seriesData, setSeriesData] = useState([]);
  const [totalInvestment, setTotalInvestment] = useState([]);
  const [chartHeight, setChartHeight] = useState(0);
  const token = useSelector((state) => state.auth.token);

  const fetchInvestorAnalysisData = async () => {
    const res = await HomeServices.investorAmountAnalysis(token);
    if (res && res.status) {
      const repaidAmount = [];
      const balanceAmount = []; // New array to store balance amount
      const category = [];
      let totalInvestment = 0; // Initialize totalInvestment variable

      res.data.forEach((ele) => {
        repaidAmount.push(ele.repaidAmount);
        balanceAmount.push(ele.balanceAmount);
        category.push(ele.investorName);
        totalInvestment += ele.totalInvestment;
      });

      setSeriesData([
        {
          name: "Repaid Amount",
          data: repaidAmount,
        },
        {
          name: "Balance Amount",
          data: balanceAmount,
        },
      ]);
      setCategories(category);
      setTotalInvestment(totalInvestment); // Set totalInvestment state
    } else {
      toast.error("Unable To Fetch data");
    }
  };

  // useEffect(() => {
  //   fetchInvestorAnalysisData();
  // }, []);

  useEffect(() => {
    const chartHeight = Math.max(categories.length * 50, 150);
    setChartHeight(chartHeight);
    fetchInvestorAnalysisData();
  }, [categories.length]);

  const chartData = {
    series: seriesData,
    options: {
      chart: {
        width: "100%",
        type: "bar",
        stacked: true,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: "20%",
        },
      },
      dataLabels: {
        style: {
          colors: ["#151515"],
          fontSize: "12px",
          fontWeight: "600",
        },
        formatter: (val) => {
          return val?.toLocaleString("en-US");
        },
      },
      colors: ["#f0e199", "#b2d7f9"],
      states: {
        hover: {
          filter: {
            type: "none",
          },
        },
      },
      xaxis: {
        show: true,
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        categories: categories,
      },
      fill: {
        opacity: 1,
      },
      legend: {
        position: "top",
        fontSize: "10px",
        fontWeight: "500",
        horizontalAlign: "left",
        markers: {
          shape: "circle",
          width: "8px",
          height: "8px",
          borderRadius: "50%",
        },
      },
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      tooltip: {
        enabled: true,
        followCursor: true,
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          let tooltipContent =
            '<div class="arrow_box">' +
            '<span class="headspan">Investor Name : </span>' +
            '<span class="paraspan">' +
            w.globals.labels[dataPointIndex] +
            "</span>" +
            "</div>";

          if (seriesIndex === 0) {
            // Repaid Amount series
            const repaidAmount = parseFloat(series[0][dataPointIndex]);
            tooltipContent +=
              '<div class="arrow_box">' +
              '<span class="headspan">Repaid Amount : </span>' +
              '<span class="paraspan">' +
              repaidAmount.toLocaleString("en-US") +
              "</span>" +
              "</div>";

            tooltipContent +=
              '<div class="arrow_box">' +
              '<span class="headspan">Total Active and Closed Invested Amount : </span>' +
              '<span class="paraspan">' +
              totalInvestment.toLocaleString("en-US") + // Use totalInvestment here
              "</span>" +
              "</div>";
          } else if (seriesIndex === 1) {
            // Balance Amount series
            const balanceAmount = parseFloat(series[1][dataPointIndex]);
            tooltipContent +=
              '<div class="arrow_box">' +
              '<span class="headspan">Balance Amount : </span>' +
              '<span class="paraspan">' +
              balanceAmount.toLocaleString("en-US") +
              "</span>" +
              "</div>";

            tooltipContent +=
              '<div class="arrow_box">' +
              '<span class="headspan">Total Active and Closed Invested Amount : </span>' +
              '<span class="paraspan">' +
              totalInvestment.toLocaleString("en-US") + // Use totalInvestment here
              "</span>" +
              "</div>";
          }

          return tooltipContent;
        },
      },
    },
  };

  return (
    <div className="InvestorBar" style={{ backgroundColor: "#fff" }}>
      <div className="earnedTop">
        <p className="earnedTopHead">Top 10 Investors by Invested Amount</p>
      </div>
      <div className="earnedBtm">
        {seriesData.length > 0 && categories.length > 0 ? (
          <ReactApexChart
            options={chartData.options}
            series={chartData.series}
            height={chartHeight}
            type="bar"
          />
        ) : null}
      </div>
    </div>
  );
};

export default HorizontalBar;
