import React, { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { MDBDataTable } from "mdbreact";
import "./Skusummary.css";
import HomeServices from "../../../../services/HomeServices";
import toast from "react-hot-toast";
import { RiArrowDropDownLine } from "react-icons/ri";
import { useSelector } from "react-redux";

const Snusummary = () => {
  const [SalesValueAnalysis, setSalesValueAnalysis] = useState([]);
  const [LoanValueAnalysis, setLoanValueAnalysis] = useState([]);
  const [isDrop, setIsDrop] = useState(false);
  const [snData, setSNdata] = useState([]);
  const [SNData, setSNData] = useState(null);
  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    if (snData.length > 0) {
      setSNData(snData[0]); // Set SNData to the first value from snData
      fetchSalesValueAnalysis(snData[0]); // Fetch data for the first SNData value
      fetchLoanValueAnalysis(snData[0]); // Fetch data for the first SNData value
    }
  }, [snData]);

  const fetchingDropDownSNvalue = async () => {
    try {
      const res = await HomeServices.getSNDropDown(token);
      if (res && res.status) {
        setSNdata(res.data.map((item) => item.snId));
      } else {
        toast.error("Unable To Fetch SN Data");
      }
    } catch (error) {
      console.error("Error fetching SN dropdown values:", error);
      toast.error("Unable To Fetch SN Data");
    }
  };

  const rows = SalesValueAnalysis?.map((item, ind) => ({
    skid: item?.skuId,
    snid: item?.snId,
    sector: item?.sector,
    country: item?.country,
    skname: item?.skuName,
    merchname: item?.merchantName,
    salereve: item?.salesRevenue?.toLocaleString("en-US"),
    retuquan: item?.returnQuantity,
    promar: item?.profitMargin,
    loanamnt: item?.loanAmount,
    repamnt: item?.repaidAmount,
    balamnt: item?.balanceAmount,
    daysdue: item?.daysDueForPayment,
    rbp: item?.expectedRBP,
    rbu: item?.rbu,
    tenure: item?.tenure,
    quansold: item?.quantitySold,
    sellpri: item?.sellingPrice,
    movingavg: item?.movingAvg5DaySales,
    default: item?.default,
    returns: item?.effectiveROR ? `${item?.effectiveROR}%` : "-",
  }));

  // >>>fetch payment due data<<<
  const fetchSalesValueAnalysis = async (snId) => {
    const res = await HomeServices.loanAndSales(snId, token);
    if (res && res?.status) {
      setSalesValueAnalysis(res?.data);
    } else {
      toast.error("Unable to fetch Data");
    }
  };

  const fetchLoanValueAnalysis = async (snId) => {
    try {
      const res = await HomeServices.loanAndSales(snId, token);
      if (res && res.status) {
        setLoanValueAnalysis(
          res.data.map((item) => ({
            ...item,
            skid: item?._id?.skuId,
            snid: item?.snId,
            skname: item?.name,
            merchname: item?.fullName,
            loanamnt: parseFloat(item?.loanAmount.toFixed(2))?.toLocaleString(
              "en-US"
            ),
            repamnt: parseFloat(
              item?.totalRepaidAmount.toFixed(2)
            )?.toLocaleString("en-US"),
            balamnt:
              item?.totalbalanceAmount < 0
                ? 0
                : item?.totalbalanceAmount?.toLocaleString("en-US"),

            daysdue: item?.daysToMaturity < 0 ? 0 : item?.daysToMaturity,
            rbp: item?.expectedRBP,
            tenure: item?.tenure,
            qntsold: item?.totalQuantitySold,
            sellpri: parseFloat(
              item?.salesPricePerUnit.toFixed(2)
            )?.toLocaleString("en-US"),
          }))
        );
      }
    } catch (error) {
      console.error("Error fetching loan value analysis:", error);
    }
  };

  useEffect(() => {
    // Fetch data for initial SNData value
    fetchSalesValueAnalysis(SNData);
    fetchLoanValueAnalysis(SNData);
  }, [SNData]); // Add SNData as a dependency so that this effect runs whenever SNData changes

  useEffect(() => {
    fetchingDropDownSNvalue();
  }, []);

  const data = {
    columns: [
      {
        label: "SKU ID",
        field: "skid",
        sort: "asc",
      },

      {
        label: "SKU Name",
        field: "skname",
        sort: "asc",
      },
      {
        label: "Sector",
        field: "sector",
        sort: "asc",
      },
      {
        label: "Country",
        field: "country",
        sort: "asc",
      },
      {
        label: "Merchant Name",
        field: "merchname",
        sort: "asc",
      },
      {
        label: "Quantity Sold",
        field: "quansold",
        sort: "asc",
      },

      {
        label: "Sales Revenue",
        field: "salereve",
        sort: "asc",
      },
      {
        label: "Return Quantity",
        field: "retuquan",
        sort: "asc",
      },
      {
        label: "Profit Margin",
        field: "promar",
        sort: "asc",
      },
    ],
    rows: rows,
  };

  const data2 = {
    columns: [
      {
        label: "SKU ID",
        field: "skid",
        sort: "asc",
      },

      {
        label: "SKU Name",
        field: "skname",
        sort: "asc",
      },
      {
        label: "Sector",
        field: "sector",
        sort: "asc",
      },
      {
        label: "Country",
        field: "country",
        sort: "asc",
      },
      {
        label: "Merchant Name",
        field: "merchname",
        sort: "asc",
      },
      {
        label: "Loan Amount",
        field: "loanamnt",
        sort: "asc",
      },

      {
        label: "Repaid Amount",
        field: "repamnt",
        sort: "asc",
      },
      {
        label: "Balance Amount",
        field: "balamnt",
        sort: "asc",
      },
      {
        label: "Days Due for Payment",
        field: "daysdue",
        sort: "asc",
      },
      {
        label: "Expected RBP",
        field: "rbp",
        sort: "asc",
      },
      {
        label: "RBU",
        field: "rbu",
        sort: "asc",
      },
      {
        label: "Tenure",
        field: "tenure",
        sort: "asc",
      },
      {
        label: "Quantity Sold",
        field: "quansold",
        sort: "asc",
      },
      {
        label: "Selling Price",
        field: "sellpri",
        sort: "asc",
      },
      {
        label: "Moving Average (Last 5 Days)",
        field: "movingavg",
        sort: "asc",
      },
      {
        label: "Default (%)",
        field: "default",
        sort: "asc",
      },
    ],
    rows: rows,
  };

  return (
    <>
      <section className="snuSumSection">
        <div className="snuTabsMainDiv">
          <Tabs>
            <TabList>
              <Tab>Sales Volume Analysis</Tab>
              <Tab>Loan Amount Analysis</Tab>
            </TabList>

            <TabPanel>
              <div className="starSelectAllDivsDiv">
                <p className="starSnSelecText">*Select Notes from dropdown</p>
                <div className="starDropSelectDiv">
                  <div className="allSnsDivsDiv">
                    <div
                      className="snspArtDiv"
                      onClick={(e) => {
                        setIsDrop((prev) => !prev);
                      }}
                    >
                      <span>{SNData}</span>
                      <span className="snIconSpan">
                        <RiArrowDropDownLine size={"2em"} />
                      </span>
                    </div>
                    {isDrop && (
                      <div className="dropDownStartDiv">
                        <ul className="dropDownSnUl">
                          {snData.map((val, index) => {
                            return (
                              <li key={index} className="dropDownSnLi">
                                <input
                                  type="radio"
                                  name="snName"
                                  onClick={() => {
                                    setIsDrop(false);
                                    setSNData(val);
                                    fetchSalesValueAnalysis(val);
                                    fetchLoanValueAnalysis(val);
                                  }}
                                />
                                <label htmlFor="">{val}</label>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div>
                <p className="mdbPrevText">Sales Volume Analysis</p>
                <div className="mdbTableParDiv mdCmnTableDiv">
                  <MDBDataTable
                    bordered
                    entries={10000}
                    hover
                    data={data}
                    noBottomColumns={true}
                    small
                    stripes
                    selectRows={true}
                    className="text-center"
                  />
                </div>
              </div>
            </TabPanel>

            <TabPanel>
              <div className="starSelectAllDivsDiv">
                <p className="starSnSelecText">*Select Notes from dropdown</p>
                <div className="starDropSelectDiv">
                  <div className="allSnsDivsDiv">
                    <div
                      className="snspArtDiv"
                      onClick={(e) => {
                        setIsDrop((prev) => !prev);
                      }}
                    >
                      <span>{SNData}</span>
                      <span className="snIconSpan">
                        <i class="fa-solid fa-angle-down"></i>
                      </span>
                    </div>
                    {isDrop && (
                      <div className="dropDownStartDiv">
                        <ul className="dropDownSnUl">
                          {snData.map((val, index) => {
                            return (
                              <li key={index} className="dropDownSnLi">
                                <input
                                  type="radio"
                                  name="snName"
                                  onClick={() => {
                                    setIsDrop(false);
                                    setSNData(val);
                                    fetchSalesValueAnalysis(val);
                                    fetchLoanValueAnalysis(val);
                                  }}
                                />
                                <label htmlFor="">{val}</label>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div>
                <p className="mdbPrevText">Loan Amount Analysis</p>
                <div className="mdbTableParDiv2 mdCmnTableDiv">
                  <MDBDataTable
                    entries={10000}
                    bordered
                    hover
                    data={data2}
                    noBottomColumns={true}
                    small
                    stripes
                    selectRows={true}
                    className="text-center"
                  />
                </div>
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </section>
    </>
  );
};

export default Snusummary;
