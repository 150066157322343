import React, { useEffect, useState } from "react";
import "./Dailysales.css";
import "../MerchantSummary/Merchant.css";
import { MDBDataTable } from "mdbreact";
import DailySalesChart from "./DailySalesChart";
import HomeServices from "../../../../services/HomeServices";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { RiArrowDropDownLine } from "react-icons/ri";

const Dailysales = () => {
  const [showmore, setShowmore] = useState(false);
  const [selectedSKU, setSelectedSKU] = useState([]);
  const [QuantitySoldReport, setQuantitySoldReport] = useState([]);
  const [totalQuantitySold, settotalQuantitySold] = useState(null);
  const [isDrop, setIsDrop] = useState(false);
  const [projectedSales, setProjectedSales] = useState([]);
  const [totalProjectedSales, setTotalProjectedSales] = useState(null);
  const [snData, setSNdata] = useState([]);
  const token = useSelector((state) => state.auth.token);

  //table data
  const data = {
    columns: [
      {
        label: "Time Period",
        field: "timeperi",
        sort: "asc",
      },

      {
        label: "Sum of Quantity Sold",
        field: "sumquant",
        sort: "asc",
      },
    ],
    rows: [...QuantitySoldReport],
  };

  const projectedSalesData = {
    columns: [
      {
        label: "Time Period",
        field: "timeperi",
        sort: "asc",
      },
      {
        label: "Projected Sales (Moving Avg last 5 days)",
        field: "sumproj",
        sort: "asc",
      },
    ],
    rows: [...projectedSales],
  };

  const handleClick = () => {
    // setShowmore(!showmore);
  };

  const fetchingDropDownSNvalue = async () => {
    try {
      const res = await HomeServices.getSKUDropDown(token);
      if (res && res.status) {
        setSNdata(res.data.map((item) => item.skuId));
      } else {
        toast.error("Unable To Fetch SN Data");
      }
    } catch (error) {
      toast.error("Unable To Fetch SN Data");
    }
  };

  const fetchSales = async (selectedSKUs = null) => {
    const res = await HomeServices.dailySalesReport(selectedSKUs, token);

    if (res && res?.status) {
      const previous30DaysData = res?.previous30Days?.map((item) => ({
        timeperi: item?.timePeriod,
        sumquant: item?.quantitySold,
      }));

      const projectedSalesData = res?.projectedSales?.map((item) => ({
        timeperi: item?.timePeriod,
        sumproj: item?.quantitySold,
      }));

      setQuantitySoldReport(previous30DaysData.slice(0, -1));

      // Set QuantitySoldReport with the merged data
      setProjectedSales(projectedSalesData);

      settotalQuantitySold(res?.totalQuantitySold);
      setTotalProjectedSales(res?.totalProjectedSales);
    } else {
      toast.error("Unable to fetch Data");
    }
  };

  useEffect(() => {
    fetchingDropDownSNvalue();
    fetchSales();
  }, []);

  const handleSelectSKU = (skuId) => {
    // Check if the SKU is already selected
    if (selectedSKU.includes(skuId)) {
      // If selected, remove it from the array
      setSelectedSKU((prevSelectedSKU) =>
        prevSelectedSKU.filter((id) => id !== skuId)
      );
    } else {
      // If not selected, add it to the array
      setSelectedSKU((prevSelectedSKU) => [...prevSelectedSKU, skuId]);
    }

    // Call fetchSales with the updated selectedSKU
    fetchSales(
      selectedSKU.includes(skuId)
        ? selectedSKU.filter((id) => id !== skuId)
        : [...selectedSKU, skuId]
    );
  };

  return (
    <>
      <section className="dailySalesSection">
        <div className="dailySalesChartDiv">
          <div className="dailyTooltip">
            <div className="focusIcon">
              <i class="fa-solid fa-thumbtack"></i>
              <p className="focusPara">Pin Visual</p>
            </div>
            <div className="copyIcon">
              <i class="fa-regular fa-copy"></i>
              <p className="copyIconPara">Copy as image with caption</p>
            </div>
            <div className="focusIcon">
              <i class="fa-solid fa-expand"></i>
              <p className="focusPara">Focus Para</p>
            </div>
            <div className="moreIcon">
              <i class="fa-solid fa-ellipsis"></i>
              <p className="moreIconPara" onClick={handleClick}>
                More Options
              </p>
              {showmore && (
                <div className="moreIconMenu">
                  <ul>
                    <li className="shareLi">
                      <div>
                        <i class="fa-regular fa-share-from-square"></i>Share
                      </div>
                      <i class="fa-solid fa-chevron-right"></i>
                      <div className="shareMenu">
                        <ul>
                          <li>
                            <i class="fa-solid fa-link"></i>Link to this visual
                          </li>
                          <li>
                            <i class="fa-solid fa-user-plus"></i>Chat in Teams
                          </li>
                          <li>
                            <i class="fa-regular fa-file-powerpoint"></i>Open in
                            Powerpoint
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li>
                      <div>
                        <i class="fa-regular fa-message"></i>Add a comment
                      </div>
                    </li>
                    <li>
                      <div>
                        <i class="fa-regular fa-bell"></i>Set alert
                      </div>
                    </li>
                    <li>
                      <div>
                        <i class="fa-solid fa-file-export"></i>Export data
                      </div>
                    </li>
                    <li>
                      <div>
                        <i class="fa-solid fa-table"></i>Show as a table
                      </div>
                    </li>
                    <li>
                      <div>
                        <i class="fa-regular fa-lightbulb"></i>Spotlight
                      </div>
                    </li>
                    <li>
                      <div>
                        <i class="fa-solid fa-calendar-days"></i>Get insights
                      </div>
                    </li>
                    <li className="sortLi">
                      <div>Sort axis</div>
                      <i class="fa-solid fa-chevron-right"></i>
                      <div className="sortAxisMenu">
                        <ul>
                          <li>SKU Name</li>
                          <li>Balance Amount</li>
                          <li>Loan Amount</li>
                        </ul>
                        <ul className="sortbyUl">
                          <li>
                            <i class="fa-solid fa-arrow-down-z-a"></i>Sort
                            Descending
                          </li>
                          <li>
                            <i class="fa-solid fa-arrow-down-a-z"></i>Sort
                            Ascending
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
          <div className="dailyAllSelecDiv">
            <div className="starSelectAllDivsDiv">
              <p className="starSnSelecText">*Select SKU from dropdown</p>
              <div className="starDropSelectDiv">
                <div className="allSnsDivsDiv">
                  <div
                    className="snspArtDiv"
                    onClick={(e) => {
                      setIsDrop((prev) => !prev);
                    }}
                  >
                    <span>{snData ? snData[0] : "-"}</span>
                    <span className="snIconSpan">
                      <RiArrowDropDownLine size={"2em"} />
                    </span>
                  </div>
                  {isDrop && (
                    <div className="dropDownStartDiv">
                      <ul className="dropDownSnUl">
                        {snData.length > 0 &&
                          snData.map((val, index) => (
                            <li key={index} className="dropDownSnLi">
                              <input
                                type="checkbox"
                                name="snName"
                                checked={selectedSKU.includes(val)}
                                onChange={() => handleSelectSKU(val)}
                              />
                              <label htmlFor="">{val}</label>
                            </li>
                          ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <DailySalesChart selectedSKU={selectedSKU} />
        </div>
        <div>
          <div className="container-fluid dailySalesSection">
            <div className="row justify-content-center align-items-center">
              <div className="col-xl-4 mb-4 mb-xl-0">
                <ul className="dailySaleUl">
                  <li className="dailySaleLi">
                    <div
                      className="alltoolTipDivsDiv"
                      style={{ marginBottom: 20 }}
                    >
                      <div className="toolTipBtnsDiv"></div>
                      <div className="toolTipDiv">
                        <div className="toolTipInnerDiv">
                          <div>
                            <p className="toolInSmallTexts">
                              Quantity Sold (Previous 30 Days)
                            </p>
                            <p className="toolInSmallDates">
                              {totalQuantitySold?.toLocaleString("en-US")}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="dailyTblInn col-xl-4">
                      <p className="dailyTableText">
                        Quantity Sold (Previous 30 Days)
                      </p>
                      <div className="dailyQuantityTableDiv">
                        <MDBDataTable
                          bordered
                          hover
                          entries={10000}
                          data={data}
                          noBottomColumns={true}
                          small
                          stripes
                          selectRows={true}
                          className="text-center"
                        />
                      </div>
                    </div>
                  </li>
                  {/* New box for total projected sales */}
                  <li className="dailySaleLi">
                    <div
                      className="alltoolTipDivsDiv"
                      style={{ marginBottom: 20 }}
                    >
                      {/* Tooltip buttons */}
                      <div className="toolTipBtnsDiv">
                        {/* Buttons icons */}
                      </div>
                      <div className="toolTipDiv">
                        <div className="toolTipInnerDiv">
                          <div>
                            <p className="toolInSmallTexts">
                              Projected Sales (Next 10 Days)
                            </p>
                            <p className="toolInSmallDates">
                              {totalProjectedSales?.toLocaleString("en-US")}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="dailyTblInn col-xl-4">
                      <p className="dailyTableText">
                        Projected Sales (Moving Avg last 5 days)
                      </p>
                      <div className="dailyQuantityTableDiv">
                        {/* Table for projected sales */}
                        <MDBDataTable
                          bordered
                          hover
                          entries={10000}
                          data={projectedSalesData}
                          noBottomColumns={true}
                          small
                          stripes
                          selectRows={true}
                          className="text-center"
                        />
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Dailysales;
