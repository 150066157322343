import { FC, useState } from "react";
import { Badge, Modal, Table } from "@mantine/core";
import { useStyle } from "./IndividualTable.styles";
import { getKycColor } from "../../Utils/kyc";
import { capitalizeFirstLetter } from "@funded-here-interface/common/src/Utils/string";
import { getName } from "country-list";
import IndividualDetails from "../IndividualDetails/IndividualDetails";
import { useDisclosure } from "@mantine/hooks";
import { convertDate } from "@funded-here-interface/common/src/Utils/date";

interface IProps {
  data: any;
  refetchList: () => void;
}

const IndividualTable: FC<IProps> = ({ data, refetchList }) => {
  const { classes } = useStyle();
  const [id, setid] = useState<number>(0);
  const [opened, { open, close }] = useDisclosure(false);

  const rows = data.map((row: any, index: number) => (
    <tr
      key={index}
      onClick={() => {
        open();
        setid(row.id);
      }}
    >
      <td className={classes.td}>
        {row?.createdDate ? convertDate(row.createdDate) : "-"}
      </td>
      <td className={classes.td}>{row?.user[0]?.email || "-"}</td>
      <td className={classes.td}>{row?.user[0]?.firstName || "-"}</td>
      <td className={classes.td}>{row?.user[0]?.lastName || "-"}</td>
      <td className={classes.td}>
        {getName(row?.countryISOCode || "") || "-"}
      </td>
      <td className={classes.td}>
        {capitalizeFirstLetter(row?.user[0]?.orgRole[0]?.role?.name) || "-"}
      </td>
      <td>
        <Badge color={getKycColor(row?.kycStatus)}>
          {row?.kycStatus || "-"}
        </Badge>
      </td>
    </tr>
  ));

  return (
    <div className={classes.tableFlex}>
      <Table
        highlightOnHover
        withBorder
        withColumnBorders
        className={classes.csvTable}
      >
        <thead>
          <tr className={classes.tr}>
            <th className={classes.th}>
              <p className={classes.thinside}>Onboarding Date</p>
            </th>
            <th className={classes.th}>
              <p className={classes.thinside}>Email</p>
            </th>
            <th className={classes.th}>
              <p className={classes.thinside}>First Name</p>
            </th>
            <th className={classes.th}>
              <p className={classes.thinside}>Last Name</p>
            </th>
            <th className={classes.th}>
              <p className={classes.thinside}>Country</p>
            </th>
            <th className={classes.th}>
              <p className={classes.thinside}>Role</p>
            </th>
            <th className={classes.th}>
              <p className={classes.thinside}>KYC Status</p>
            </th>
          </tr>
        </thead>
        <tbody style={{ cursor: "pointer" }}>{rows}</tbody>
      </Table>

      <Modal
        opened={opened}
        onClose={close}
        size="85%"
        withCloseButton={false}
        padding="0"
      >
        <IndividualDetails id={id} close={close} refetchList={refetchList} />
      </Modal>
    </div>
  );
};

export default IndividualTable;
