import { color } from "@funded-here-interface/common";
import { createStyles } from "@mantine/styles";

export const useStyle = createStyles(() => ({
  wrapper: {
    marginTop: "20px",
  },
  header: {
    marginBottom: "20px",
  },
  view: {
    color: color.FHADMIN,
    cursor: "pointer",
  },
}));
