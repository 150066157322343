import { getApiResponseErrorMsg } from "@funded-here-interface/common/src/Utils/apiHelper";
import {
  BASE_URL,
  ENV,
} from "@funded-here-interface/common/src/constant/constant";
import axios from "axios";

export interface DocuSignPostData {
  webDomain: string;
  redirectUrl: string;
  orgId: number;
  token: string;
}

const fetchBmcaSecondSignerSignData = async ({
  webDomain,
  redirectUrl,
  orgId,
  token,
}: DocuSignPostData) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/trade-directory/agreement/second-signer-master-sku-borrower-url`,
      { webDomain, redirectUrl, orgId },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      }
    );

    // if (ENV === "production") {
    //   const url = response.data.signerUrls?.[0]?.url || "";
    //   const { docuSignEnvelopeId } = response.data;
    //   return { docuSignEnvelopeId, url };
    // } else {
    return response.data;
    // }
  } catch (e) {
    const message = getApiResponseErrorMsg(e);

    throw new Error(message);
  }
};

export { fetchBmcaSecondSignerSignData };
