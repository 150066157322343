import { useMutation } from "react-query";
import bulkDisburseDistributorFunds from "../services/bulkDisburseDistributorFunds";

function useBulkDisburseDistributorFunds() {
  return useMutation(
    ["bulk-disburse-distributor-funds"],
    bulkDisburseDistributorFunds
  );
}

export default useBulkDisburseDistributorFunds;
