import { NavLink } from "react-router-dom";
import "./HeaderPMDashboard.css";

const HeaderPmDashboard = () => {
  return (
    <header>
      <section className={"headerPart"}>
        <div className="container-fluid">
          <div className="headerInn">
            <ul className="headerUl">
              <li>
                <NavLink to="/pm-dashboard/overview">Overall View</NavLink>
              </li>
              <li>
                <NavLink to="/pm-dashboard/investor">Investor</NavLink>
              </li>
              <li>
                <NavLink to="/pm-dashboard/tracking">
                  Repayment Tracking
                </NavLink>
              </li>
              <li>
                <NavLink to="/pm-dashboard/note">Note Summary</NavLink>
              </li>
              <li>
                <NavLink to="/pm-dashboard/skusummary">SKU Summary</NavLink>
              </li>
              <li>
                <NavLink to="/pm-dashboard/merchant">Merchant Summary</NavLink>
              </li>
              <li>
                <NavLink to="/pm-dashboard/dailysales">
                  Daily Sales Report
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="btmheader">
        <div className="container-fluid">
          <p className="headerPara">*All amounts are in SGD</p>
        </div>
      </section>
    </header>
  );
};

export default HeaderPmDashboard;
