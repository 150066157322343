export function capitalizeFirstLetter(value: string | undefined) {
  if (!value) {
    return "";
  }

  return value.charAt(0).toUpperCase() + value.slice(1);
}

export function isStringNumberZero(str: string) {
  // Parse the string into a number
  const num = parseFloat(str);

  // Check if the number is exactly 0
  return num === 0;
}
