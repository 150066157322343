import axios from "axios";
import { BASE_URL } from "@funded-here-interface/common/src/constant/constant";
import { getApiResponseErrorMsg } from "@funded-here-interface/common/src/Utils/apiHelper";

interface GetSkuPendingPmVerifyRequest {
  token: string;
}

export interface GetSkuPendingPmVerifyResponse {
  skuId: string;
  upc: string;
  status: string;
  description: string;
  skuReturnPercentage: string;
  skuReturnMoreThanLimitReason: string;
  purchasePricePerUnit: string;
  sellingPricePerUnit: string;
  discount: string;
  tax: string;
  paymentTerm: string;
  partialPaymentFirstPaymentPercentage: string;
  nettInvoiceAmount: string;
  otherCosts: string;
  poNumber: string;
  poDate: string;
  deliveryDate: string;
  expectedPaymentDate: string;
  quantityPurchased: string;
  loanApplicationCurrency: string;
}

const getSkuPendingPmVerify = async ({
  token,
}: GetSkuPendingPmVerifyRequest): Promise<GetSkuPendingPmVerifyResponse[]> => {
  try {
    const response = await axios.get(
      `${BASE_URL}/sku-finance/loan-application/get-sku-loan-applications-pending-pm-verification`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      }
    );

    return response.data;
  } catch (e) {
    const message = getApiResponseErrorMsg(e);
    throw new Error(message);
  }
};
export default getSkuPendingPmVerify;
