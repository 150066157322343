import { useMutation } from "react-query";
import downloadVirtualAccountsCSV from "../services/downloadVirtualAccountsCSV";

function useDownloadVirtualAccountsCSV() {
  return useMutation(
    ["download-virtual-accounts-csv"],
    downloadVirtualAccountsCSV
  );
}

export default useDownloadVirtualAccountsCSV;
