import axios from "axios";
import { BASE_URL } from "@funded-here-interface/common/src/constant/constant";
import { getApiResponseErrorMsg } from "@funded-here-interface/common/src/Utils/apiHelper";

interface DownloadCampaignCSVRequest {
  token: string;
}

const downloadCampaignCsv = async ({ token }: DownloadCampaignCSVRequest) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/sku-finance/campaign/download-campaign-csv-file-sample`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob",
        withCredentials: true,
      }
    );

    return response.data;
  } catch (e) {
    const message = getApiResponseErrorMsg(e);

    throw new Error(message);
  }
};

export default downloadCampaignCsv;
