import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import HomeServices from "../../../../services/HomeServices";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

function Potential() {
  const [investorRepaymentTrackingData, setInvestorRepaymentTrackingData] =
    useState([]);
  const [totalSNdata, setTotalSNdata] = useState([]);
  const [categories, setCategories] = useState([]);
  const [seriesData, setSeriesData] = useState([]);
  const token = useSelector((state) => state.auth.token);
  const [chartHeight, setChartHeight] = useState(0);
  const [defaultValues, setDefaultValues] = useState([]);

  const state = {
    series: seriesData,
    options: {
      chart: {
        width: "100%",
        type: "bar",
        stacked: true,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: "60%",
          barWidth: "80%",
          distributed: false,
        },
      },
      dataLabels: {
        style: {
          colors: ["#151515"],
          fontSize: "12px",
          fontWeight: "600",
        },
        formatter: (val) => {
          return val?.toLocaleString("en-US");
        },
      },
      colors: ["#b2d7f9", "#92bbac"],
      states: {
        hover: {
          filter: {
            type: "none",
          },
        },
      },
      title: {
        text: "Top Notes with Highest Default (in %)",
        style: {
          fontSize: "13px",
          colors: "#595959",
        },
      },
      xaxis: {
        show: true,
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        categories: categories,
      },
      fill: {
        opacity: 1,
      },
      legend: {
        position: "top",
        fontSize: "10px",
        fontWeight: "500",
        horizontalAlign: "left",
        offsetY: -10,
        markers: {
          shape: "circle",
          width: "8px",
          height: "8px",
          borderRadius: "50%",
        },
      },
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
        padding: {
          top: -22,
          bottom: -15,
        },
      },
      tooltip: {
        enabled: true,
        followCursor: true,
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          const seriesName = w.config.series[seriesIndex].name;
          let tooltipContent = "";
          if (
            seriesName === "Balance Amount" ||
            seriesName === "Repaid Amount"
          ) {
            const skuName = w.globals.labels[dataPointIndex];
            const balanceAmount = parseFloat(series[0][dataPointIndex]);
            const repaidAmount = parseFloat(series[1][dataPointIndex]);
            const defaultValue = defaultValues[dataPointIndex];

            const totalLoanAmount = balanceAmount + repaidAmount;

            tooltipContent =
              '<div class="arrow_box">' +
              '<span class="headspan">' +
              "SKU : " +
              "</span>" +
              '<span class="paraspan">' +
              skuName +
              "</span>" +
              "</div>";

            if (seriesName === "Balance Amount") {
              tooltipContent +=
                '<div class="arrow_box">' +
                '<span class="headspan">' +
                "Balance Amount: " +
                "</span>" +
                '<span class="paraspan">' +
                balanceAmount.toLocaleString("en-US") +
                "</span>" +
                "</div>";
            } else if (seriesName === "Repaid Amount") {
              tooltipContent +=
                '<div class="arrow_box">' +
                '<span class="headspan">' +
                "Repaid Amount: " +
                "</span>" +
                '<span class="paraspan">' +
                repaidAmount.toLocaleString("en-US") +
                "</span>" +
                "</div>";
            }

            tooltipContent +=
              '<div class="arrow_box">' +
              '<span class="headspan">' +
              "Total Loan Amount: " +
              "</span>" +
              '<span class="paraspan">' +
              totalLoanAmount.toLocaleString("en-US") +
              "</span>" +
              "</div>";
            tooltipContent +=
              '<div class="arrow_box">' +
              '<span class="headspan">' +
              "Default Value: " +
              "</span>" +
              '<span class="paraspan">' +
              defaultValue +
              "</span>" +
              "</div>";
          }
          return tooltipContent;
        },
        y: {
          formatter: function (val) {
            return val?.toLocaleString("en-US");
          },
        },
      },
    },
  };

  const fetchBalanceAmount = async () => {
    try {
      const res = await HomeServices.highestPotentialSN(token);
      if (res && res?.status) {
        const sortedData = res.data.sort(
          (a, b) => b.balanceAmount - a.balanceAmount
        );
        const balanceData = sortedData.map((ele) => ele.balanceAmount);
        const loanData = sortedData.map((ele) => ele.repaidAmount);
        const category = sortedData.map((ele) => ele.snId);
        const defaults = sortedData.map((ele) => ele.default);

        const chartHeight = Math.max(balanceData.length * 40, 100);
        setChartHeight(chartHeight);

        setSeriesData([
          {
            name: "Balance Amount",
            data: balanceData,
          },
          {
            name: "Repaid Amount",
            data: loanData,
          },
        ]);
        setCategories(category);
        setDefaultValues(defaults);
      } else {
        toast.error("Unable to Fetch Balance Amount Analysis");
      }
    } catch (error) {
      console.error("Error fetching balance amount analysis: ", error);
      toast.error("Unable to Fetch Balance Amount Analysis");
    }
  };

  useEffect(() => {
    fetchBalanceAmount();
  }, []);

  return (
    <div className="InvestorBar" style={{ backgroundColor: "#fff" }}>
      <h3
        style={{
          fontSize: "14px",
          padding: "10px",
          fontWeight: "bold",
          color: "#595959",
        }}
      >
        Top Notes with Highest Default (in %)
      </h3>
      {seriesData.length > 0 && categories.length > 0 ? (
        <ReactApexChart
          options={state.options}
          series={state.series}
          height={chartHeight}
          type="bar"
        />
      ) : null}
    </div>
  );
}

export default Potential;
