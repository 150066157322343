import { FC } from "react";
import { useStyles } from "./ltvgm.styles";
import { Text } from "@mantine/core";
import { BarGraphChart } from "@funded-here-interface/common";

type OriginalDataItem = {
  name: string;
  value: number;
};

type LTVType = {
  LTVData: OriginalDataItem[];
  GMData: OriginalDataItem[];
  selectedLtvData: any[];
  selectedGmData: any[];
  scale: number;
};

const LTVGM: FC<LTVType> = ({
  LTVData,
  GMData,
  selectedLtvData,
  selectedGmData,
  scale,
}) => {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.childContainer}>
        <Text className={classes.chartTitle}>LTV</Text>
        <BarGraphChart
          chartData={LTVData}
          XAxisLabel="% LTV"
          YAxisLabel="# of SKU"
          selectedPoolData={selectedLtvData}
          scale={scale}
          YAxisScaleInterval={1}
        />
      </div>
      <div className={classes.seperator}></div>
      <div className={classes.childContainer}>
        <Text className={classes.chartTitle}>GM</Text>
        <BarGraphChart
          chartData={GMData}
          XAxisLabel="% GM"
          YAxisLabel="# of SKU"
          selectedPoolData={selectedGmData}
          scale={scale}
          YAxisScaleInterval={1}
        />
      </div>
    </div>
  );
};

export default LTVGM;
