import axios from "axios";
import { BASE_URL } from "@funded-here-interface/common/src/constant/constant";
import { getApiResponseErrorMsg } from "@funded-here-interface/common/src/Utils/apiHelper";
import { LoanStatus } from "../constant/enum";

export interface LoanManagementResponse {
  additionalSinkingFund: null | string;
  additionalSinkingFundPaid: null | string;
  availableCreditLine: null | string;
  createdAt: null | string;
  distributorFundAmountDispersed: null | string;
  distributorFundDispersedDate: null | string;
  distributorInvoiceDate: null | string;
  distributorInvoiceNumber: null | string;
  distributorInvoiceUploadedDate: null | string;
  distributorInvoiceVerificationStatus: null | string;
  distributorOrgName: null | string;
  distributorPartialFundFirstAmountDispersed: null | string;
  distributorPartialFundFirstDispersedDate: null | string;
  distributorPartialFundSecondAmountDispersed: null | string;
  distributorPartialFundSecondDispersedDate: null | string;
  doi: null | string;
  expectedPaymentDate: null | string;
  grnNumber: null | string;
  grnUploadedDate: null | string;
  grns: null | string;
  grossMargin: null | string;
  highestOrderedQuantityFromPastRecords: null | number;
  interestRate: null | string;
  interest: null | string;
  invoiceValue: null | string;
  isAdditionalSinkingFund: null | string;
  isLoaReceived: null | boolean;
  isMoePaid: null | string;
  isSinkingFundPaid: null | string;
  loanAmount0: null | string;
  loanAmount1: null | string;
  loanAmount2: null | string;
  adminFee: null | string;
  transAndChannelFee: null | string;
  thirdPartyFee: null | string;
  acquirerFees: null | string;
  loanApplicationCurrency: null | string;
  loanApplicationRejectionReason: null | string;
  loanToValuePercentage: null | string;
  moe: null | string;
  moePaid: null | string;
  orgName: null | string;
  paymentTerm: null | string;
  pendingDistributorFundsDispersal: null | boolean;
  preFinancingFees: null | string;
  preFinancingFeesPaid: null | string;
  productSalesVolume: null | number;
  quantityOrderedPo: null | number;
  shortfall: null | number;
  sinkingFund: null | string;
  sinkingFundPaid: null | string;
  skuId: null | string;
  skuReturn: null | string;
  skuSalesVolume: null | number;
  status: null | string;
  tenure: null | number;
  topUpAmount: null | string;
  topUpAmountPaid: null | string;
  upc: null | string;
  wmsDeliveredDate: null | string;
  wmsQuantityDelivered: null | number;
}

interface LoanManagementRequest {
  token: string;
  type: LoanStatus;
  noteId?: string;
}

const loanManagement = async ({
  token,
  type,
  noteId,
}: LoanManagementRequest): Promise<LoanManagementResponse[]> => {
  let url = `${BASE_URL}/sku-finance/loan-management?sortOrder=DESC&skuStatusFilterType=${type}`;

  if (noteId) {
    url += `&noteId=${noteId}`;
  }

  try {
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    });

    return response.data;
  } catch (error) {
    const message = getApiResponseErrorMsg(error);

    throw new Error(message);
  }
};

export default loanManagement;
