import React from "react";
import Repaid from "./Repaid";
import Potential from "./Potential";
import TrackingTableChart from "./TrackingTableChart";
import ErrorBoundary from "@funded-here-interface/common/src/dashboard/components/ErrorBoundry";

function Index() {
  return (
    <ErrorBoundary>
      <div className="repayment_tracking">
        <div className="top">
          Active SNs Repayments Analysis as on 03-Jan-24
        </div>
        <div className="repayTab mt-2 mb-3">
          <Repaid />
          <Potential />
        </div>
        <div className=" mt-2">
          <TrackingTableChart />
        </div>
      </div>
    </ErrorBoundary>
  );
}

export default Index;
