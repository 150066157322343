import { SkuLoanApplicationPaymentTerm } from "@funded-here-interface/common/src/constant/enum";

const formatDate = (dateStr: string): string | null => {
  if (!dateStr || isNaN(Date.parse(dateStr))) {
    return null;
  }

  return dateStr.split("T")[0];
};

const containsNoNumbers = (input: string): boolean => {
  const noNumbersRegex = /^[^\d]*$/;
  return noNumbersRegex.test(input.trim());
};

const isValidPrecisionScale = (
  input: string,
  precision: number,
  scale: number
): boolean => {
  const regex = new RegExp(
    `^\\d{1,${precision - scale}}(\\.\\d{1,${scale}})?$`
  );
  return regex.test(input.trim());
};

const isValidPaymentTerm = (
  term: string
): term is SkuLoanApplicationPaymentTerm => {
  return Object.values(SkuLoanApplicationPaymentTerm).includes(
    term as SkuLoanApplicationPaymentTerm
  );
};

const isValidNumber = (input: string): boolean => {
  const isNumber = /^\d+(\.\d+)?$/.test(input.trim());
  if (!isNumber) {
    return false;
  }

  const parsedValue = parseFloat(input.trim());
  return !isNaN(parsedValue) && parsedValue >= 0;
};

const isLaterThanToday = (dateStr: string): boolean => {
  const today = new Date();
  const inputDate = new Date(dateStr);

  today.setHours(0, 0, 0, 0);
  inputDate.setHours(0, 0, 0, 0);
  return inputDate > today;
};

export const validatePoInput = (values: any) => {
  let hasErrors = false;
  const errors = [];

  if (values.deliveryDate) {
    if (!formatDate(values.deliveryDate)) {
      errors.push("Delivery Date must be in this format: YYYY-MM-DD");
      hasErrors = true;
    }
  }

  if (values.poDate) {
    if (!formatDate(values.poDate)) {
      errors.push("PO Date must be in this format: YYYY-MM-DD");
      hasErrors = true;
    }
  }

  if (values.expectedPaymentDate) {
    if (!formatDate(values.expectedPaymentDate)) {
      errors.push("Expected Payment Date must be in this format: YYYY-MM-DD");
      hasErrors = true;
    } else if (!isLaterThanToday(values.expectedPaymentDate)) {
      errors.push("Expected Payment Date must be later than today");
      hasErrors = true;
    }
  }

  if (values.paymentTerm) {
    if (!isValidPaymentTerm(values.paymentTerm)) {
      const acceptedTerms = Object.values(SkuLoanApplicationPaymentTerm).join(
        ", "
      );
      errors.push(
        `Payment term must be one of the following: ${acceptedTerms}`
      );

      hasErrors = true;
    }
  }

  if (values.discount) {
    if (!isValidNumber(values.discount)) {
      errors.push("Discount must be a valid number");

      hasErrors = true;
    } else if (!isValidPrecisionScale(values.discount, 10, 2)) {
      errors.push(
        "Discount must be a valid number with up to 10 total digits and 2 decimal places"
      );
      hasErrors = true;
    }
  }

  if (values.nettInvoiceAmount) {
    if (!isValidNumber(values.nettInvoiceAmount)) {
      errors.push("Total Amount must be a valid number");

      hasErrors = true;
    } else if (!isValidPrecisionScale(values.nettInvoiceAmount, 10, 2)) {
      errors.push(
        "Total Amount must be a valid number with up to 10 total digits and 2 decimal places"
      );
      hasErrors = true;
    }
  }

  if (values.otherCosts) {
    if (!isValidNumber(values.otherCosts)) {
      errors.push("Other Costs Amount must be a valid number");

      hasErrors = true;
    } else if (!isValidPrecisionScale(values.otherCosts, 10, 2)) {
      errors.push(
        "Other Costs must be a valid number with up to 10 total digits and 2 decimal places"
      );
      hasErrors = true;
    }
  }

  if (values.purchasePricePerUnit) {
    if (!isValidNumber(values.purchasePricePerUnit)) {
      errors.push("Price Per Unit must be a valid number");

      hasErrors = true;
    } else if (!isValidPrecisionScale(values.purchasePricePerUnit, 10, 2)) {
      errors.push(
        "Price Per Unit must be a valid number with up to 10 total digits and 2 decimal places"
      );
      hasErrors = true;
    }
  }

  if (values.quantityPurchased) {
    const isWholeNumber = /^\d+$/.test(
      values.quantityPurchased.toString().trim()
    );

    if (!isValidNumber(values.quantityPurchased.toString())) {
      errors.push("Quantity must be a valid number");

      hasErrors = true;
    } else if (!isWholeNumber) {
      errors.push("Quantity must be a whole number (no decimals)");
      hasErrors = true;
    }
  }

  if (values.sellingPricePerUnit) {
    if (!isValidNumber(values.sellingPricePerUnit)) {
      errors.push("Selling Price Per Unit must be a valid number");

      hasErrors = true;
    } else if (!isValidPrecisionScale(values.sellingPricePerUnit, 10, 2)) {
      errors.push(
        "Selling Price Per Unit must be a valid number with up to 10 total digits and 2 decimal places"
      );
      hasErrors = true;
    }
  }

  if (values.tax) {
    if (!isValidNumber(values.tax)) {
      errors.push("Tax must be a valid number");

      hasErrors = true;
    } else if (!isValidPrecisionScale(values.tax, 10, 2)) {
      errors.push(
        "Tax must be a valid number with up to 10 total digits and 2 decimal places"
      );
      hasErrors = true;
    }
  }

  if (values.skuReturnPercentage) {
    if (!isValidNumber(values.skuReturnPercentage)) {
      errors.push("Return Percentage must be a valid number");

      hasErrors = true;
    } else if (parseFloat(values.skuReturnPercentage) > 100) {
      errors.push("Return Percentage cannot be greater than 100");

      hasErrors = true;
    } else if (!isValidPrecisionScale(values.skuReturnPercentage, 10, 2)) {
      errors.push(
        "Return Percentage must be a valid number with up to 10 total digits and 2 decimal places"
      );
      hasErrors = true;
    }
  }

  if (values.partialPaymentFirstPaymentPercentage) {
    if (!isValidNumber(values.partialPaymentFirstPaymentPercentage)) {
      errors.push(
        "Partial Payment's First Payment Percentage must be a valid number"
      );

      hasErrors = true;
    } else if (parseFloat(values.partialPaymentFirstPaymentPercentage) > 100) {
      errors.push(
        "Partial Payment's First Payment Percentage cannot be greater than 100"
      );

      hasErrors = true;
    } else if (
      !isValidPrecisionScale(values.partialPaymentFirstPaymentPercentage, 10, 8)
    ) {
      errors.push(
        "Partial Payment's First Payment Percentage must be a valid number with up to 10 total digits and 8 decimal places"
      );
      hasErrors = true;
    }
  }

  if (values.nettInvoiceAmountOtherCurrency) {
    if (!isValidNumber(values.nettInvoiceAmountOtherCurrency)) {
      errors.push("Total Amount (Other Currency) must be a valid number");

      hasErrors = true;
    } else if (
      !isValidPrecisionScale(values.nettInvoiceAmountOtherCurrency, 10, 2)
    ) {
      errors.push(
        "Total Amount (Other Currency) must be a valid number with up to 10 total digits and 2 decimal places"
      );
      hasErrors = true;
    }
  }

  if (values.fxRateOtherCurrency) {
    if (!isValidNumber(values.fxRateOtherCurrency)) {
      errors.push("Fx Rate must be a valid number");

      hasErrors = true;
    } else if (!isValidPrecisionScale(values.fxRateOtherCurrency, 20, 10)) {
      errors.push(
        "Fx Rate must be a valid number with up to 20 total digits and 10 decimal places"
      );
      hasErrors = true;
    }
  }

  if (values.otherCurrency) {
    if (!containsNoNumbers(values.otherCurrency)) {
      errors.push("Other Currency cannot contain numbers");
      hasErrors = true;
    } else if (values.otherCurrency.toLowerCase() === "sgd") {
      errors.push("Other Currency cannot be SGD");
      hasErrors = true;
    }
  }

  return { hasErrors, errors };
};
