import { FC, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import useGetPmVerifySkuLoanApplications from "../../../../hooks/useGetPmVerifySkuLoanApplications";
import { toast } from "react-toastify";
import { MRT_ColumnDef, MRT_RowSelectionState } from "mantine-react-table";
import {
  DataRow,
  GetPmVerifySkuLoanApplicationsResponse,
} from "../../../../services/getPmVerifySkuLoanApplications";
import { Divider, Modal, Title } from "@mantine/core";
import { useStyle } from "./VerifyInvoice.styles";
import { formatNumbersWithThousandSeperators } from "@funded-here-interface/common/src/Utils/formatter";
import useDownloadInvoice from "../../../../hooks/useDownloadInvoice";
import VerifyInvoiceMantineTable from "../Table/VerifyInvoiceMantineTable";
import { downloadFile } from "@funded-here-interface/common/src/Utils/file";
import { FileType } from "@funded-here-interface/common/src/constant/enum";
import { Button, color } from "@funded-here-interface/common";
import useDownloadVerifyInvoices from "../../../../hooks/useDownloadVerifyInvoices";
import { useDisclosure } from "@mantine/hooks";
import useVerifySkuLoanApplications from "../../../../hooks/useVerifySkuLoanApplications";
import VerifySkuSummary from "../../../../components/Modal/VerifySkuSummary";

const VerifyInvoice: FC = () => {
  const { classes } = useStyle();
  const token = useSelector((state: RootState) => state.auth.token);
  const useGetPmVerifySkuLoanApplicationsMutation =
    useGetPmVerifySkuLoanApplications();
  const [isLoading, setIsLoading] = useState(true);
  const [isApproveSkuLoading, setIsApproveSkuLoading] = useState(false);
  const [data, setData] = useState<GetPmVerifySkuLoanApplicationsResponse>({});
  const useDownloadInvoiceMutation = useDownloadInvoice();
  const useDownloadVerifyInvoicesMutation = useDownloadVerifyInvoices();
  const [allSelectedIdsMap, setAllSelectedIdsMap] = useState<{
    [key: string]: string[];
  }>({});
  const [allSelectedIds, setAllSelectedIds] = useState<string[]>([]);
  const [opened, { open, close }] = useDisclosure(false);
  const useVerifySkuLoanApplicationsMutation = useVerifySkuLoanApplications();
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

  const fetchData = async () => {
    setIsLoading(true);
    useGetPmVerifySkuLoanApplicationsMutation.mutate(
      { token },
      {
        onSuccess: (data) => {
          setData(data);
          setIsLoading(false);
        },
        onError: (error) => {
          toast.error((error as Error).message);
          setIsLoading(false);
        },
      }
    );
  };
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const allValues = Object.values(allSelectedIdsMap);
    setAllSelectedIds(allValues.flatMap((value) => value));
  }, [allSelectedIdsMap]);

  const handleDownloadInvoice = (invoiceId: string) => {
    useDownloadInvoiceMutation.mutate(
      { token, invoiceId },
      {
        onSuccess: (data: Blob) => {
          downloadFile(data, `Invoice_${invoiceId}`, FileType.PDF);
        },
        onError: (e) => {
          toast.error((e as Error).message);
        },
      }
    );
  };

  const columns = useMemo<MRT_ColumnDef<DataRow>[]>(
    () => [
      {
        accessorKey: "skuId",
        header: "SKU ID",
      },
      {
        accessorKey: "upc",
        header: "UPC",
      },
      {
        accessorKey: "merchantOrgName",
        header: "Org Name",
      },
      {
        accessorKey: "paymentTerm",
        header: "Terms of Payment",
      },

      {
        accessorKey: "quantityPurchased",
        header: "Qty Ordered PO",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<number>()
              ? formatNumbersWithThousandSeperators(cell.getValue<number>())
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "nettInvoiceAmount",
        header: "Total Invoice Amt",
        Cell: ({ row }) => {
          const currency = row?.original?.loanApplicationCurrency || "";
          const amount = formatNumbersWithThousandSeperators(
            parseFloat(row?.original?.nettInvoiceAmount || "0")
          );
          return <p>{`${currency} ${amount}`}</p>;
        },
      },
      {
        accessorKey: "deliveredQuanty",
        header: "WMS Qty Delivered",
      },
      {
        accessorKey: "deliveredQuantityshortfall",
        header: "Shortfall (PO-WMS)",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<number>()
              ? formatNumbersWithThousandSeperators(cell.getValue<number>())
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "distributorOrgName",
        header: "Supplier Name",
      },
      {
        accessorKey: "distributorInvoiceUploadedDate",
        header: "Invoice Date",
        Cell: ({ cell }) => <p>{cell.getValue<string>() ?? "-"}</p>,
      },
    ],
    []
  );

  const updateAllSelectedIds = (tableId: string, selectedIds: string[]) => {
    setAllSelectedIdsMap((prevSelectedIds) => {
      const updatedSelections = { ...prevSelectedIds };
      Object.keys(updatedSelections).forEach((key) => {
        if (key !== tableId) {
          updatedSelections[key] = updatedSelections[key].filter(
            (id) => !selectedIds.includes(id)
          );
        }
      });

      updatedSelections[tableId] = selectedIds;
      return updatedSelections;
    });
  };

  const handleDownloadCsv = () => {
    useDownloadVerifyInvoicesMutation.mutate(
      { token },
      {
        onSuccess: (data: Blob) => {
          downloadFile(data, "Invoices for PM Verification", FileType.CSV);
        },
        onError: (e) => {
          toast.error((e as Error).message);
        },
      }
    );
  };

  const handleApproveInvoices = () => {
    setIsApproveSkuLoading(true);
    useVerifySkuLoanApplicationsMutation.mutate(
      { token, skuIds: allSelectedIds },
      {
        onSuccess: () => {
          toast.success(`SKUs approved successfully`);
          setRowSelection({});
          fetchData();
          close();
          setIsApproveSkuLoading(false);
        },
        onError: (error) => {
          toast.error((error as Error).message);
          setIsApproveSkuLoading(false);
        },
      }
    );
  };

  return (
    <>
      <Modal
        opened={opened}
        onClose={close}
        size="lg"
        title={
          <Title order={4} style={{ fontWeight: "bold" }}>
            Confirmation of SKUs
          </Title>
        }
      >
        <VerifySkuSummary
          isLoading={isApproveSkuLoading}
          skuIds={allSelectedIds}
          onSubmit={handleApproveInvoices}
          header="The SKUs below will be approved:"
        />
      </Modal>
      <div className={classes.buttonWrapper}>
        <Button
          onClick={open}
          backgroundColor={color.FHGREEN}
          textColor="#fff"
          children="Approve"
          disabled={allSelectedIds.length === 0}
          width="154px"
        />
        <Button
          onClick={handleDownloadCsv}
          backgroundColor={color.LOANTEXT}
          textColor="#fff"
          children="Download CSV"
          width="154px"
        />
      </div>
      {Object.entries(data).map(([key, value], index, array) => (
        <div key={key}>
          <VerifyInvoiceMantineTable
            onUpdateSelectedIds={(tableId, selectedIds) =>
              updateAllSelectedIds(tableId, selectedIds)
            }
            invoiceId={key}
            onDownloadInvoice={(invoiceId) => handleDownloadInvoice(invoiceId)}
            columns={columns}
            data={value}
            isLoading={isLoading}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
          />
          {index !== array.length - 1 && (
            <Divider my="md" className={classes.divider} />
          )}
        </div>
      ))}
    </>
  );
};

export default VerifyInvoice;
