import { FC, useState } from "react";
import { Data, UserData } from "../../../types/org.type";
import { useStyles } from "./Documents.styles";
import { Text, Image, Divider } from "@mantine/core";
import { toast } from "react-toastify";
import downloadbutton from "../../../assets/download_24px.svg";
import { RootState } from "../../../store";
import { useSelector } from "react-redux";
import useGetDocument from "../../../hooks/useGetDocument";
import { downloadFile } from "@funded-here-interface/common/src/Utils/file";
import {
  FileType,
  SpvFile,
} from "@funded-here-interface/common/src/constant/enum";
import useAdminUploadDocument from "../../../hooks/useAdminUploadDocument";
import { File as FileEnum } from "@funded-here-interface/common/src/constant/enum";
import {
  AdminUploadDocumentReponse,
  AdminUploadDocumentRequest,
} from "../../../services/adminUploadDocument";
import { DocumentsUpload } from "@funded-here-interface/common/src/constant/type";
import DocumentList from "./Components/DocumentList";
import { spvFiles } from "@funded-here-interface/common/src/constant/constant";

interface DocumentsProps {
  data: Data | undefined;
  userData: UserData | undefined;
}

const Documents: FC<DocumentsProps> = ({ data, userData }) => {
  const { classes } = useStyles();
  const token = useSelector((state: RootState) => state.auth.token);
  const useGetDocumentMutaiton = useGetDocument();
  const useAdminUploadDocumentMutation = useAdminUploadDocument();

  const [documentPaths, setDocumentPaths] = useState({
    businessProfile: data?.documentsUpload?.businessProfile || [],
    shareHoldersDirectorsRegisters:
      data?.documentsUpload?.shareHoldersDirectorsRegisters || [],
    balanceSheetPAndLStatement:
      data?.documentsUpload?.balanceSheetPAndLStatement || [],
    proofOfAddress: data?.documentsUpload?.proofOfAddress || [],
    financialStatement: data?.documentsUpload?.financialStatement || [],
    salaryProof: data?.documentsUpload?.salaryProof || [],
    investmentMandate: data?.documentsUpload?.investmentMandate || [],
    fundMangerLicense: data?.documentsUpload?.fundMangerLicense || [],
    bankStatement: data?.documentsUpload?.bankStatement || [],
  });

  const additionalDocs = data?.documentsUpload?.additionals || [];
  const hasSpvCompany = data?.spv;
  let spvFilesList = [];
  let additionalFilesList = [];

  if (hasSpvCompany && additionalDocs.length > 0) {
    for (const doc of additionalDocs) {
      if (spvFiles.includes(doc.docName as SpvFile)) {
        spvFilesList.push(doc);
      } else {
        additionalFilesList.push(doc);
      }
    }
  } else {
    additionalFilesList = [...additionalDocs];
  }

  const documentMapping: Record<FileEnum, keyof DocumentsUpload> = {
    [FileEnum.BUSINESS_PROFILE]: "businessProfile",
    [FileEnum.SHAREHOLDERS_DIRECTORS_REGISTERS]:
      "shareHoldersDirectorsRegisters",
    [FileEnum.BALANCE_SHEET]: "balanceSheetPAndLStatement",
    [FileEnum.PROOF_OF_ADDRESS]: "proofOfAddress",
    [FileEnum.BANK_STATEMENT]: "bankStatement",
    [FileEnum.FINANCIAL_STATEMENT]: "financialStatement",
    [FileEnum.SALARY_PROOF]: "salaryProof",
    [FileEnum.INVESTMENT_MANDATE]: "investmentMandate",
    [FileEnum.FUND_MANAGER_LICENSE]: "fundMangerLicense",
  };

  const downloadDocument = (path: string, fileName: string) => {
    useGetDocumentMutaiton.mutate(
      { token, path },
      {
        onSuccess: (data: Blob) => {
          downloadFile(data, fileName, FileType.PDF);
        },
        onError: (e) => {
          toast.error((e as Error).message);
        },
      }
    );
  };

  const uploadDocument = (file: File | null, fileType: FileEnum) => {
    if (!file) {
      toast.error("Uploaded file not found.");

      return;
    }

    useAdminUploadDocumentMutation.mutate(
      {
        token,
        file,
        name: file?.name,
        fileType,
        userId: userData?.id!,
      } as AdminUploadDocumentRequest,
      {
        onSuccess: (data: AdminUploadDocumentReponse) => {
          const newPath = data.path;
          const documentKey = documentMapping[fileType];
          setDocumentPaths((prevPaths) => ({
            ...prevPaths,
            [documentKey]: [...prevPaths[documentKey], newPath],
          }));
        },
        onError: (e) => {
          toast.error((e as Error).message);
        },
      }
    );
  };

  const getNameFromPath = (path: string) => {
    const parts = path.split(`\\`);
    const extractedName = parts.slice(1).join(`\\`);

    return extractedName;
  };

  return (
    <>
      <div className={classes.valueContainerDoc}>
        {documentPaths.businessProfile.length > 0 && (
          <DocumentList
            documents={documentPaths.businessProfile}
            header="Business profiles"
            fileType={FileEnum.BUSINESS_PROFILE}
            uploadDocument={uploadDocument}
            downloadDocument={downloadDocument}
            getNameFromPath={getNameFromPath}
          />
        )}
        {documentPaths.shareHoldersDirectorsRegisters.length > 0 && (
          <DocumentList
            documents={documentPaths.shareHoldersDirectorsRegisters}
            header="Shareholders & Directors Registers"
            fileType={FileEnum.SHAREHOLDERS_DIRECTORS_REGISTERS}
            uploadDocument={uploadDocument}
            downloadDocument={downloadDocument}
            getNameFromPath={getNameFromPath}
          />
        )}
        {documentPaths.balanceSheetPAndLStatement.length > 0 && (
          <DocumentList
            documents={documentPaths.balanceSheetPAndLStatement}
            header="Balance Sheet & P&L Statements"
            fileType={FileEnum.BALANCE_SHEET}
            uploadDocument={uploadDocument}
            downloadDocument={downloadDocument}
            getNameFromPath={getNameFromPath}
          />
        )}
        {documentPaths.proofOfAddress.length > 0 && (
          <DocumentList
            documents={documentPaths.proofOfAddress}
            header="Proof of Address"
            fileType={FileEnum.PROOF_OF_ADDRESS}
            uploadDocument={uploadDocument}
            downloadDocument={downloadDocument}
            getNameFromPath={getNameFromPath}
          />
        )}
        {documentPaths.financialStatement.length > 0 && (
          <DocumentList
            documents={documentPaths.financialStatement}
            header="Financial Statements"
            fileType={FileEnum.FINANCIAL_STATEMENT}
            uploadDocument={uploadDocument}
            downloadDocument={downloadDocument}
            getNameFromPath={getNameFromPath}
          />
        )}
        {documentPaths.salaryProof.length > 0 && (
          <DocumentList
            documents={documentPaths.salaryProof}
            header="Salary Proofs"
            fileType={FileEnum.SALARY_PROOF}
            uploadDocument={uploadDocument}
            downloadDocument={downloadDocument}
            getNameFromPath={getNameFromPath}
          />
        )}
        {documentPaths.investmentMandate.length > 0 && (
          <DocumentList
            documents={documentPaths.investmentMandate}
            header="Investment Mandates"
            fileType={FileEnum.INVESTMENT_MANDATE}
            uploadDocument={uploadDocument}
            downloadDocument={downloadDocument}
            getNameFromPath={getNameFromPath}
          />
        )}
        {documentPaths.fundMangerLicense.length > 0 && (
          <DocumentList
            documents={documentPaths.fundMangerLicense}
            header="Fund Manager License"
            fileType={FileEnum.FUND_MANAGER_LICENSE}
            uploadDocument={uploadDocument}
            downloadDocument={downloadDocument}
            getNameFromPath={getNameFromPath}
          />
        )}
        {documentPaths.bankStatement.length > 0 && (
          <DocumentList
            documents={documentPaths.bankStatement}
            header="Bank Statements"
            fileType={FileEnum.BANK_STATEMENT}
            uploadDocument={uploadDocument}
            downloadDocument={downloadDocument}
            getNameFromPath={getNameFromPath}
          />
        )}
        {additionalFilesList && additionalFilesList.length > 0 && (
          <div>
            {additionalFilesList.map((doc) => (
              <div className={classes.DocContain}>
                <Text className={classes.additionalDocName}>{doc.docName}</Text>
                <Image
                  onClick={() => downloadDocument(doc.path, doc.docName)}
                  src={downloadbutton}
                  className={classes.downloadIcon}
                />
              </div>
            ))}
          </div>
        )}
      </div>
      {spvFilesList.length > 0 && (
        <div style={{ paddingLeft: 44, paddingBottom: 44 }}>
          <Divider my="md" />
          <h2 className={classes.spvHeader}>SPV</h2>
          {spvFilesList.map((doc) => (
            <div className={classes.DocContain}>
              <Text className={classes.additionalDocName}>{doc.docName}</Text>
              <Image
                onClick={() => downloadDocument(doc.path, doc.docName)}
                src={downloadbutton}
                className={classes.downloadIcon}
              />
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default Documents;
