import { FC, useState, useEffect } from "react";
import CustomTableForOrgDetails from "../../components/TableOrgDetails/CustomTableOrgDetails";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import { useStyles } from "./OrgDetails.styles";
import useGetOrgsMutation from "../../hooks/useGetOrgs";
import {
  Box,
  Divider,
  Group,
  LoadingOverlay,
  Select,
  TextInput,
  Title,
  Text,
} from "@mantine/core";
import { KYCStatus } from "@funded-here-interface/common/src/constant/enum";
import useDebounce from "@funded-here-interface/common/src/hooks/useDebounce";

const OrgDetailsPage: FC = () => {
  const { classes } = useStyles();
  const token = useSelector((state: RootState) => state.auth.token);
  const noKycFilter = "ALL";
  const limits = ["10", "20", "50"];
  const [page, setPage] = useState("1");
  const [limit, setLimit] = useState("10");
  const [organizations, setOrganizations] = useState([]);
  const [kycStatus, setKycStatus] = useState(noKycFilter);
  const [search, setSearch] = useState("");
  const kycStatusFilter = [
    noKycFilter,
    KYCStatus.APPROVED.toUpperCase(),
    KYCStatus.PENDING.toUpperCase(),
    KYCStatus.REJECTED.toUpperCase(),
  ];

  const debouncedSearch = useDebounce(search, 1000);
  const { data, error, isLoading, refetch } = useGetOrgsMutation(
    token,
    limit,
    page,
    false,
    kycStatus !== noKycFilter ? kycStatus.toLowerCase() : "",
    debouncedSearch
  );

  useEffect(() => {
    setPage("1");
  }, [debouncedSearch]);

  useEffect(() => {
    setOrganizations(data?.organizations || []);
  }, [data]);

  const handleSearchChange = (e: React.FormEvent<HTMLInputElement>) => {
    const searchedTerm = (e.target as HTMLTextAreaElement).value;
    setSearch(searchedTerm);
  };

  const handlePageChange = async (event: any) => {
    const pageNo = event.selected + 1;
    setPage(`${pageNo}`);
  };

  if (error) {
    toast.error((error as Error).message);
  }

  const handleLimitChange = async (limit: string) => {
    setPage("1");
    setLimit(limit);
  };

  return (
    <div>
      <Title order={1}>Organizations</Title>
      <Divider my="md" />
      <Box pos="relative">
        <LoadingOverlay visible={isLoading} />
        <Group align="end">
          <Select
            style={{ width: "130px", marginTop: "20px" }}
            label="KYC Status"
            value={kycStatus}
            onChange={(value: string) => {
              setPage("1");
              setKycStatus(value);
            }}
            data={kycStatusFilter}
          />
          <TextInput
            mt="md"
            label="Search"
            onChange={handleSearchChange}
            placeholder="Search company name"
          />
        </Group>
        <div>
          <CustomTableForOrgDetails
            data={organizations}
            refetchList={refetch}
          />
        </div>

        {!isLoading && organizations.length === 0 && (
          <div className={classes.noData}>
            <Text>No data available</Text>
          </div>
        )}

        <div className={classes.buttonContainer}>
          <div className={classes.rowSelection}>
            <Select
              placeholder={limit}
              data={limits}
              onChange={handleLimitChange}
            />
          </div>
          <ReactPaginate
            nextLabel=">"
            onPageChange={handlePageChange}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={Math.ceil(data?.totalCount / parseInt(limit))}
            previousLabel="<"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
            forcePage={parseInt(page) - 1}
          />
        </div>
      </Box>
    </div>
  );
};

export default OrgDetailsPage;
