import axios from "axios";
import { BASE_URL } from "@funded-here-interface/common/src/constant/constant";
import { getApiResponseErrorMsg } from "@funded-here-interface/common/src/Utils/apiHelper";
import { SkuLoanApplicationPaymentTerm } from "@funded-here-interface/common/src/constant/enum";

interface UpdateSkuPoOcrRequest {
  deliveryDate?: string;
  discount?: string;
  expectedPaymentDate?: string;
  nettInvoiceAmount?: string;
  otherCosts?: string;
  partialPaymentFirstPaymentPercentage?: string;
  paymentTerm?: SkuLoanApplicationPaymentTerm;
  poDate?: string;
  poNumber?: string;
  purchasePricePerUnit?: string;
  quantityPurchased?: number;
  sellingPricePerUnit?: string;
  skuReturnMoreThanLimitReason?: string;
  skuReturnPercentage?: string;
  tax?: string;
  upc?: string;
  id: number;
  token: string;
}

const updateSkuPoOcr = async ({
  token,
  id,
  ...skuData
}: UpdateSkuPoOcrRequest) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/sku-finance/loan-application/${id}/update-sku-po-ocr`,
      { ...skuData },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      }
    );

    return response.data;
  } catch (e) {
    const message = getApiResponseErrorMsg(e);
    throw new Error(message);
  }
};
export default updateSkuPoOcr;
