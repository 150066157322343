import { createStyles } from "@mantine/core";

export const useStyles = createStyles(() => ({
  valueContainerDoc: {
    padding: "44px",
  },
  DocContain: {
    display: "flex",
    marginTop: "20px",
    marginLeft: "30px",
  },
  additionalDocName: {
    fontSize: "18px",
    width: "400px",
    fontWeight: 700,
  },
  downloadIcon: {
    cursor: "pointer",
    display: "flex",
  },
  spvHeader: {
    marginLeft: 30,
    marginTop: 44,
    marginBottom: 30,
    fontWeight: 700,
  },
}));
