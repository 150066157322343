import { FC, useMemo } from "react";
import { AmtTransferDataItem } from "../../../../services/getAmtTransfer";
import { useStyle } from "./TransferFund.styles";
import { toast } from "react-toastify";
import { Button, Modal, Text, Group } from "@mantine/core";
import {
  MRT_ColumnDef,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import useGetAmtTransfer from "../../../../hooks/useGetAmtTransferMutation";
import { useState, useEffect } from "react";
import { useDisclosure } from "@mantine/hooks";
import useTransferFundToJVA from "../../../../hooks/useTransferFundToJVAConfirmed";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";

interface Props {
  id: string;
}

const TransferFund: FC<Props> = ({ id }) => {
  const token = useSelector((state: RootState) => state.auth.token);
  const { classes } = useStyle();
  const [data, setData] = useState<AmtTransferDataItem[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [amtTransferInput, setAmtTransferInput] = useState<string>("");
  const [editingRowIndex, setEditingRowIndex] = useState<number | null>(null);
  const amtTransferMutation = useGetAmtTransfer();
  const [oriTotalAmtInvest, setOriTotalAmtInvest] = useState(0);
  const [updatedTotalAmtInvest, setUpdatedTotalAmtInvest] = useState(0);
  const [isTransferLoading, setIsTransferLoading] = useState(false);
  const [openedTransfer, modalAction] = useDisclosure(false);
  const transferFundConfirmed = useTransferFundToJVA();
  const [transferBtndisabled, setTransferBtndisabled] = useState(false);

  const calculateTotalInvest = (data: AmtTransferDataItem[]) => {
    let total = 0;
    for (const item of data) {
      total += item.amountToTransfer;
    }
    return total;
  };

  const handleTransferClick = () => {
    setIsTransferLoading(true);
    modalAction.open();
    setIsTransferLoading(false);
  };

  const handleTransferConfirmClick = async () => {
    const merchantTransferTemp = data.filter(x => x.amountToTransfer !== 0).map((merchant) => ({
      merchantOrgName: merchant.merchantOrgName,
      merchantOrgRoleId: merchant.merchantOrgRoleId,
      amountToTransfer: merchant.amountToTransfer,
      currency: merchant.currency,
      structuredNoteId: Number(id),
    }));
    transferFundConfirmed.mutate(
      {
        token: token,
        merchantTransfer: merchantTransferTemp,
      },
      {
        onSuccess: () => {
          toast.info("Funds is transferred successfully.");
          fetchData();
        },
        onError: (e) => {
          toast.error((e as Error).message);
          fetchData();
        },
        onSettled: () =>{
          modalAction.close();
        }
      }
    );
  };

  const fetchData = async () => {
    amtTransferMutation.mutate(
      {
        token,
        id,
      },
      {
        onSuccess: (data) => {
          setData(data.amtTransferResult || []);
          setTransferBtndisabled(false);
          setOriTotalAmtInvest(calculateTotalInvest(data.amtTransferResult));
          setUpdatedTotalAmtInvest(
            calculateTotalInvest(data.amtTransferResult)
          );
          setAmtTransferInput("");
          setIsLoading(false);
        },
        onError: (error) => {
          setTransferBtndisabled(true);
          setIsLoading(false);
          toast.error((error as Error).message);
        },
      }
    );
  };

  useEffect(() => {
    fetchData();
  },[]);

  useEffect(() => {
    setUpdatedTotalAmtInvest(calculateTotalInvest(data));
    if (updatedTotalAmtInvest <= 0) {
      setTransferBtndisabled(true);
    }
  }, [data]);

  useEffect(() => {
    if (updatedTotalAmtInvest > oriTotalAmtInvest) {
      toast.error(
        "Amount to transferred is more than the maximum available balance for Junior investment tranche."
      );
      setTransferBtndisabled(true);
      return;
    } else if (updatedTotalAmtInvest <= 0) {
      setTransferBtndisabled(true);
    } else {
      setTransferBtndisabled(false);
    }
  }, [oriTotalAmtInvest, updatedTotalAmtInvest]);

  const columns = useMemo<MRT_ColumnDef<AmtTransferDataItem>[]>(
    () => [
      {
        accessorKey: "merchantOrgName",
        header: "Merchant Name",
      },
      {
        accessorKey: "currency",
        header: "Currency",
      },
      {
        accessorKey: "amountToTransfer",
        header: "Amount To Be Transferred",
        Cell: ({ cell, row }) => {
          const currentValue = cell.getValue<number>();
          const valueToDisplay =
            editingRowIndex === row.index
              ? amtTransferInput !== null && amtTransferInput !== undefined
                ? amtTransferInput
                : parseFloat(currentValue.toFixed(2))
              : parseFloat(currentValue.toFixed(2));
          return (
            <>
              <input
                className={classes.input}
                type="number"
                min="0"
                step="0.01"
                value={valueToDisplay}
                onChange={(e) => {
                  const inputVal = e.target.value;
                  if (!isNaN)
                    setAmtTransferInput("0.00");
                  else
                    setAmtTransferInput(inputVal);
                }}
                onBlur={() => {
                  const valToSave = parseFloat(amtTransferInput);

                  if (
                    row.index >= 0 &&
                    row.index < data.length &&
                    editingRowIndex !== null
                  ) {
                    const updatedValue = [...data];
                    updatedValue[row.index].amountToTransfer = isNaN(valToSave)? 0.00 : valToSave;
                    setData(updatedValue);
                    setEditingRowIndex(null);
                  }
                }}
                onFocus={() => setEditingRowIndex(row.index)}
              />
            </>
          );
        },
      },
    ],
    [data, amtTransferInput, editingRowIndex, calculateTotalInvest, classes.input]
  );

  const table = useMantineReactTable({
    enableColumnActions: false,
    enableColumnFilters: false,
    paginationDisplayMode: "pages",
    state: { isLoading },
    columns,
    data: data,
    initialState: { density: "xs" },
    mantineTableProps: {
      withBorder: true,
    },
  });

  return (
    <div>
      <div>
        <div className={classes.buttonWrapper}>
          <Button
            onClick={() => handleTransferClick()}
            disabled={transferBtndisabled}
            color="fh-green"
            loading={isTransferLoading}
          >
            Transfer Funds
          </Button>
        </div>
        <MantineReactTable table={table} />
      </div>
      <Modal opened={openedTransfer} onClose={modalAction.close} size="lg">
        <Text
          ta="center"
          style={{
            color: "#338F86",
            fontWeight: "bold",
            fontSize: "22px",
          }}
        >
          Proceed to transfer
        </Text>
        <Text fz="sm" ta="center" mt="lg">
          Please note that {oriTotalAmtInvest} will be transfer to Funded Here
          junior investor's virtual account
        </Text>
        <Group mt="xl" position="center">
          <button
            style={{
              color: "white",
              backgroundColor: "338F86",
              borderRadius: "5px",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              padding: "12px 20px",
              fontSize: "16px",
              border: "none",
            }}
            onClick={modalAction.close}
          >
            No, I will not transfer.
          </button>
          <button
            style={{
              color: "white",
              backgroundColor: "#979797",
              borderRadius: "5px",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              padding: "12px 20px",
              fontSize: "16px",
              border: "none",
            }}
            onClick={handleTransferConfirmClick}
          >
            Yes, I will transfer the funds.
          </button>
        </Group>
      </Modal>
    </div>
  );
};

export default TransferFund;
