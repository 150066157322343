import { FC } from "react";
import { Button, color } from "@funded-here-interface/common";
import { useStyle } from "./VerifySkuSummary.styles";

interface IProps {
  skuIds: string[];
  onSubmit: () => void;
  isLoading: boolean;
  header: string;
}

const VerifySkuSummary: FC<IProps> = (props) => {
  const { classes } = useStyle();
  const { skuIds, onSubmit, isLoading, header } = props;

  return (
    <div>
      <p>{header}</p>
      <div className={classes.modalContent}>
        <ul>
          {skuIds.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
        <br />
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          <Button
            onClick={onSubmit}
            backgroundColor={color.FHGREEN}
            textColor={color.WHITE}
            children="Submit"
            width="20%"
            disabled={isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default VerifySkuSummary;
