import { color } from "@funded-here-interface/common";
import { MantineTheme, createStyles } from "@mantine/core";

export const useStyle = createStyles((theme: MantineTheme) => ({
  buttonDiv: {
    display: "flex",
    flexDirection: "row",
    paddingLeft: "0px",
  },
  buttonWrapper: {
    display: "flex",
    marginTop: "10px",
  },
  headerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  headerText: {
    marginLeft: "50px",
  },
  optionsDiv: {
    position: "absolute",
    backgroundColor: color.BGGREY,
    padding: "1.6rem",
    marginTop: "45px",
    borderRadius: "10px",
    zIndex: 100,
  },
  options: {
    marginBottom: "1.2rem",
    cursor: "pointer",
  },
  inviteDiv: {
    zIndex: 100,
    width: "31.8rem",
    padding: "3.6rem",
    backgroundColor: color.WHITE,
    boxShadow: "5px 5px 5px 5px rgba(0,0,0,0.2)",
    height: "560px",
    overflow: "auto",
  },
  popupDiv: {
    position: "fixed",
    top: 0,
    left: 0,
    backgroundColor: "rgba(255, 255, 255, 0.50)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    zIndex: 100,
    overflow: "auto",
  },
  inviteText: {
    fontSize: "1.5rem",
    fontWeight: 700,
    marginBottom: "1.4rem",
  },
  morePeopleText: {
    display: "flex",
    marginTop: "1rem",
    marginBottom: "1rem",
    cursor: "pointer",
  },
  crossIcon: {
    marginLeft: "auto",
    marginBottom: "5px",
  },
  divider: {
    marginBottom: "40px !important",
  },
}));
