// export const BASE_URL =
//   "http://k8s-default-fundedhe-4267302373-676271173.ap-southeast-1.elb.amazonaws.com";

import { KYCStatus, Persona, SpvFile } from "./enum";

// k8s-default-fundedhe-4267302373-676271173.ap-southeast-1.elb.amazonaws.com/trade-directory
// export const BASE_URL = "https://api-uat.fundedhere.com";
export const BASE_URL = "https://api-newdev.fundedhere.com";
// export const BASE_URL = "http://localhost:3001";
export const BASE_URL_LANDING_PAGE = "https://uat.fundedhere.info";

export const TOKEN =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjI2LCJvcmdSb2xlSWQiOjcsImlhdCI6MTcwNTU1OTc4NCwiZXhwIjoxNzA1NjQ2MTg0fQ.hjWLt-BdGlfGQrOG-KJDYtyDEUojYmlCTkctvJXvgNc";

export const merchants = [Persona.MERCHANT, Persona.MERCHANT_SPV];

export const haveDirectors = [...merchants, Persona.INVESTOR];

export const kycStatuses = Object.values(KYCStatus);

export const spvFiles = Object.values(SpvFile);

export const ENV: "local" | "production" = "local";

export const CLIENT_ID_LUMINOR_DID = "6617836d2d4969cd7e3abed9";

export const CLOUDFLARE_TURNSTILE_SITE_KEY = "0x4AAAAAAA7ugUGnFB8fT6sh";

export const FH_JUNIOR_INVESTOR_ACCOUNT_ID_DEV = "316972";

export const FH_JUNIOR_INVESTOR_ACCOUNT_ID_UAT =
  "2721282b-53e5-469b-a4ef-e96d716c80ca";

export const FH_JUNIOR_INVESTOR_ACCOUNT_ID_PROD =
  "38e5e940-05f8-4be2-b7c9-51987258a88d";
