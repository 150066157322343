import { useMutation } from "react-query";
import getPmVerifySkuLoanApplications from "../services/getPmVerifySkuLoanApplications";

function useGetPmVerifySkuLoanApplications() {
  return useMutation(
    ["get-pm-verify-sku-loan-applications"],
    getPmVerifySkuLoanApplications
  );
}

export default useGetPmVerifySkuLoanApplications;
