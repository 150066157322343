import { BASE_URL } from "@funded-here-interface/common/src/constant/constant";
import axios from "axios";
import { getApiResponseErrorMsg } from "@funded-here-interface/common/src/Utils/apiHelper";

interface BmcaComplete {
  token: string;
  orgId: number;
}
const completeBmca = async ({ token, orgId }: BmcaComplete) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/trade-directory/organization/bmca-complete`,
      {
        orgId: orgId,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      }
    );

    return response.data;
  } catch (e) {
    const message = getApiResponseErrorMsg(e);
    throw new Error(message);
  }
};

export default completeBmca;
