import { MantineTheme, createStyles } from "@mantine/core";

export const useStyles = createStyles((theme: MantineTheme) => ({
  valueContainer: {
    padding: "44px",
    display: "flex",
    flexDirection: "column",
    // flexWrap: "wrap",
    // height: "750px",
  },
  DocContain: {
    display: "flex",
    marginTop: "10px",
    marginLeft: "5px",
  },
  DocText: {
    fontSize: "18px",
    width: "400px",
  },
  downloadIcon: {
    cursor: "pointer",
    display: "flex",
  },
  headerText: {
    fontSize: "16px",
    fontWeight: 700,
    marginBottom: "20px",
  },
}));
