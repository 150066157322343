import { useMutation } from "react-query";
import verifySkuLoanApplications from "../services/verifySkuLoanApplications";

function useVerifySkuLoanApplications() {
  return useMutation(
    ["verify-sku-loan-applications"],
    verifySkuLoanApplications
  );
}

export default useVerifySkuLoanApplications;
