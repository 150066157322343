import React, { useState } from "react";
import "./overallView.css";
import OverallPieone from "./OverallPieone";
import OverallPieTwo from "./OverallPieTwo";

const ActiveSNInvested = () => {
  const [isCountry, setIsCountry] = useState(true);

  return (
    <>
      <div className="struCOlInDiv">
        <div className="topSkusDiv">
          <p className="topSkuHead">
            Active Notes Invested Amount by {isCountry ? "Country" : "Sector"}
          </p>
          <div className="topSkuTenBtnsDiv">
            <button
              className={`topSkuTenBtns ${isCountry ? "active" : ""}`}
              onClick={() => setIsCountry(true)}
            >
              Country
            </button>
            <button
              className={`topSkuTenBtns ${!isCountry ? "active" : ""}`}
              onClick={() => setIsCountry(false)}
            >
              Sector
            </button>
          </div>
        </div>
        {isCountry ? <OverallPieone /> : <OverallPieTwo />}
      </div>
    </>
  );
};

export default ActiveSNInvested;
