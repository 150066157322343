import { MantineTheme, createStyles } from "@mantine/core";

export const useStyle = createStyles((theme: MantineTheme) => ({
  headerText: {
    fontSize: 24,
    fontWeight: 700,
    lineHeight: "normal",
    letterSpacing: "0em",
    textAlign: "left",
    fontStyle: "normal",
  },
  heading: {
    color: "#000",
    fontSize: 17,
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
    textAlign: "left",
    marginLeft: 40,
  },
  uline: {
    width: 50,
    height: 3,
    background: "#3786D6",
    marginLeft: 60,
  },
}));
