import React, { useEffect, useState } from "react";
import "./Merchant.css";
import MerDataSec from "./MerDataSec";
import MerchantChartTab from "./MerchantChartTab";
import MerchantDataTable from "./MerchantDataTable";
import { MerchantProvider } from "./MerchantContext";
import HomeServices from "../../../../services/HomeServices";
import { useSelector } from "react-redux";

const Merchant = () => {
  const [merchantOptions, setMerchantOptions] = useState([]);
  const [selectedMerchant, setSelectedMerchant] = useState({
    value: "",
    label: "",
  });
  const token = useSelector((state) => state.auth.token);

  const fetchInvestorData = async () => {
    const res = await HomeServices.getAllMerchantwithID(token);
    if (res && res.status) {
      const options = res.data.map((merchant) => ({
        label: merchant.merchantName,
        value: merchant.orgRoleId,
      }));
      setMerchantOptions(options);
      if (options.length > 0) {
        setSelectedMerchant(options[0]);
      }
    } else {
      // Handle error
    }
  };

  useEffect(() => {
    fetchInvestorData();
  }, []);

  const handleMerchantChange = (selectedOption) => {
    setSelectedMerchant(selectedOption);
  };

  return (
    <MerchantProvider>
      {/* {!required && <HeaderPmDashboard />}{" "} */}
      <div className="mrchnt_summry">
        <MerDataSec selectedMerchant={selectedMerchant} />
        {selectedMerchant.value && (
          <MerchantChartTab
            merchantOptions={merchantOptions}
            handleMerchantChange={handleMerchantChange}
            selectedMerchant={selectedMerchant}
          />
        )}
      </div>
      {selectedMerchant.value && (
        <MerchantDataTable selectedMerchant={selectedMerchant} />
      )}
    </MerchantProvider>
  );
};

export default Merchant;
