import { useMutation } from "react-query";
import downloadVATransactionsCSV from "../services/downloadVATransactionsCSV";

function useDownloadVATransactionsCSV() {
  return useMutation(
    ["download-va-transactions-csv"],
    downloadVATransactionsCSV
  );
}

export default useDownloadVATransactionsCSV;
