import { useQuery } from "react-query";
import getCurrentSn from "../services/getCurrentSn";

function useGetSN(token: string, id: string) {
  return useQuery(["get-note", token, id], () => getCurrentSn({ token, id }), {
    retry: false,
    enabled: !!id,
  });
}

export default useGetSN;
