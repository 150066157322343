import axios from "axios";
import { BASE_URL } from "@funded-here-interface/common/src/constant/constant";
import { getApiResponseErrorMsg } from "@funded-here-interface/common/src/Utils/apiHelper";

export interface CampaignResponse {
  Campaign: string;
  Createddate: string;
  Country: string;
  upc: string[];
}

const getCampaign = async ({
  token,
}: {
  token: string;
}): Promise<CampaignResponse[]> => {
  try {
    const response = await axios.get(`${BASE_URL}/sku-finance/campaign`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    });

    return response.data;
  } catch (error) {
    const message = getApiResponseErrorMsg(error);

    throw new Error(message);
  }
};

export default getCampaign;
