import axios from "axios";
import { BASE_URL } from "@funded-here-interface/common/src/constant/constant";
import { getApiResponseErrorMsg } from "@funded-here-interface/common/src/Utils/apiHelper";

export interface BulkDisbursementSummaryResponse {
  allowBulkFundsDisbursement: boolean;
  skusFundsDisbursementNotAllowed: string[];
  totalAmountToDisburseInSgd: string;
  totalAmountToDisburseInOtherCurrency: string;
  otherCurrency: string;
}

interface BulkDisbursementSummaryRequest {
  token: string;
  noteId: number;
}

const getBulkDisbursementSummary = async ({
  token,
  noteId,
}: BulkDisbursementSummaryRequest): Promise<BulkDisbursementSummaryResponse> => {
  try {
    const response = await axios.post(
      `${BASE_URL}/sku-finance/loan-disbursement/get-bulk-distributor-disbursement`,
      { noteId },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      }
    );

    return response.data;
  } catch (error) {
    const message = getApiResponseErrorMsg(error);

    throw new Error(message);
  }
};

export default getBulkDisbursementSummary;
