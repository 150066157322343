import {
  Button,
  Card,
  Group,
  Select,
  SimpleGrid,
  TextInput,
  Text,
  FileButton,
  Image,
  Textarea,
} from "@mantine/core";
import { FC, useEffect, useRef, useState } from "react";
import {
  Data,
  OrgDocsUploadAdditionals,
  UserData,
} from "../../../types/org.type";
import { useStyles } from "./LuminorDetails.styles";
import { CLIENT_ID_LUMINOR_DID } from "@funded-here-interface/common/src/constant/constant";
import usePassportApproval from "../../../hooks/usePassportApproval";
import { toast } from "react-toastify";
import { RootState } from "../../../store";
import { useSelector } from "react-redux";
import useReuploadDocument from "../../../hooks/useReuploadDocument";
import {
  ReuploadDocumentReponse,
  ReuploadDocumentRequest,
} from "../../../services/reuploadDocument";
import downloadbutton from "../../../assets/download_24px.png";
import { downloadFile } from "@funded-here-interface/common/src/Utils/file";
import { FileType } from "@funded-here-interface/common/src/constant/enum";
import useGetDocument from "../../../hooks/useGetDocument";

interface LuminorDetailsProps {
  data: (OrgDocsUploadAdditionals | undefined)[];
  user: UserData | undefined;
}

const LuminorDetails: FC<LuminorDetailsProps> = ({ user, data }) => {
  const { classes } = useStyles();
  const useApprovalMutation = usePassportApproval();
  const token = useSelector((state: RootState) => state.auth.token);
  const [selectedValue, setSelectedValue] = useState<
    "approved" | "rejected" | "pending"
  >("pending");
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const useReuploadDocumentMutation = useReuploadDocument();
  const [documentPaths, setDocumentPaths] = useState({
    notarisedReupload: data || [],
  });
  const useGetDocumentMutation = useGetDocument();

  useEffect(() => {
    if (
      user?.luminorVerifcationStatus &&
      user?.luminorVerifcationStatus !== "pending"
    ) {
      setSelectedValue(user?.luminorVerifcationStatus);
    }
  }, []);

  const getVerification = (data: UserData) => {
    if (data?.luminorVerifcationStatus) {
      let result = "";
      switch (data?.luminorVerifcationStatus) {
        case "approved":
          result = "Successful";
          break;

        case "rejected":
          result = "Failed";
          break;
      }

      return result;
    }
    if (data?.didMetaData) {
      if (data?.didMetaData.errorMsg) {
        let errorLength = data?.didMetaData.errorMsg.split(",").length;
        if (
          errorLength > 1 ||
          !data?.didMetaData.errorMsg.includes("overwritten")
        ) {
          return "Failed";
        }
      }

      if (data?.didMetaData.Type === "e-passport") {
        return "Successful";
      }
    }

    return "-";
  };

  const handleSelectChange = (value: "approved" | "rejected") => {
    setSelectedValue(value);
  };

  const onSubmitClick = async (user: UserData) => {
    setIsSubmitLoading(true);
    const payload = {
      token,
      clientId: CLIENT_ID_LUMINOR_DID,
      userAddress: user?.didMetaData.LedgerAddress as string,
      dvct: user?.didPassportDVCT as string,

      status: selectedValue,
      userId: user?.id,
    };
    useApprovalMutation.mutate(payload, {
      onSuccess: () => {
        setIsSubmitLoading(false);
        user.luminorVerifcationStatus = selectedValue;
        if (selectedValue === "approved") {
          user.didRegistrationId = payload.userAddress;
        }
      },
      onError: (e) => {
        toast.error((e as Error).message);
        setIsSubmitLoading(false);
      },
    });
  };

  const resetRef = useRef<() => void>(null);

  const handleFileChange = (file: File | null) => {
    uploadDocument(file);

    // Reset the file input value to allow the same file to be selected again
    if (resetRef.current) {
      resetRef.current();
    }
  };

  const downloadDocument = (path: string, fileName: string) => {
    useGetDocumentMutation.mutate(
      { token, path },
      {
        onSuccess: (data: Blob) => {
          downloadFile(data, fileName, FileType.PDF);
        },
        onError: (e) => {
          toast.error((e as Error).message);
        },
      }
    );
  };

  const uploadDocument = (file: File | null) => {
    if (!file) {
      toast.error("Uploaded file not found.");

      return;
    }

    useReuploadDocumentMutation.mutate(
      {
        token,
        file,
        name: file?.name,
        userId: user?.id!,
      } as ReuploadDocumentRequest,
      {
        onSuccess: (data: ReuploadDocumentReponse) => {
          setDocumentPaths((prevPaths) => ({
            ...prevPaths,
            ["notarisedReupload"]: [...prevPaths["notarisedReupload"], data],
          }));

          (user as UserData).luminorVerifcationStatus = undefined;
          setSelectedValue("pending");
        },
        onError: (e) => {
          toast.error((e as Error).message);
        },
      }
    );
  };

  const renderVerification = () => {
    if (
      (user?.didRegistrationId && user?.didRegistrationId !== "") ||
      (user?.luminorVerifcationStatus !== undefined &&
        user?.luminorVerifcationStatus !== null &&
        user?.luminorVerifcationStatus !== "pending")
    ) {
      return (
        <TextInput
          className={classes.textInput}
          label="Verification"
          value={getVerification(user)}
          disabled
        />
      );
    }
  };

  return (
    <Card
      shadow="sm"
      padding="lg"
      radius="md"
      withBorder
      className={classes.personCard}
    >
      <Card.Section withBorder inheritPadding py="xs" mb="xs">
        <Group position="apart">
          <Text weight={500}>{user?.firstName + " " + user?.lastName}</Text>
          <Text weight={200}>{user?.jobTitle}</Text>
        </Group>
      </Card.Section>

      <SimpleGrid cols={1} spacing="sm" verticalSpacing="sm">
        <TextInput
          className={classes.textInput}
          label="Passport"
          value={user?.didPassportType ? user.didPassportType : "-"}
          disabled
        />

        <TextInput
          className={classes.textInput}
          label="Passport Name"
          value={user?.didMetaData ? user.didMetaData.PassportName : "-"}
          disabled
        />

        <TextInput
          className={classes.textInput}
          label="Passport Number"
          value={user?.didMetaData ? user.didMetaData.PassportNumber : "-"}
          disabled
        />

        {user?.didPassportPhoto && (
          <>
            <div className={classes.photoDisplay}>
              <Text className={classes.inputTitle}>Passport Photo</Text>
              <img className={classes.photo} src={user?.didPassportPhoto} />
            </div>
          </>
        )}

        {user?.didNotarisedPhoto && (
          <>
            <div className={classes.photoDisplay}>
              <Text className={classes.inputTitle}>Notarised Photo</Text>
              <img className={classes.photo} src={user?.didNotarisedPhoto} />
            </div>
          </>
        )}

        {renderVerification()}

        {!user?.luminorVerifcationStatus &&
          user?.didPassportType === "passport" && (
            <>
              <Select
                label="Verification"
                readOnly={user.didMetaData?.Type === "e-passport"}
                placeholder="Pending"
                data={[
                  { value: "rejected", label: "Rejected" },
                  { value: "approved", label: "Approved" },
                ]}
                value={selectedValue}
                onChange={handleSelectChange}
              />

              <Button
                disabled={selectedValue === "pending"}
                onClick={() => {
                  onSubmitClick(user);
                }}
                loading={isSubmitLoading}
              >
                Submit
              </Button>
            </>
          )}

        {documentPaths && documentPaths.notarisedReupload.length > 0 && (
          <div>
            {documentPaths.notarisedReupload.map((doc) => (
              <div className={classes.DocContain}>
                <Text className={classes.additionalDocName}>
                  {doc?.docName}
                </Text>
                <Image
                  onClick={() =>
                    downloadDocument(
                      doc?.path as string,
                      doc?.docName as string
                    )
                  }
                  src={downloadbutton}
                  className={classes.downloadIcon}
                />
              </div>
            ))}
          </div>
        )}

        {user?.luminorVerifcationStatus &&
          user?.luminorVerifcationStatus === "rejected" && (
            <FileButton
              resetRef={resetRef}
              onChange={handleFileChange}
              accept=".pdf"
            >
              {(props) => (
                <Text {...props} className={classes.upload}>
                  Upload
                </Text>
              )}
            </FileButton>
          )}

        {user?.didMetaData && user.didMetaData?.errorMsg && (
          // <TextInput
          //   className={classes.textInput}
          //   label="Reason for Failure"
          //   value={user.didMetaData?.errorMsg}
          //   disabled
          // />
          <Textarea
            className={classes.textInput}
            label="Remarks"
            value={user.didMetaData?.errorMsg}
            disabled
            minRows={2}
            maxRows={4}
          />
        )}
      </SimpleGrid>
    </Card>
  );
};

export default LuminorDetails;
