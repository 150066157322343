import { InputGroupDisable } from "@funded-here-interface/common";
import { FC } from "react";
import { Data, Spv, UserData } from "../../../types/org.type";
import { useStyles } from "./AuthRep.styles";
import { Divider } from "@mantine/core";
import { Persona } from "@funded-here-interface/common/src/constant/enum";

interface IProps {
  data: Data | undefined;
  userData: UserData | undefined;
  role: Persona;
  spvData?: Spv;
}

const AuthRep: FC<IProps> = ({ data, userData, spvData, role }) => {
  const { classes } = useStyles();
  const name = data?.nameOfAuthorisedRepresentiative || "-";
  const title = data?.titleOfAuthorisedRepresentiative || "-";
  const phoneNumber = data?.companyPhoneNumber || "-";
  const repEmail = data?.emailOfAuthorisedRepresentiative;
  const userIsNotAuthRep = repEmail && repEmail !== userData?.email;
  const userName =
    userData?.firstName && userData?.lastName
      ? `${userData.firstName} ${userData.lastName}`
      : "-";
  const userEmail = userData?.email || "-";
  const userTitle = userData?.jobTitle || "-";

  return (
    <>
      <div className={classes.valueContainer}>
        {role === Persona.DISTRIBUTOR && (
          <>
            <InputGroupDisable
              labelText="Admin's Email"
              id="distEmail"
              value={userEmail}
            />
            <InputGroupDisable
              labelText="Admin's Name"
              id="distName"
              value={userName}
            />
          </>
        )}
        {role !== Persona.DISTRIBUTOR && (
          <>
            {repEmail && (
              <InputGroupDisable
                labelText="Auth Rep's Email"
                id="repemail"
                value={repEmail}
              />
            )}
            <InputGroupDisable
              labelText="Auth Rep's Name"
              id="repName"
              value={name}
            />
            <InputGroupDisable
              labelText="Auth Rep's Title"
              id="repTitle"
              value={title}
            />
            <InputGroupDisable
              labelText="Auth Rep's Phone Number"
              id="repPhoneNumber"
              value={phoneNumber}
            />
            {userIsNotAuthRep && (
              <div>
                <InputGroupDisable
                  labelText="Admin's Email"
                  id="email"
                  value={userEmail}
                />
                <InputGroupDisable
                  labelText="Admin's Name"
                  id="name"
                  value={userName}
                />
                <InputGroupDisable
                  labelText="Admin's Title"
                  id="title"
                  value={userTitle}
                />
              </div>
            )}
          </>
        )}
      </div>
      {spvData && (
        <>
          <Divider my="md" />
          <h2 style={{ marginLeft: 44, marginTop: 44, fontWeight: 700 }}>
            SPV
          </h2>
          <div className={classes.valueContainer}>
            <InputGroupDisable
              labelText="Auth Rep's Email"
              id="spvRepEmail"
              value={spvData?.emailOfAuthorisedRepresentiative}
            />
            <InputGroupDisable
              labelText="Auth Rep's Name"
              id="spvRepName"
              value={spvData?.nameOfAuthorisedRepresentiative}
            />
            <InputGroupDisable
              labelText="Auth Rep's Title"
              id="spvRepTitle"
              value={spvData?.titleOfAuthorisedRepresentiative}
            />
            <InputGroupDisable
              labelText="Auth Rep's Phone Number"
              id="spvRepPhoneNumber"
              value={spvData?.companyPhoneNumber}
            />
            {spvData?.emailOfAuthorisedRepresentiative !== userData?.email && (
              <div>
                <InputGroupDisable
                  labelText="Admin's Email"
                  id="email"
                  value={userEmail}
                />
                <InputGroupDisable
                  labelText="Admin's Name"
                  id="name"
                  value={userName}
                />
                <InputGroupDisable
                  labelText="Admin's Title"
                  id="title"
                  value={userTitle}
                />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default AuthRep;
