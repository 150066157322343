import { FC, useEffect, useMemo, useState } from "react";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useStyle } from "./PmVerifySkuLoans.styles";

import useGetLoanForVerification from "../../hooks/useGetLoanForVerification";
import {
  MRT_ColumnDef,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import { Divider, Title } from "@mantine/core";
import { GetLoanForVerificationResponse } from "../../services/getLoanForVerification";
import { useNavigate } from "react-router-dom";

interface IOrgList {
  orgName: string;
}

const PmVerifySkuLoans: FC = () => {
  const { classes } = useStyle();
  const token = useSelector((state: RootState) => state.auth.token);
  const useGetLoanForVerificationMutation = useGetLoanForVerification();
  const [data, setData] = useState<GetLoanForVerificationResponse>({});
  const [orgList, setOrgList] = useState<IOrgList[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const getData = () => {
    setIsLoading(true);
    useGetLoanForVerificationMutation.mutate(
      { token },
      {
        onSuccess: (data) => {
          setData(data);
          setIsLoading(false);
        },
        onError: (error) => {
          toast.error((error as Error).message);
          setIsLoading(false);
        },
      }
    );
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (data) {
      const listArray: IOrgList[] = Object.keys(data).map((key) => ({
        orgName: key,
      }));

      setOrgList(listArray);
    }
  }, [data]);

  const columns = useMemo<MRT_ColumnDef<IOrgList>[]>(
    () => [
      {
        accessorKey: "orgName",
        header: "Org Name",
      },
    ],
    []
  );

  const table = useMantineReactTable({
    enableColumnActions: false,
    enableColumnFilters: false,
    paginationDisplayMode: "pages",
    state: { isLoading },
    columns,
    data: orgList,
    initialState: { density: "xs" },
    mantineTableProps: {
      withBorder: true,
    },
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        navigate("/verify-sku-loan-details", {
          state: { orgName: row.original?.orgName },
        });
      },
      sx: {
        cursor: "pointer",
      },
    }),
  });

  return (
    <div>
      <Title>CSVs</Title>
      <Divider my="md" className={classes.divider} />
      <div style={{ maxWidth: "500px" }}>
        <MantineReactTable table={table} />
      </div>
    </div>
  );
};

export default PmVerifySkuLoans;
