import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import { FC, useEffect, useMemo } from "react";
import downloadbutton from "../../../../assets/download_24px.svg";
import { Image, Text } from "@mantine/core";
import { useStyles } from "./VerifySkuLoanDetailsMantineTable.styles";
import { DataRow } from "../../../../services/getLoanForVerification";
import { convertDate } from "@funded-here-interface/common/src/Utils/date";
import {
  formatNumbersWithThousandSeperators,
  formatPercentageFromRatio,
} from "@funded-here-interface/common/src/Utils/formatter";

interface IProps {
  poId: string;
  data: DataRow[];
  onDownloadPo: (poId: string) => void;
  onUpdateSelectedIds: (tableId: string, selectedIds: string[]) => void;
  rowSelection: MRT_RowSelectionState;
  setRowSelection: React.Dispatch<React.SetStateAction<MRT_RowSelectionState>>;
}

const VerifySkuLoanDetailsMantineTable: FC<IProps> = (props) => {
  const {
    poId,
    data,
    onDownloadPo,
    onUpdateSelectedIds,
    rowSelection,
    setRowSelection,
  } = props;
  const { classes } = useStyles();

  useEffect(() => {
    const selectedIds = Object.keys(rowSelection)
      .filter((key) => rowSelection[key])
      .map(String);

    onUpdateSelectedIds(poId, selectedIds);
  }, [rowSelection]);

  const columns = useMemo<MRT_ColumnDef<DataRow>[]>(
    () => [
      {
        accessorKey: "skuId",
        header: "SKU ID",
      },
      {
        accessorKey: "upc",
        header: "UPC",
      },
      {
        accessorKey: "poNumber",
        header: "PO Number",
      },
      {
        accessorKey: "poDate",
        header: "PO Date",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? convertDate(cell.getValue<string>())
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "deliveryDate",
        header: "Deliver Date",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? convertDate(cell.getValue<string>())
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "purchasePricePerUnit",
        header: "Purchase Price",
        Cell: ({ row }) => {
          const currency = row?.original?.loanApplicationCurrency || "";
          const amount = formatNumbersWithThousandSeperators(
            parseFloat(row?.original?.purchasePricePerUnit || "0")
          );
          return <p>{`${currency} ${amount}`}</p>;
        },
      },
      {
        accessorKey: "sellingPricePerUnit",
        header: "Selling Price",
        Cell: ({ row }) => {
          const currency = row?.original?.loanApplicationCurrency || "";
          const amount = formatNumbersWithThousandSeperators(
            parseFloat(row?.original?.sellingPricePerUnit || "0")
          );
          return <p>{`${currency} ${amount}`}</p>;
        },
      },
      {
        accessorKey: "quantityPurchased",
        header: "Quantity Ordered",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<number>()
              ? formatNumbersWithThousandSeperators(cell.getValue<number>())
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "discount",
        header: "Discount",
        Cell: ({ row }) => {
          const currency = row?.original?.loanApplicationCurrency || "";
          const amount = formatNumbersWithThousandSeperators(
            parseFloat(row?.original?.discount || "0")
          );
          return <p>{`${currency} ${amount}`}</p>;
        },
      },
      {
        accessorKey: "otherCosts",
        header: "Other Cost",
        Cell: ({ row }) => {
          const currency = row?.original?.loanApplicationCurrency || "";
          const amount = formatNumbersWithThousandSeperators(
            parseFloat(row?.original?.otherCosts || "0")
          );
          return <p>{`${currency} ${amount}`}</p>;
        },
      },
      {
        accessorKey: "tax",
        header: "Tax",
        Cell: ({ row }) => {
          const currency = row?.original?.loanApplicationCurrency || "";
          const amount = formatNumbersWithThousandSeperators(
            parseFloat(row?.original?.tax || "0")
          );
          return <p>{`${currency} ${amount}`}</p>;
        },
      },
      {
        accessorKey: "nettInvoiceAmount",
        header: "Total Invoice",
        Cell: ({ row }) => {
          const currency = row?.original?.loanApplicationCurrency || "";
          const amount = formatNumbersWithThousandSeperators(
            parseFloat(row?.original?.nettInvoiceAmount || "0")
          );
          return <p>{`${currency} ${amount}`}</p>;
        },
      },
      {
        accessorKey: "nettInvoiceAmountOtherCurrency",
        header: "Total Invoice (Other Currency)",
        Cell: ({ row }) => {
          const currency = row?.original?.otherCurrency || "";
          const amount = formatNumbersWithThousandSeperators(
            parseFloat(row?.original?.nettInvoiceAmountOtherCurrency || "0")
          );
          return <p>{`${currency} ${amount}`}</p>;
        },
      },
      {
        accessorKey: "fxRateOtherCurrency",
        header: "FX Rate",
      },
      {
        accessorKey: "expectedPaymentDate",
        header: "Expected Payment Date",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? convertDate(cell.getValue<string>())
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "paymentTerm",
        header: "Terms of Payment",
      },
      {
        accessorKey: "partialPaymentFirstPaymentPercentage",
        header: "First Payment % (If Partial)",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? formatPercentageFromRatio(cell.getValue<string>()) + "%"
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "skuReturnPercentage",
        header: "Estimated Sku Return",
        Cell: ({ cell }) => (
          <p>{cell.getValue<string>() ? cell.getValue<string>() + "%" : "-"}</p>
        ),
      },
    ],
    []
  );

  const table = useMantineReactTable({
    enableColumnActions: false,
    enableColumnFilters: false,
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    getRowId: (row) => row?.skuId,
    paginationDisplayMode: "pages",
    initialState: { density: "xs" },
    state: { rowSelection },
    columns,
    data,
    mantineTableProps: {
      withBorder: true,
    },
  });

  return (
    <>
      <div className={classes.DocContain}>
        <Text className={classes.DocText}>{`PO Id: ${poId}` ?? "-"}</Text>
        <Image
          onClick={() => onDownloadPo(poId)}
          src={downloadbutton}
          className={classes.downloadIcon}
        />
      </div>

      <MantineReactTable table={table} />
    </>
  );
};

export default VerifySkuLoanDetailsMantineTable;
