import { InputGroupDisable } from "@funded-here-interface/common";
import { FC } from "react";
import { UserData } from "../../../types/org.type";
import { useStyles } from "./BankDetails.styles";
import { capitalizeFirstLetter } from "@funded-here-interface/common/src/Utils/string";

interface IProps {
  userData: UserData | undefined;
}

const BankDetails: FC<IProps> = ({ userData }) => {
  const { classes } = useStyles();

  const accountType =
    capitalizeFirstLetter(userData?.orgRole?.[0]?.bank?.accountType) || "-";
  const name = userData?.orgRole?.[0]?.bank?.bankName || "-";
  const swiftCode = userData?.orgRole?.[0]?.bank?.swiftCode || "-";
  const number = userData?.orgRole?.[0]?.bank?.accountNo || "-";
  const holderName = userData?.orgRole?.[0]?.bank?.acountHolderName;

  return (
    <div className={classes.valueContainer}>
      {holderName && (
        <InputGroupDisable
          labelText="Account Holder's Name"
          id="acc_holder_name"
          value={holderName}
        />
      )}
      <InputGroupDisable
        labelText="Bank Account Type"
        id="acc_type"
        value={accountType}
      />
      <InputGroupDisable labelText="Bank Name " id="bank_name " value={name} />
      <InputGroupDisable
        labelText="Bank SWIFT Code"
        id="swift_code"
        value={swiftCode}
      />
      <InputGroupDisable
        labelText="Account Number"
        id="acc_number"
        value={number}
      />
    </div>
  );
};

export default BankDetails;
