import { createSlice } from "@reduxjs/toolkit";

const repSlice = createSlice({
  name: "rep",
  initialState: {
    repName: null,
    repTitle: null,
  },
  reducers: {
    setRepName: (state, { payload: { repName } }) => {
      state.repName = repName;
    },
    setRepTitle: (state, { payload: { repTitle } }) => {
      state.repTitle = repTitle;
    },
  },
});

export const { setRepName, setRepTitle } = repSlice.actions;

export default repSlice.reducer;
