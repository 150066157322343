import { FC, useMemo } from "react";
import {
  MRT_ColumnDef,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import { toast } from "react-toastify";
import { formatNumbersWithThousandSeperators } from "@funded-here-interface/common/src/Utils/formatter";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import useGetNoteDefault from "../../../../hooks/useGetNoteDefault";
import { GetNoteDefaultResponse } from "../../../../services/getNoteDefault";
import { color } from "@funded-here-interface/common";

interface IProps {
  noteId: string;
}

const Default: FC<IProps> = ({ noteId }) => {
  const token = useSelector((state: RootState) => state.auth.token);
  const { data, error, isLoading } = useGetNoteDefault(token, noteId);

  if (error) {
    toast.error((error as Error).message);
  }

  const highlightedColumns = [
    "loan2AndEquityWithoutRepaidAmount",
    "coverageDL0AddAminEqualsQcSFASF",
    "coverageDL1AddInEqualsForceSell",
  ];

  const highlightedTabStyle = {
    backgroundColor: color.FHGREEN,
    color: "white",
  };
  const highlightedColumnStyle = {
    backgroundColor: "orange",
    color: "white",
  };

  const columns = useMemo<MRT_ColumnDef<GetNoteDefaultResponse>[]>(
    () => [
      {
        accessorKey: "merchant",
        header: "Merchant",
      },
      {
        accessorKey: "skuId",
        header: "SKU",
      },
      {
        accessorKey: "salesProcced",
        header: "Sales Proceed",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? formatNumbersWithThousandSeperators(
                  parseFloat(cell.getValue<string>())
                )
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "totalFundsInflow",
        header: "Total Funds Inflow",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? formatNumbersWithThousandSeperators(
                  parseFloat(cell.getValue<string>())
                )
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "topUp",
        header: "Top Up",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? formatNumbersWithThousandSeperators(
                  parseFloat(cell.getValue<string>())
                )
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "l2+E",
        header: "L2+E",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? formatNumbersWithThousandSeperators(
                  parseFloat(cell.getValue<string>())
                )
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "repaymentBeforeDefault",
        header: "Repayment Before Default",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? formatNumbersWithThousandSeperators(
                  parseFloat(cell.getValue<string>())
                )
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "repaidAmount",
        header: "Repayment",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? formatNumbersWithThousandSeperators(
                  parseFloat(cell.getValue<string>())
                )
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "loan2AndEquityWithoutRepaidAmount",
        header: "L2+E-Repayment",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? formatNumbersWithThousandSeperators(
                  parseFloat(cell.getValue<string>())
                )
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "spar.paidUp",
        header: "Spar",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? formatNumbersWithThousandSeperators(
                  parseFloat(cell.getValue<string>())
                )
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "indicativeDl0",
        header: "DL0 Expected",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? formatNumbersWithThousandSeperators(
                  parseFloat(cell.getValue<string>())
                )
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "dl0",
        header: "DL0 Paid",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? formatNumbersWithThousandSeperators(
                  parseFloat(cell.getValue<string>())
                )
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "fhAddAdminFee.totalDue",
        header: "Add Admin Expected",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? formatNumbersWithThousandSeperators(
                  parseFloat(cell.getValue<string>())
                )
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "fhAddAdminFee.paidUp",
        header: "Add Admin Paid",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? formatNumbersWithThousandSeperators(
                  parseFloat(cell.getValue<string>())
                )
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "moeUtilized",
        header: "Own QC Utilized",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? formatNumbersWithThousandSeperators(
                  parseFloat(cell.getValue<string>())
                )
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "sfUtilized",
        header: "Own SF Utilized",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? formatNumbersWithThousandSeperators(
                  parseFloat(cell.getValue<string>())
                )
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "asfUtilized",
        header: "Own ASF Utilized",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? formatNumbersWithThousandSeperators(
                  parseFloat(cell.getValue<string>())
                )
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "moeOtherUtilized",
        header: "Oth QC Utilized",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? formatNumbersWithThousandSeperators(
                  parseFloat(cell.getValue<string>())
                )
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "moeOtherUtilizedSku",
        header: "Oth QC Utilized Sku",
        Cell: ({ cell }) => (
          <p>{cell.getValue<string>() ? cell.getValue<string>() : "-"}</p>
        ),
      },
      {
        accessorKey: "sfOtherUtilized",
        header: "Oth SF Utilized",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? formatNumbersWithThousandSeperators(
                  parseFloat(cell.getValue<string>())
                )
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "sfOtherUtilizedSku",
        header: "Oth SF Utilized Sku",
        Cell: ({ cell }) => (
          <p>{cell.getValue<string>() ? cell.getValue<string>() : "-"}</p>
        ),
      },
      {
        accessorKey: "asfOtherUtilized",
        header: "Oth ASF Utilized",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? formatNumbersWithThousandSeperators(
                  parseFloat(cell.getValue<string>())
                )
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "asfOtherUtilizedSku",
        header: "Oth ASF Utilized Sku",
        Cell: ({ cell }) => (
          <p>{cell.getValue<string>() ? cell.getValue<string>() : "-"}</p>
        ),
      },
      {
        accessorKey: "indicativeDl1",
        header: "DL1 Expected",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? formatNumbersWithThousandSeperators(
                  parseFloat(cell.getValue<string>())
                )
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "dl1",
        header: "DL1 Paid",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? formatNumbersWithThousandSeperators(
                  parseFloat(cell.getValue<string>())
                )
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "addInt.totalDue",
        header: "Add I Expected",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? formatNumbersWithThousandSeperators(
                  parseFloat(cell.getValue<string>())
                )
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "addInt.paidUp",
        header: "Add I Paid",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? formatNumbersWithThousandSeperators(
                  parseFloat(cell.getValue<string>())
                )
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "indicativeFhAdditionalInterestFees",
        header: "FH Add I Expected",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? formatNumbersWithThousandSeperators(
                  parseFloat(cell.getValue<string>())
                )
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "fhAdditionalInterestFees",
        header: "FH Add I Paid",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? formatNumbersWithThousandSeperators(
                  parseFloat(cell.getValue<string>())
                )
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "juniorInvestorAddInterest.totalDue",
        header: "Add Junior I Expected",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? formatNumbersWithThousandSeperators(
                  parseFloat(cell.getValue<string>())
                )
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "juniorInvestorAddInterest.paidUp",
        header: "Add Junior I Paid",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? formatNumbersWithThousandSeperators(
                  parseFloat(cell.getValue<string>())
                )
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "juniorInvestorInterest.totalDefaultDue",
        header: "Outstanding Junior I Expected",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? formatNumbersWithThousandSeperators(
                  parseFloat(cell.getValue<string>())
                )
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "juniorInvestorInterest.paidUp",
        header: "Outstanding Junior I Paid",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? formatNumbersWithThousandSeperators(
                  parseFloat(cell.getValue<string>())
                )
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "juniorInvestorPrincipal.totalDefaultDue",
        header: "Outstanding Junior P Expected",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? formatNumbersWithThousandSeperators(
                  parseFloat(cell.getValue<string>())
                )
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "juniorInvestorPrincipal.paidUp",
        header: "Outstanding Junior P Paid",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? formatNumbersWithThousandSeperators(
                  parseFloat(cell.getValue<string>())
                )
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "indicativeDl2",
        header: "DL2 Expected",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? formatNumbersWithThousandSeperators(
                  parseFloat(cell.getValue<string>())
                )
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "dl2",
        header: "DL2 Paid",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? formatNumbersWithThousandSeperators(
                  parseFloat(cell.getValue<string>())
                )
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "seniorInvestorAddInterest.totalDue",
        header: "Add Senior I Expected",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? formatNumbersWithThousandSeperators(
                  parseFloat(cell.getValue<string>())
                )
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "seniorInvestorAddInterest.paidUp",
        header: "Add Senior I Paid",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? formatNumbersWithThousandSeperators(
                  parseFloat(cell.getValue<string>())
                )
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "seniorInvestorInterest.totalDefaultDue",
        header: "Outstanding Senior I Expected",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? formatNumbersWithThousandSeperators(
                  parseFloat(cell.getValue<string>())
                )
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "seniorInvestorInterest.paidUp",
        header: "Outstanding Senior I Paid",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? formatNumbersWithThousandSeperators(
                  parseFloat(cell.getValue<string>())
                )
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "seniorInvestorPrincipal.totalDefaultDue",
        header: "Outstanding Senior P Expected",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? formatNumbersWithThousandSeperators(
                  parseFloat(cell.getValue<string>())
                )
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "seniorInvestorPrincipal.paidUp",
        header: "Outstanding Senior P Paid",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? formatNumbersWithThousandSeperators(
                  parseFloat(cell.getValue<string>())
                )
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "forceSell.totalDue",
        header: "Force Sell Expected",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? formatNumbersWithThousandSeperators(
                  parseFloat(cell.getValue<string>())
                )
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "forceSell.actual",
        header: "Force Sell Paid",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? formatNumbersWithThousandSeperators(
                  parseFloat(cell.getValue<string>())
                )
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "coverageDL0AddAminEqualsQcSFASF",
        header: "DL0+Add Adm = QC&SF&ASF+DL1",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? formatNumbersWithThousandSeperators(
                  parseFloat(cell.getValue<string>())
                )
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "coverageDL1AddInEqualsForceSell",
        header: "DL1+ Add I= Force Sell",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? formatNumbersWithThousandSeperators(
                  parseFloat(cell.getValue<string>())
                )
              : "-"}
          </p>
        ),
      },
    ],
    []
  );

  const table = useMantineReactTable({
    enableColumnActions: false,
    enableColumnFilters: false,
    paginationDisplayMode: "pages",
    state: { isLoading },
    columns,
    data: data ?? [],
    enablePinning: true,
    initialState: {
      density: "xs",
      columnPinning: { left: ["merchant", "skuId"] },
    },
    mantineTableProps: {
      withBorder: true,
    },
    mantineTableHeadCellProps: ({ column }) => ({
      style: highlightedColumns.includes(column.id)
        ? highlightedColumnStyle
        : undefined,
    }),
    mantineTableBodyCellProps: ({ column }) => ({
      style: highlightedColumns.includes(column.id)
        ? highlightedTabStyle
        : undefined,
    }),
  });

  return <MantineReactTable table={table} />;
};

export default Default;
