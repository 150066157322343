import { BASE_URL } from "@funded-here-interface/common/src/constant/constant";
import axios from "axios";
import { getApiResponseErrorMsg } from "@funded-here-interface/common/src/Utils/apiHelper";

type TypegetCurrentSn = {
  token: string;
  id: string;
};

interface PoolingSkuItem {
  id: number;
  createdDate: string;
  status: string;
}

export interface InvestorDataItem {
  orgRoleId: number;
  investorDeposit: string | null;
  name: string;
  type: string;
  intRate: string;
  agreement: string;
}

export interface MerchantDataItem {
  skuId: string;
  borrower: string;
  loanAmount: string;
  intRate: string;
  tenure: number;
  agreement: string;
}

export interface GetCurrentSNResponse {
  id: number;
  createdAt: string;
  issuedAt: string;
  seniorAllocationPercentage: string;
  juniorAllocationPercentage: string;
  privatePlacementAllocationPercentage: string;
  tenor: number;
  snInterestRate: number;
  totalSKU: number;
  totalMerchants: number;
  currency: string;
  status: string;
  RBU: number;
  RBP: number;
  poolingSku: PoolingSkuItem[];
  noteValue: string;
  signedAgrements: number;
  signedLoans: number;
  investorData: InvestorDataItem[];
  merchantData: MerchantDataItem[];
  numberOfInvestors: number;
  seniorInterestRate: number;
  juniorInterestRate: number;
  merchantInterestRate: string;
  snBalance: string;
  totalSnValue: string;
  totalSF: string;
  totalASF: string;
  totalMOE: string;
  totalFloat: string;
  amountAvailableForInvestment: string;
  allowNoteToBeIssued: boolean;
  isNoteFullyInvested: boolean;
  areAllMerchantLoanAgreementSigned: boolean;
  structuredNoteDisplayId: string;
}
const getCurrentSn = async ({
  token,
  id,
}: TypegetCurrentSn): Promise<GetCurrentSNResponse> => {
  try {
    const response = await axios.get(`${BASE_URL}/sku-finance/sn/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    });

    return response.data;
  } catch (e) {
    const message = getApiResponseErrorMsg(e);

    throw new Error(message);
  }
};

export default getCurrentSn;
