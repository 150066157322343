import React, { useEffect, useState } from "react";
import HomeServices from "../../../../services/HomeServices";
import { useSelector } from "react-redux";

function Repaid() {
  const [totalSNdata, setTotalSNdata] = useState({
    totalInvestment: 0,
    repaidAmount: 0,
    balanceAmount: 0,
  });
  const token = useSelector((state) => state.auth.token);

  const fetchActiveRepaymentTracking = async () => {
    try {
      const res = await HomeServices.activeRepaymentTracking(token);
      if (res && res.status && res.data && res.data.length > 0) {
        setTotalSNdata(res.data[0]);
      }
    } catch (error) {
      console.error("Error fetching active repayment tracking data:", error);
    }
  };

  useEffect(() => {
    fetchActiveRepaymentTracking();
  }, []);

  const loop = [
    { text: "Total Investments in SN", points: totalSNdata.totalInvestment },
    { text: "Repaid Amount", points: totalSNdata.repaidAmount },
    { text: "Outstanding Amount", points: totalSNdata.balanceAmount },
  ];

  return (
    <div className="Repaid">
      <div>
        {loop.map((item, index) => {
          return (
            <div className="box" key={index}>
              <h5>{item.text}</h5>
              <h4>{item.points}</h4>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Repaid;
