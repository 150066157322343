import { InputGroupDisable } from "@funded-here-interface/common";
import { FC } from "react";
import { Data } from "../../../types/org.type";
import { useStyles } from "./FundLimit.styles";

interface IProps {
  data: Data | undefined;
}

const FundLimit: FC<IProps> = ({ data }) => {
  const { classes } = useStyles();
  const turnover = data?.previousYearClosingInventory || "-";

  return (
    <div className={classes.valueContainer}>
      <InputGroupDisable
        labelText="Previous year closing inventory amount (as per Balance sheet)"
        id="First Name"
        value={turnover}
      />
    </div>
  );
};

export default FundLimit;
