export enum LoanStatus {
  APPLICATION = "application",
  DISBURSEMENT = "disbursement",
  LOA = "loa",
  Ltv = "ltv_sf",
}

export enum LoanTab {
  APPLICATION = "application",
  LTV_SF = "ltvSf",
  LOA = "loa",
  INVOICES = "invoices",
  DISBURSEMENT = "disbursement",
}

export enum SkuStatus {
  CREATED = "created",
  DISABLED = "disabled",
  LOAN_APPLICATION_PENDING = "loan-application-pending",
  LOAN_APPLICATION_PENDING_PM_VERIFICATION = "loan-application-pending-pm-verification",
  LOAN_APPLICATION_REJECTED_CREDIT_LINE_EXCEEDED = "loan-application-rejected-credit-line-exceeded",
  LOAN_APPLICATION_REJECTED_DELIVERY_DAYS_EXCEEDED = "loan-application-rejected-delivery-days-exceeded",
  LOAN_APPLICATION_REJECTED_RETURN_RATIO_EXCEEDED = "loan-application-rejected-return-ratio-exceeded",
  LOAN_APPLICATION_REJECTED_PO_INVALID = "loan-application-rejected-po-invalid",
  LOAN_APPLICATION_REJECTED = "loan-application-rejected",
  LOAN_APPLICATION_COMPLETED = "loan-application-completed",
  LOAN_APPLICATION_APPROVED = "loan-application-approved",
  LOAN_APPLICATION_PENDING_FUNDING_SUBMISSION = "loan-application-pending-funding-submission",
  LOAN_APPLICATION_PENDING_FUNDING = "loan-application-pending-funding",
  PENDING_STRUCTURED_NOTE_POOL = "pending-structured-note-pool",
  IN_STRUCTURED_NOTE_POOL = "in-structured-note-pool",
  LOAN_DISBURSEMENT_PENDING_GRN_UPLOAD = "loan-disbursement-pending-grn-upload",
  LOAN_DISBURSEMENT_PENDING_INVOICE_UPLOAD = "loan-disbursement-pending-invoice-upload",
  LOAN_DISBURSEMENT_PENDING_PM_VERIFY_INVOICE = "loan-disbursement-pending-pm-verify-invoice",
  LOAN_DISBURSEMENT_INVOICE_UPLOADED = "loan-disbursement-invoice-uploaded",
  LOAN_DISBURSEMENT_COMPLETED = "loan-disbursement-completed",
}

export enum Risk {
  LOW = "low",
  MEDIUM = "medium",
  HIGH = "high",
}
