import { BASE_URL } from "@funded-here-interface/common/src/constant/constant";
import axios from "axios";
import { getApiResponseErrorMsg } from "@funded-here-interface/common/src/Utils/apiHelper";
import { KYCStatus } from "@funded-here-interface/common/src/constant/enum";

interface UpdateSpvKycRequest {
  token: string;
  kycStatus: KYCStatus;
  orgRoleId: number;
}

const updateSpvKyc = async ({
  token,
  kycStatus,
  orgRoleId,
}: UpdateSpvKycRequest) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/trade-directory/organization/spv-kyc-update`,
      { kycStatus, orgRoleId },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      }
    );

    return response.data;
  } catch (e) {
    const message = getApiResponseErrorMsg(e);

    throw new Error(message);
  }
};

export default updateSpvKyc;
