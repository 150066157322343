import { createStyles } from "@mantine/styles";

export const useStyles = createStyles(() => ({
  mainContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "100vh",
    backgroundColor: "#ffffff",
  },
  hero: {
    overflow: "auto",
    flexGrow: 1,
    padding: "58px 48px",
    marginLeft: "240px",
  },
}));
