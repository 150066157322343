import axios from "axios";
import { BASE_URL } from "@funded-here-interface/common/src/constant/constant";
import { getApiResponseErrorMsg } from "@funded-here-interface/common/src/Utils/apiHelper";
import { LoanStatus } from "../constant/enum";

interface LoanDisbursementRequest {
  token: string;
  type: LoanStatus;
  skuIds?: string;
}

const downloadDisbursementCSV = async ({
  token,
  type,
  skuIds
}: LoanDisbursementRequest) => {
  let url = `${BASE_URL}/sku-finance/loan-disbursement/download-disbursement-csv?skuStatusFilterType=${type}&skuIds=${skuIds}`;

  try {
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      responseType: "blob",
      withCredentials: true,
    });

    return response.data

  } catch (error) {
    const message = getApiResponseErrorMsg(error);

    throw new Error(message);
  }
};

export default downloadDisbursementCSV;
