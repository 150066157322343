import React from "react";
import "./overallView.css";
// import Pie from "../../Component/Pie";
import Investment from "./Investment";
// import InvestedSector from "./InvestedSector";
// import LoanAnalysis from "./LoanAnalysis";
import SNSanalysis from "./SNSanalysis";
import ActiveSNInvested from "./ActiveSNInvested";
import BalanceLoanAnalysis from "./BalanceLoanAnalysis";
import "../PMDashboard.css";
import ErrorBoundary from "@funded-here-interface/common/src/dashboard/components/ErrorBoundry";

function Index() {
  return (
    <ErrorBoundary>
      <div className="overall_view">
        <div className="graphPart">
          <Investment />
          <ActiveSNInvested />
        </div>

        <div className="overallBtmPart mt-3">
          <BalanceLoanAnalysis />
          <SNSanalysis />
        </div>
      </div>
    </ErrorBoundary>
  );
}

export default Index;
