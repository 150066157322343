import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import HomeServices from "../../../../services/HomeServices";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

const BarSKuChart = () => {
  const [doi, setDOI] = useState([]);
  const [names, setNames] = useState([]);
  const token = useSelector((state) => state.auth.token);

  const options = {
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: "top",
        },
      },
    },
    colors: ["#f0e199"],
    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
    },
    dataLabels: {
      enabled: true,
      offsetX: -6,
      style: {
        colors: ["#151515"],
        fontSize: "8px",
        fontWeight: "600",
      },
      formatter: function (val) {
        return val;
      },
    },
    legend: {
      position: "top",
      fontSize: "13px",
      fontWeight: "500",
      horizontalAlign: "left",
      markers: {
        shape: "circle",
        width: "8px",
        height: "8px",
        borderRadius: "50%",
      },
    },
    stroke: {
      show: false,
      width: 1,
      colors: ["#fff"],
    },
    xaxis: {
      show: true,
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      categories: names,
    },
    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    tooltip: {
      enabled: true,
      followCursor: true,
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return (
          '<div class="arrow_box">' +
          '<span class="headspan">' +
          "SKU Name: " +
          "</span>" +
          '<span class="paraspan">' +
          w.globals.labels[dataPointIndex] +
          "</span>" +
          "</div>" +
          '<div class="arrow_box">' +
          '<span class="headspan">' +
          "DOI : " +
          "</span>" +
          '<span class="paraspan">' +
          doi[dataPointIndex] +
          "</span>" +
          "</div>"
        );
      },
      y: {
        formatter: function (val) {
          return val + "K";
        },
      },
    },
  };

  const fetchSKUsBySN = async () => {
    try {
      const res = await HomeServices.getSKUsBySN(token);
      if (res && res.status) {
        const validData = res.data.filter((item) => item.doi && item.skuName);
        const sortedData = validData.sort(
          (a, b) => parseFloat(a.doi) - parseFloat(b.doi)
        );
        const skuData = sortedData.map((item) => ({
          doi: parseFloat(item.doi),
          skuName: item.skuName,
        }));
        const extractedNames = skuData.map((item) => item.skuName);
        const extractedDOI = skuData.map((item) => item.doi);

        setNames(extractedNames);
        setDOI(extractedDOI);
      } else {
        toast.error("Unable to fetch data");
      }
    } catch (error) {
      toast.error("Error fetching SKUs data");
    }
  };

  useEffect(() => {
    fetchSKUsBySN();
  }, []);

  useEffect(() => {
    const chartContainer = document.querySelector(".apexcharts-canvas");
    if (chartContainer) {
      chartContainer.style.height = "400px";
      const bars = chartContainer.querySelectorAll(".apexcharts-bar");
      bars.forEach((bar) => {
        bar.style.height = "30px";
        bar.style.marginBottom = "10px";
      });
    }
  }, [names]);

  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={[{ data: doi }]}
        type="bar"
        height={400}
      />
    </div>
  );
};

export default BarSKuChart;
