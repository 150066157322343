import { useEffect, useState } from "react";
import {
  InputGroup,
  LargeBtn,
  InputSelect,
  NumberInputGroup,
  Button,
  color,
} from "@funded-here-interface/common";
import { Modal, Title } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import useGetVirtualAccounts from "../../../hooks/useGetVirtualAccounts";
import { toast } from "react-toastify";
import useInternalTransfer from "../../../hooks/useInternalTransfer";
import { useQueryClient } from "react-query";
import { VirtualAccountResponse } from "../../../services/getVirtualAccounts";
import {
  FH_JUNIOR_INVESTOR_ACCOUNT_ID_DEV,
  FH_JUNIOR_INVESTOR_ACCOUNT_ID_PROD,
  FH_JUNIOR_INVESTOR_ACCOUNT_ID_UAT,
} from "@funded-here-interface/common/src/constant/constant";

const InternalTransfer = () => {
  const { token, env } = useSelector((state: RootState) => state.auth);
  const { data } = useGetVirtualAccounts(token);

  const queryClient = useQueryClient();

  const [senderID, setSenderId] = useState("");
  const [receiverID, setReceiverId] = useState("");
  const [currency, setCurrency] = useState("");
  const [amount, setAmount] = useState("0.00");
  const [remarks, setRemarks] = useState("");

  const [sender_va_type, setSenderVAType] = useState("");
  const [receiver_va_type, setReceiverVAType] = useState("");

  const [senderBalance, setSenderBalance] = useState("");
  const [receiverBalance, setReceiverBalance] = useState("");

  const [senderOrgName, setSenderOrgName] = useState("");
  const [receiverOrgName, setReceiverOrgName] = useState("");

  const [senderOrgDetails, setSenderOrgDetails] = useState("");
  const [receiverOrgDetails, setReceiverOrgDetails] = useState("");

  const [senderBankAcc, setSenderBankAcc] = useState("");
  const [receiverBankAcc, setReceiverBankAcc] = useState("");

  const [prevSenderVAType, setPrevSenderVAType] = useState("");
  const [prevReceiverVAType, setPrevReceiverVAType] = useState("");

  const [disabledSenderOption, setDisabledSenderOption] = useState(true);
  const [disabledReceiverOption, setDisabledReceiverOption] = useState(true);

  const [prev_currency, setPrevCurrency] = useState("");

  const [formError, setFormError] = useState({
    senderID: "",
    receiverID: "",
    currency: "",
    amount: "",
    remarks: "",
    sender_va_type: "",
    receiver_va_type: "",
  });

  const [opened, { open, close }] = useDisclosure(false);

  const useInternalTransferMutation = useInternalTransfer();

  interface VirtualAccount {
    id: string;
    orgName: string;
    orgDetails: string;
    type: string;
    ref: string;
    createdDate: string;
    currency: string;
    balance: string;
    refId: string;
    bankAccountId: string;
  }

  const [senderFilteredData, setSenderFilteredData] = useState<
    VirtualAccount[]
  >([]);
  const [receiverFilteredData, setReceiverFilteredData] = useState<
    VirtualAccount[]
  >([]);

  const vaTypesOptions = [
    { value: "sf", label: "Sinking Fund" },
    { value: "asf", label: "Additional Sinking Fund" },
    { value: "qc", label: "Qualifying Capital" },
    { value: "sku", label: "Store Keeping Unit" },
    { value: "sn", label: "Structured Note" },
    { value: "fh_admin", label: "FundedHere Admin" },
    { value: "investor", label: "FundedHere Junior Investor" },
  ];

  const handleSubmit = () => {
    let newErrors = {
      senderID: "",
      receiverID: "",
      currency: "",
      amount: "",
      remarks: "",
      sender_va_type: "",
      receiver_va_type: "",
    };

    let isValid = true;

    if (!senderID) {
      isValid = false;
      newErrors.senderID = "Sender is required";
    }

    if (!receiverID) {
      isValid = false;
      newErrors.receiverID = "Receiver is required";
    }

    if (!currency) {
      isValid = false;
      newErrors.currency = "Currency is required";
    }

    if (!amount) {
      isValid = false;
      newErrors.amount = "Amount is required";
    }
    if (!remarks) {
      isValid = false;
      newErrors.remarks = "Remarks is required";
    }

    if (!sender_va_type) {
      isValid = false;
      newErrors.sender_va_type = "Sender Virtual Account Type is required";
    }

    if (!receiver_va_type) {
      isValid = false;
      newErrors.receiver_va_type = "Receiver Virtual Account Type is required";
    }

    if (parseFloat(amount) > parseFloat(senderBalance)) {
      isValid = false;
      newErrors.amount = `Amount transferred is more than the balance: ${currency} ${senderBalance}`;
    }

    setFormError(newErrors);

    if (isValid) {
      open();
    }
  };

  const handleSenderChange = (orgDetails: string) => {
    formError.senderID = "";
    const selectedOrg = senderFilteredData.find(
      (item) => item.orgDetails === orgDetails
    );
    if (selectedOrg) {
      setSenderId(selectedOrg.id);
      setSenderBalance(selectedOrg.balance);
      setSenderBankAcc(selectedOrg.bankAccountId);
      setSenderOrgName(
        selectedOrg.orgName ? selectedOrg.orgName : selectedOrg.refId
      );
      setSenderOrgDetails(orgDetails);
    }
  };

  const handleReceiverChange = (orgDetails: string) => {
    formError.receiverID = "";
    const selectedOrg = receiverFilteredData.find(
      (item) => item.orgDetails === orgDetails
    );
    if (selectedOrg) {
      setReceiverId(selectedOrg.id);
      setReceiverBalance(selectedOrg.balance);
      setReceiverBankAcc(selectedOrg.bankAccountId);
      setReceiverOrgName(
        selectedOrg.orgName ? selectedOrg.orgName : selectedOrg.refId
      );
      setReceiverOrgDetails(orgDetails);
    }
  };

  const checkPrevSenderVaType = (newtype: string) => {
    if (newtype !== prevSenderVAType) {
      setPrevSenderVAType(newtype);
      setSenderId("");
      setSenderOrgName("");
      setSenderOrgDetails("");
    }
  };

  const checkPrevReceiverVaType = (newtype: string) => {
    if (newtype !== prevReceiverVAType) {
      setPrevReceiverVAType(newtype);
      setReceiverId("");
      setReceiverOrgName("");
      setReceiverOrgDetails("");
    }
  };

  const checkPrevCurrency = (newCurrency: string) => {
    if (newCurrency !== prev_currency) {
      setPrevCurrency(newCurrency);
      setSenderId("");
      setSenderOrgName("");
      setReceiverId("");
      setReceiverOrgName("");
      setSenderOrgDetails("");
      setReceiverOrgDetails("");
    }
  };

  const confirmTransfer = () => {
    useInternalTransferMutation.mutate(
      {
        token: token,
        senderVirtualAccountPrimaryId: parseInt(senderID, 10),
        receiverVirtualAccountPrimaryId: parseInt(receiverID, 10),
        currency: currency,
        amount: amount.toString(),
        remarks: remarks,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries("get-vaacounts");
          toast.success("Transfer Executed");
          close();
          clear();
        },
        onError: (error) => {
          toast.error((error as Error).message);
        },
      }
    );
  };

  const clear = () => {
    setSenderId("");
    setSenderBalance("");
    setSenderBankAcc("");
    setSenderOrgName("");
    setSenderOrgDetails("");
    setReceiverId("");
    setReceiverBalance("");
    setReceiverBankAcc("");
    setReceiverOrgName("");
    setReceiverOrgDetails("");
    setRemarks("");
    setAmount("0.00");
    setSenderVAType("");
    setReceiverVAType("");
    setCurrency("");
    setPrevSenderVAType("");
    setPrevReceiverVAType("");
    setPrevCurrency("");
    setSenderFilteredData([]);
    setReceiverFilteredData([]);
  };

  useEffect(() => {
    const junior_dev: string = FH_JUNIOR_INVESTOR_ACCOUNT_ID_DEV;
    const junior_uat: string = FH_JUNIOR_INVESTOR_ACCOUNT_ID_UAT;
    const junior_prod: string = FH_JUNIOR_INVESTOR_ACCOUNT_ID_PROD;

    let junior: string = "";

    if (env === "local") {
      junior = junior_dev;
    } else if (env === "uat") {
      junior = junior_uat;
    } else if (env === "production") {
      junior = junior_prod;
    }

    if (sender_va_type && currency) {
      let senderFilteredData: VirtualAccountResponse[] | undefined;

      if (sender_va_type === "investor") {
        senderFilteredData = data?.filter((item: any) => {
          return item.accountId === junior;
        });
      } else {
        senderFilteredData = data?.filter(
          (item: any) =>
            item.type === sender_va_type && item.currency === currency
        );
      }

      if (senderFilteredData) {
        setSenderFilteredData(senderFilteredData);
        setDisabledSenderOption(false);
      }
    } else {
      setDisabledSenderOption(true);
    }

    if (receiver_va_type && currency) {
      let receiverFilteredData: VirtualAccountResponse[] | undefined;

      if (receiver_va_type === "investor") {
        receiverFilteredData = data?.filter((item: any) => {
          return item.accountId === junior;
        });
      } else {
        receiverFilteredData = data?.filter(
          (item: any) =>
            item.type === receiver_va_type && item.currency === currency
        );
      }

      if (receiverFilteredData) {
        setReceiverFilteredData(receiverFilteredData);
        setDisabledReceiverOption(false);
      }
    } else {
      setDisabledReceiverOption(true);
    }
  }, [sender_va_type, receiver_va_type, currency, data, env]);

  const handleBlurAmount = () => {
    let parseAmount = parseFloat(amount).toFixed(2);
    setAmount(parseAmount);
  };

  return (
    <div>
      <div style={{ width: "44%" }}>
        <label>Currency</label>
        <InputSelect
          isErrored={formError.currency}
          onChange={(value: string) => {
            formError.currency = "";
            setCurrency(value);
            checkPrevCurrency(value);
          }}
          labelText=""
          id="currency"
          value={currency}
          data={["SGD"]}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          marginTop: "20px",
        }}
      >
        <div style={{ width: "50%", marginRight: 60 }}>
          <label>Sender Virtual Account Type</label>
          <InputSelect
            isErrored={formError.sender_va_type}
            onChange={(value: string) => {
              formError.sender_va_type = "";
              const selectedType = vaTypesOptions.find(
                (option) => option.label === value
              );
              if (selectedType) {
                setSenderVAType(selectedType.value);
                checkPrevSenderVaType(selectedType.value);
              }
            }}
            labelText=""
            id="senderVA"
            value={
              vaTypesOptions.find((option) => option.value === sender_va_type)
                ?.label || null
            }
            data={vaTypesOptions.map((option) => option.label)}
          />
        </div>

        <div style={{ width: "50%", marginRight: 60 }}>
          <label>Receiver Virtual Account Types</label>
          <InputSelect
            isErrored={formError.receiver_va_type}
            onChange={(value: string) => {
              formError.receiver_va_type = "";
              const selectedType = vaTypesOptions.find(
                (option) => option.label === value
              );
              if (selectedType) {
                setReceiverVAType(selectedType.value);
                checkPrevReceiverVaType(selectedType.value);
              }
            }}
            labelText=""
            id="receiverVA"
            value={
              vaTypesOptions.find((option) => option.value === receiver_va_type)
                ?.label || null
            }
            data={vaTypesOptions.map((option) => option.label)}
          />
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          marginTop: "20px",
        }}
      >
        <div style={{ width: "50%", marginRight: 60 }}>
          <label>Sender Virtual Account Number</label>
          <InputSelect
            isErrored={formError.senderID}
            onChange={(value: string) => {
              formError.senderID = "";
              handleSenderChange(value);
            }}
            labelText=""
            id="sender"
            value={senderOrgDetails}
            data={senderFilteredData.map((item) => item.orgDetails as string)}
            disabled={disabledSenderOption}
          />
        </div>
        <div style={{ width: "50%", marginRight: 60 }}>
          <label>Receiver Virtual Account Number</label>
          <InputSelect
            isErrored={formError.receiverID}
            onChange={(value: string) => {
              formError.receiverID = "";
              handleReceiverChange(value);
            }}
            labelText=""
            id="receiver"
            value={receiverOrgDetails}
            data={receiverFilteredData.map((item) => item.orgDetails as string)}
            disabled={disabledReceiverOption}
          />
        </div>
      </div>

      <div style={{ width: "50%", marginRight: 60, marginTop: 20 }}>
        <label>Amount</label>
        <NumberInputGroup
          isErrored={formError.amount}
          onChange={(value: string) => {
            formError.amount = "";
            setAmount(value);
          }}
          labelText=""
          id="amount"
          value={amount}
          min={0}
          onBlur={handleBlurAmount}
        />
      </div>
      <div style={{ width: "100%", marginRight: 80 }}>
        <label>Remarks</label>

        <InputGroup
          isErrored={formError.remarks}
          onChange={(value: string) => {
            formError.remarks = "";
            setRemarks(value);
          }}
          labelText=""
          value={remarks}
          id="remarks"
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "start",
          marginTop: "10px",
        }}
      >
        <LargeBtn
          onClick={handleSubmit}
          backgroundColor="#3786D6"
          textColor="#FFFFFF"
          width="auto"
        >
          Transfer
        </LargeBtn>
      </div>

      <Modal
        opened={opened}
        onClose={close}
        size="lg"
        title={
          <Title order={4} style={{ fontWeight: "bold" }}>
            Confirm Internal Transfer
          </Title>
        }
      >
        <div style={{ padding: "0px 30px" }}>
          <p style={{ padding: "10px 0px" }}>
            Do you want to execute the following internal transfer?
          </p>

          <p>
            <strong>Sender Bank Account:</strong> {senderBankAcc}
          </p>
          <p>
            <strong>Sender:</strong> {senderOrgName}
          </p>

          <p>
            <strong>Receiver Bank Account:</strong> {receiverBankAcc}
          </p>
          <p>
            <strong>Receiver:</strong> {receiverOrgName}
          </p>

          <p>
            <strong>Amount:</strong> {currency} {parseFloat(amount).toFixed(2)}
          </p>
          <p>
            <strong>Sender Original Balance:</strong> {senderBalance}
          </p>
          <p>
            <strong>Sender Balance After Transfer:</strong>{" "}
            {(parseFloat(senderBalance) - parseFloat(amount)).toFixed(2)}
          </p>

          <p>
            <strong>Receiver Original Balance:</strong> {receiverBalance}
          </p>
          <p>
            <strong>Receiver Balance After Transfer:</strong>{" "}
            {(parseFloat(receiverBalance) + parseFloat(amount)).toFixed(2)}
          </p>

          <p>
            <strong>Remarks:</strong> {remarks}
          </p>

          <div style={{ display: "flex" }}>
            <div style={{ margin: "10px auto" }}>
              <div style={{ paddingBottom: "10px" }}>
                <Button
                  onClick={confirmTransfer}
                  backgroundColor={color.FHGREEN}
                  textColor={color.WHITE}
                  children="Confirm"
                ></Button>
              </div>
              <div>
                <Button
                  onClick={close}
                  backgroundColor={color.REJECT}
                  textColor={color.WHITE}
                  children="Cancel"
                ></Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default InternalTransfer;
