import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";

import authReducer from "../src/features/auth/authSlice";
import bizSlice from "./features/registration/bizSlice";
import repSlice from "./features/registration/repSlice";
import bankSlice from "./features/registration/bankSlice";
import docSlice from "./features/registration/docSlice";
import bizBorSlice from "./features/merchant/biz";
import BorbankSlice from "./features/merchant/bankSlice";
import BorrepSlice from "./features/merchant/rep";
import docBorrowerSlice from "./features/merchant/doc";
import bulkSlice from "./features/sku/bulkSlice";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"], // Add other reducers you want to persist here
};
const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    auth: authReducer,
    biz: bizSlice,
    rep: repSlice,
    bank: bankSlice,
    doc: docSlice,
    bulk: bulkSlice,
    borBiz: bizBorSlice,
    BorBank: BorbankSlice,
    Borrep: BorrepSlice,
    bordoc: docBorrowerSlice,
  })
);

const store = configureStore({
  reducer: persistedReducer,
});

const persistor = persistStore(store);

export { store, persistor };
export type RootState = ReturnType<typeof store.getState>;
