import { FC, useMemo, useState } from "react";
import {
  MRT_ColumnDef,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import { toast } from "react-toastify";
import { formatNumbersWithThousandSeperators } from "@funded-here-interface/common/src/Utils/formatter";
import useGetNoteRepayment from "../../../../hooks/useGetNoteRepayment";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import {
  SKUDetails,
  SKUTransferToOtherSkuDetails,
} from "../../../../services/getNoteRepayment";
import { Button, color } from "@funded-here-interface/common";
import { Modal, Title } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";

interface IProps {
  noteId: string;
}

//  This is a test commit message

const Sku: FC<IProps> = ({ noteId }) => {
  const token = useSelector((state: RootState) => state.auth.token);
  const { data, error, isLoading } = useGetNoteRepayment(token, noteId);
  const [openedTransferredToOtherSkuTransactionsModal, { open, close }] =
    useDisclosure(false);
  const [
    selectedTransferredToAnotherSkuData,
    setSelectedTransferredToAnotherSkuData,
  ] = useState<Array<SKUTransferToOtherSkuDetails>>([]);

  if (error) {
    toast.error((error as Error).message);
  }

  const columns = useMemo<MRT_ColumnDef<SKUDetails>[]>(
    () => [
      {
        accessorKey: "merchant",
        header: "Merchant",
      },
      {
        accessorKey: "skuId",
        header: "SKU ID",
      },
      {
        accessorKey: "acquirerFees.totalDue",
        header: "Acquirer Fees Expected",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? formatNumbersWithThousandSeperators(
                  parseFloat(cell.getValue<string>())
                )
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "acquirerFees.paidUp",
        header: "Acquirer Fees Paid",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? formatNumbersWithThousandSeperators(
                  parseFloat(cell.getValue<string>())
                )
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "fhAdminFee.totalDue",
        header: "FH Admin Fees Expected",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? formatNumbersWithThousandSeperators(
                  parseFloat(cell.getValue<string>())
                )
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "fhAdminFee.paidUp",
        header: "FH Admin Fees Paid",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? formatNumbersWithThousandSeperators(
                  parseFloat(cell.getValue<string>())
                )
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "intDiff.totalDue",
        header: "Int Difference Expected",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? formatNumbersWithThousandSeperators(
                  parseFloat(cell.getValue<string>())
                )
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "intDiff.paidUp",
        header: "Int Difference Paid",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? formatNumbersWithThousandSeperators(
                  parseFloat(cell.getValue<string>())
                )
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "seniorInvestorPrincipal.totalDue",
        header: "Sr. Principal Expected",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? formatNumbersWithThousandSeperators(
                  parseFloat(cell.getValue<string>())
                )
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "seniorInvestorPrincipal.paidUp",
        header: "Sr. Principal Paid",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? formatNumbersWithThousandSeperators(
                  parseFloat(cell.getValue<string>())
                )
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "seniorInvestorInterest.totalDue",
        header: "Sr. Interest Expected",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? formatNumbersWithThousandSeperators(
                  parseFloat(cell.getValue<string>())
                )
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "seniorInvestorInterest.paidUp",
        header: "Sr. Interest Paid",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? formatNumbersWithThousandSeperators(
                  parseFloat(cell.getValue<string>())
                )
              : "-"}
          </p>
        ),
      },
      // Only calculate when default
      // {
      //   accessorKey: "seniorInvestorAddInterest.totalDue",
      //   header: "Sr. Add Interest Expected",
      //   Cell: ({ cell }) => (
      //     <p>
      //       {cell.getValue<string>()
      //         ? formatNumbersWithThousandSeperators(
      //             parseFloat(cell.getValue<string>())
      //           )
      //         : "-"}
      //     </p>
      //   ),
      // },
      // {
      //   accessorKey: "seniorInvestorAddInterest.paidUp",
      //   header: "Sr. Add Interest Paid",
      //   Cell: ({ cell }) => (
      //     <p>
      //       {cell.getValue<string>()
      //         ? formatNumbersWithThousandSeperators(
      //             parseFloat(cell.getValue<string>())
      //           )
      //         : "-"}
      //     </p>
      //   ),
      // },
      {
        accessorKey: "juniorInvestorPrincipal.totalDue",
        header: "Jr. Principal Expected",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? formatNumbersWithThousandSeperators(
                  parseFloat(cell.getValue<string>())
                )
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "juniorInvestorPrincipal.paidUp",
        header: "Jr. Principal Paid",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? formatNumbersWithThousandSeperators(
                  parseFloat(cell.getValue<string>())
                )
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "juniorInvestorInterest.totalDue",
        header: "Jr. Interest Expected",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? formatNumbersWithThousandSeperators(
                  parseFloat(cell.getValue<string>())
                )
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "juniorInvestorInterest.paidUp",
        header: "Jr. Interest Paid",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? formatNumbersWithThousandSeperators(
                  parseFloat(cell.getValue<string>())
                )
              : "-"}
          </p>
        ),
      },
      // Only calculate when default
      // {
      //   accessorKey: "juniorInvestorAddInterest.totalDue",
      //   header: "Jr. Add Interest Expected",
      //   Cell: ({ cell }) => (
      //     <p>
      //       {cell.getValue<string>()
      //         ? formatNumbersWithThousandSeperators(
      //             parseFloat(cell.getValue<string>())
      //           )
      //         : "-"}
      //     </p>
      //   ),
      // },
      // {
      //   accessorKey: "juniorInvestorAddInterest.paidUp",
      //   header: "Jr. Add Interest Paid",
      //   Cell: ({ cell }) => (
      //     <p>
      //       {cell.getValue<string>()
      //         ? formatNumbersWithThousandSeperators(
      //             parseFloat(cell.getValue<string>())
      //           )
      //         : "-"}
      //     </p>
      //   ),
      // },
      {
        accessorKey: "spar.paidUp",
        header: "SPAR Merchant",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? formatNumbersWithThousandSeperators(
                  parseFloat(cell.getValue<string>())
                )
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "additionalInterestsPaidToFh.paidUp",
        header: "Additional Interests Paid to FH",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? formatNumbersWithThousandSeperators(
                  parseFloat(cell.getValue<string>())
                )
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "transferToOtherSkusTransactions",
        header: "View SKU Transfer",
        Cell: ({ cell }) => (
          <Button
            onClick={() => {
              setSelectedTransferredToAnotherSkuData(
                cell.getValue() as Array<SKUTransferToOtherSkuDetails>
              );
              open();
            }}
            backgroundColor={color.FHGREEN}
            textColor={color.WHITE}
            children="View"
            border={`1px solid ${color.FHGREEN}`}
            width="30%"
          />
        ),
      },
    ],
    []
  );

  const transferredToOtherSkuTableColumns = useMemo<
    MRT_ColumnDef<SKUTransferToOtherSkuDetails>[]
  >(
    () => [
      {
        accessorKey: "senderSku",
        header: "From SKU",
      },
      {
        accessorKey: "currency",
        header: "Currency",
      },
      {
        accessorKey: "amount",
        header: "Amount",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? formatNumbersWithThousandSeperators(
                  parseFloat(cell.getValue<string>())
                )
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "receiverSku",
        header: "To SKU",
      },
      {
        accessorKey: "date",
        header: "Date",
      },
    ],
    []
  );

  const table = useMantineReactTable({
    enableColumnActions: false,
    enableColumnFilters: false,
    paginationDisplayMode: "pages",
    state: { isLoading },
    columns,
    data: data?.SKULevel ?? [],
    enablePinning: true,
    initialState: {
      density: "xs",
      columnPinning: { left: ["merchant", "skuId"] },
    },
    mantineTableProps: {
      withBorder: true,
    },
  });

  let transferredToOtherSkuTable = useMantineReactTable({
    enableColumnActions: false,
    enableColumnFilters: false,
    enableFullScreenToggle: false,
    paginationDisplayMode: "pages",
    state: { isLoading },
    columns: transferredToOtherSkuTableColumns,
    data: selectedTransferredToAnotherSkuData,
    initialState: { density: "xs" },
    mantineTableProps: {
      withBorder: true,
    },
  });

  return (
    <>
      <MantineReactTable table={table} />

      <Modal
        opened={openedTransferredToOtherSkuTransactionsModal}
        onClose={close}
        size="1000"
        title={
          <Title order={4} style={{ fontWeight: "bold" }}>
            Transferred to other SKUs transactions
          </Title>
        }
      >
        <MantineReactTable table={transferredToOtherSkuTable} />
      </Modal>
    </>
  );
};

export default Sku;
