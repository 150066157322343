import { BASE_URL } from "@funded-here-interface/common/src/constant/constant";
import axios from "axios";
import { getApiResponseErrorMsg } from "@funded-here-interface/common/src/Utils/apiHelper";

type GetOrgsRequest = {
  token: string;
  limit: string;
  page: string;
  isIndividual: boolean;
  kycStatus?: string;
  search?: string;
};

const getOrgs = async ({
  token,
  page,
  limit,
  kycStatus,
  isIndividual,
  search,
}: GetOrgsRequest) => {
  try {
    let url = `${BASE_URL}/trade-directory/organization?page=${page}&limit=${limit}&isIndividual=${isIndividual}`;

    if (kycStatus) {
      url = `${url}&kycStatus=${kycStatus}`;
    }

    if (search) {
      url = `${url}&search=${search}`;
    }

    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    });

    return response.data;
  } catch (e) {
    const message = getApiResponseErrorMsg(e);

    throw new Error(message);
  }
};

export default getOrgs;
