import { useQuery } from "react-query";
import getOrgs from "../services/getOrgs";

const useGetOrgsMutation = (
  token: string,
  limit: string,
  page: string,
  isIndividual: boolean,
  kycStatus?: string,
  search?: string
) => {
  return useQuery(
    ["get-orgs", token, limit, page, isIndividual, kycStatus, search],
    () => getOrgs({ token, limit, page, isIndividual, kycStatus, search }),
    {
      retry: false,
    }
  );
};
export default useGetOrgsMutation;
