import React, { useEffect, useState } from "react";
import "./Structure.css";
import BarSKuChart from "./BarSKuChart";
import { Link } from "react-router-dom";
import PieSkuCountryChart from "./PieSkuCountryChart";
import PieSkuSectorChart from "./PieSkuSectorChart";
import HomeServices from "../../../../services/HomeServices";
import toast from "react-hot-toast";
import moment from "moment";
import { RiArrowDropDownLine } from "react-icons/ri";
import { useSelector } from "react-redux";

const Structure = () => {
  const [showmore, setShowmore] = useState(false);
  const [structureNoteData, setStructureNoteData] = useState([]);
  const [snData, setSNdata] = useState([]);
  const [SNData, setSNData] = useState(null);
  const [isCountry, setIsCountry] = useState(true);
  const [isDrop, setIsDrop] = useState(false);
  const [showTop10Button, setShowTop10Button] = useState(true);
  const token = useSelector((state) => state.auth.token);

  const currentDate = moment().format("DD-MMM-YYYY");

  useEffect(() => {
    if (snData.length > 0) {
      setSNData(snData[0]); // Set the initial value of SNData to the first value from snData
      handlePostValue(snData[0]); // Fetch data for the first SNData value
    }
  }, [snData]);

  const structNodesArr = [
    {
      struHead: "Issue Date",
      struDate: structureNoteData?.[0]?.issueDate
        ? moment(structureNoteData[0].issueDate).format("DD-MMM-YYYY")
        : "",
    },
    {
      struHead: "Tenure",
      struDate: structureNoteData?.[0]?.tenure,
    },
    {
      struHead: "Maturity Date",
      struDate: structureNoteData?.[0]?.maturityDate
        ? moment(structureNoteData[0].maturityDate).format("DD-MMM-YYYY")
        : "",
    },
    {
      struHead: "Days to Maturity",
      struDate:
        structureNoteData?.[0]?.daysToMaturity < 0
          ? 0
          : structureNoteData?.[0]?.daysToMaturity,
      // struDate: structureNoteData?.[0]?.daysToMature,
    },
    {
      struHead: "SF",
      struDate: structureNoteData?.[0]?.sf?.toLocaleString("en-US"),
    },
    {
      struHead: "ASF",
      struDate: structureNoteData?.[0]?.asf?.toLocaleString("en-US"),
    },
    {
      struHead: "QC",
      struDate: structureNoteData?.[0]?.moePaid?.toLocaleString("en-US"),
    },
    {
      struHead: "Expected RBP",
      struDate: structureNoteData?.[0]?.expectedRBP,
    },
    {
      struHead: "Merchants Count",
      struDate: structureNoteData?.[0]?.totalMerchants,
    },
    {
      struHead: "SKU Count",
      struDate: structureNoteData?.[0]?.totalSKU,
    },
    {
      struHead: "Invested Amount",
      struDate: structureNoteData?.[0]?.investedAmount?.toLocaleString("en-US"),
    },
    {
      struHead: "Repaid Amount",
      struDate: structureNoteData?.[0]?.repaidAmount?.toLocaleString("en-US"),
    },
    {
      struHead: "Outstanding Amount",
      struDate: structureNoteData?.[0]?.balanceAmount?.toLocaleString("en-US"),
    },
    {
      struHead: "Interest Rate",
      struDate: structureNoteData?.[0]?.interestRate,
    },
    {
      struHead: "Effective ROR (in %)",
      struDate: structureNoteData?.[0]?.effectiveROR,
    },
    {
      struHead: "Investors Count",
      struDate: structureNoteData?.[0]?.totalInvestors,
    },
  ];

  // >>>Handling Dropdown<<<
  const handleClick = () => {
    setShowmore(!showmore);
  };

  const fetchingDropDownSNvalue = async () => {
    try {
      const res = await HomeServices.getSNDropDown(token);
      if (res && res.status) {
        setSNdata(res.data.map((item) => item.snId)); // Update snData state with only snId values
      } else {
        toast.error("Unable To Fetch SN Data");
      }
    } catch (error) {
      toast.error("Unable To Fetch SN Data");
    }
  };

  // >>>Handle Post Api Data<<<snsdata
  const handlePostValue = async (val) => {
    const res = await HomeServices.structureNote(val, token);

    if (res && res?.status) {
      setStructureNoteData(res?.data);
    } else {
      toast.error("Unable To Fetch Data");
    }
  };

  const handleTop10ButtonClick = () => {
    setShowTop10Button(false); // Hide the top 10 SKUs button
  };

  useEffect(() => {
    handlePostValue(SNData); // Pass the initial SNData value to fetch data
  }, []);

  useEffect(() => {
    // processData();
    fetchingDropDownSNvalue();
  }, []);

  return (
    <>
      <section className="structureSection">
        <div className="strucFirstDiv">
          <p className="strucHead">Notes as on {currentDate}</p>
          <div>
            <p className="starSelecText">
              *Select Notes from dropdown to filter the entire screen
            </p>
            <div className="starDropSelectDiv">
              <div className="allSnsDivsDiv">
                <div
                  className="snspArtDiv"
                  onClick={(e) => {
                    setIsDrop((prev) => !prev);
                  }}
                >
                  <span>{SNData}</span>
                  <span className="snIconSpan">
                    <RiArrowDropDownLine size={"2em"} />
                    {/* <i class="fa-solid fa-angle-down"></i> */}
                  </span>
                </div>
                {isDrop && (
                  <div className="dropDownStartDiv">
                    <ul className="dropDownSnUl">
                      {snData.map((val, index) => {
                        return (
                          <li key={index} className="dropDownSnLi">
                            <input
                              type="radio"
                              name="snName"
                              onClick={() => {
                                setIsDrop(false);
                                setSNData(val);
                                handlePostValue(val); // Pass the selected snId to handlePostValue
                              }}
                            />
                            <label htmlFor="">{val}</label>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="mainAllToUlDiv">
          <div className="allToolPrntDiv">
            {structNodesArr.map((item, indx) => {
              return (
                <div className="alltoolTipDivsDiv" key={indx}>
                  <div className="strucTooltip">
                    <div className="focusIcon">
                      <i class="fa-solid fa-thumbtack"></i>
                      <p className="focusPara">Pin Visual</p>
                    </div>
                    <div className="copyIcon">
                      <i class="fa-regular fa-copy"></i>
                      <p className="copyIconPara">Copy as image with caption</p>
                    </div>
                    <div className="focusIcon">
                      <i class="fa-solid fa-expand"></i>
                      <p className="focusPara">Focus Para</p>
                    </div>
                    <div className="moreIcon">
                      <i class="fa-solid fa-ellipsis"></i>
                      <p className="moreIconPara" onClick={handleClick}>
                        More Options
                      </p>
                    </div>
                  </div>
                  <div className="toolTipDiv">
                    <div className="toolTipInnerDiv">
                      <div>
                        <p className="toolInSmallTexts">{item.struHead}</p>
                        <p className="toolInSmallDates">{item.struDate}</p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <section className="structureBtmPartSection">
        <div className="StructureFlex">
          <div className="struCOlInDiv">
            <div className="topSkusDiv">
              <p className="topSkuHead">Top 10 SKUs by DOI</p>
              <div className="topSkuTenBtnsDiv">
                <button
                  className="topSkuTenBtns"
                  onClick={handleTop10ButtonClick}
                >
                  Top 10 SKUs By DOI
                </button>
                <Link to="/pm-dashboard/snusummary" className="topSkuTenBtns">
                  SKU Details
                </Link>
              </div>
            </div>
            <BarSKuChart />
          </div>
          <div className="struCOlInDiv">
            <div className="topSkusDiv">
              <p className="topSkuHead">
                SKU Allocation by {isCountry ? "Country" : "Sector"}
              </p>
              <div className="topSkuTenBtnsDiv">
                <button
                  className={`topSkuTenBtns ${isCountry ? "active" : ""}`}
                  onClick={() => setIsCountry(true)}
                >
                  Country
                </button>
                <button
                  className={`topSkuTenBtns ${!isCountry ? "active" : ""}`}
                  onClick={() => setIsCountry(false)}
                >
                  Sector
                </button>
              </div>
            </div>
            {isCountry ? <PieSkuSectorChart /> : <PieSkuCountryChart />}
          </div>
        </div>
      </section>
    </>
  );
};

export default Structure;
