import { BASE_URL } from "@funded-here-interface/common/src/constant/constant";
import axios from "axios";
import { getApiResponseErrorMsg } from "@funded-here-interface/common/src/Utils/apiHelper";
import { Risk } from "../constant/enum";

interface OrganizationRiskRequest {
  token: string;
  orgId: number;
  risk: Risk;
}

const organizationRisk = async ({
  token,
  orgId,
  risk,
}: OrganizationRiskRequest) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/trade-directory/organization/organization-risk`,
      { orgId, risk },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      }
    );

    return response.data;
  } catch (e) {
    const message = getApiResponseErrorMsg(e);

    throw new Error(message);
  }
};

export default organizationRisk;
