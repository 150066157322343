import { createSlice } from "@reduxjs/toolkit";

interface AuthState {
  token: string;
  env: string;
  isMerchantAuthRep: boolean | null;
  userId: string;
}

const initialState: AuthState = {
  token: "none",
  env: "none",
  isMerchantAuthRep: null,
  userId: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken: (state, action) => {
      const { token } = action.payload;
      state.token = token;
    },
    setEnvironment: (state, action) => {
      const { env } = action.payload;
      state.env = env;
    },
    setIsMerchantAuthRep: (state, action) => {
      const { isMerchantAuthRep } = action.payload;
      state.isMerchantAuthRep = isMerchantAuthRep;
    },
    resetAuthState: () => initialState,
  },
});

export const { setToken, setEnvironment, resetAuthState } = authSlice.actions;
export default authSlice.reducer;
