import { createStyles } from "@mantine/core";

export const useStyle = createStyles(() => ({
  divider: {
    marginBottom: "40px !important",
    marginTop: "40px !important",
  },
  buttonWrapper: {
    display: "flex",
    width: "360px",
    justifyContent: "space-between",
    marginBottom: "30px",
  },
}));
