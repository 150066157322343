import axios from "axios";
import { BASE_URL } from "@funded-here-interface/common/src/constant/constant";
import { getApiResponseErrorMsg } from "@funded-here-interface/common/src/Utils/apiHelper";

export interface DisbursementSummaryResponse {
  amountToDisburse: string;
  amountToDisburseOtherCurrency: string;
  currency: string;
  otherCurrency: string;
  distributorBankAccountNo: string;
  distributorBenefId: string;
  distributorOrgName: string;
  externalUserId: string;
  paymentTerm: string;
  skuId: string;
  partialPaymentType?: string;
  expectedPaymentDate: string;
  allowFundsDisbursement: boolean;
}

interface DisbursementSummaryRequest {
  token: string;
  skuId: string;
}

const getDisbursementSummary = async ({
  token,
  skuId,
}: DisbursementSummaryRequest): Promise<DisbursementSummaryResponse> => {
  try {
    const response = await axios.post(
      `${BASE_URL}/sku-finance/loan-disbursement/get-distributor-disbursement-amount`,
      { skuId },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      }
    );

    return response.data;
  } catch (error) {
    const message = getApiResponseErrorMsg(error);

    throw new Error(message);
  }
};

export default getDisbursementSummary;
