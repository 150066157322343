import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { MDBDataTable } from "mdbreact";
// import DataTable from "../../Component/DataTable";
import HomeServices from "../../../../services/HomeServices";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

const InvestorTableChart = () => {
  const [investorData, setInvestorData] = useState([]);
  const [investorSnData, setInvestorSnData] = useState([]);
  const token = useSelector((state) => state.auth.token);

  //  >>>>Showing Invester Level Analysis Data
  const columns = [
    { label: "Investor Name", field: "investor_name" },
    { label: "Committed Amount", field: "cmmtd_amt" },
    { label: "Invested Amount", field: "Invested_amt" },
    { label: "Repaid Amount", field: "repaid_Amt" },
    { label: "Returns (in %)", field: "return_prcnt" },
    { label: "Amount Available for Investment", field: "amt_avlbl" },
  ];
  const rows = investorData.map((item, index) => ({
    key: index,
    investor_name: item?.investorName,
    cmmtd_amt: item?.commitedAmount?.toLocaleString("en-US"),
    Invested_amt: item?.totalInvestment?.toLocaleString("en-US"),
    repaid_Amt: item?.repaidAmount?.toLocaleString("en-US"),
    return_prcnt: item?.effectiveROR?.toLocaleString("en-US"),
    amt_avlbl: item?.amountAvailabeForInvestment?.toLocaleString("en-US"),
  }));

  //  >>>>Showing Invester Sn Level Analysis Data
  const snrows = investorSnData.map((item, index) => ({
    sn_id: item?.snId,
    investor_name: item?.investorName,
    Invested_amt: item?.totalInvestment?.toLocaleString("en-US"),
    repaid_Amt: item?.repaidAmount?.toLocaleString("en-US"),
    blnc_amt:
      item?.balanceAmount < 0
        ? 0
        : item?.balanceAmount?.toLocaleString("en-US"),
    return_prcnt: item?.effectiveROR?.toLocaleString("en-US"),
    rbp: item.rbp,
    tenure: item.tenure,
    default: item.default,
  }));

  const myData = {
    columns: [
      {
        label: "Note ID",
        field: "sn_id",
        // sort: "asc",
      },

      {
        label: "Investor Name",
        field: "investor_name",
        // sort: "asc",
      },
      {
        label: "Invested Amount",
        field: "Invested_amt",
        // sort: "asc",
      },
      {
        label: "Repaid Amount",
        field: "repaid_Amt",
        // sort: "asc",
      },
      {
        label: "Balance Amount",
        field: "blnc_amt",
        // sort: "asc",
      },
      {
        label: "Returns (in %)",
        field: "return_prcnt",
        // sort: "asc",
      },
      {
        label: "RBP",
        field: "rbp",
        // sort: "asc",
      },
      {
        label: "Tenure",
        field: "tenure",
        // sort: "asc",
      },
      {
        label: "Default (in %)",
        field: "default",
        // sort: "asc",
      },
    ],

    rows: snrows,
  };

  // >>>>Investor-Level-Analysis<<<<
  const fetchInvestorData = async () => {
    const res = await HomeServices.investorAnalysis(undefined, token);
    if (res && res?.status) {
      setInvestorData(res?.data);
    } else {
      toast.error("Error fetching investor data");
    }
  };

  // >>>>Investor-Sn-Level-Analysis<<<<
  const fetchInvestorSnData = async () => {
    const res = await HomeServices.investorSnAnalysis(token);
    if (res && res?.status) {
      setInvestorSnData(res?.data);
    } else {
      toast.error("Error fetching investor data");
    }
  };

  useEffect(() => {
    fetchInvestorData();
    fetchInvestorSnData();
  }, []);

  return (
    <>
      <div className="investorTable">
        <Tabs>
          <TabList>
            <Tab onClick={fetchInvestorData}>
              Investor Level Analysis
              <span class="pmTooltip">Click here to follow link</span>
            </Tab>
            <Tab onClick={fetchInvestorSnData}>
              Investor Notes Level Analysis
              <span class="pmTooltip">Click here to follow link</span>
            </Tab>
            <Link to="/pm-dashboard/investorsummary" className="InvesttabLink">
              Investor Summary
              <span class="pmTooltip">Click here to follow link</span>
            </Link>
          </TabList>

          <TabPanel>
            <div className="invAnlysTable">
              <p className="invTabPara">Investor Level Analysis</p>
              <MDBDataTable
                bordered
                entries={10000}
                hover
                // data={data}
                data={{ columns, rows }}
                noBottomColumns={true}
                small
                stripes
                selectRows={true}
                className="text-center"
              />
            </div>
          </TabPanel>

          <TabPanel>
            <div className="invSNTable">
              <p className="invTabPara">Investor Notes Level Analysis</p>
              <MDBDataTable
                bordered
                entries={10000}
                hover
                data={myData}
                // data={{snColumns, snRows}}
                noBottomColumns={true}
                small
                stripes
                selectRows={true}
                className="text-center"
              />
            </div>
          </TabPanel>
        </Tabs>
      </div>
    </>
  );
};

export default InvestorTableChart;
