import { MantineTheme, createStyles } from "@mantine/core";

export const useStyles = createStyles((theme: MantineTheme) => ({
  valueContainer: {
    padding: "44px",
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    maxHeight: "500px",
  },
}));
