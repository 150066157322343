import { MantineTheme, createStyles } from "@mantine/styles";

export const useStyles = createStyles((theme: MantineTheme) => ({
  sideBarTitle: {
    marginTop: "87px",
    backgroundColor: "#151515",
    color: "white",
    paddingTop: "16px",
    paddingBottom: "17px",
    paddingLeft: "32px",
    fontWeight: 700,
  },
  dashboardHeading: {
    fontSize: "24px",
    fontWeight: 700,
  },
  dashboardCointainer: {
    height: "100vh",
    paddingTop: "95px",
    paddingLeft: "283px",
  },
  dashboardBox: {
    width: "368px",
    height: "249px",
    backgroundColor: "#D9D9D9",
    margin: "10px",
  },
  dashboardGrid: {
    marginLeft: "108px",
    marginTop: "15px",
  },
  ProgramManagerMenu: {
    position: "absolute",
    top: "20px",
    right: "45px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));
