import { Text } from "@mantine/core";
import { FC } from "react";
import { useStyles } from "./DashBoard.styles";
import profile from "../../assets/person_outline_24px_outlined.svg";
// @ts-ignore
import OverviewDashboard from "./Components/OverviewDashboard";
// @ts-ignore
import Investor from "./Components/Investor/Investor";
//  @ts-ignore
import Repayment from "./Components/RepaymentTracking";
//  @ts-ignore
import Note from "./Components/StructureNote/Structure";
//  @ts-ignore
import SkuSummary from "./Components/SKUSummary/Skusummary";
//  @ts-ignore
import MerchantSummary from "./Components/MerchantSummary/Merchant";
//  @ts-ignore
import DailySalesReport from "./Components/DailySalesReport/Dailysales";
import HeaderPmDashboard from "./HeaderPmDashboard";

import { useParams } from "react-router-dom";
import ErrorBoundary from "@funded-here-interface/common/src/dashboard/components/ErrorBoundry";
import InvestorSummary from "./Components/Investor/InvestorSummary";
import Skusummary from "./Components/SKUSummary/Skusummary";
import Merchant from "./Components/MerchantSummary/Merchant";

const Dashboard: FC = () => {
  let { subpath } = useParams();
  const { classes } = useStyles();

  return (
    <ErrorBoundary>
      <div className="mainContainer">
        <HeaderPmDashboard />

        {subpath === "overview" ? (
          <OverviewDashboard />
        ) : subpath === "investor" ? (
          <Investor />
        ) : subpath === "tracking" ? (
          <Repayment />
        ) : subpath === "note" ? (
          <Note />
        ) : subpath === "skusummary" ? (
          <SkuSummary />
        ) : subpath === "merchant" ? (
          <MerchantSummary />
        ) : subpath === "dailysales" ? (
          <DailySalesReport />
        ) : subpath === "investorsummary" ? (
          <InvestorSummary />
        ) : subpath === "snusummary" ? (
          <Skusummary />
        ) : subpath === "merchant" ? (
          <Merchant />
        ) : null}
      </div>
    </ErrorBoundary>
  );
};

export default Dashboard;
