import axios from "axios";
import { BASE_URL } from "@funded-here-interface/common/src/constant/constant";
import { getApiResponseErrorMsg } from "@funded-here-interface/common/src/Utils/apiHelper";
import {
  EntityTypeEnum,
  InvestorCategoryEnum,
} from "@funded-here-interface/common/src/constant/enum";

type InviteUserRequest = {
  token: string;
  invites: Invites[];
  userType: string;
};

interface InvestorCategoryType {
  entityType: EntityTypeEnum;
  category?: InvestorCategoryEnum;
  privatePlacement: boolean;
}

export interface Invites {
  firstName: string;
  lastName: string;
  email: string;
  investorCategory?: InvestorCategoryType;
  entityType?: EntityTypeEnum;
}

const inviteUser = async ({ invites, token, userType }: InviteUserRequest) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/trade-directory/users/invite-user`,
      {
        userType,
        bulkUsers: invites,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      }
    );

    return response.data;
  } catch (e) {
    const message = getApiResponseErrorMsg(e);

    throw new Error(message);
  }
};

export default inviteUser;
