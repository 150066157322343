import { useQuery } from "react-query";
import getPendingSku from "../services/getPendingSku";

const useGetPendingSku = (token: string, orgRoleId?: number) => {
  return useQuery(
    ["get-pending-sku", token, orgRoleId],
    () => getPendingSku({ token, orgRoleId }),
    {
      retry: false,
    }
  );
};

export default useGetPendingSku;
