import { FC, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import { useStyles } from "./NotelistCss";
import useNotelist from "../../hooks/useNotelist";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  MRT_ColumnDef,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import { NoteData } from "../../services/getNotelist";
import { convertDate } from "@funded-here-interface/common/src/Utils/date";
import { Divider, Title } from "@mantine/core";
import { LoanTab } from "../../constant/enum";
import { formatNumbersWithThousandSeperators } from "@funded-here-interface/common/src/Utils/formatter";

const Notelist: FC = () => {
  const { classes } = useStyles();
  const [data, setData] = useState<NoteData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const NotelistMutation = useNotelist();
  const navigate = useNavigate();
  const token = useSelector((state: RootState) => state.auth.token);

  const columns = useMemo<MRT_ColumnDef<NoteData>[]>(
    () => [
      {
        accessorKey: "structuredNoteDisplayId",
        id: "snId",
        header: "Note Id",
      },
      {
        accessorKey: "createdAt",
        header: "Created Date",
        Cell: ({ cell }) => <p>{convertDate(cell.getValue<string>())}</p>,
      },
      {
        accessorKey: "issuedAt",
        header: "Issuance Date",
        Cell: ({ cell }) =>
          cell.getValue<string>() ? (
            <p>{convertDate(cell.getValue<string>())}</p>
          ) : (
            <p>-</p>
          ),
      },
      {
        accessorKey: "closedAt",
        header: "Closed Date",
        Cell: ({ cell }) =>
          cell.getValue<string>() ? (
            <p>{convertDate(cell.getValue<string>())}</p>
          ) : (
            <p>-</p>
          ),
      },
      {
        accessorKey: "status",
        header: "Status",
      },
      {
        accessorKey: "value",
        header: "Value",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? formatNumbersWithThousandSeperators(
                  parseFloat(cell.getValue<string>())
                )
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "ispv",
        header: "ISPV",
      },
      {
        accessorKey: "id",
        header: "Issue",
        id: "issue",
        enableSorting: false,
        Cell: ({ cell }) => (
          <p
            className={classes.view}
            onClick={() => handleOnIssueClick(cell.getValue<string>())}
          >
            View
          </p>
        ),
      },
      {
        accessorKey: "id",
        header: "Disbursement",
        id: "disbursement",
        enableSorting: false,
        Cell: ({ cell }) => (
          <p
            className={classes.view}
            onClick={() => handleOnDisbursementClick(cell.getValue<string>())}
          >
            View
          </p>
        ),
      },
      {
        accessorKey: "id",
        header: "Repayment",
        id: "repayment",
        enableSorting: false,
        Cell: ({ cell }) => (
          <p
            className={classes.view}
            onClick={() => handleOnRepaymentClick(cell.getValue<string>())}
          >
            View
          </p>
        ),
      },
    ],
    []
  );

  const table = useMantineReactTable({
    enableColumnActions: false,
    enableColumnFilters: false,
    paginationDisplayMode: "pages",
    state: { isLoading },
    columns,
    data: data,
    initialState: { density: "xs" },
    mantineTableProps: {
      withBorder: true,
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      NotelistMutation.mutate(
        { token },
        {
          onSuccess: (data) => {
            setData(
              data.sort(
                (a, b) =>
                  new Date(b["createdAt"]).getTime() -
                  new Date(a["createdAt"]).getTime()
              )
            );
            setIsLoading(false);
          },
          onError: (error) => {
            toast.error((error as Error).message);
          },
        }
      );
    };
    fetchData();
  }, []);

  const handleOnIssueClick = (noteId: string) => {
    navigate(`/issue-note/${noteId}`);
  };
  const handleOnDisbursementClick = (noteId: string) => {
    navigate(`/loan-details?noteId=${noteId}`, {
      state: { tab: LoanTab.DISBURSEMENT, backPath: "/note-list" },
    });
  };
  const handleOnRepaymentClick = (noteId: string) => {
    navigate(`/repayment/${noteId}`);
  };

  return (
    <div>
      <Title>Notes</Title>
      <Divider my="md" className={classes.divider} />
      <MantineReactTable table={table} />
    </div>
  );
};

export default Notelist;
