import axios from "axios";
import { BASE_URL } from "@funded-here-interface/common/src/constant/constant";
import { getApiResponseErrorMsg } from "@funded-here-interface/common/src/Utils/apiHelper";

interface GetNoteRepaymentRequest {
  noteId: string;
  token: string;
}

interface FeeDetails {
  totalDue: string;
  paidUp: string;
  balance: string;
}

export interface SKUTransferToOtherSkuDetails {
  senderSku: string;
  currency: string;
  amount: string;
  receiverSku: string;
  date: string;
}

export interface SKUDetails {
  fhAdminFee: FeeDetails;
  fhAddAdminFee: FeeDetails;
  spar: FeeDetails;
  seniorInvestorPrincipal: FeeDetails;
  juniorInvestorPrincipal: FeeDetails;
  seniorInvestorInterest: FeeDetails;
  juniorInvestorInterest: FeeDetails;
  seniorInvestorAddInterest: FeeDetails;
  juniorInvestorAddInterest: FeeDetails;
  acquirerFees: FeeDetails;
  intDiff: FeeDetails;
}

interface Fees {
  fhAdminFee: FeeDetails;
  fhAddAdminFee: FeeDetails;
  spar: FeeDetails;
  seniorInvestorPrincipal: FeeDetails;
  juniorInvestorPrincipal: FeeDetails;
  seniorInvestorInterest: FeeDetails;
  juniorInvestorInterest: FeeDetails;
  seniorInvestorAddInterest: FeeDetails;
  juniorInvestorAddInterest: FeeDetails;
  acquirerFees: FeeDetails;
  intDiff: FeeDetails;
}

export interface GetNoteRepaymentResponse {
  SNLevel: Fees;
  SKULevel: SKUDetails[];
}

const getNoteRepayment = async ({
  token,
  noteId,
}: GetNoteRepaymentRequest): Promise<GetNoteRepaymentResponse> => {
  try {
    const response = await axios.get(
      `${BASE_URL}/sku-finance/loan-repayment/structured-note-repayment-detail/${noteId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      }
    );

    return response.data;
  } catch (e) {
    const message = getApiResponseErrorMsg(e);

    throw new Error(message);
  }
};

export default getNoteRepayment;
