import { color } from "@funded-here-interface/common";
import { createStyles } from "@mantine/core";

export const useStyle = createStyles(() => ({
  headerText: {
    height: 46,
    fontSize: 24,
    fontWeight: 700,
  },
  text: {
    fontSize: 16,
    fontWeight: 700,
    marginBottom: "16px",
    color: color.FHADMIN,
  },
  button: {
    marginTop: 15,
    marginBottom: 10,
  },
  header: {
    display: "flex",
    gap: 10,
    alignItems: "start",
  },
}));
