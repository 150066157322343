import { FC, useEffect, useMemo, useState } from "react";
import {
  MRT_ColumnDef,
  MRT_PaginationState,
  MRT_SortingState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import { toast } from "react-toastify";
import { SkuData } from "../../../../services/getNoteSales";
import { formatNumbersWithThousandSeperators } from "@funded-here-interface/common/src/Utils/formatter";
import useGetNoteSales from "../../../../hooks/useGetNoteSales";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";

interface IProps {
  // data: SkuData[] | undefined;
  // error: Error;
  // isLoading: boolean;
  noteId: string;
  onCanCloseNoteChange: CallableFunction;
}

const Sales: FC<IProps> = ({ noteId, onCanCloseNoteChange }) => {
  // const { data, error, isLoading } = props;
  const token = useSelector((state: RootState) => state.auth.token);

  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const { data, error, isLoading } = useGetNoteSales(
    token,
    noteId,
    globalFilter,
    pagination,
    sorting
  );

  // Pass the canNoteBeClosed value to the parent when data is available
  useEffect(() => {
    if (data) {
      onCanCloseNoteChange(data.canNoteBeClosed, data.noteDisplayId);
    }
  }, [data, onCanCloseNoteChange]);

  if (error) {
    toast.error((error as Error).message);
  }

  const columns = useMemo<MRT_ColumnDef<SkuData>[]>(
    () => [
      {
        accessorKey: "merchant",
        header: "Merchant",
      },
      {
        accessorKey: "skuId",
        header: "SKU ID",
      },
      {
        accessorKey: "unitsDelivered",
        header: "Units Delivered",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<number>()
              ? formatNumbersWithThousandSeperators(cell.getValue<number>())
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "unitsSold",
        header: "Units Sold",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<number>()
              ? formatNumbersWithThousandSeperators(cell.getValue<number>())
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "minimumSellingPricePerUnit",
        header: "Minimum Selling Price",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? formatNumbersWithThousandSeperators(
                  parseFloat(cell.getValue<string>())
                )
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "sellingPricePerUnit",
        header: "Selling Price/Unit",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? formatNumbersWithThousandSeperators(
                  parseFloat(cell.getValue<string>())
                )
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "totalFundsInflow",
        header: "Total Funds Inflow",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? formatNumbersWithThousandSeperators(
                  parseFloat(cell.getValue<string>())
                )
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "salesProcced",
        header: "Sales Proceeds",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? formatNumbersWithThousandSeperators(
                  parseFloat(cell.getValue<string>())
                )
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "l2+E",
        header: "L2+E",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? formatNumbersWithThousandSeperators(
                  parseFloat(cell.getValue<string>())
                )
              : "-"}
          </p>
        ),
      },
    ],
    []
  );

  const totalRowCount = data?.pagination?.totalRecords ?? 0;

  const table = useMantineReactTable({
    enableColumnActions: false,
    enableColumnFilters: false,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    // paginationDisplayMode: "pages",
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    state: {
      globalFilter,
      isLoading,
      pagination,
      // showProgressBars: isRefetching,
      sorting,
    },
    columns,
    data: data?.skus ?? [],
    initialState: { density: "xs" },
    rowCount: totalRowCount,
    mantineTableProps: {
      withBorder: true,
    },
  });

  return <MantineReactTable table={table} />;
};

export default Sales;
