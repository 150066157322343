import { InputGroupDisable } from "@funded-here-interface/common";
import { FC } from "react";
import {
  Data,
  OrgDocsUploadAdditionals,
  UserData,
} from "../../../types/org.type";
import { useStyles } from "./PEPSanctions.styles";
import { Flex, Image, Text } from "@mantine/core";
import downloadbutton from "../../../assets/download_24px.svg";
import jsPDF from "jspdf";
import LuminorDetails from "../LuminorDetails/LuminorDetails";

interface SanctionsProps {
  data: Data | undefined;
  userData: UserData[] | undefined;
}

const Sanctions: FC<SanctionsProps> = ({ data, userData }) => {
  const { classes } = useStyles();

  const sanctionstatus = data?.sanctionIoStatus || "-";

  const downloadKYCData = () => {
    const doc = new jsPDF();
    let y = 10;
    doc.setFontSize(16);
    doc.text("Sanction.io data", 105, y, { align: "center" });
    y += 10;
    const jsonDataString = JSON.stringify(data, null, 2);
    const lines = doc.splitTextToSize(jsonDataString, 190);
    doc.setFontSize(10);
    lines.forEach((line: any) => {
      if (y > 280) {
        doc.addPage();
        y = 10;
      }
      doc.text(line, 10, y);
      y += 7;
    });
    doc.save("KYCData.pdf");
  };

  const getNotarisedDoc = (user: UserData) => {
    let updatedData: (OrgDocsUploadAdditionals | undefined)[] = [];
    if (data?.documentsUpload?.notarisedReupload) {
      updatedData = data.documentsUpload.notarisedReupload.filter((doc) => {
        const docId = doc.docName.split("-")[0];

        if (docId === user.id.toString()) {
          return doc;
        }
      });
    }

    return updatedData;
  };

  return (
    <div className={classes.valueContainer}>
      <Text className={classes.headerText}>Sanction.io</Text>
      <InputGroupDisable
        isErrored=""
        labelText="Status"
        id="Name "
        value={
          sanctionstatus === "approved"
            ? "Passed"
            : sanctionstatus === "rejected"
            ? "Failed"
            : sanctionstatus
        }
      />
      {sanctionstatus == "rejected" && (
        <>
          <div className={classes.DocContain}>
            <Text className={classes.DocText}>Download Sanction.Io Data</Text>
            <Image
              onClick={() => downloadKYCData()}
              src={downloadbutton}
              className={classes.downloadIcon}
            />
          </div>
        </>
      )}

      <Text className={classes.headerText}>Luminor ID</Text>
      <Flex gap="md" wrap="wrap">
        {userData?.map((user, index) => (
          <LuminorDetails
            key={index}
            user={user}
            data={getNotarisedDoc(user)}
          />
        ))}
      </Flex>
    </div>
  );
};

export default Sanctions;
