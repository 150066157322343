import { createStyles } from "@mantine/core";

export const useStyle = createStyles(() => ({
  button: {
    display: "flex",
    justifyContent: "center",
  },
  modalContent: {
    padding: "0px 30px",
  },
}));
