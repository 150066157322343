import { FC, useMemo } from "react";
import {
  MRT_ColumnDef,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import { GetAllocateSkuResponse } from "../../services/getIspv";
import { convertDate } from "@funded-here-interface/common/src/Utils/date";

interface IProps {
  data: any;
  isLoading: boolean;
}

const CustomTableForIssuingSPV: FC<IProps> = ({ data, isLoading }) => {
  const columns = useMemo<MRT_ColumnDef<GetAllocateSkuResponse>[]>(
    () => [
      {
        accessorKey: "name",
        header: "SPV Name",
      },
      {
        accessorKey: "createdDate",
        header: "Created Date",
        Cell: ({ cell }) => <p>{convertDate(cell.getValue<string>())}</p>,
      },
      {
        accessorKey: "onBoardingStatus",
        header: "Onboarding",
      },
      {
        accessorKey: "status",
        header: "Status",
      },
      {
        accessorKey: "SNDisplayId",
        header: "Note ID Issued",
      },
      {
        accessorKey: "SNIssueNumberDate",
        header: "Note Issued Date",
        Cell: ({ cell }) => <p>{convertDate(cell.getValue<string>())}</p>,
      },
    ],
    []
  );

  const table = useMantineReactTable({
    enableColumnActions: false,
    enableColumnFilters: false,
    paginationDisplayMode: "pages",
    state: { isLoading },
    columns,
    data: data,
    initialState: { density: "xs" },
    mantineTableProps: {
      withBorder: true,
    },
  });

  return <MantineReactTable table={table} />;
};

export default CustomTableForIssuingSPV;
