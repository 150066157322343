import axios from "axios";
import { BASE_URL } from "@funded-here-interface/common/src/constant/constant";
import { getApiResponseErrorMsg } from "@funded-here-interface/common/src/Utils/apiHelper";
import { UserType } from "../types/login.type";

export type NoteData = {
  id: string;
  createdAt: string;
  totalSKU: string;
  ispv: string;
  totalMerchants: string;
  status: string;
  value: string;
};
const getNotelist = async ({ token }: UserType): Promise<NoteData[]> => {
  try {
    const response = await axios.get(
      `${BASE_URL}/sku-finance/loan-repayment/structured-note-list`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      }
    );

    return response.data;
  } catch (e) {
    const message = getApiResponseErrorMsg(e);
    throw new Error(message);
  }
};

export default getNotelist;
