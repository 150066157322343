import axios from "axios";
import { BASE_URL } from "@funded-here-interface/common/src/constant/constant";
import { getApiResponseErrorMsg } from "@funded-here-interface/common/src/Utils/apiHelper";

interface GetPendingSkuRequest {
  token: string;
  orgRoleId?: number;
}

export interface GetPendingSkuResponse {
  upc: string;
  description: string;
  email: string;
  orgRoleId: number;
}

const getPendingSku = async ({
  token,
  orgRoleId,
}: GetPendingSkuRequest): Promise<GetPendingSkuResponse[]> => {
  try {
    const params: Record<string, any> = {};

    if (orgRoleId) {
      params.orgRoleId = orgRoleId;
    }

    const response = await axios.get(
      `${BASE_URL}/sku-finance/loan-application/pending-sku-loan-applications`,
      {
        params,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      }
    );

    return response.data;
  } catch (e) {
    const message = getApiResponseErrorMsg(e);
    throw new Error(message);
  }
};
export default getPendingSku;
