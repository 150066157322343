import axios from "axios";
import { BASE_URL } from "@funded-here-interface/common/src/constant/constant";
import { getApiResponseErrorMsg } from "@funded-here-interface/common/src/Utils/apiHelper";

export interface DataRow {
  skuId: string;
  upc: string;
  merchantOrgName: string;
  paymentTerm: string;
  quantityPurchased: number;
  deliveredQuanty: number;
  deliveredQuantityshortfall: number;
  loanApplicationCurrency: string;
  nettInvoiceAmount: string;
  distributorOrgName: string;
  distributorInvoiceUploadedDate: Date;
  distributorUploadedInvoiceId: number;
}

export interface GetPmVerifySkuLoanApplicationsResponse {
  [key: string]: DataRow[];
}

interface GetPmVerifySkuLoanApplicationsRequest {
  token: string;
}

const getPmVerifySkuLoanApplications = async ({
  token,
}: GetPmVerifySkuLoanApplicationsRequest): Promise<GetPmVerifySkuLoanApplicationsResponse> => {
  try {
    const response = await axios.get(
      `${BASE_URL}/sku-finance/loan-disbursement/pm-verify-sku-loan-applications`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      }
    );

    return response.data;
  } catch (error) {
    const message = getApiResponseErrorMsg(error);

    throw new Error(message);
  }
};

export default getPmVerifySkuLoanApplications;
