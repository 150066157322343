/* eslint-disable react-hooks/exhaustive-deps */
import { Select, Text, TextInput } from "@mantine/core";
import { useStyles } from "./OrgDetails.styles";
import { FC, useState, useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { Button, color } from "@funded-here-interface/common";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Data, Director, UserData } from "../../types/org.type";
import {
  haveDirectors,
  kycStatuses,
  merchants,
} from "@funded-here-interface/common/src/constant/constant";
import Organisation from "../KycTabs/Organization/Organisation";
import AuthRep from "../KycTabs/AuthRep/AuthRep";
import ShareHolder from "../KycTabs/Shareholder/ShareHolder";
import BankDetails from "../KycTabs/BankDetails/BankDetails";
import Documents from "../KycTabs/Documents/Documents";
import Sanctions from "../KycTabs/PEPSanctions/PEP-Sanctions";
import {
  EntityTypeEnum,
  KYCStatus,
  Persona,
} from "@funded-here-interface/common/src/constant/enum";
import useGetOrgMutation from "../../hooks/useGetOrg";
import useUpdateKyc from "../../hooks/useUpdateKyc";
import useInviteUser from "../../hooks/useInviteUserToOrg";
import useFundingLimit from "../../hooks/useFundingLimit";
import FundLimit from "../KycTabs/FundLimit/FundLimit";
import Agreements from "../KycTabs/Agreements/Agreements";
import { riskValues } from "../../constant/risk";
import { Risk } from "../../constant/enum";
import useOrganizationRisk from "../../hooks/useOrganizationRisk";
import useUpdateSpvKyc from "../../hooks/useUpdateSpvKyc";

interface IProps {
  close: () => void;
  id: number;
  role: Persona;
  refetchList: () => void;
}

const OrgDetails: FC<IProps> = ({ close, id, role, refetchList }) => {
  const [tab, setTab] = useState(1);
  const [data, setdata] = useState<Data>();
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState<UserData>();
  const [agreedToMembership, setAgreedToMembership] = useState<boolean>(false);
  const [agreedToSpvMembership, setAgreedToSpvMembership] =
    useState<boolean>(false);
  const [allUsersData, setAllUsersData] = useState<UserData[]>();
  const token = useSelector((state: RootState) => state.auth.token);
  const getOrgMutation = useGetOrgMutation();
  const updateKycMutation = useUpdateKyc();
  const useUpdateSpvKycMutation = useUpdateSpvKyc();
  const useInviteUserMutation = useInviteUser();
  const useFundingLimitMutaiton = useFundingLimit();
  const useOrganizationRiskMutation = useOrganizationRisk();
  const { classes } = useStyles();
  const isSpvOrg = role === Persona.MERCHANT_SPV;
  const [bmcaSigning, setBmcaSigning] = useState(0);
  const [selectedValue, setSelectedValue] = useState<string>(
    data?.kycStatus || ""
  );
  const [selectedSpvKyc, setSelectedSpvKyc] = useState<string>(
    data?.spv?.kycStatus || ""
  );
  const [selectedRisk, setSelectedRisk] = useState<Risk | undefined>();
  const [disableSaveBtn, setDisableSaveBtn] = useState(false);

  useEffect(() => {
    if (data?.kycStatus) {
      setSelectedValue(data.kycStatus);
    }

    if (data?.spv?.kycStatus) {
      setSelectedSpvKyc(data?.spv?.kycStatus);
    }
  }, [data?.kycStatus, data?.spv?.kycStatus]);

  const handleSelectChange = (value: string) => {
    setSelectedValue(value);
  };

  const handleSpvKycSelectChange = (value: string) => {
    setSelectedSpvKyc(value);
  };

  const handleRiskSelect = (risk: Risk) => {
    setSelectedRisk(risk);

    useOrganizationRiskMutation.mutate(
      { token, risk, orgId: data?.id! },
      {
        onError: (e) => {
          toast.error((e as Error).message);
        },
      }
    );
  };

  useEffect(() => {
    getOrgMutation.mutate(
      {
        token,
        id: `${id}`,
      },
      {
        onSuccess: (data) => {
          setDisableSaveBtn(false);

          if (data.signDocument) {
            const signDoc = data.signDocument.filter(
              (e: any) => e.documentName === "Master SKU Agreement"
            );
            if (signDoc.length !== 0) {
              for (const signer of signDoc[0].personSigning) {
                if (signer.status === "pending") {
                  data.documentsUpload?.signedDocuments.push({
                    docName: "Master SKU Agreement",
                    path: "",
                  });
                  setBmcaSigning(id);
                  setDisableSaveBtn(true);

                  break;
                }
              }
            }
          }

          setdata(data);
          setSelectedRisk(data?.risk);
          setAllUsersData(data.user);

          if (data?.user?.length > 1) {
            for (const user of data.user) {
              if (user?.orgRole?.[0]?.role?.name === Persona.INVESTOR) {
                if (user?.agreedToMembershipAgreement === true) {
                  setAgreedToMembership(true);
                }

                const directorEmails = data?.boardOfDirectors.map(
                  (user: Director) => user.email
                );

                if (!directorEmails.includes(user?.email)) {
                  if (data?.authRepIsMerchant) {
                    setUserData(user);
                  } else {
                    if (
                      data?.emailOfAuthorisedRepresentiative !== user?.email
                    ) {
                      setUserData(user);
                    }
                  }
                }
              } else {
                if (user?.agreedToMembershipAgreement === true) {
                  setAgreedToMembership(true);
                }

                if (user?.agreedToSpvMembershipAgreement === true) {
                  setAgreedToSpvMembership(true);
                }

                if (user?.orgRole?.[0]?.creditLimit) {
                  setUserData(user);
                }
              }
            }
          } else {
            setUserData(data.user[0]);
            setAgreedToMembership(
              data.user[0]?.agreedToMembershipAgreement ?? false
            );
          }
        },
        onError: (e) => {
          toast.error((e as Error).message);
        },
      }
    );
  }, []);
  const [fundingLimit, setFundingLimit] = useState<string>("");

  const inviteShareholder = async (onBoardingUserType: Persona) => {
    useInviteUserMutation.mutate(
      {
        token,
        orgId: id,
        onBoardingUserType,
        isSpvOrg,
      },
      {
        onSuccess: () => {
          setIsLoading(false);
          refetchList();
          close();
        },
        onError: (e) => {
          setIsLoading(false);
          toast.error((e as Error).message);
        },
      }
    );
  };

  const handleMerchant = async (orgRoleId: number) => {
    if (fundingLimit || selectedValue === KYCStatus.APPROVED) {
      if (selectedValue !== KYCStatus.APPROVED) {
        toast.error("Must approve kyc to set funding limit");

        return;
      }

      if (!fundingLimit || fundingLimit === "0") {
        toast.error("Must set funding limit when approving KYC");

        return;
      }

      setIsLoading(true);

      useFundingLimitMutaiton.mutate(
        {
          token,
          orgRoleId: orgRoleId as number,
          creditLimit: fundingLimit,
        },
        {
          onSuccess: () => {
            updateKycMutation.mutate(
              {
                orgRoleId,
                token,
                kycStatus: selectedValue,
              },
              {
                onSuccess: () => {
                  inviteShareholder(Persona.MERCHANT);
                },
                onError: (e) => {
                  setIsLoading(false);
                  toast.error((e as Error).message);
                },
              }
            );

            return;
          },
          onError: (e) => {
            setIsLoading(false);
            toast.error((e as Error).message);
          },
        }
      );
    } else {
      updateKyc(orgRoleId);

      return;
    }
  };

  const updateKyc = async (orgRoleId: number) => {
    setIsLoading(true);

    updateKycMutation.mutate(
      {
        orgRoleId: orgRoleId as number,
        token,
        kycStatus: selectedValue as KYCStatus,
      },
      {
        onSuccess: () => {
          if (
            userData?.entityType === EntityTypeEnum.INSTITUTION &&
            userData?.pendingOnBoardingAs === Persona.INVESTOR &&
            selectedValue === KYCStatus.APPROVED
          ) {
            inviteShareholder(Persona.INVESTOR);
          } else {
            setIsLoading(false);
            refetchList();
            close();
          }
        },
        onError: (e) => {
          setIsLoading(false);
          toast.error((e as Error).message);
        },
      }
    );
  };

  const updateSpvKyc = async (orgRoleId: number) => {
    setIsLoading(true);

    useUpdateSpvKycMutation.mutate(
      {
        orgRoleId,
        token,
        kycStatus: selectedSpvKyc as KYCStatus,
      },
      {
        onSuccess: () => {
          setIsLoading(false);
          refetchList();
          close();
        },
        onError: (e) => {
          setIsLoading(false);
          toast.error((e as Error).message);
        },
      }
    );
  };

  const handleSave = async () => {
    const orgRoleId = userData?.orgRole?.[0]?.id;

    if (
      data?.spv &&
      role === Persona.MERCHANT_SPV &&
      selectedSpvKyc !== KYCStatus.PENDING &&
      selectedSpvKyc !== data?.spv?.kycStatus
    ) {
      updateSpvKyc(orgRoleId as number);

      return;
    }

    if (
      selectedValue === KYCStatus.PENDING ||
      selectedValue === data?.kycStatus
    ) {
      close();

      return;
    }

    if (merchants.includes(role)) {
      if (
        userData?.email === data?.emailOfAuthorisedRepresentiative &&
        selectedValue === KYCStatus.APPROVED
      ) {
        if (!userData?.didRegistrationId) {
          toast.error("User has not setup DID");

          return;
        }
      }

      handleMerchant(orgRoleId as number);

      return;
    }

    if (
      selectedValue === KYCStatus.APPROVED &&
      role !== Persona.DISTRIBUTOR &&
      role !== Persona.ISPV &&
      userData?.entityType !== EntityTypeEnum.INSTITUTION
    ) {
      if (!userData?.didRegistrationId) {
        toast.error("User has not setup DID");

        return;
      }
    }

    if (
      userData?.entityType === EntityTypeEnum.INSTITUTION &&
      userData?.email === data?.emailOfAuthorisedRepresentiative &&
      selectedValue === KYCStatus.APPROVED
    ) {
      if (!userData?.didRegistrationId) {
        toast.error("User has not setup DID");

        return;
      }
    }

    updateKyc(orgRoleId as number);
  };

  const signedDocuments = data?.documentsUpload?.signedDocuments
    ? data.documentsUpload.signedDocuments.filter((doc) => {
        if (doc.docName === "membership_agreement" && !agreedToMembership) {
          return false;
        }

        if (
          doc.docName === "spv_membership_agreement" &&
          !agreedToSpvMembership
        ) {
          return false;
        }
        return true;
      })
    : [];

  return (
    <div className={classes.OuterContainer}>
      <div className={classes.innerContainer}>
        <ToastContainer />

        <AiOutlineClose
          onClick={() => {
            close();
            setSelectedValue("");
          }}
          className={classes.closeIcon}
        />

        <Text className={classes.heading}>Org KYC Details</Text>
        <div className={classes.SubHeadingOutterContainer}>
          {merchants.includes(role) && (
            <div className={classes.subHeadingContainer}>
              <Text className={classes.subHeadingText}>Funding Limit</Text>
              <TextInput
                size="md"
                className={classes.commentInputForFund}
                value={fundingLimit}
                placeholder={
                  userData?.orgRole?.[0]?.creditLimit
                    ? `${userData.orgRole[0].creditLimit}`
                    : "0"
                }
                onChange={(e) => setFundingLimit(e.target.value)}
              />
            </div>
          )}
          <div className={classes.subHeadingContainer}>
            <Text className={classes.subHeadingText}>KYC</Text>
            <Select
              className={classes.KycSelect}
              data={kycStatuses}
              value={selectedValue}
              onChange={handleSelectChange}
              size="md"
            />
          </div>
          {data?.spv && (
            <div className={classes.subHeadingContainer}>
              <Text className={classes.subHeadingText}>SPV KYC</Text>
              <Select
                className={classes.KycSelect}
                data={kycStatuses}
                value={selectedSpvKyc}
                onChange={handleSpvKycSelectChange}
                size="md"
              />
            </div>
          )}
          <div className={classes.subHeadingContainer}>
            <Text className={classes.subHeadingText}>Customer Risk</Text>
            <Select
              className={classes.KycSelect}
              data={riskValues}
              value={selectedRisk}
              placeholder="Select Risk"
              onChange={handleRiskSelect}
              size="md"
            />
          </div>
        </div>
        <div>
          <ul className={classes.listContainer}>
            <li
              onClick={() => setTab(1)}
              className={`${classes.listText} ${
                tab === 1 && classes.stepActive
              }`}
            >
              Organisation
            </li>
            <li
              onClick={() => setTab(2)}
              className={`${classes.listText} ${
                tab === 2 && classes.stepActive
              }`}
            >
              Auth Rep/Admin
            </li>
            {haveDirectors.includes(role) && (
              <li
                onClick={() => setTab(3)}
                className={`${classes.listText} ${
                  tab === 3 && classes.stepActive
                }`}
              >
                Directors
              </li>
            )}

            <li
              onClick={() => setTab(4)}
              className={`${classes.listText} ${
                tab === 4 && classes.stepActive
              }`}
            >
              Bank Details
            </li>
            {role !== Persona.DISTRIBUTOR && (
              <li
                onClick={() => setTab(5)}
                className={`${classes.listText} ${
                  tab === 5 && classes.stepActive
                }`}
              >
                Documents
              </li>
            )}
            {merchants.includes(role) && (
              <li
                onClick={() => setTab(6)}
                className={`${classes.listText} ${
                  tab === 6 && classes.stepActive
                }`}
              >
                Funding Limit
              </li>
            )}
            {
              <li
                onClick={() => setTab(7)}
                className={`${classes.listText} ${
                  tab === 7 && classes.stepActive
                }`}
              >
                Luminor ID/Sanctions
              </li>
            }
            {signedDocuments.length > 0 && (
              <li
                onClick={() => setTab(8)}
                className={`${classes.listText} ${
                  tab === 8 && classes.stepActive
                }`}
              >
                Agreements
              </li>
            )}
          </ul>
          <div className={classes.contentContainer}>
            {tab === 1 && (
              <Organisation
                data={data}
                userData={userData}
                role={role}
                spvData={data?.spv}
              />
            )}
            {tab === 2 && (
              <AuthRep
                data={data}
                userData={userData}
                role={role}
                spvData={data?.spv}
              />
            )}
            {tab === 3 && <ShareHolder data={data} spvData={data?.spv} />}
            {tab === 4 && <BankDetails userData={userData} />}
            {tab === 5 && <Documents data={data} userData={userData} />}
            {tab === 6 && <FundLimit data={data} />}
            {tab === 7 && <Sanctions data={data} userData={allUsersData} />}
            {signedDocuments.length > 0 && tab === 8 && (
              <Agreements
                bmcaSigning={bmcaSigning}
                signedDocuments={signedDocuments}
              />
            )}
          </div>

          <div className={classes.buttonWrapper}>
            <Button
              disabled={isLoading || disableSaveBtn}
              onClick={handleSave}
              backgroundColor={color.FHADMIN}
              textColor={color.WHITE}
              width="80%"
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrgDetails;
