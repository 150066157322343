import axios from "axios";
import { BASE_URL } from "@funded-here-interface/common/src/constant/constant";
import { getApiResponseErrorMsg } from "@funded-here-interface/common/src/Utils/apiHelper";

export interface AmtTransferResult {
  merchantOrgName: string;
  merchantOrgRoleId: number;
  amountToTransfer: number;
  currency: string;
  structuredNoteId: number;
}

export interface SendAmtTransferResult {
  token: string;
  merchantTransfer: AmtTransferResult[];
}

export const sendTransferFundToJVA = async ({
  token,
  merchantTransfer,
}: SendAmtTransferResult) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/payment/ledger/transfer-fund-to-fh-junior-investor-va`,
      {
        merchantTransfer: merchantTransfer,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      }
    );
    return response.data;
  } catch (e) {
    const message = getApiResponseErrorMsg(e);
    throw new Error(message);
  }
};
