import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import { FC, useEffect } from "react";
import { DataRow } from "../../../../services/getPmVerifySkuLoanApplications";
import downloadbutton from "../../../../assets/download_24px.svg";
import { useStyles } from "./VerifyInvoiceMantineTable.styles";
import { Image, Text } from "@mantine/core";

interface IProps {
  invoiceId: string;
  columns: MRT_ColumnDef<DataRow>[];
  data: DataRow[];
  onDownloadInvoice: (invoiceId: string) => void;
  isLoading: boolean;
  onUpdateSelectedIds: (tableId: string, selectedIds: string[]) => void;
  rowSelection: MRT_RowSelectionState;
  setRowSelection: React.Dispatch<React.SetStateAction<MRT_RowSelectionState>>;
}

const VerifyInvoiceMantineTable: FC<IProps> = (props) => {
  const {
    invoiceId,
    columns,
    data,
    isLoading,
    onDownloadInvoice,
    onUpdateSelectedIds,
    rowSelection,
    setRowSelection,
  } = props;
  const { classes } = useStyles();

  useEffect(() => {
    const selectedIds = Object.keys(rowSelection)
      .filter((key) => rowSelection[key])
      .map(String);

    onUpdateSelectedIds(invoiceId, selectedIds);
  }, [rowSelection]);

  const table = useMantineReactTable({
    enableColumnActions: false,
    enableColumnFilters: false,
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    getRowId: (row) => row?.skuId,
    paginationDisplayMode: "pages",
    initialState: { density: "xs" },
    state: { rowSelection, isLoading },
    columns,
    data,
    mantineTableProps: {
      withBorder: true,
    },
  });

  return (
    <>
      <div className={classes.DocContain}>
        <Text className={classes.DocText}>
          {`Invoice Id: ${invoiceId}` ?? "-"}
        </Text>
        <Image
          onClick={() => onDownloadInvoice(invoiceId)}
          src={downloadbutton}
          className={classes.downloadIcon}
        />
      </div>

      <MantineReactTable table={table} />
    </>
  );
};

export default VerifyInvoiceMantineTable;
