import React, { useEffect, useState } from "react";
import v from "../Images/Capture1.PNG";
import v1 from "../Images/Capture.PNG";
import HomeServices from "../../../services/HomeServices";
import { useSelector } from "react-redux";

function Investment() {
  const [allOverView, setAllOverView] = useState([]);
  const token = useSelector((state) => state.auth.token);

  const fetchAllOverViewData = async () => {
    const res = await HomeServices.allInvestorOverView(token);
    setAllOverView(res?.data);
  };

  useEffect(() => {
    fetchAllOverViewData();
  }, []);

  return (
    <>
      <div className="graphData">
        <div className="graphAmtDiv">
          <div className="graphInner">
            {allOverView?.map((item, index) => {
              return (
                <>
                  <div className="graphAmtDivInn" key={index}>
                    <h5 className="graphPoint">Commited Amount</h5>
                    {/* <p className="graphNum">{item?.totalCommitedAmount}</p> */}
                    <p className="graphNum">
                      {parseFloat(item?.totalCommitedAmount).toLocaleString(
                        "en-US",
                        { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                      )}
                    </p>
                  </div>

                  <div className="graphAmtDivInn">
                    <h5 className="graphPoint">Total Investment in SN</h5>
                    {/* <p className="graphNum">{item?.totalInvestedAmount}</p> */}
                    <p className="graphNum">
                      {parseFloat(item?.totalInvestedAmount).toLocaleString(
                        "en-US",
                        { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                      )}
                    </p>
                  </div>

                  <div className="graphAmtDivInn">
                    <h5 className="graphPoint">
                      Amount Available For Investment
                    </h5>
                    {/* <p className="graphNum">{item?.amountForInvestment}</p> */}
                    <p className="graphNum">
                      {parseFloat(
                        item?.amountAvailableForInvestment
                      ).toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </p>
                  </div>
                </>
              );
            })}
          </div>
          <figure className="graphamtFig">
            <img src={v} alt="img" />
          </figure>
        </div>

        <div className="graphAmtDiv">
          <div className="graphInner">
            {allOverView.map((details, index) => {
              return (
                <>
                  <div className="graphAmtDivInn" key={index}>
                    <h5 className="graphPoint">Investors Count</h5>
                    <p className="graphNum">{details?.investorUserCount}</p>
                  </div>

                  <div className="graphAmtDivInn" key={index}>
                    <h5 className="graphPoint">Invested Structure Note</h5>
                    <p className="graphNum">{details?.investedNoteCount}</p>
                  </div>
                </>
              );
            })}
          </div>
          <figure className="graphamtFig">
            <img src={v1} alt="img" />
          </figure>
        </div>
      </div>
    </>
  );
}

export default Investment;
