import { BASE_URL } from "@funded-here-interface/common/src/constant/constant";
import axios from "axios";
import { getApiResponseErrorMsg } from "@funded-here-interface/common/src/Utils/apiHelper";
import { store } from "../store";
import { resetAuthState } from "../features/auth/authSlice";

export type LogoutRequest = {
  token: string;
};

const logout = async ({ token }: LogoutRequest) => {
  try {
    await axios.post(
      `${BASE_URL}/trade-directory/auth/logout`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    store.dispatch(resetAuthState());
  } catch (e) {
    const message = getApiResponseErrorMsg(e);

    throw new Error(message);
  }
};

export default logout;
