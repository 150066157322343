/* eslint-disable react-hooks/exhaustive-deps */
import { Select, Text } from "@mantine/core";
import { useStyles } from "./IndividualDetails.styles";
import { FC, useState, useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { Button, color } from "@funded-here-interface/common";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Data, UserData } from "../../types/org.type";
import { kycStatuses } from "@funded-here-interface/common/src/constant/constant";
import BankDetails from "../KycTabs/BankDetails/BankDetails";
import Documents from "../KycTabs/Documents/Documents";
import Sanctions from "../KycTabs/PEPSanctions/PEP-Sanctions";
import { KYCStatus } from "@funded-here-interface/common/src/constant/enum";
import Individual from "../KycTabs/Individual/Individual";
import useGetOrgMutation from "../../hooks/useGetOrg";
import useUpdateKyc from "../../hooks/useUpdateKyc";
import Agreements from "../KycTabs/Agreements/Agreements";
import useOrganizationRisk from "../../hooks/useOrganizationRisk";
import { Risk } from "../../constant/enum";
import { riskValues } from "../../constant/risk";

interface IProps {
  close: () => void;
  id: number;
  refetchList: () => void;
}

const IndividualDetails: FC<IProps> = ({ close, id, refetchList }) => {
  const [tab, setTab] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setdata] = useState<Data>();
  const [userData, setUserData] = useState<UserData>();
  const [allUsersData, setAllUsersData] = useState<UserData[]>();
  const token = useSelector((state: RootState) => state.auth.token);
  const getOrgMutation = useGetOrgMutation();
  const udpdateKycMutation = useUpdateKyc();
  const useOrganizationRiskMutation = useOrganizationRisk();
  const [selectedRisk, setSelectedRisk] = useState<Risk | undefined>();

  const { classes } = useStyles();

  const [selectedValue, setSelectedValue] = useState<string>(
    data?.kycStatus || KYCStatus.PENDING
  );

  useEffect(() => {
    if (data?.kycStatus) {
      setSelectedValue(data.kycStatus);
    }
  }, [data?.kycStatus]);

  const handleSelectChange = (value: string) => {
    setSelectedValue(value);
  };

  useEffect(() => {
    getOrgMutation.mutate(
      {
        token,
        id: `${id}`,
      },
      {
        onSuccess: (data) => {
          setdata(data);
          setAllUsersData(data.user);
          setUserData(data.user[0]);
          setSelectedRisk(data?.risk);
        },
        onError: (e) => {
          toast.error((e as Error).message);
        },
      }
    );
  }, []);

  const handleRiskSelect = (risk: Risk) => {
    setSelectedRisk(risk);

    useOrganizationRiskMutation.mutate(
      { token, risk, orgId: data?.id! },
      {
        onError: (e) => {
          toast.error((e as Error).message);
        },
      }
    );
  };

  const handleNext = async () => {
    if (selectedValue === KYCStatus.APPROVED) {
      if (!userData?.didRegistrationId) {
        toast.error("User has not setup DID");

        return;
      }

      if (!userData?.declarationAISubmitted) {
        toast.error("Declaration of AI has not been signed");

        return;
      }
    }

    if (
      selectedValue === KYCStatus.PENDING ||
      selectedValue === data?.kycStatus
    ) {
      close();

      return;
    }

    const orgRoleId = userData?.orgRole?.[0]?.id;

    setIsLoading(true);
    udpdateKycMutation.mutate(
      {
        orgRoleId: orgRoleId as number,
        token,
        kycStatus: selectedValue as KYCStatus,
      },
      {
        onSuccess: () => {
          setIsLoading(false);
          refetchList();
          close();
        },
        onError: (e) => {
          setIsLoading(false);
          toast.error((e as Error).message);
        },
      }
    );
  };

  const signedDocuments = data?.documentsUpload?.signedDocuments
    ? data?.documentsUpload?.signedDocuments.filter(
        (doc) =>
          !(
            doc.docName === "membership_agreement" &&
            !userData?.agreedToMembershipAgreement
          )
      )
    : [];

  return (
    <div className={classes.OuterContainer}>
      <div className={classes.innerContainer}>
        <ToastContainer />

        <AiOutlineClose
          onClick={() => {
            close();
            setSelectedValue("");
          }}
          className={classes.closeIcon}
        />

        <Text className={classes.heading}>Individual KYC Details</Text>
        <div className={classes.SubHeadingOutterContainer}>
          <div className={classes.subHeadingContainer}>
            <Text className={classes.subHeadingText}>KYC</Text>
            <Select
              className={classes.KycSelect}
              data={kycStatuses}
              value={selectedValue}
              onChange={handleSelectChange}
              size="md"
            />
          </div>
          <div className={classes.subHeadingContainer}>
            <Text className={classes.subHeadingText}>Customer Risk</Text>
            <Select
              className={classes.KycSelect}
              data={riskValues}
              value={selectedRisk}
              placeholder="Select Risk"
              onChange={handleRiskSelect}
              size="md"
            />
          </div>
        </div>
        <div>
          <ul className={classes.listContainer}>
            <li
              onClick={() => setTab(1)}
              className={`${classes.listText} ${
                tab === 1 && classes.stepActive
              }`}
            >
              Individual
            </li>

            <li
              onClick={() => setTab(2)}
              className={`${classes.listText} ${
                tab === 2 && classes.stepActive
              }`}
            >
              Bank Details
            </li>
            <li
              onClick={() => setTab(3)}
              className={`${classes.listText} ${
                tab === 3 && classes.stepActive
              }`}
            >
              Documents
            </li>
            <li
              onClick={() => setTab(4)}
              className={`${classes.listText} ${
                tab === 4 && classes.stepActive
              }`}
            >
              Luminor ID/Sanctions
            </li>
            {signedDocuments.length > 0 && (
              <li
                onClick={() => setTab(5)}
                className={`${classes.listText} ${
                  tab === 5 && classes.stepActive
                }`}
              >
                Agreements
              </li>
            )}
          </ul>
          <div className={classes.contentContainer}>
            {tab === 1 && <Individual data={data} userData={userData} />}
            {tab === 2 && <BankDetails userData={userData} />}
            {tab === 3 && <Documents data={data} userData={userData} />}
            {tab === 4 && <Sanctions data={data} userData={allUsersData} />}
            {signedDocuments.length > 0 && tab === 5 && (
              <Agreements signedDocuments={signedDocuments} />
            )}
          </div>
          <div className={classes.buttonWrapper}>
            <Button
              disabled={isLoading}
              onClick={handleNext}
              backgroundColor={color.FHADMIN}
              textColor={color.WHITE}
              width="80%"
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndividualDetails;
