import { createSlice } from "@reduxjs/toolkit";

const repSlice = createSlice({
  name: "rep",
  initialState: {
    repName: null,
    repTitle: null,
    shareholders: [],
    directors: [],
  },
  reducers: {
    setRepName: (state, { payload: { repName } }) => {
      state.repName = repName;
    },
    setRepTitle: (state, { payload: { repTitle } }) => {
      state.repTitle = repTitle;
    },
    setShareholderRedux: (state, { payload: { shareholders } }) => {
      state.shareholders = shareholders;
    },
    setDirectorsRedux: (state, { payload: { directors } }) => {
      state.directors = directors;
    },
  },
});

export const {
  setRepName,
  setRepTitle,
  setShareholderRedux,
  setDirectorsRedux,
} = repSlice.actions;

export default repSlice.reducer;
