import { FC, ReactNode } from "react";
import { useStyles } from "./loginlayout.styles";
import { ToastContainer } from "react-toastify";

type Props = {
  content: ReactNode;
  boxStructure?: boolean;
};

const LoginLayout: FC<Props> = ({ content, boxStructure }) => {
  const { classes } = useStyles();
  const Content = content;
  const BoxStructure = boxStructure;

  return (
    <div>
      <ToastContainer />
      <div className={classes.container}>
        <div className={classes.leftContainer}>
          <div
            className={`${BoxStructure} ? ${classes.pageContainer} : ${classes.pageContainer2}`}
          >
            {Content}
          </div>
        </div>
        <div className="rightContainer">
          <img
            src="https://media.istockphoto.com/id/1305268276/vector/registration-abstract-concept-vector-illustration.jpg?s=612x612&w=0&k=20&c=nfvUbHjcNDVIPdWkaxGx0z0WZaAEuBK9SyG-aIqg2-0="
            className="BackImage"
            style={{ minHeight: "100vh" }}
          />
        </div>
      </div>
    </div>
  );
};

export default LoginLayout;
