import { createStyles } from "@mantine/core";

export const useStyles = createStyles(() => ({
  DocContain: {
    display: "flex",
    marginBottom: "20px",
  },
  DocText: {
    fontSize: "21px",
    marginRight: "20px",
  },
  downloadIcon: {
    width: "20px !important",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
  },
}));
