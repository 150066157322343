import { MantineTheme, createStyles } from "@mantine/core";

export const useStyles = createStyles((theme: MantineTheme) => ({
  OuterContainer: {
    backgroundColor: "#D9DCE1",
    paddingTop: "20px",
    paddingBottom: "20px",
  },
  innerContainer: {
    paddingRight: "82px",
    paddingLeft: "82px",
  },
  SubHeadingOutterContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "30px",
    marginBottom: "20px",
  },
  subHeadingContainer: {
    display: "flex",
    alignItems: "center",
  },
  closeIcon: {
    position: "absolute",
    right: 26,
    fontSize: "24px",
    cursor: "pointer",
  },
  heading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "20px",
    fontWeight: 700,
  },
  subHeadingText: {
    marginLeft: "25px",
  },
  KycSelect: {
    width: "145px",
    marginLeft: "15px",
  },
  commentInput: {
    width: "301px",
    marginLeft: "10px",
    padding: "0px",
  },
  commentInputForFund: {
    width: "150px",
    marginLeft: "5px",
    marginRight: "15px",
    padding: "0px",
  },
  listContainer: {
    listStyleType: "none",
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: "0px",
    marginBottom: "0px",
  },
  listText: {
    textAlign: "center",
    width: "100%",
    height: "43px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  stepActive: {
    backgroundColor: "white",
    borderTopRightRadius: "10px",
    borderTopLeftRadius: "10px",
    fontWeight: 700,
  },
  contentContainer: {
    // width: "100%",
    // height: "602px",
    backgroundColor: "#fff",
    overflowX: "auto",
  },
  buttonWrapper: {
    paddingRight: "00px",
    width: "221px",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "10px",
    marginBottom: "20px",
    paddingBottom: "20px",
  },
  inviteButton: {
    width: "221px",
    marginLeft: "auto",
    marginTop: "-50px",
  },
}));
