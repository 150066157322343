import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import toast from "react-hot-toast";
import HomeServices from "../../../../services/HomeServices";
import { useSelector } from "react-redux";

const TopSKUChart = ({ selectedMerchant }) => {
  const [chartHeight, setChartHeight] = useState(0);
  const token = useSelector((state) => state.auth.token);
  const [chartData, setChartData] = useState({
    series: [],
    options: {
      chart: {
        width: "100%",
        height: "100%",
        type: "bar",
        stacked: true,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: true,
          resetIcon: {
            offsetX: -10,
            offsetY: 0,
            fillColor: "#fff",
            strokeColor: "#37474F",
          },
          selection: {
            background: "#90CAF9",
            border: "#0D47A1",
          },
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: "75%",
        },
      },
      dataLabels: {
        style: {
          colors: ["#151515"],
          fontSize: "8px",
          fontWeight: "600",
        },
        formatter: (val) => {
          return val?.toLocaleString("en-US");
        },
      },
      colors: ["#92bbac", "#f0e199"],
      states: {
        hover: {
          filter: {
            type: "none",
          },
        },
      },
      title: {
        text: "Top SKUs with Highest Potential Default (in %)",
        style: {
          fontSize: "13px",
          colors: "#595959",
        },
      },
      xaxis: {
        show: true,
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        categories: [],
      },
      fill: {
        opacity: 1,
      },
      legend: {
        position: "top",
        fontSize: "10px",
        fontWeight: "500",
        horizontalAlign: "left",
        offsetY: -10,

        markers: {
          shape: "circle",
          width: "8px",
          height: "8px",
          borderRadius: "50%",
        },
      },
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
        padding: {
          top: -22,
          bottom: -15,
        },
      },

      tooltip: {
        enabled: true,
        followCursor: true,
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          let tooltipContent = "";
          const skuName = w.globals.labels[dataPointIndex];
          const def = series[0][dataPointIndex];

          tooltipContent =
            '<div class="arrow_box">' +
            '<span class="headspan">' +
            "SKU : " +
            "</span>" +
            '<span class="paraspan">' +
            skuName +
            "</span>" +
            "</div>";

          tooltipContent +=
            '<div class="arrow_box">' +
            '<span class="headspan">' +
            "Default (in %): " +
            "</span>" +
            '<span class="paraspan">' +
            def +
            "</span>" +
            "</div>";
          return tooltipContent;
        },
        y: {
          formatter: function (val) {
            return val?.toLocaleString("en-US");
          },
        },
      },
    },
  });

  const fetchAmountDueChartData = async () => {
    try {
      const res = await HomeServices.highestPotentialSku(
        selectedMerchant.value,
        token
      );
      if (res && res.status) {
        let data = res.data.sort((a, b) => b.default - a.default);
        const names = data.map((item) => item.skuName);
        const def = data.map((item) => item.default);
        const chartHeight = Math.max(def.length * 50, 150);
        setChartHeight(chartHeight);

        setChartData({
          ...chartData,
          options: {
            ...chartData.options,
            xaxis: {
              ...chartData.options.xaxis,
              categories: names,
            },
          },
          series: [
            {
              name: "Default (in %)",
              data: def,
            },
            // {
            //   name: "Repaid Amount",
            //   data: repaidAmounts,
            // },
          ],
        });
      } else {
        toast.error("Unable to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("An error occurred while fetching data");
    }
  };

  useEffect(() => {
    fetchAmountDueChartData(selectedMerchant.value);
  }, [selectedMerchant]);

  return (
    <div className="MerAmtDueChart" style={{ backgroundColor: "#fff" }}>
      {chartData.series.length > 0 ? (
        <ReactApexChart
          options={chartData.options}
          series={chartData.series}
          height={chartHeight}
          type="bar"
        />
      ) : null}
    </div>
  );
};

export default TopSKUChart;
