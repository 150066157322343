import { InputGroupDisable } from "@funded-here-interface/common";
import { FC } from "react";
import { Data, Spv } from "../../../types/org.type";
import { useStyles } from "./Shareholder.styles";
import { Divider } from "@mantine/core";

interface ShareHolderProps {
  data: Data | undefined;
  spvData?: Spv;
}

const ShareHolder: FC<ShareHolderProps> = ({ data, spvData }) => {
  const { classes } = useStyles();

  return (
    <>
      <div className={classes.valueContainer}>
        <InputGroupDisable
          labelText="Total no. of Directors"
          id="Total no. of Directors"
          value={data?.boardOfDirectors?.length || 0}
        />

        {data?.boardOfDirectors && (
          <>
            {data?.boardOfDirectors.map((directorData, index) => (
              <div key={index} className={classes.shareholderDiv}>
                <InputGroupDisable
                  labelText="Director's Name"
                  id={`director_name_${index}`}
                  value={`${directorData?.firstName} ${directorData?.lastName}`}
                />
                <InputGroupDisable
                  labelText="Director's Email Id"
                  id={`director_email_${index}`}
                  value={directorData?.email || "-"}
                />
              </div>
            ))}
          </>
        )}
      </div>
      {spvData && (
        <>
          <Divider my="md" />
          <h2 style={{ marginLeft: 44, marginTop: 44, fontWeight: 700 }}>
            SPV
          </h2>
          <div className={classes.valueContainer}>
            <InputGroupDisable
              labelText="Total no. of Directors"
              id="Total no. of Directors"
              value={spvData?.boardOfDirectors?.length || 0}
            />
            {spvData?.boardOfDirectors.map((directorData, index) => (
              <div key={index} className={classes.shareholderDiv}>
                <InputGroupDisable
                  labelText="Director's Name"
                  id={`spv_director_name_${index}`}
                  value={`${directorData?.firstName} ${directorData?.lastName}`}
                />
                <InputGroupDisable
                  labelText="Director's Email Id"
                  id={`spv_director_email_${index}`}
                  value={directorData?.email || "-"}
                />
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default ShareHolder;
