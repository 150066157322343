import { MantineTheme, createStyles } from "@mantine/core";

export const useStyles = createStyles((theme: MantineTheme) => ({
  skuLoanTitle: {
    marginTop: "15px",
    size: "16px",
    fontWeight: 700,
  },
  skuLoanContainer: {
    backgroundColor: "#EEF0F4",
    marginTop: "17px",
    fontSize: "16px",
  },
  skuLoanSummaryContainer: {
    display: "flex",
    backgroundColor: "#EEF0F4",
    padding: "30px 10px 19px 10px",
  },
  skuLoanScrollableBody: {
    maxHeight: "100px",
    backgroundColor: "red",
    overflowY: "auto",
  },
  skuSummaryValues: {
    fontWeight: 700,
    marginRight: "50px",
    marginLeft: "27px",
  },
  skuLoanTableThead: {
    backgroundColor: "#EEF0F4",
    position: "sticky",
    top: 0,
    zIndex: 99,
  },
  skuLoansGraphsContainer: {
    marginTop: "17px",
  },
}));
