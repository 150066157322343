import React, { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { MDBDataTable } from "mdbreact";
import HomeServices from "../../../../services/HomeServices";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

const MerchantDataTable = ({ selectedMerchant }) => {
  const [activePaymentData, setActivePaymentData] = useState([]);
  const [closePaymentData, setClosePaymentData] = useState([]);
  const [defaultPaymentData, setDefaultPaymentData] = useState([]);
  const token = useSelector((state) => state.auth.token);

  // >>>Showing Active Payment Data<<<
  const rows = activePaymentData
    ?.map((item, ind) => ({
      sku_id: item?.skuId,
      sku_name: item?.skuName,
      sector: item?.sector,
      country: item?.country,
      doi: item?.skuLiveDOI,
      tenure: item?.tenure,
      days_to_maturity: item?.daysToMaturity < 0 ? 0 : item?.daysToMaturity,
      projected_sales: item?.projectedSales,
      rbp: item?.expectedRBP,
      loan_amt: item?.loanAmount?.toLocaleString("en-US"),
      repaid_amt: item?.repaidAmount?.toLocaleString("en-US"),
      blnc_amt: item?.balanceAmount?.toLocaleString("en-US"),
      total_sales: item?.totalSalesProceed?.toLocaleString("en-US"),
      rbu: item?.rbu,
      quantity_sold: item?.quantitySold,
      selling_price: item?.sellingPrice?.toLocaleString("en-US"),
      topup_amt:
        item?.topupAmount < 0 ? 0 : item?.topupAmount?.toLocaleString("en-US"),
      default: item?.default,
    }))
    .sort((a, b) => b.item?.skuId - a.item?.skuId);

  const data = {
    columns: [
      {
        label: "SKU ID",
        field: "sku_id",
        sort: "asc",
      },
      {
        label: "SKU Name",
        field: "sku_name",
        sort: "asc",
      },
      {
        label: "Sector",
        field: "sector",
        sort: "asc",
      },
      {
        label: "Country",
        field: "country",
        sort: "asc",
      },
      {
        label: "SKU Live DOI",
        field: "doi",
        sort: "asc",
      },
      {
        label: "Tenure",
        field: "tenure",
        sort: "asc",
      },
      {
        label: "Days to Maturity",
        field: "days_to_maturity",
        sort: "asc",
      },
      {
        label: "Projected Sales (Last 5 Days)",
        field: "projected_sales",
        sort: "asc",
      },
      {
        label: "Expected RBP",
        field: "rbp",
        sort: "asc",
      },
      {
        label: "Loan Amount",
        field: "loan_amt",
        sort: "asc",
      },
      {
        label: "Repaid Amount",
        field: "repaid_amt",
        sort: "asc",
      },
      {
        label: "Balance Amount",
        field: "blnc_amt",
        sort: "asc",
      },
      {
        label: "Total Sales Proceed",
        field: "total_sales",
        sort: "asc",
      },
      {
        label: "RBU",
        field: "rbu",
        sort: "asc",
      },
      {
        label: "Quantity Sold",
        field: "quantity_sold",
        sort: "asc",
      },
      {
        label: "Selling Price",
        field: "selling_price",
        sort: "asc",
      },
      {
        label: "Topup Amount",
        field: "topup_amt",
        sort: "asc",
      },
      {
        label: "Default (%)",
        field: "default",
        sort: "asc",
      },
    ],
    rows: rows,
  };

  // >>>Showing Closed Payment Data<<<
  const rows2 = closePaymentData?.map((item, ind) => ({
    sku_id: item?.skuId,
    sku_name: item?.skuName,
    sector: item?.sector,
    country: item?.country,
    loan_amt: item?.loanAmount?.toLocaleString("en-US"),
    repaid_amt: item?.repaidAmount?.toLocaleString("en-US"),
    int_amt: item?.interestAmount?.toLocaleString("en-US"),
    doi: item?.skuLiveDOI,
    rbp: item?.expectedRBP,
    tenure: item?.tenure,
    rbu: item?.rbu,
    qty_sold: item?.quantitySold?.toLocaleString("en-US"),
    sp: item?.sellingPrice?.toLocaleString("en-US"),
  }));

  const datatwo = {
    columns: [
      {
        label: "SKU ID",
        field: "sku_id",
        sort: "asc",
      },
      {
        label: "SKU Name",
        field: "sku_name",
        sort: "asc",
      },
      {
        label: "Sector",
        field: "sector",
        sort: "asc",
      },
      {
        label: "Country",
        field: "country",
        sort: "asc",
      },
      {
        label: "Loan Amount",
        field: "loan_amt",
        sort: "asc",
      },
      {
        label: "Repaid Amount",
        field: "repaid_amt",
        sort: "asc",
      },
      {
        label: "Interest Amount",
        field: "int_amt",
        sort: "asc",
      },
      {
        label: "DOI",
        field: "doi",
        // sort: "asc",
      },
      {
        label: "Expected RBP",
        field: "rbp",
        sort: "asc",
      },
      {
        label: "Tenure",
        field: "tenure",
        sort: "asc",
      },
      {
        label: "RBU",
        field: "rbu",
        sort: "asc",
      },
      {
        label: "Quantity Sold",
        field: "qty_sold",
        sort: "asc",
      },
      {
        label: "Selling Price",
        field: "sp",
        sort: "asc",
      },
    ],
    rows: rows2,
  };

  // >>>Showing Closed Payment Data<<<
  const rows3 = defaultPaymentData
    ?.map((item, ind) => ({
      sku_id: item?.skuId,
      sku_name: item?.skuName,
      sector: item?.sector,
      country: item?.country,
      tenure: item?.tenure,
      days_exceed_maturity:
        item?.daysPastMaturityDate < 0 ? 0 : item?.daysPastMaturityDate,
      projected_sales: item?.projectedSales,
      rbp: item?.expectedRBP,
      loan_amt: item?.loanAmount?.toLocaleString("en-US"),
      repaid_amt: item?.repaidAmount?.toLocaleString("en-US"),
      blnc_amt: item?.defaultBalanceAmount?.toLocaleString("en-US"),
      topup_amt: item?.borrowerTopupAmount,
      admin_fee: item?.addAdminFee,
      additional_interest: item?.additionalInterest,
      moe: item?.moe,
      sf: item?.sf,
      asf: item?.asf,
      total_sales: item?.totalSalesProceed?.toLocaleString("en-US"),
      rbu: item?.rbu,
      balance_quantity: item?.balanceQuantity,
      selling_price: item?.sellingPrice?.toLocaleString("en-US"),
      force_sell_amount: item?.forceSellAmount,
    }))
    .sort((a, b) => b.item?.skuId - a.item?.skuId);

  // interest_amount

  const datathree = {
    columns: [
      {
        label: "SKU ID",
        field: "sku_id",
        sort: "asc",
      },
      {
        label: "SKU Name",
        field: "sku_name",
        sort: "asc",
      },
      {
        label: "Sector",
        field: "sector",
        sort: "asc",
      },
      {
        label: "Country",
        field: "country",
        sort: "asc",
      },
      {
        label: "Tenure",
        field: "tenure",
        sort: "asc",
      },
      {
        label: "Days Exceeded Maturity Date",
        field: "days_exceed_maturity",
        sort: "asc",
      },
      {
        label: "Projected Sales (Moving Avg Last 5 Days)",
        field: "projected_sales",
        sort: "asc",
      },
      {
        label: "Expected RBP",
        field: "rbp",
        sort: "asc",
      },
      {
        label: "Loan Amount",
        field: "loan_amt",
        sort: "asc",
      },
      {
        label: "Repaid Amount",
        field: "repaid_amt",
        sort: "asc",
      },
      {
        label: "Default Balance Amount (DLO)",
        field: "blnc_amt",
        sort: "asc",
      },
      {
        label: "Borrower Topup Amount",
        field: "topup_amt",
        sort: "asc",
      },
      {
        label: "Add Admin Fee",
        field: "admin_fee",
        sort: "asc",
      },
      {
        label: "Additional Interest",
        field: "additional_interest",
        sort: "asc",
      },
      {
        label: "QC",
        field: "moe",
        sort: "asc",
      },
      {
        label: "SF",
        field: "sf",
        sort: "asc",
      },
      {
        label: "ASF",
        field: "asf",
        sort: "asc",
      },
      {
        label: "Balance Quantity (in units)",
        field: "balance_quantity",
        sort: "asc",
      },
      {
        label: "Selling Price",
        field: "selling_price",
        sort: "asc",
      },
      {
        label: "Force Sell Amount ($)",
        field: "force_sell_amount",
        sort: "asc",
      },
    ],
    rows: rows3,
  };

  // >>>Fetch Active Merchant Payment Report<<<
  const fetchActivePayment = async () => {
    const res = await HomeServices.getActiveMerchantPaymentReport(
      selectedMerchant.value,
      token
    );
    if (res && res?.status) {
      const sortedData = res.data.sort((a, b) => a.snId - b.snId);
      setActivePaymentData(sortedData);
    } else {
      toast.error("Unable to fetch data");
    }
  };

  // >>>Fetch Close Merchant Payment Report<<<
  const fetchClosePayment = async () => {
    const res = await HomeServices.getCloseMerchantPaymentReport(
      selectedMerchant.value,
      token
    );
    if (res && res?.status) {
      const sortedData = res.data.sort((a, b) => a.snId - b.snId);
      setClosePaymentData(sortedData);
    } else {
      toast.error("Unable to fetch data");
    }
  };

  // >>>Fetch Close Merchant Payment Report<<<
  const fetchDefaultPayment = async () => {
    const res = await HomeServices.getDefaultMerchantPayment(
      selectedMerchant.value,
      token
    );
    if (res && res?.status) {
      const sortedData = res.data.sort((a, b) => a.snId - b.snId);
      setDefaultPaymentData(sortedData);
    } else {
      toast.error("Unable to fetch data");
    }
  };

  useEffect(() => {
    fetchActivePayment();
    fetchClosePayment();
    fetchDefaultPayment();
  }, [selectedMerchant]);

  return (
    <>
      <div className="merchantTable">
        <Tabs>
          <TabList>
            <Tab>
              Active Payment
              <span class="pmTooltip">Click here to follow link</span>
            </Tab>
            <Tab>
              Closed Payment
              <span class="pmTooltip">Click here to follow link</span>
            </Tab>
            <Tab>
              Default Payment
              <span class="pmTooltip">Click here to follow link</span>
            </Tab>
          </TabList>

          <TabPanel>
            <div className="activeInvst">
              <p className="TableHead">Active Payment</p>
              <div className="dataTableDiv">
                <MDBDataTable
                  bordered
                  entries={10000}
                  hover
                  data={data}
                  noBottomColumns={true}
                  small
                  stripes
                  selectRows={true}
                  className="text-center"
                />
              </div>
            </div>
          </TabPanel>

          <TabPanel>
            <div>
              <p className="TableHead">Closed Payment</p>
              <div className="dataTableDiv">
                <MDBDataTable
                  bordered
                  entries={10000}
                  hover
                  data={datatwo}
                  noBottomColumns={true}
                  small
                  stripes
                  selectRows={true}
                  className="text-center"
                />
              </div>
            </div>
          </TabPanel>

          <TabPanel>
            <div>
              <p className="TableHead">Default Payment</p>
              <div className="dataTableDiv">
                <MDBDataTable
                  bordered
                  entries={10000}
                  hover
                  data={datathree}
                  noBottomColumns={true}
                  small
                  stripes
                  selectRows={true}
                  className="text-center"
                />
              </div>
            </div>
          </TabPanel>
        </Tabs>
      </div>
    </>
  );
};

export default MerchantDataTable;
