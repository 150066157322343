import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
// import DataTable from "../../Component/DataTable";
import { MDBDataTable } from "mdbreact";
import HomeServices from "../../../../services/HomeServices";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

function TrackingTableChart() {
  const [paymentDueData, setPaymentDueData] = useState([]);
  const token = useSelector((state) => state.auth.token);
  const [defaultPaymentData, setDefaultPaymentData] = useState([]);

  // >>>Showing Payment Due Data in Table<<<
  const rows = paymentDueData?.map((item, ind) => ({
    SN_ID: item?.snId,
    merchant_name: item?.merchantName,
    loan_amt: item?.loanAmount?.toLocaleString("en-US"),
    repaid_amt: item?.repaidAmount?.toLocaleString("en-US"),
    balance_Amt: item?.balanceAmount?.toLocaleString("en-US"),
    tenure: item?.tenure,
    maturityDay: item?.daysToMaturity < 0 ? 0 : item?.daysToMaturity,
    rbp: item?.expectedRBP,
    default: item?.default,
    default_status: item?.defaultStatus,
  }));

  const data = {
    columns: [
      {
        label: "Notes ID",
        field: "SN_ID",
        sort: "asc",
      },
      {
        label: "Merchant Name",
        field: "merchant_name",
        sort: "asc",
      },

      {
        label: "Loan Amount",
        field: "loan_amt",
        sort: "asc",
      },
      {
        label: "Repaid Amount",
        field: "repaid_amt",
        sort: "asc",
      },
      {
        label: "Balance Amount",
        field: "balance_Amt",
        sort: "asc",
      },
      {
        label: "Tenure",
        field: "tenure",
        sort: "asc",
      },
      {
        label: "Days to Maturity",
        field: "maturityDay",
        sort: "asc",
      },
      {
        label: "Expected RBP",
        field: "rbp",
        sort: "asc",
      },
      {
        label: "Default (in %)",
        field: "default",
        sort: "asc",
      },
    ],
    rows: rows,
  };

  const rows2 = defaultPaymentData?.map((item, ind) => ({
    SN_ID: item?.snId,
    merchant_name: item?.merchantName,
    loan_amt: item?.loanAmount?.toLocaleString("en-US"),
    repaid_amt: item?.repaidAmount?.toLocaleString("en-US"),
    tenure: item?.tenure,
    days_past_maturity:
      item?.daysPastMaturityDate < 0 ? 0 : item?.daysPastMaturityDate,
    topup_amount: item?.topupAmount,
    rbp: item?.expectedRBP,
    admin_fee: item?.addAdminFee,
    add_interest: item?.additionalInterest,
    default_balance: item?.defaultBalanceAmount,
    moe: item?.moe,
    sf: item?.sf,
    asf: item?.asf,
    force_sell: item?.forceSellAmount,
  }));

  const data2 = {
    columns: [
      {
        label: "Notes ID",
        field: "SN_ID",
        sort: "asc",
      },
      {
        label: "Merchant Name",
        field: "merchant_name",
        sort: "asc",
      },
      {
        label: "Loan Amount",
        field: "loan_amt",
        sort: "asc",
      },
      {
        label: "Amount Paid",
        field: "repaid_amt",
        sort: "asc",
      },
      {
        label: "Tenure",
        field: "tenure",
        sort: "asc",
      },
      {
        label: "Days Past Maturity Date",
        field: "days_past_maturity",
        sort: "asc",
      },
      {
        label: "Topup Amount",
        field: "topup_amount",
        sort: "asc",
      },
      {
        label: "Expected RBP",
        field: "rbp",
        sort: "asc",
      },
      {
        label: "Add Admin Fee",
        field: "admin_fee",
        sort: "asc",
      },
      {
        label: "Add Interest",
        field: "add_interest",
        sort: "asc",
      },
      {
        label: "Default Balance Amount",
        field: "default_balance",
        sort: "asc",
      },
      {
        label: "QC",
        field: "moe",
        sort: "asc",
      },
      {
        label: "SF",
        field: "sf",
        sort: "asc",
      },
      {
        label: "ASF",
        field: "asf",
        sort: "asc",
      },
      {
        label: "Force Sell Amount ($)",
        field: "force_sell",
        sort: "asc",
      },
    ],
    rows: rows2,
  };

  // >>>fetch payment due data<<<
  const fetchPaymentDue = async () => {
    const res = await HomeServices.paymentDue(token);
    if (res && res?.status) {
      setPaymentDueData(res?.data);
    } else {
      toast.error("Unable to fetch Data");
    }
  };

  const fetchDefaultPaymentDue = async () => {
    const res = await HomeServices.defaultPaymentData(token);

    if (res && res?.status) {
      setDefaultPaymentData(res?.data);
    } else {
      toast.error("Unable to fetch Data");
    }
  };

  useEffect(() => {
    fetchPaymentDue();
    fetchDefaultPaymentDue();
  }, []);

  return (
    <div className="investorTable">
      <Tabs>
        <TabList>
          <Tab>
            Payments Due
            <span class="pmTooltip">Click here to follow link</span>
          </Tab>
          <Tab>
            Defaulted Notes
            <span class="pmTooltip">Click here to follow link</span>
          </Tab>
          <Link to="/pm-dashboard/merchant" className="InvesttabLink">
            Merchant Summary
            <span class="pmTooltip">Click here to follow link</span>
          </Link>
        </TabList>
        <TabPanel>
          <div className="payment-due">
            <p className="invTabPara">Payments Due</p>
            <MDBDataTable
              bordered
              entries={10000}
              hover
              data={data}
              noBottomColumns={true}
              small
              stripes
              selectRows={true}
              className="text-center"
            />
          </div>
        </TabPanel>
        <TabPanel>
          <div className="payment-due">
            <p className="invTabPara">Defaulted Notes Analysis</p>
            <MDBDataTable
              bordered
              entries={10000}
              hover
              data={data2}
              noBottomColumns={true}
              small
              stripes
              selectRows={true}
              className="text-center"
            />
          </div>
        </TabPanel>
      </Tabs>
    </div>
  );
}

export default TrackingTableChart;
