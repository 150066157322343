import { createSlice } from "@reduxjs/toolkit";

const docBorrowerSlice = createSlice({
  name: "bordoc",
  initialState: {
    business: [],
    share: [],
    balance: [],
    proof: [],
    bank: [],
  },
  reducers: {
    setBusinessDoc: (state, { payload: { business } }) => {
      state.business = business;
    },
    setBalance: (state, { payload: { balance } }) => {
      state.balance = balance;
    },
    setShareDoc: (state, { payload: { share } }) => {
      state.share = share;
    },
    setProofDoc: (state, { payload: { proof } }) => {
      state.proof = proof;
    },
    setBankDoc: (state, { payload: { bank } }) => {
      state.bank = bank;
    },
  },
});

export const {
  setBusinessDoc,
  setShareDoc,
  setBalance,
  setProofDoc,
  setBankDoc,
} = docBorrowerSlice.actions;

export default docBorrowerSlice.reducer;
