import { color } from "@funded-here-interface/common";
import { MantineTheme, createStyles } from "@mantine/core";

export const useStyles = createStyles((theme: MantineTheme) => ({
  personCard: {
    width: "400px",
  },
  textInput: {
    ".mantine-Input-input:disabled": {
      backgroundColor: "#fff",
      color: "#000",
    },
  },

  photoDisplay: {
    width: "80%",
  },
  photo: {
    width: "100%",
  },
  inputTitle: {
    display: "inline-block",
    fontSize: "0.875rem",
    fontWeight: 500,
    color: "#212529",
  },
  upload: {
    fontSize: "16px",
    fontWeight: 700,
    marginTop: "10px",
    color: color.FHGREEN,
    cursor: "pointer",
  },

  DocContain: {
    display: "flex",
  },
  additionalDocName: {
    fontSize: "16px",
    width: "400px",
    marginRight: "20px",
  },
  downloadIcon: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
  },
}));
