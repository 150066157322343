import { InputGroupDisable } from "@funded-here-interface/common";
import { FC } from "react";
import { Data, UserData } from "../../../types/org.type";
import { useStyles } from "./Individual.styles";
import { getName } from "country-list";
import { capitalizeFirstLetter } from "@funded-here-interface/common/src/Utils/string";
import { convertDate } from "@funded-here-interface/common/src/Utils/date";

interface IProps {
  data: Data | undefined;
  userData: UserData | undefined;
}

const Individual: FC<IProps> = ({ data, userData }) => {
  const { classes } = useStyles();

  const email = userData?.email ?? "-";
  const phoneNumber = data?.companyPhoneNumber ?? "-";
  let onboardedAs =
    capitalizeFirstLetter(userData?.orgRole?.[0]?.role?.name) || "-";
  const formattedDate = data?.createdDate ? convertDate(data.createdDate) : "-";
  const firstName = capitalizeFirstLetter(userData?.firstName) || "-";
  const lastName = capitalizeFirstLetter(userData?.lastName) || "-";
  const industry = data?.industry || "-";
  const occupation = data?.occupation || "-";
  const experience = data?.numberofYears || "-";
  const country = getName(data?.countryISOCode || "") || "-";
  const nationality = data?.nationality || "-";
  const address = data?.legalEntityAddress || "-";
  const postal = data?.postalCode || "-";
  const investmentMode =
    capitalizeFirstLetter(data?.investmentPreferences?.investmentMode) || "-";
  const investmentAmount = data?.investmentPreferences?.investmentSize || "-";

  if (userData?.investorCategory?.category) {
    onboardedAs = `${capitalizeFirstLetter(
      userData?.investorCategory?.category
    )} ${onboardedAs}`;
  }

  return (
    <div className={classes.valueContainer}>
      <InputGroupDisable
        isErrored=""
        labelText="Email"
        id="email"
        value={email}
      />
      <InputGroupDisable
        isErrored=""
        labelText="Phone Number"
        id="phoneNumber"
        value={phoneNumber}
      />
      <InputGroupDisable
        isErrored=""
        labelText="Onboarded as "
        id="Onboarded as "
        value={onboardedAs}
      />
      <InputGroupDisable
        isErrored=""
        labelText="Date onboarded "
        id="Date onboarded "
        value={formattedDate}
      />
      <InputGroupDisable
        isErrored=""
        labelText="First Name"
        id="first_name"
        value={firstName}
      />
      <InputGroupDisable
        isErrored=""
        labelText="Last Name"
        id="last_name"
        value={lastName}
      />
      <InputGroupDisable
        isErrored=""
        labelText="Industry"
        id="industry"
        value={industry}
      />
      <InputGroupDisable
        isErrored=""
        labelText="Specific Occupation"
        id="occupation"
        value={occupation}
      />
      <InputGroupDisable
        isErrored=""
        labelText="No. of Years in this Occupation"
        id="occupation_experience"
        value={experience}
      />
      <InputGroupDisable
        isErrored=""
        labelText="Country of residence"
        id="country_of_residence"
        value={country}
      />
      <InputGroupDisable
        isErrored=""
        labelText="Nationality"
        id="nationality"
        value={nationality}
      />
      <InputGroupDisable
        isErrored=""
        labelText="Address"
        id="address"
        value={address}
      />
      <InputGroupDisable
        isErrored=""
        labelText="Postal Code"
        id="postal_code"
        value={postal}
      />
      <InputGroupDisable
        isErrored=""
        labelText="Investment Mode"
        id="investment_mode"
        value={investmentMode}
      />
      <InputGroupDisable
        isErrored=""
        labelText="Preferred Investment Amount (SGD) "
        id="investment_amount"
        value={investmentAmount}
      />
    </div>
  );
};

export default Individual;
