import { MantineTheme, createStyles } from "@mantine/core";

export const useStyles = createStyles((theme: MantineTheme) => ({
  mainContainer: {
    marginTop: "65px",
    display: "flex",
    flexDirection: "column",
  },
  spvDropdown: { width: "50%", marginBottom: "60px" },
  divider: {
    width: "100%",
  },
  investorDetailsContainer: {
    paddingTop: "36px",
    display: "flex",
    flexDirection: "column",

    div: {
      display: "flex",
    },
  },
  investorInterestRate: {
    display: "flex",
    flexDirection: "column",
    flex: 2,
  },
  investorTotalNumberContainer: {
    fontWeight: 700,
    flex: 1,
  },
  investorTypeContainer: {
    flexDirection: "column",
    marginBottom: "5px",
    marginLeft: "15px"
  },
  percentageInput:{
    background: "none",
    outline: "none",
    height: "20px",
    width: "30px",
    border: "none",
    color: "#3786D6",
    fontSize: "1rem",
    textAlign: 'end'
  },
  percentage:{
    color: "#3786D6",
    fontSize: "1rem"
  },
  investorTypeTitle: {
    fontWeight: 700,
    marginRight: "35px"
  },
  investorJuniorTypeDetailsContainer: {
    display: "flex",
    width: "800px",

    div: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  },
  investorTypeDetailsContainer: {
    display: "flex",

    div: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  },

  detailTitle: {
    color: "#595959",
    fontWeight: 400,
    fontSize: "16px",
  },
  allocationPercentage:{
    width: "60px",
    height: "40px",
    backgroundColor: "#D9D9D9",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  investorTypeOuterContainer:{
    display: "flex",
    alignItems: "center", 
    marginBottom: "37px"
  }
}));
