import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import HomeServices from "../../../services/HomeServices";
import { useSelector } from "react-redux";

const OverallPieTwo = () => {
  const [chartData, setChartData] = useState([]);
  const token = useSelector((state) => state.auth.token);
  const [state, setState] = useState({
    options: {
      chart: {
        type: "donut",
        width: "100%",
      },
      legend: {
        position: "right",
      },
      plotOptions: {
        pie: {
          customScale: 0.8,
          size: "60%",
        },
      },
      stroke: {
        show: false,
        width: 1,
      },
      tooltip: {
        enabled: true,
        followCursor: true,
        custom: function ({ series, seriesIndex, w }) {
          const sector = w.config.labels[seriesIndex];

          const skuAllocation = series[seriesIndex];

          return (
            '<div class="arrow_box">' +
            '<span class="headspan">' +
            "Sector: " +
            "</span>" +
            '<span class="paraspan">' +
            sector +
            "</span>" +
            "</div>" +
            '<div class="arrow_box">' +
            '<span class="headspan">' +
            "Active Notes Investment : " +
            "</span>" +
            '<span class="paraspan">' +
            skuAllocation +
            "</span>" +
            "</div>"
          );
        },
      },
    },
    series: [],
    labels: [],
  });

  const fetchSKUsByCountry = async () => {
    try {
      const res = await HomeServices.activeAmountBySector(token);
      if (res && res.status) {
        const labels = res.data.map((item) => item.sector);
        const series = res.data.map((item) => item.investorDeposit);

        setState((prevState) => ({
          ...prevState,
          options: {
            ...prevState.options,
            labels: labels,
          },
          series: series,
        }));
        setChartData(res.data);
      }
    } catch (error) {
      console.error("Error fetching SKU data by country:", error);
    }
  };
  useEffect(() => {
    fetchSKUsByCountry();
  }, []);

  return (
    <div id="chart">
      <ReactApexChart
        options={state.options}
        series={state.series}
        type="donut"
        height={350}
      />
    </div>
  );
};

export default OverallPieTwo;
