import React, { useEffect, useState } from "react";
import AmountDueChart from "./AmountDueChart";
import HomeServices from "../../../../services/HomeServices";
import toast from "react-hot-toast";
import TopSkuChart from "./TopSkuChart";
import { RiArrowDropDownLine } from "react-icons/ri";

const MerchantChartTab = ({
  selectedMerchant,
  handleMerchantChange,
  merchantOptions,
}) => {
  const [isDrop, setIsDrop] = useState(false);
  const [snData, setSNdata] = useState([]);
  const [SNData, setSNData] = useState("Albert Mathews");
  const [showAmountDue, setShowAmountDue] = useState(true); // State to manage visibility of AmountDueChart
  const [showTopSku, setShowTopSku] = useState(false); // State to manage visibility of TopSkuChart
  const [showmenu, setShowmenu] = useState(false);

  const handClick = () => {
    setShowmenu(!showmenu);
  };

  return (
    <>
      <div className="merChartTab">
        <div className="merChartInn" style={{ marginLeft: 5 }}>
          <div className="flex-container">
            <div className="button-group">
              <button
                className="amountDueAnalysisBtn"
                style={{ marginRight: 10 }}
                onClick={() => {
                  setShowAmountDue(true);
                  setShowTopSku(false);
                }}
              >
                Amount Due Analysis
              </button>
              <button
                className="topSkuBtn"
                onClick={() => {
                  setShowTopSku(true);
                  setShowAmountDue(false);
                }}
              >
                Top SKU with Highest Default
              </button>
            </div>
          </div>
        </div>
        {showAmountDue && (
          <AmountDueChart selectedMerchant={selectedMerchant} />
        )}
        {showTopSku && <TopSkuChart selectedMerchant={selectedMerchant} />}
        <div className="selectDropDiv">
          <p className="selectDroptext">*Select Merchant from dropdown</p>
          <div className="merselectDiv">
            <button className="merselectBtn" onClick={handClick}>
              {selectedMerchant.label}
              <span className="snIconSpan">
                <RiArrowDropDownLine size={"2em"} />
              </span>
            </button>
            {showmenu && (
              <div className="merSlctDrop">
                {merchantOptions.map((option) => (
                  <div key={option.value} className="inputDiv">
                    <input
                      type="radio"
                      checked={option.value === selectedMerchant.value}
                      onChange={() => handleMerchantChange(option)}
                    />
                    <p>{option.label}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MerchantChartTab;
