import { BASE_URL } from "@funded-here-interface/common/src/constant/constant";
import axios from "axios";
import { getApiResponseErrorMsg } from "@funded-here-interface/common/src/Utils/apiHelper";

type GetOrgRequest = {
  token: string;
  id: string;
};

const getOrg = async ({ token, id }: GetOrgRequest) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/trade-directory/organization/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      }
    );

    return response.data;
  } catch (e) {
    const message = getApiResponseErrorMsg(e);

    throw new Error(message);
  }
};

export default getOrg;
