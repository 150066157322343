import { createStyles } from "@mantine/core";

export const useStyle = createStyles(() => ({
  divider: {
    marginBottom: "40px !important",
    marginTop: "40px !important",
  },
  header: {
    display: "flex",
    gap: 20,
    alignItems: "start",
  },
  headerText: {
    fontSize: 24,
    fontWeight: 700,
  },
  buttonWrapper: {
    display: "flex",
    width: "560px",
    justifyContent: "space-between",
    marginBottom: "30px",
  },
}));
