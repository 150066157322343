import { FC, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import useLoanManagement from "../../../../hooks/useLoanManagement";
import { LoanManagementResponse } from "../../../../services/loanManagement";
import { LoanStatus } from "../../../../constant/enum";
import { toast } from "react-toastify";
import {
  MRT_ColumnDef,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import {
  formatNumbersWithThousandSeperators,
  formatPercentageFromRatio,
} from "@funded-here-interface/common/src/Utils/formatter";

const Loa: FC = () => {
  const token = useSelector((state: RootState) => state.auth.token);
  const useLoanManagementMutation = useLoanManagement();
  const [isLoading, setisLoading] = useState(true);
  const [data, setData] = useState<LoanManagementResponse[]>([]);

  useEffect(() => {
    setisLoading(true);
    useLoanManagementMutation.mutate(
      { token, type: LoanStatus.LOA },
      {
        onSuccess: (data) => {
          setData(data);
          setisLoading(false);
        },
        onError: (error) => {
          setisLoading(false);
          toast.error((error as Error).message);
        },
      }
    );
  }, []);

  const columns = useMemo<MRT_ColumnDef<LoanManagementResponse>[]>(
    () => [
      {
        accessorKey: "skuId",
        header: "SKU ID",
      },
      {
        accessorKey: "upc",
        header: "UPC",
      },
      {
        accessorKey: "orgName",
        header: "Org Name",
      },
      {
        accessorKey: "distributorOrgName",
        header: "Supplier Name", // Distributor / Supplier
      },
      {
        accessorKey: "availableCreditLine",
        header: "Available Credit Line",
        Cell: ({ row }) => {
          const currency = row?.original?.loanApplicationCurrency || "";
          const creditLine = formatNumbersWithThousandSeperators(
            parseFloat(row?.original?.availableCreditLine || "0")
          );
          return <p>{`${currency} ${creditLine}`}</p>;
        },
      },
      {
        accessorKey: "invoiceValue",
        header: "Invoice Value",
        Cell: ({ row }) => {
          const currency = row?.original?.loanApplicationCurrency || "";
          const creditLine = formatNumbersWithThousandSeperators(
            parseFloat(row?.original?.invoiceValue || "0")
          );
          return <p>{`${currency} ${creditLine}`}</p>;
        },
      },
      {
        accessorKey: "status",
        header: "Status",
      },
      {
        accessorKey: "loanAmount0",
        header: "Loan Amount 0",
        Cell: ({ row }) => {
          const currency = row?.original?.loanApplicationCurrency || "";
          const formattedLoanAmt0 = formatNumbersWithThousandSeperators(
            parseFloat(row?.original?.loanAmount0 || "0")
          );
          return <p>{`${currency} ${formattedLoanAmt0}`}</p>;
        },
      },
      {
        accessorKey: "loanAmount1",
        header: "Loan Amount 1",
        Cell: ({ row }) => {
          const currency = row?.original?.loanApplicationCurrency || "";
          const formattedLoanAmt1 = formatNumbersWithThousandSeperators(
            parseFloat(row?.original?.loanAmount1 || "0")
          );
          return <p>{`${currency} ${formattedLoanAmt1}`}</p>;
        },
      },
      {
        accessorKey: "loanAmount2",
        header: "Loan Amount 2",
        Cell: ({ row }) => {
          const currency = row?.original?.loanApplicationCurrency || "";
          const formattedLoanAmt2 = formatNumbersWithThousandSeperators(
            parseFloat(row?.original?.loanAmount2 || "0")
          );
          return <p>{`${currency} ${formattedLoanAmt2}`}</p>;
        },
      },
      {
        accessorKey: "adminFee",
        header: "Admin Fee",
        Cell: ({ cell, row }) => {
          const currency = row?.original?.loanApplicationCurrency || "";
          return (
            <p>
              {currency} {cell.getValue<string>() || "-"}
            </p>
          );
        },
      },
      {
        accessorKey: "transAndChannelFee",
        header: "Trans & Channel Fee",
        Cell: ({ cell, row }) => {
          const currency = row?.original?.loanApplicationCurrency || "";
          return (
            <p>
              {currency} {cell.getValue<string>() || "-"}
            </p>
          );
        },
      },
      {
        accessorKey: "thirdPartyFee",
        header: "Third Party Fee",
        Cell: ({ cell, row }) => {
          const currency = row?.original?.loanApplicationCurrency || "";
          return (
            <p>
              {currency} {cell.getValue<string>() || "-"}
            </p>
          );
        },
      },
      {
        accessorKey: "acquirerFees",
        header: "Acquirer Fees",
        Cell: ({ cell, row }) => {
          const currency = row?.original?.loanApplicationCurrency || "";
          return (
            <p>
              {currency} {cell.getValue<string>() || "-"}
            </p>
          );
        },
      },
      {
        accessorKey: "interestRate",
        header: "Interest Rate",
        Cell: ({ cell }) => (
          <p>{formatPercentageFromRatio(cell.getValue<string>()) + "%"}</p>
        ),
      },
      {
        accessorKey: "interest",
        header: "Interest",
        Cell: ({ cell, row }) => {
          const currency = row?.original?.loanApplicationCurrency || "";
          return (
            <p>
              {currency} {cell.getValue<number>() || "-"}
            </p>
          );
        },
      },
      {
        accessorKey: "tenure",
        header: "Tenure",
        Cell: ({ cell }) => <p>{cell.getValue<number>() || "-"}</p>,
      },
      {
        accessorKey: "moe",
        header: "QC",
        Cell: ({ row }) => {
          const currency = row?.original?.loanApplicationCurrency || "";
          const creditLine = formatNumbersWithThousandSeperators(
            parseFloat(row?.original?.moe || "0")
          );
          return <p>{`${currency} ${creditLine}`}</p>;
        },
      },
      {
        accessorKey: "isMoePaid",
        header: "QC Payment Status",
      },
      {
        accessorKey: "sinkingFund",
        header: "SF",
        Cell: ({ row }) => {
          const currency = row?.original?.loanApplicationCurrency || "";
          const creditLine = formatNumbersWithThousandSeperators(
            parseFloat(row?.original?.sinkingFund || "0")
          );
          return <p>{`${currency} ${creditLine}`}</p>;
        },
      },
      {
        accessorKey: "isSinkingFundPaid",
        header: "SF Payment Status",
      },
      {
        accessorKey: "additionalSinkingFund",
        header: "ASF",
        Cell: ({ row }) => {
          const currency = row?.original?.loanApplicationCurrency || "";
          const creditLine = formatNumbersWithThousandSeperators(
            parseFloat(row?.original?.additionalSinkingFund || "0")
          );
          return <p>{`${currency} ${creditLine}`}</p>;
        },
      },
      {
        accessorKey: "isAdditionalSinkingFund",
        header: "ASF Payment Status",
      },
      {
        accessorKey: "grossMargin",
        header: "Gross Margin",
        Cell: ({ cell }) => <p>{cell.getValue<string>() || "-"}</p>,
      },
      {
        accessorKey: "rbp",
        header: "RBP",
        Cell: ({ cell }) => <p>{cell.getValue<string>() || "-"}</p>,
      },
      {
        accessorKey: "rbu",
        header: "RBU",
        Cell: ({ cell }) => <p>{cell.getValue<string>() || "-"}</p>,
      },
    ],
    []
  );

  const table = useMantineReactTable({
    enableColumnActions: false,
    enableColumnFilters: false,
    paginationDisplayMode: "pages",
    state: { isLoading },
    columns,
    data: data,
    initialState: { density: "xs" },
    mantineTableProps: {
      withBorder: true,
    },
  });

  return <MantineReactTable table={table} />;
};

export default Loa;
