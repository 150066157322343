import { BASE_URL } from "@funded-here-interface/common/src/constant/constant";
import axios from "axios";
import { getApiResponseErrorMsg } from "@funded-here-interface/common/src/Utils/apiHelper";

interface GetDocumentRequest {
  token: string;
  poId: string;
}

const downloadPo = async ({ token, poId }: GetDocumentRequest) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/sku-finance/loan-application/download-sku-loan-applications-po-pm-verification/${poId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob",
        withCredentials: true,
      }
    );

    return response.data;
  } catch (e) {
    const message = getApiResponseErrorMsg(e);

    throw new Error(message);
  }
};

export default downloadPo;
