import axios from "axios";
import { BASE_URL } from "@funded-here-interface/common/src/constant/constant";
import { getApiResponseErrorMsg } from "@funded-here-interface/common/src/Utils/apiHelper";

interface DownloadCampaignCSVRequest {
  token: string;
  formData: FormData;
}

const createCampaign = async ({
  token,
  formData,
}: DownloadCampaignCSVRequest) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/sku-finance/campaign`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      }
    );

    return response.data;
  } catch (e) {
    const message = getApiResponseErrorMsg(e);

    throw new Error(message);
  }
};

export default createCampaign;
