import React, { FC, useState } from "react";
import { Tabs } from "@mantine/core";
import { useStyles } from "./structuredNote.styles";
import SkuPooling from "./Tabs/SkuPooling";
import Review from "./Tabs/Review";
import Allocate from "./Tabs/Allocate";
import { useNavigate, useParams } from "react-router-dom";

const StructuredNotePage: FC = () => {
  const { classes } = useStyles();
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const navigate = useNavigate();
  const { tabValue } = useParams();

  return (
    <div className={classes.mainContainer}>
      <Tabs
        value={tabValue}
        onTabChange={(value) => navigate(`/structured-note/${value}`)}
        defaultValue="sku-pooling"
      >
        <Tabs.List>
          <Tabs.Tab value="sku-pooling">SKU Pooling</Tabs.Tab>
          {selectedRows?.length > 0 && (
            <>
              <Tabs.Tab value="review">Review</Tabs.Tab>
              <Tabs.Tab value="allocate">Allocate</Tabs.Tab>
            </>
          )}
        </Tabs.List>
        <Tabs.Panel value="sku-pooling">
          <SkuPooling
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
          />
        </Tabs.Panel>
        <Tabs.Panel value="review">
          <Review selectedRows={selectedRows} />
        </Tabs.Panel>

        <Tabs.Panel value="allocate">
          <Allocate selectedRows={selectedRows} />
        </Tabs.Panel>
      </Tabs>
    </div>
  );
};
export default StructuredNotePage;
