import { FC, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useStyle } from "./Repayment.styles";
import { Tabs } from "@mantine/core";
import Sales from "./Components/Tabs/Sales";
import useGetNoteSales from "../../hooks/useGetNoteSales";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { Button, color } from "@funded-here-interface/common";
import Note from "./Components/Tabs/Note";
import Sku from "./Components/Tabs/Sku";
import Default from "./Components/Tabs/Default";
import { FaArrowLeftLong } from "react-icons/fa6";
import useCloseSNMutation from "../../hooks/useCloseSN";

const Repayment: FC = () => {
  const { classes } = useStyle();
  const { id } = useParams();
  const token = useSelector((state: RootState) => state.auth.token);
  // const { data, error, isLoading } = useGetNoteSales(token, id!);

  const useCloseSnMutation = useCloseSNMutation();
  const [canCloseNote, setCanCloseNote] = useState(false);
  const [initalRun, setInitalRun] = useState(true);
  const [noteId, setNoteId] = useState("");
  enum RepaymentTab {
    SALES = "sales",
    SN = "sn",
    SKU = "sku",
    DEFAULT = "default",
  }

  // useEffect(() => {
  //   if (data) {
  //     setCanCloseNote(data.canNoteBeClosed);
  //   }
  // }, [data]);

  const updateCanCloseNote = (canClose: boolean, noteDisplayId: string) => {
    if (initalRun) {
      setCanCloseNote(canClose);
      setInitalRun(false);
    }
    setNoteId(noteDisplayId);
  };

  const handleCloseNote = () => {
    useCloseSnMutation.mutate(
      { structuredNoteId: parseInt(id!), token },
      {
        onSuccess: () => {
          setCanCloseNote(false);

          toast.success("Note Closed Successfully");
        },
        onError: (e) => {
          toast.error((e as Error).message);
        },
      }
    );
  };

  return (
    <div>
      <div>
        <div className={classes.header}>
          <Link to="/note-list">
            <FaArrowLeftLong style={{ color: color.FHADMIN, fontSize: 32 }} />
          </Link>
          <h1 className={classes.headerText}>Track Repayment</h1>
        </div>
        <h1 className={classes.text}>{`Note Id: ${noteId}`}</h1>
      </div>
      <Tabs defaultValue={RepaymentTab.SALES}>
        <Tabs.List>
          <Tabs.Tab value={RepaymentTab.SALES}>Sales Proceeds</Tabs.Tab>
          <Tabs.Tab value={RepaymentTab.SN}>Repayment-Note</Tabs.Tab>
          <Tabs.Tab value={RepaymentTab.SKU}>Repayment-SKU</Tabs.Tab>
          <Tabs.Tab value={RepaymentTab.DEFAULT}>Default</Tabs.Tab>
        </Tabs.List>

        <div className={classes.button}>
          <Button
            onClick={handleCloseNote}
            backgroundColor={color.FHGREEN}
            textColor="#fff"
            children="Close Note"
            disabled={!canCloseNote}
            width="260px"
          />
        </div>

        <Tabs.Panel value={RepaymentTab.SALES} pt="xs">
          {/* <Sales
            isLoading={isLoading}
            data={data?.skus}
            error={error as Error}
          /> */}
          <Sales noteId={id!} onCanCloseNoteChange={updateCanCloseNote} />
        </Tabs.Panel>
        <Tabs.Panel value={RepaymentTab.SN} pt="xs">
          <Note noteId={id!} />
        </Tabs.Panel>
        <Tabs.Panel value={RepaymentTab.SKU} pt="xs">
          <Sku noteId={id!} />
        </Tabs.Panel>
        <Tabs.Panel value={RepaymentTab.DEFAULT} pt="xs">
          <Default noteId={id!} />
        </Tabs.Panel>
      </Tabs>
    </div>
  );
};

export default Repayment;
