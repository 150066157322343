import { FC, useMemo, useState } from "react";
import {
  MRT_ColumnDef,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import { VirtualAccountTransactionsResponse } from "../../../services/getVirtualAccountsTransactions";
import { convertDate } from "@funded-here-interface/common/src/Utils/date";

import { toast } from "react-toastify";
import useGetVATransaction from "../../../hooks/useGetVATransactions";
import { Box, Button } from "@mantine/core";
import { IconDownload } from "@tabler/icons-react";
import useDownloadVATransactionsCSV from "../../../hooks/useDownloadVATransactionsCSV";
import { downloadFile } from "@funded-here-interface/common/src/Utils/file";
import { FileType } from "@funded-here-interface/common/src/constant/enum";
import { RootState } from "../../../store";
import { useSelector } from "react-redux";

const Transactions: FC = () => {
  const useDownloadVATransactionsCSVMutation = useDownloadVATransactionsCSV();
  const token = useSelector((state: RootState) => state.auth.token);
  const { data, error, isLoading } = useGetVATransaction(token);
  const [isExportLoading, setIsExportLoading] = useState(false);

  if (error) {
    toast.error((error as Error).message);
  }

  const handleExportData = () => {
    setIsExportLoading(true);
    useDownloadVATransactionsCSVMutation.mutate(
      { token },
      {
        onSuccess: (data: Blob) => {
          setIsExportLoading(false);

          downloadFile(data, "VA Transactions", FileType.CSV);
        },
        onError: (e) => {
          setIsExportLoading(false);

          toast.error((e as Error).message);
        },
      }
    );
  };

  const columns = useMemo<MRT_ColumnDef<VirtualAccountTransactionsResponse>[]>(
    () => [
      {
        id: "sender",
        header: "Sender details",
        mantineTableHeadCellProps: {
          sx: {
            backgroundColor: "#a6cbed",
          },
        },
        columns: [
          {
            accessorKey: "senderVAAccountId",
            header: "Sender Virtual Account ID",
            accessorFn: (row) => row.senderVAAccountId ?? "",
            Cell: ({ renderedCellValue }) =>
              renderedCellValue ? (
                <span>{renderedCellValue}</span>
              ) : (
                <div>&#8210;</div>
              ),
          },
          {
            accessorKey: "senderAccountId",
            header: "Sender Virtual Account Number",
            accessorFn: (row) => row.senderAccountId ?? "",
            Cell: ({ renderedCellValue }) =>
              renderedCellValue ? (
                <span>{renderedCellValue}</span>
              ) : (
                <div>&#8210;</div>
              ),
          },
          {
            accessorKey: "senderRefId",
            header: "Sender Reference ID",
          },
          {
            accessorKey: "senderStructuredNoteId",
            header: "Sender Note ID",
            accessorFn: (row) => row.senderStructuredNoteId ?? "",
            Cell: ({ renderedCellValue }) => 
              renderedCellValue ? (
                <span>{renderedCellValue}</span>
              ) : (
                <div>&#8210;</div>
              )
          },
          {
            accessorKey: "senderType",
            header: "Sender Virtual Account Type",
            accessorFn: (row) => row.senderType ?? "",
            Cell: ({ renderedCellValue }) =>
              renderedCellValue ? (
                <span>{renderedCellValue}</span>
              ) : (
                <div>&#8210;</div>
              ),
          },
          {
            accessorKey: "senderBalanceBefore",
            header: "Sender VA Opening Balance",
            Cell: ({ renderedCellValue }) =>
              renderedCellValue ? (
                <span>{renderedCellValue}</span>
              ) : (
                <div>&#8210;</div>
              ),
          },
          {
            accessorKey: "senderBalanceAfter",
            header: "Sender VA Closing Balance",
            Cell: ({ renderedCellValue }) =>
              renderedCellValue ? (
                <span>{renderedCellValue}</span>
              ) : (
                <div>&#8210;</div>
              ),
          },
        ],
      },
      {
        id: "receiver",
        header: "Receiver details",
        mantineTableHeadCellProps: {
          sx: {
            backgroundColor: "#D9D9D9",
          },
        },
        columns: [
          {
            accessorKey: "receiverVaAccountId",
            header: "Receiver Virtual Account ID",
            accessorFn: (row) => row.receiverVaAccountId ?? "",
            Cell: ({ renderedCellValue }) =>
              renderedCellValue ? (
                <span>{renderedCellValue}</span>
              ) : (
                <div>&#8210;</div>
              ),
          },
          {
            accessorKey: "receiverAccountId",
            header: "Receiver Virtual Account Number",
          },
          {
            accessorKey: "receiverRefId",
            header: "Receiver Reference ID",
          },
          {
            accessorKey: "receiverStructuredNoteId",
            header: "Receiver Note ID",
            accessorFn: (row) => row.receiverStructuredNoteId ?? "",
            Cell: ({ renderedCellValue }) => 
              renderedCellValue ? (
                <span>{renderedCellValue}</span>
              ) : (
                <div>&#8210;</div>
              )
          },
          {
            accessorKey: "receiverType",
            header: "Receiver Virtual Account type",
            accessorFn: (row) => row.receiverType ?? "",
            Cell: ({ renderedCellValue }) =>
              renderedCellValue ? (
                <span>{renderedCellValue}</span>
              ) : (
                <div>&#8210;</div>
              ),
          },
          {
            accessorKey: "receiverBalanceBefore",
            header: "Receiver VA Opening Balance",
          },
          {
            accessorKey: "receiverBalanceAfter",
            header: "Receiver VA Closing Balance",
          },
        ],
      },
      {
        id: "basic",
        header: "",
        columns: [
          {
            accessorKey: "currency",
            header: "Currency",
          },
          {
            accessorKey: "amount",
            header: "Amount",
          },
          {
            accessorKey: "otherCurrency",
            header: "Other Currency",
            accessorFn: (row) => row.otherCurrency ?? "",
            Cell: ({ renderedCellValue }) => 
              renderedCellValue ? (
                <span>{renderedCellValue}</span>
              ) : (
                <div>&#8210;</div>
              )
          },
          {
            accessorKey: "amountOtherCurrency",
            header: "Amount (Other Currency)",
            accessorFn: (row) => row.amountOtherCurrency ?? "",
            Cell: ({ renderedCellValue }) => 
              renderedCellValue ? (
                <span>{renderedCellValue}</span>
              ) : (
                <div>&#8210;</div>
              )
          },
          {
            accessorKey: "feesIncurred",
            header: "Fee Incurred",
            accessorFn: (row) => row.feesIncurred ?? "",
            Cell: ({ renderedCellValue }) => 
              renderedCellValue ? (
                <span>{renderedCellValue}</span>
              ) : (
                <div>&#8210;</div>
              )
          },
          {
            accessorKey: "createdDate",
            header: "Date",
            Cell: ({ cell }) => <p>{convertDate(cell.getValue<string>())}</p>,
          },
          {
            accessorKey: "remarks",
            header: "Remarks",
            accessorFn: (row) => row.remarks ?? "",
            Cell: ({ renderedCellValue }) => 
              renderedCellValue ? (
                <span>{renderedCellValue}</span>
              ) : (
                <div>&#8210;</div>
              )
          },
        ],
      },
    ],
    []
  );

  const table = useMantineReactTable({
    enableColumnActions: false,
    enableColumnFilters: false,
    enableDensityToggle: false,
    paginationDisplayMode: "pages",
    state: { isLoading },
    columns,
    data: data ?? [],
    initialState: { density: "xs" },
    mantineTableProps: {
      withColumnBorders: true,
    },
    mantineTableHeadCellProps: {
      style: {
        padding: "0.5rem 1.0rem",
        color: "#23262a",
      },
    },
    mantineTableBodyCellProps: {
      style: {
        padding: "0.5rem",
      },
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          color="lightblue"
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          onClick={handleExportData}
          leftIcon={<IconDownload />}
          variant="filled"
          loading={isExportLoading}
        >
          Export All Data
        </Button>
      </Box>
    ),
  });

  return (
    <div>
      <MantineReactTable table={table} />
    </div>
  );
};

export default Transactions;
