import { MantineTheme, createStyles } from "@mantine/core";

export const useStyle = createStyles((theme: MantineTheme) => ({
  buttonWrapper: {
    display: "flex",
    marginTop: "20px",
    align: "right",
  },
  input:{
    marginTop: "2px",
    paddingLeft: "14px",
    paddingRight: "26px",
    height: "45px",
    width: "360px",
    marginBottom: "25px",
    border: "0.0625rem solid #ced4da;",
    borderRadius: "0.25rem",
    fontSize: '1rem',
    '&:focus': {
      outline: "none",
      borderColor: "#228be6", // Change border color on focus
    },
  }
}));
