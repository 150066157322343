import { useQuery } from "react-query";
import getNoteSales from "../services/getNoteSales";

function useGetNoteSales(
  token: string,
  noteId: string,
  globalFilter: string,
  pagination: any,
  sorting: any[]
) {
  return useQuery(
    ["get-note-sales", noteId, token, globalFilter, pagination, sorting],
    () => getNoteSales({ noteId, token, globalFilter, pagination, sorting }),
    {
      retry: false,
    }
  );
}

export default useGetNoteSales;
