import {
  AcknowledgementLayout,
  LoginLayout,
  PasswordReset,
  PasswordResetMsg,
} from "@funded-here-interface/common";
import "./App.css";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import StructuredNotePage from "./screens/StructuredNote/StructuredNote";
import OrgDetailsPage from "./screens/OrgDetails/OrgDetailsPage";
import Issusingspv from "./screens/IssusingSPV.tsx/Issusingspv";
import Dashboard from "./screens/PMDashboard/DashBoard";
import CampaignList from "./screens/CampaignList/CampaignList";
import { QueryClientProvider } from "react-query";
import CreateCampaign from "./screens/CreateCampaign/CreateCampaign";
import Login from "./screens/Login/Login";
import { store, persistor } from "./store";
import { Provider } from "react-redux";
import Notelist from "./screens/Notelist/Notelist";
import PmVerifySkuLoans from "./screens/PmVerifySkuLoans/PmVerifySkuLoans";
import OperationAndRiskLayout from "./layouts/OperationAndRisk/OperationAndRiskLayout";
import ProductLayout from "./layouts/ProductLayout/ProductLayout";
import IndividualList from "./screens/IndividualList/IndividualList";
import InvestorSummary from "./screens/PMDashboard/Components/Investor/InvestorSummary";
import Merchant from "./screens/PMDashboard/Components/MerchantSummary/Merchant";
import Skusummary from "./screens/PMDashboard/Components/SKUSummary/Skusummary";
import VirtualAccounts from "./screens/VirtualAccounts/VirtualAccounts";
import LoanDetails from "./screens/LoanDetails/LoanDetails";
import PmVerifySkuLoanDetails from "./screens/PmVerifySkuLoanDetails/PmVerifySkuLoanDetails";
import Repayment from "./screens/PMRepayment/Repayment";
import IssueNote from "./screens/IssueSNOverview/IssueNote";
import SkuLoanApplication from "./screens/SkuLoanApplication/SkuLoanApplication";
import SalesSupportLayout from "./layouts/SalesSupportLayout/SalesSupportLayout";
import { client } from "./reactQuery";

const PrivateRoute = ({ children }: any) => {
  const token = store.getState().auth.token;

  return !token || token === "none" ? <Navigate to={"/login"} /> : children;
};

function App() {
  return (
    <QueryClientProvider client={client}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <Routes>
              <Route path="*" Component={() => <Navigate to={"/login"} />} />
              <Route
                path="/login"
                element={<LoginLayout content={<Login />} />}
              />
              <Route
                path="/password-reset"
                element={<AcknowledgementLayout content={<PasswordReset />} />}
              />
              <Route
                path="/reset-message"
                element={
                  <AcknowledgementLayout content={<PasswordResetMsg />} />
                }
              />
              <Route
                path="/pm-dashboard/:subpath"
                element={
                  <PrivateRoute>
                    <ProductLayout content={<Dashboard />} />
                  </PrivateRoute>
                }
              />
              <Route
                path="/investorsummary"
                element={
                  <PrivateRoute>
                    <ProductLayout content={<InvestorSummary />} />
                  </PrivateRoute>
                }
              />
              <Route
                path="/merchant"
                element={
                  <PrivateRoute>
                    <ProductLayout content={<Merchant />} />
                  </PrivateRoute>
                }
              />
              <Route
                path="/snusummary"
                element={
                  <PrivateRoute>
                    <ProductLayout content={<Skusummary />} />
                  </PrivateRoute>
                }
              />
              <Route
                path="/repayment/:id"
                element={
                  <PrivateRoute>
                    <ProductLayout content={<Repayment />} />
                  </PrivateRoute>
                }
              />
              <Route
                path="/note-list"
                element={
                  <PrivateRoute>
                    <ProductLayout content={<Notelist />} />
                  </PrivateRoute>
                }
              />
              <Route
                path="/create-campaign"
                element={
                  <PrivateRoute>
                    <ProductLayout content={<CreateCampaign />} />
                  </PrivateRoute>
                }
              />
              <Route
                path="/org-list"
                element={
                  <PrivateRoute>
                    <OperationAndRiskLayout content={<OrgDetailsPage />} />
                  </PrivateRoute>
                }
              />
              <Route
                path="/individual-list"
                element={
                  <PrivateRoute>
                    <OperationAndRiskLayout content={<IndividualList />} />
                  </PrivateRoute>
                }
              />
              <Route
                path="/campaign-list"
                element={
                  <PrivateRoute>
                    <ProductLayout content={<CampaignList />} />
                  </PrivateRoute>
                }
              />
              <Route
                path="/loan-details"
                element={
                  <PrivateRoute>
                    <ProductLayout content={<LoanDetails />} />
                  </PrivateRoute>
                }
              />
              <Route
                path="/verify-sku-loans"
                element={
                  <PrivateRoute>
                    <ProductLayout content={<PmVerifySkuLoans />} />
                  </PrivateRoute>
                }
              />
              <Route
                path="/verify-sku-loan-details"
                element={
                  <PrivateRoute>
                    <ProductLayout content={<PmVerifySkuLoanDetails />} />
                  </PrivateRoute>
                }
              />
              <Route
                path="/issue-note/:id"
                element={
                  <PrivateRoute>
                    <ProductLayout content={<IssueNote />} />
                  </PrivateRoute>
                }
              />
              <Route
                path="/issuisng-spv"
                element={
                  <PrivateRoute>
                    <ProductLayout content={<Issusingspv />} />
                  </PrivateRoute>
                }
              />
              <Route
                path="/structured-note/:tabValue"
                element={
                  <PrivateRoute>
                    <ProductLayout content={<StructuredNotePage />} />
                  </PrivateRoute>
                }
              />
              <Route
                path="/virtual-accounts"
                element={
                  <PrivateRoute>
                    <ProductLayout content={<VirtualAccounts />} />
                  </PrivateRoute>
                }
              />
              <Route
                path="/sales-support/:tabValue"
                element={
                  <PrivateRoute>
                    <SalesSupportLayout content={<SkuLoanApplication />} />
                  </PrivateRoute>
                }
              />
            </Routes>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  );
}
export default App;
