import axios from "axios";
import { BASE_URL } from "@funded-here-interface/common/src/constant/constant";
import { getApiResponseErrorMsg } from "@funded-here-interface/common/src/Utils/apiHelper";

export interface InternalTransferType {
  token: string;
  senderVirtualAccountPrimaryId: number;
  receiverVirtualAccountPrimaryId: number;
  currency: string;
  amount: string;
  remarks: string;
}

const internalFundsTransfer = async ({
  token,
  senderVirtualAccountPrimaryId,
  receiverVirtualAccountPrimaryId,
  currency,
  amount,
  remarks,
}: InternalTransferType) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/payment/ledger/internal-funds-transfer`,
      {
        senderVirtualAccountPrimaryId,
        receiverVirtualAccountPrimaryId,
        currency,
        amount,
        remarks,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      }
    );

    return response.data.status
 
  } catch (error) {
    const message = getApiResponseErrorMsg(error);
    
    throw new Error(message);
  }
};

export default internalFundsTransfer;