import { MDBDataTable } from "mdbreact";
import React from "react";
function DataTable({data}) {

  return (
    <div className="Data_Table">
      <MDBDataTable bordered hover data={data} noBottomColumns={true} small stripes selectRows={true} className="text-center"/>
    </div>
  );
}

export default DataTable;

