import { FC, ReactNode } from "react";
import { useStyles } from "./SalesSupportLayout.styles";
import { ToastContainer } from "react-toastify";
import { IconHome, IconPhotoDollar, IconSignature } from "@tabler/icons-react";
import NavBar from "../../components/NavBar/NavBar";

interface IProps {
  content: ReactNode;
}

const SalesSupportLayout: FC<IProps> = ({ content }) => {
  const { classes } = useStyles();

  const menus = [
    {
      label: "SKU Loan",
      path: "/sales-support/new",
      icon: <IconPhotoDollar />,
    },
  ];

  return (
    <div className={classes.mainContainer}>
      <NavBar menus={menus} />
      <ToastContainer />
      <div style={{ paddingBottom: "30px" }} className={classes.hero}>
        {content}
      </div>
    </div>
  );
};

export default SalesSupportLayout;
