import { InputGroupDisable } from "@funded-here-interface/common";
import { FC } from "react";
import { Data, Spv, UserData } from "../../../types/org.type";
import { useStyles } from "./Organisation.styles";
import { capitalizeFirstLetter } from "@funded-here-interface/common/src/Utils/string";
import { getName } from "country-list";
import { Persona } from "@funded-here-interface/common/src/constant/enum";
import { convertDate } from "@funded-here-interface/common/src/Utils/date";
import { Divider } from "@mantine/core";

interface OrganisationProps {
  data: Data | undefined;
  userData: UserData | undefined;
  role: Persona;
  spvData?: Spv;
}

const Organisation: FC<OrganisationProps> = ({
  data,
  userData,
  role,
  spvData,
}) => {
  const { classes } = useStyles();

  let onboardedAs =
    capitalizeFirstLetter(userData?.orgRole?.[0]?.role?.name) || "-";
  const formattedDate = data?.createdDate ? convertDate(data.createdDate) : "-";
  const companyName = data?.companyName || "-";
  const registrationNo = data?.companyRegistrationNo || "-";
  const legalStructure = data?.legalStructure || "-";
  const countryOfIncorp = data?.countryOfIncorporation || "-";
  const countryOfDomicile = getName(data?.countryISOCode || "") || "-";
  const address = data?.legalEntityAddress || "-";
  const postal = data?.postalCode || "-";
  const website = data?.website || "-";
  const industry = data?.industry || "-";
  const incorporationDate = data?.companyStartDate
    ? convertDate(data.companyStartDate)
    : "-";
  const investmentMode =
    capitalizeFirstLetter(data?.investmentPreferences?.investmentMode) || "-";
  const investmentAmount = data?.investmentPreferences?.investmentSize || "-";

  if (userData?.investorCategory?.category) {
    onboardedAs = `${capitalizeFirstLetter(
      userData?.investorCategory?.category
    )} ${onboardedAs}`;
  }

  return (
    <>
      <div className={classes.valueContainer}>
        <InputGroupDisable
          labelText="Onboarded as "
          id="Onboarded as "
          value={onboardedAs}
        />
        <InputGroupDisable
          labelText="Date onboarded "
          id="Date onboarded "
          value={formattedDate}
        />
        <InputGroupDisable
          labelText="Company Name"
          id="Company Name"
          value={companyName}
        />
        <InputGroupDisable
          labelText="Registration Number"
          id="Registration Number"
          value={registrationNo}
        />
        <InputGroupDisable
          labelText="Legal Structure"
          id="Legal Structure"
          value={legalStructure}
        />
        <InputGroupDisable
          labelText="Country of Incorporation"
          id="country_of_incorportation"
          value={countryOfIncorp}
        />
        <InputGroupDisable
          labelText="Country of Domicile"
          id="country_of_domicile"
          value={countryOfDomicile}
        />
        <InputGroupDisable labelText="Address" id="Address" value={address} />
        <InputGroupDisable
          labelText="Postal Code"
          id="Postal Code"
          value={postal}
        />
        <InputGroupDisable
          labelText="Company website/Online Marketplace Link"
          id="Company website/Online Marketplace Link"
          value={website}
        />
        <InputGroupDisable
          labelText="Industry"
          id="Industry"
          value={industry}
        />
        <InputGroupDisable
          labelText="Date of incorporation"
          id="startdate"
          value={incorporationDate}
        />
        {role === Persona.INVESTOR && (
          <div>
            <InputGroupDisable
              labelText="Investment Mode"
              id="investment_mode"
              value={investmentMode}
            />
          </div>
        )}
        <InputGroupDisable
          labelText="Preferred Investment Amount (SGD) "
          id="investment_amount"
          value={investmentAmount}
        />
      </div>
      {spvData && (
        <>
          <Divider my="md" />
          <h2 style={{ marginLeft: 44, marginTop: 44, fontWeight: 700 }}>
            SPV
          </h2>
          <div className={classes.valueContainer}>
            <InputGroupDisable
              labelText="Date onboarded "
              id="dateOnboarded"
              value={spvData?.createdAt ? convertDate(spvData.createdAt) : "-"}
            />
            <InputGroupDisable
              labelText="Company Name"
              id="companyName"
              value={spvData?.companyName}
            />
            <InputGroupDisable
              labelText="Registration Number"
              id="registrationNum"
              value={spvData?.companyRegistrationNo}
            />
            <InputGroupDisable
              labelText="Legal Structure"
              id="legalStructure"
              value={spvData?.legalStructure}
            />
            <InputGroupDisable
              labelText="Country of Incorporation"
              id="countryOfIncorp"
              value={spvData?.countryOfIncorporation}
            />
            <InputGroupDisable
              labelText="Country of Domicile"
              id="countryOfDomicile"
              value={getName(spvData?.countryISOCode || "") || "-"}
            />
            <InputGroupDisable
              labelText="Address"
              id="address"
              value={spvData?.legalEntityAddress}
            />
            <InputGroupDisable
              labelText="Postal Code"
              id="postalCode"
              value={spvData?.postalCode}
            />
            <InputGroupDisable
              labelText="Company website/Online Marketplace Link"
              id="website"
              value={spvData?.website}
            />
            <InputGroupDisable
              labelText="Industry"
              id="industry"
              value={spvData?.industry}
            />
          </div>
        </>
      )}
    </>
  );
};

export default Organisation;
