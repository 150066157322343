import { BASE_URL } from "@funded-here-interface/common/src/constant/constant";
import axios from "axios";
import { getApiResponseErrorMsg } from "@funded-here-interface/common/src/Utils/apiHelper";

interface GetDocumentRequest {
  token: string;
  invoiceId: string;
}

const downloadInvoice = async ({ token, invoiceId }: GetDocumentRequest) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/sku-finance/loan-disbursement/download-sku-invoice-pm-verification/${invoiceId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob",
        withCredentials: true,
      }
    );

    return response.data;
  } catch (e) {
    const message = getApiResponseErrorMsg(e);

    throw new Error(message);
  }
};

export default downloadInvoice;
