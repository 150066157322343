import { FC, useMemo } from "react";
import { InvestorDataItem } from "../../../../services/getCurrentSn";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import useGetDocument from "../../../../hooks/useGetDocument";
import { downloadFile } from "@funded-here-interface/common/src/Utils/file";
import { FileType } from "@funded-here-interface/common/src/constant/enum";
import { toast } from "react-toastify";
import {
  MRT_ColumnDef,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import { useStyle } from "./Investor.styles";
import { formatNumbersWithThousandSeperators } from "@funded-here-interface/common/src/Utils/formatter";

interface IProps {
  isLoading: boolean;
  data: InvestorDataItem[] | undefined;
}

const Investor: FC<IProps> = ({ isLoading, data }) => {
  const token = useSelector((state: RootState) => state.auth.token);
  const useGetDocumentMutaiton = useGetDocument();
  const { classes } = useStyle();

  const downloadDocument = async (path: string) => {
    useGetDocumentMutaiton.mutate(
      { token, path },
      {
        onSuccess: (data: Blob) => {
          downloadFile(data, "Loan Agreement", FileType.PDF);
        },
        onError: (e) => {
          toast.error((e as Error).message);
        },
      }
    );
  };

  const columns = useMemo<MRT_ColumnDef<InvestorDataItem>[]>(
    () => [
      {
        accessorKey: "name",
        header: "Investor",
      },
      {
        accessorKey: "type",
        header: "Type",
      },
      {
        accessorKey: "investorDeposit",
        header: "Investment Amount",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? formatNumbersWithThousandSeperators(
                  parseFloat(cell.getValue<string>())
                )
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "intRate",
        header: "Int Rate",
      },
      {
        accessorKey: "agreement",
        header: "Subscription Agreement",
        enableSorting: false,
        Cell: ({ cell }) => {
          const agreementPath = cell.getValue<string>();

          return agreementPath ? (
            <p
              className={classes.view}
              onClick={() => downloadDocument(agreementPath)}
            >
              Download
            </p>
          ) : (
            <p>-</p>
          );
        },
      },
    ],
    []
  );

  const table = useMantineReactTable({
    enableColumnActions: false,
    enableColumnFilters: false,
    state: { isLoading },
    columns,
    paginationDisplayMode: "pages",
    data: data ?? [],
    initialState: { density: "xs" },
    mantineTableProps: {
      withBorder: true,
    },
  });

  return <MantineReactTable table={table} />;
};

export default Investor;
