import axios from "axios";
import { BASE_URL } from "@funded-here-interface/common/src/constant/constant";
import { getApiResponseErrorMsg } from "@funded-here-interface/common/src/Utils/apiHelper";

export interface GetLoanForVerificationResponse {
  [key: string]: InvoiceGroup;
}

export interface InvoiceGroup {
  [key: string]: DataRow[];
}

export interface DataRow {
  skuId: string;
  upc: string;
  orgName: string;
  poNumber: string;
  poDate: string;
  deliveryDate: string;
  purchasePricePerUnit: string;
  loanApplicationCurrency: string;
  sellingPricePerUnit: string;
  quantityPurchased: number;
  discount: string;
  otherCosts: string;
  tax: string;
  nettInvoiceAmount: string;
  nettInvoiceAmountOtherCurrency: string;
  fxRateOtherCurrency: string;
  otherCurrency: string;
  expectedPaymentDate: string;
  paymentTerm: string;
  partialPaymentFirstPaymentPercentage: string | null;
  skuReturnPercentage: string;
  skuPo: {
    id: number;
    orgRoleId: number;
    s3FilePath: string;
  };
}

interface GetLoanForVerificationRequest {
  token: string;
}

const getLoanForVerification = async ({
  token,
}: GetLoanForVerificationRequest): Promise<GetLoanForVerificationResponse> => {
  try {
    const response = await axios.get(
      `${BASE_URL}/sku-finance/loan-application/get-sku-loan-applications-for-pm-verification`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      }
    );

    return response.data;
  } catch (error) {
    const message = getApiResponseErrorMsg(error);

    throw new Error(message);
  }
};

export default getLoanForVerification;
